import { showToast } from "@siemens/ix-react";
import { wait } from "@motius/customer-heartbeat-utils";
import { querySelectorAllShadows } from "../../utils";

const configureToast = () => {
  // The getToastContainer function is not giving the correct toast container
  const toastContainer = document.getElementById("toast-container");
  if (
    toastContainer &&
    !toastContainer.classList.contains("theme-brand-dark")
  ) {
    toastContainer.classList.add("theme-brand-dark");
    toastContainer?.setAttribute("popover", "true");
  }
  toastContainer?.hidePopover();
  toastContainer?.showPopover();
  toastContainer?.style.setProperty("visibility", "visible");
};

/**
 * Hides the close button of the toasts
 * Hide the toast temporarily for a dialog so that close button can be removed
 * The toast is still not available even the show toast promise has been resolved so a timeout is needed
 */
const hideCloseButton = async () => {
  const toastContainer = document.getElementById("toast-container");
  toastContainer?.style.setProperty("visibility", "hidden");
  await wait(50);
  querySelectorAllShadows(".toast-close").forEach((node) => {
    (node as HTMLElement).style.setProperty("display", "none");
  });
};

/**
 * Show a toast with a message
 * @param message - Message to display
 * @param type - Type of toast
 * @param hideAllClose - Hide all close buttons on active toasts
 */
const showToastWithConfiguration = async (
  message: string,
  type: "success" | "error",
  hideAllClose?: boolean,
) => {
  await showToast({ message, type });
  if (hideAllClose) {
    await hideCloseButton();
  }
  configureToast();
};

/**
 *  Notify user of a successful action
 * @param message - Message to display
 * @param hideAllClose - Hide all close buttons on active toasts
 */
export const success = async (message: string, hideAllClose?: boolean) => {
  await showToastWithConfiguration(message, "success", hideAllClose);
};

/**
 * Notify user of an error
 * @param message - Message to display
 * @param hideAllClose - Hide all close buttons on active toasts
 */
export const error = async (message: string, hideAllClose?: boolean) => {
  await showToastWithConfiguration(message, "error", hideAllClose);
};

export const notify = { success, error };
