import React from "react";
import { Button, ButtonProps } from "./Button";
import { cn } from "../../utils";
import { IxIconButton } from "@siemens/ix-react";

/**
 * Icon button component
 *
 * @param props Props for the component
 * @param props.className Class name
 */
export const IconButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, ...props }, ref) => {
    return (
      <IxIconButton
        icon="close"
        {...props}
        // ref={ref}
        variant="secondary"
        className={cn(
          "flex h-8 w-8 items-center justify-center p-0 shrink-0 border-none bg-transparent hover:bg-secondary-button-hover",
          className,
        )}
      />
    );
  },
);
