import { Button, ButtonProps, notify } from "@motius/customer-heartbeat-ui";
import React from "react";

interface ShareButtonProps extends Omit<ButtonProps, "children"> {}

export const ShareButton: React.FC<ShareButtonProps> = (props) => {
  const handleCopy = async () => {
    const currentLink = window.location.href;
    const isEndingWithView = currentLink.endsWith("/view");
    const linkToCopy = isEndingWithView ? currentLink : `${currentLink}/view`;
    await navigator.clipboard.writeText(linkToCopy);
    notify.success("Link copied!");
  };
  return (
    <Button {...props} onClick={handleCopy}>
      Copy Link
    </Button>
  );
};
