import {
  Footer,
  LoginContent,
  PageWithTopBar,
  TopBarBase,
} from "@motius/customer-heartbeat-ui";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import debug from "debug";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  isDisableAuth,
  authRequest,
} from "@motius/customer-heartbeat-utils/auth";

const log = debug("Login");

export const Login = () => {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const state = useLocation().state as { from: string };

  useEffect(() => {
    if (isDisableAuth()) {
      log("auth is disabled. redirecting to journeys");
      navigate(state?.from ?? "/journeys");
    }
    if (isAuthenticated) {
      // there have been instances where the user is
      // authenicated but login still fails somehow
      // we want to make sure we can get a token
      // before redirecting
      log("silent token acquire", accounts);
      instance
        .acquireTokenSilent({
          ...authRequest,
          account: accounts[0],
        })
        .then(() => {
          log("user is already authenticated. redirecting to home");
          navigate(state?.from ?? "/journeys");
        });
    }
  }, [isAuthenticated, navigate, instance, accounts]);

  /**
   *  Handle the login button
   */
  const handleLogin = () => {
    instance
      .loginPopup()
      .then((response) => {
        log("Login successful", response);
        navigate(state?.from ?? "/journeys");
      })
      .catch((error) => {
        log("Login failed", error);
      });
  };

  return (
    <PageWithTopBar>
      <TopBarBase />
      <LoginContent
        onLogin={handleLogin}
        footer={<Footer className="absolute bottom-0 left-4" />}
      />
    </PageWithTopBar>
  );
};
