import React from "react";
import { useFormContext, useFormState } from "react-hook-form";
import { Spinner, Stepper } from "@motius/customer-heartbeat-ui";
import { getLabelById } from "@/utils/topology";
import { CustomerFormValues } from "@motius/customer-heartbeat-utils/schemas";
import { PrepareYourJourneyStep, openDialogOrStep } from "@/utils/router";
import { ProfileCard } from "../../ProfileCard";
import { useSearchParams } from "react-router-dom";
import Headline from "../components/Headline";

interface PrepareJourneySummaryStepProps {
  onClose: (isShowConfirmation?: boolean) => void;
}

/**
 * Summary step of the prepare journey dialog
 * @param props - component props
 * @param props.onClose - callback for close the dialog
 */
export const PrepareJourneySummaryStep: React.FC<
  PrepareJourneySummaryStepProps
> = ({ onClose }) => {
  const [, setSearchParams] = useSearchParams();
  const { isValid } = useFormState();
  const { getValues } = useFormContext<CustomerFormValues>();

  const vals = getValues();

  if (
    !vals?.motivationId ||
    !vals?.intent?.intentId ||
    !vals?.industryId ||
    !vals?.typeId ||
    !vals?.roleId
  ) {
    // the wizard has not been filled out yet but somehow we are on step 7
    // that means either the user clicked browser back or he tampered with the URL
    // lets ignore the case where the user tampers with the URL because
    // the app does not need to be able to handle that.
    // when the user clicks browser back we should take him to the journeys page
    // from there he can either open the dialog again or do something else
    onClose(false);
    return <Spinner />;
  }

  const convertedValues = {
    ...vals,
    motivation: getLabelById(vals?.motivationId),
    intent: getLabelById(vals?.intent?.intentId),
    industry: getLabelById(vals?.industryId),
    type: getLabelById(vals?.typeId),
    role: getLabelById(vals?.roleId),
  };

  /**
   *  Handle Back Button Click
   */
  const handleBack = () => {
    openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Seventh);
  };

  return (
    <div className="flex h-full flex-col items-center justify-between">
      <Headline text="Ready to go!" />
      <div className="overflow-visible">
        <ProfileCard
          className="w-[30rem] shadow-3xl"
          customer={convertedValues}
        />
      </div>
      <div className="mb-4 w-full">
        <Stepper
          onBackClick={handleBack}
          onNextClick={() => {}}
          nextButtonType="submit"
          isNextDisabled={!isValid}
          steps={7}
          activeStep={6}
        />
      </div>
    </div>
  );
};
