import { create } from "zustand";
import {
  TransitionType,
  JourneyOutcome,
  LocationInput,
} from "@/utils/apollo/resolvers";
import {
  FrontendChannel,
  FrontendTouchpoint,
  FrontendLocation,
  FrontendTouchpointTopology,
  FrontendLocationTopology,
} from "@/utils/types";
import { v4 } from "uuid";
import { getLastNode, getLastTouchpointId, getNodeById } from "./utils";
import { enablePatches, produce } from "immer";
import { redo, setWithUndo, undo } from "./undo";
import { updateCustomerIds, updateJourneyIds } from "./updateIds";
import { loadJourney } from "./loadJourney";
import { Actions, ChannelState } from "./types";
import { getLocationsById } from "@/utils/navigation";

export enum ExpectedNextSelection {
  Channel,
  Touchpoint,
  Location,
  SelectAction,
  Reaction,
}

enablePatches();

export const useChannelStore = create<ChannelState & Actions>()((set, get) => ({
  journeyId: undefined,
  journeyTopologyId: undefined,

  // metadata
  industry: null,
  customerRole: null,
  customerType: null,
  region: null,
  buyerType: null,
  priorContact: null,
  motivation: null,
  avatarRef: null,
  description: null,
  ownerName: null,
  lastSavedDate: null,
  outcome: null,
  painpoints: null,
  journeyType: null,
  experienceDrivers: [],
  isPublished: false,

  comments: [],

  isAnswerNeeded: false,

  // the actual journey
  channels: [],

  // state of the navigation
  touchpointsInCurrentChannel: [],
  availableLocations: [],
  currentTouchpointId: null,
  expectedNextSelection: ExpectedNextSelection.Channel,
  currentChannelId: null,
  currentTransition: null,
  lastAddedNodeTopologyId: null,

  undoStack: [],
  undoStackIndex: 0,

  touchpointRequests: [],
  locationRequests: [],
  channelRequests: [],

  reset: () => {
    set(
      produce((state: ChannelState) => {
        state.journeyId = null;

        // metadata
        state.industry = null;
        state.customerRole = null;
        state.customerType = null;
        state.motivation = null;
        state.avatarRef = null;
        state.description = null;
        state.ownerName = null;
        state.journeyType = null;
        state.lastSavedDate = null;
        state.comments = [];
        state.isPublished = false;

        // the actual journey
        state.channels = [];

        state.undoStack = [];
        state.undoStackIndex = 0;

        state.touchpointRequests = [];
        state.locationRequests = [];
        state.channelRequests = [];
      }),
    );
  },

  addOutcome: (outcome: JourneyOutcome) => {
    setWithUndo(get(), set, (state: ChannelState) => {
      state.outcome = outcome;
    });
  },

  removeOutcome: () => {
    setWithUndo(get(), set, (state: ChannelState) => {
      state.outcome = null;
    });
  },

  /**
   *  Add a reaction to the node
   * @param id the id of the node
   * @param reaction the reaction to add
   * @param reason the reason for the reaction
   */
  addReaction: (id: string, reaction: string, reason: string) => {
    setWithUndo(get(), set, (state: ChannelState) => {
      const node = getNodeById(id, state.channels);
      if (node == null) {
        throw new Error(
          "Reactions can only be added to Touchpoints and Locations on the visual journey",
        );
      }

      node.reaction = { name: reaction, reason };
    });
  },

  addAttachment: (name: string, imageId: string, nodeId: string) => {
    setWithUndo(get(), set, (state: ChannelState) => {
      const node = getNodeById(nodeId, state.channels) as
        | FrontendTouchpoint
        | FrontendLocation;
      if (node) {
        if (!node.attachments) {
          node.attachments = [];
        }

        // only add the attachment if it is not already present
        if (!node.attachments.find((attachment) => attachment.id === imageId)) {
          node.attachments.push({ name, id: imageId });
        }
      }
    });
  },

  removeAttachment: (imageId: string, nodeId: string) => {
    setWithUndo(get(), set, (state: ChannelState) => {
      const node = getNodeById(nodeId, state.channels) as
        | FrontendTouchpoint
        | FrontendLocation;
      if (node) {
        if (!node.attachments) {
          node.attachments = [];
        }

        // only add the attachment if it is not already present
        node.attachments = node.attachments.filter((att) => att.id !== imageId);
      }
    });
  },

  /**
   *  Remove the reaction from the node
   * @param id the id of the node
   */
  removeReaction: (id: string) => {
    setWithUndo(get(), set, (state: ChannelState) => {
      const node = getNodeById(id, state.channels);
      if (node == null) {
        throw new Error(
          "Reactions can only be removed from Touchpoints and Locations on the visual journey",
        );
      }

      node.reaction = undefined;
    });
  },

  /**
   * Add a title to the node
   * @param id the id of the node
   * @param title the title to add
   */
  addTitle: (id: string, title: string) => {
    setWithUndo(get(), set, (state: ChannelState) => {
      const node = getNodeById(id, state.channels);
      if (node == null) {
        throw new Error(
          "Titles can only be added to Touchpoints and Locations on the visual journey",
        );
      }

      node.title = title;
    });
  },

  /**
   *  Remove the title from the node
   * @param id the id of the node
   */
  removeTitle: (id: string) => {
    setWithUndo(get(), set, (state: ChannelState) => {
      const node = getNodeById(id, state.channels);
      if (node == null) {
        throw new Error(
          "Titles can only be removed from Touchpoints and Locations on the visual journey",
        );
      }

      node.title = undefined;
    });
  },

  getNodeById: (id: string) => {
    return getNodeById(id, get().channels);
  },

  addChannel: (
    channel: FrontendChannel,
    tpsInChannel: FrontendTouchpointTopology[] = [],
  ) => {
    setWithUndo(get(), set, (state: ChannelState) => {
      if (state.currentTransition != null) {
        channel.prevTransition = state.currentTransition;
      }

      if (tpsInChannel.length > 0) {
        state.touchpointsInCurrentChannel = tpsInChannel;
      }

      state.expectedNextSelection = ExpectedNextSelection.SelectAction;
      if ((state.touchpointsInCurrentChannel?.length ?? 0) === 0) {
        state.expectedNextSelection = ExpectedNextSelection.SelectAction;
      }

      state.channels.push(channel);
      state.currentChannelId = channel.fid;
      state.currentTransition = null;
    });
  },

  addTouchPoint: (touchpoint: FrontendTouchpoint) => {
    setWithUndo(get(), set, (state: ChannelState) => {
      if (state.currentTransition != null) {
        touchpoint.prevTransition = state.currentTransition;
      }
      const currentChannelIndex = state.channels.findIndex(
        (channel) => channel.fid === state.currentChannelId,
      );

      if (currentChannelIndex === -1) {
        throw new Error("Current channel not found");
      }

      state.channels[currentChannelIndex].touchpoints.push(touchpoint);

      state.currentTouchpointId = touchpoint.fid;
      state.availableLocations = getLocationsById(touchpoint.fid);
      state.expectedNextSelection = ExpectedNextSelection.SelectAction;

      state.lastAddedNodeTopologyId = touchpoint.topologyId;
      state.currentTransition = null;
    });
  },

  /**
   *  Add a location to the current touchpoint
   * @param location the location to add
   */
  addLocation: (location: FrontendLocationTopology, introFragment: string) => {
    const convertToLocationInput = (
      loc: FrontendLocationTopology,
    ): LocationInput => {
      const result = {
        ...structuredClone(loc),
        attachments: [],
        intro_fragment: introFragment,
      };
      if (result.question) {
        // replace the topology id with an id for the question
        result.question.id = v4();
      }
      result.locations = result.locations ?? [];

      // LocationTopology ids have tags
      // LocationInputs have attachments
      // Besides that, they are similar enough to be converted
      return result as unknown as LocationInput & { tags: string[] };
    };

    const loc: FrontendLocation = {
      ...convertToLocationInput(location),
      fid: v4(),
      topologyId: location.id ?? "",
      id: null,
    };

    setWithUndo(get(), set, (state: ChannelState) => {
      if (!loc.question) {
        state.expectedNextSelection = ExpectedNextSelection.SelectAction;
      } else {
        state.isAnswerNeeded = true;
      }

      if (state.currentTouchpointId == null) {
        throw new Error("Current touchpoint not found");
      }

      const currentChannelIndex = state.channels.findIndex(
        (channel) => channel.fid === state.currentChannelId,
      );

      if (currentChannelIndex === -1) {
        throw new Error("Current channel not found");
      }

      const currentTouchpointIndex = state.channels[
        currentChannelIndex
      ].touchpoints.findIndex(
        (touchpoint: FrontendTouchpoint) =>
          touchpoint.fid === state.currentTouchpointId,
      );

      if (currentTouchpointIndex === -1) {
        throw new Error(
          "Current touchpoint not found within the current channel",
        );
      }

      if (loc.topologyId) {
        state.lastAddedNodeTopologyId = loc!.topologyId;
      }
      state.channels[currentChannelIndex].touchpoints[
        currentTouchpointIndex
      ].locations.push(loc);
    });
  },

  /**
   * Remove the last node from the journey
   */
  removeLast: () => {
    setWithUndo(get(), set, (state: ChannelState) => {
      if (state.channels.length > 0) {
        const lastChannel = state.channels[state.channels.length - 1];
        if (
          lastChannel.touchpoints.length === 1 &&
          lastChannel.touchpoints[0].locations.length === 0
        ) {
          state.currentTransition = lastChannel.prevTransition ?? null;
          if (state.channels.length === 1) {
            state.expectedNextSelection = ExpectedNextSelection.Channel;
          }
          if (state.channels.length > 1) {
            const prevChannel = state.channels[state.channels.length - 2];
            state.currentChannelId = prevChannel.fid;
            if (prevChannel.touchpoints.length > 0) {
              prevChannel.touchpoints[
                prevChannel.touchpoints.length - 1
              ].nextTransition = null;
            } else {
              prevChannel.nextTransition = null;
            }
          }
          state.channels.pop();
          const newLastChannel = state.channels?.[state.channels.length - 1];
          state.currentTouchpointId = getLastTouchpointId(newLastChannel);
          return;
        }
        const lastTouchpoint =
          lastChannel.touchpoints[lastChannel.touchpoints.length - 1];
        if (lastTouchpoint.locations.length === 0) {
          state.currentTransition = lastTouchpoint.prevTransition ?? null;
          state.expectedNextSelection = ExpectedNextSelection.SelectAction;
          if (lastChannel.touchpoints.length > 1) {
            const prevTouchpoint =
              lastChannel.touchpoints[lastChannel.touchpoints.length - 2];
            prevTouchpoint.nextTransition = null;
            state.lastAddedNodeTopologyId = prevTouchpoint.topologyId;
          }
          lastChannel.touchpoints.pop();
          const newLastChannel = state.channels?.[state.channels.length - 1];
          state.currentTouchpointId = getLastTouchpointId(newLastChannel);
          return;
        }

        state.expectedNextSelection = ExpectedNextSelection.SelectAction;
        if (lastTouchpoint.locations.length > 1) {
          state.lastAddedNodeTopologyId =
            lastTouchpoint.locations[
              lastTouchpoint.locations.length - 1
            ].topologyId;
        }

        lastTouchpoint.locations.pop();
      }
    });
  },
  /**
   * @param text the answer text to set
   */
  addAnswer: (text: string) => {
    setWithUndo(get(), set, (state: ChannelState) => {
      const location = getLastNode(state.channels) as LocationInput;
      if (!location.question) return;
      location.question.answer = text;
      state.isAnswerNeeded = false;
      state.expectedNextSelection = ExpectedNextSelection.SelectAction;
    });
  },

  /**
   *  Set the expected next selection
   *  @param expectedNextSelection the expected next selection
   *  @param transition the transition to set
   */
  setExpectedNextSelection: (
    expectedNextSelection: ExpectedNextSelection,
    transition: TransitionType | null = null,
  ) =>
    setWithUndo(get(), set, (state: ChannelState) => {
      state.expectedNextSelection = expectedNextSelection;
      if (expectedNextSelection === ExpectedNextSelection.Reaction) {
        return;
      }
      const lastNode = getLastNode(state.channels);
      if (transition != null) {
        if (lastNode) {
          lastNode.nextTransition = transition;
        }
        state.currentTransition = transition;
      }
    }),

  /**
   *  Set the transition of the current touchpoint
   * @param transition the transition to set
   */
  setTransition: (transition: TransitionType) => {
    setWithUndo(get(), set, (state: ChannelState) => {
      const channel = state.channels[state.channels.length - 1];
      if (channel.touchpoints.length === 0) {
        return;
      }
      const tp = channel.touchpoints[channel.touchpoints.length - 1];
      let loc = null;
      if (tp.locations.length !== 0) {
        loc = tp.locations[tp.locations.length - 1];
      }
      if (loc) {
        loc.nextTransition = transition;
      } else {
        tp.nextTransition = transition;
      }

      state.currentTransition = transition;
    });
  },

  /**
   * Set the touchpoints in the current channel
   * @param tpTopos the touchpoints in the current channel
   */
  setTouchpointTopos: (tpTopos: FrontendTouchpointTopology[]) => {
    set(
      produce((state: ChannelState) => {
        state.touchpointsInCurrentChannel = tpTopos;
      }),
    );
  },

  undo: undo(set, get),
  redo: redo(set, get),
  loadJourney: loadJourney(set, produce),
  updateCustomerIds: updateCustomerIds(set, produce),
  updateJourneyIds: updateJourneyIds(set, produce),
  addTouchpointRequest: (request) => {
    console.log("Adding touchpoint request", request);
    set(
      produce((state: ChannelState) => {
        state.touchpointRequests.push(request);
      }),
    );
  },
  addLocationRequest: (request) => {
    set(
      produce((state: ChannelState) => {
        state.locationRequests.push(request);
      }),
    );
  },
  addChannelRequest: (request) => {
    set(
      produce((state: ChannelState) => {
        state.channelRequests.push(request);
      }),
    );
  },
  setTouchpointRequests: (requests) => {
    set(
      produce((state: ChannelState) => {
        state.touchpointRequests = requests;
      }),
    );
  },
  setLocationRequests: (requests) => {
    set(
      produce((state: ChannelState) => {
        state.locationRequests = requests;
      }),
    );
  },
  setChannelRequests: (requests) => {
    set(
      produce((state: ChannelState) => {
        state.channelRequests = requests;
      }),
    );
  },
}));
