import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  PrepareJourneyTopology,
  processPrepareJourneyTopoData,
} from "@/utils/topology";
import { GET_PREPARE_JOURNEY_TOPO } from "@/utils/apollo/queries";
import { usePrepareJourneyTopoStore } from "./usePrepareJourneyTopoStore";

export const usePrepareJourneyTopo = () => {
  const [getTopo] = useLazyQuery<{
    journeyTopology?: PrepareJourneyTopology;
  }>(GET_PREPARE_JOURNEY_TOPO, {
    variables: { id: import.meta.env.VITE_TOPOLOGY_ID },
  });

  const { prepareJourneyTopology, topologyId, savePrepareJourneyTopology } =
    usePrepareJourneyTopoStore();

  const getTopology = async () => {
    const topologyData = await getTopo();
    const topoData = topologyData?.data;
    if (!topoData?.journeyTopology) return;

    const sortedTopo = structuredClone(topoData.journeyTopology);

    sortedTopo.motivations.forEach((motivation) => {
      const sortIntents = (
        intents: PrepareJourneyTopology["motivations"][number]["intents"],
      ) => {
        intents.forEach((intent) => {
          if (Array.isArray(intent.intents)) {
            sortIntents(intent.intents);
            intent.intents.sort((a, b) => a.name.localeCompare(b.name));
          }
        });
        intents.sort((a, b) => a.name.localeCompare(b.name));
      };

      sortIntents(motivation.intents);
    });

    savePrepareJourneyTopology(import.meta.env.VITE_TOPOLOGY_ID, sortedTopo);
    processPrepareJourneyTopoData(sortedTopo);
  };

  useEffect(() => {
    if (
      prepareJourneyTopology &&
      topologyId === import.meta.env.VITE_TOPOLOGY_ID
    ) {
      processPrepareJourneyTopoData(prepareJourneyTopology);
    } else {
      getTopology();
    }
  }, [prepareJourneyTopology, getTopo]);

  return { topoData: { journeyTopology: prepareJourneyTopology } };
};
