import d3 from "d3";
import { AvatarType } from "@motius/customer-heartbeat-utils/schemas";
import { Maybe } from "@motius/customer-heartbeat-utils";
import { MapChannel } from "./Map";
import { AVATAR_WIDTH_HEIGHT, RECT_WIDTH } from "./constants";

const getAvatarPath = (avatarType: Maybe<string>) => {
  if (avatarType === AvatarType.First) {
    return "/Avatar1.svg";
  }
  if (avatarType === AvatarType.Second) {
    return "/Avatar2.svg";
  }
  if (avatarType === AvatarType.Third) {
    return "/Avatar3.svg";
  }
  if (avatarType === AvatarType.Fourth) {
    return "/Avatar4.svg";
  }
  if (avatarType === AvatarType.Fifth) {
    return "/Avatar5.svg";
  }
  if (avatarType === AvatarType.Sixth) {
    return "/Avatar6.svg";
  }
  if (avatarType === AvatarType.Seventh) {
    return "/Avatar7.svg";
  }
  if (avatarType === AvatarType.Eighth) {
    return "/Avatar8.svg";
  }
  return "/Avatar9.svg";
};

export const drawAvatar = (
  svg: d3.Selection<SVGSVGElement, MapChannel[], null, undefined>,
  x: number,
  y: number,
  avatarType: Maybe<AvatarType>,
) => {
  // Draw the line from the center of the avatar to RECT_WIDTH to the right
  svg
    .append("line")
    .attr("class", "avatar-to-first-tp")
    .style("stroke", "var(--line)")
    .style("stroke-width", 2)
    .attr("x1", x)
    .attr("y1", y)
    .attr("x2", x + RECT_WIDTH)
    .attr("y2", y);

  // Draw the avatar image
  svg
    .append("image")
    .attr("x", x - AVATAR_WIDTH_HEIGHT / 2)
    .attr("y", y - AVATAR_WIDTH_HEIGHT / 2)
    .attr("href", getAvatarPath(avatarType))
    .attr("width", AVATAR_WIDTH_HEIGHT)
    .attr("height", AVATAR_WIDTH_HEIGHT);
};
