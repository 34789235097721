import { cn } from "@motius/customer-heartbeat-ui/utils";
import { useChannelStore } from "../hooks/useChannelStore";
import { ProfileCard } from "./ProfileCard";
import { AvatarType } from "../../../../packages/utils/src/schemas/customer";
import { Navigation } from "./Navigation/Navigation";
import TextJourney from "./TextJourney/TextJourney";
import { openDialogOrStep } from "@/utils/router";
import { useSearchParams } from "react-router-dom";

const Wizard = () => {
  const {
    industry,
    customerType,
    customerRole,
    motivation,
    avatarRef,
    description,
    outcome,
    region,
  } = useChannelStore();
  const [, setSearchParams] = useSearchParams();

  const handleEditClick = () => {
    openDialogOrStep(setSearchParams, "edit");
  };

  /**
   * If the journey is finalised (ie has an "outcome") then we dont show the navigation
   * All width changes that are needed to hide the navigation are within this file
   */
  return (
    <div
      className={cn(
        "flex text-gray-900 transition-all duration-500 ease-in-out bg-white overflow-hidden shrink-0",
      )}
    >
      <aside
        className={cn(
          "bg-white flex-col transition-all duration-500 ease-in-out w-[21rem]",
          "overflow-hidden flex flex-col h-full px-2",
        )}
      >
        <div className="px-1 py-3">
          <ProfileCard
            onEdit={handleEditClick}
            customer={{
              role: customerRole?.name ?? "",
              industry: industry?.name ?? "",
              motivation: motivation?.name ?? "",
              intent: motivation?.intent?.name ?? "",
              words: description ?? "",
              type: customerType?.name ?? "",
              avatar: avatarRef as AvatarType,
              region: region?.name ?? "",
            }}
            fullWidth
            className="h-auto"
          />
        </div>
        <TextJourney />
      </aside>
      <div
        className={cn(
          "theme-brand-dark bg-channel-dark flex-1 text-left justify-end flex flex-grow w-[18.25rem]",
          "transition-all duration-500 ease-in-out",
          { "w-0 p-0": outcome },
        )}
      >
        <div
          className={cn(
            "my-3 rounded-[4px] max-w-[17.25rem]",
            "border-theme-color-weak-bdr border-[1px]",
            "text-white flex w-full grow bg-chb-menu-background",
          )}
        >
          {!outcome && <Navigation />}
        </div>
      </div>
    </div>
  );
};

export default Wizard;
