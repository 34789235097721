import React from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { Select, Spinner, Stepper } from "@motius/customer-heartbeat-ui";
import { AvatarInput } from "../../Avatar/AvatarInput.tsx";
import { CustomerRoleTopology } from "@/utils/apollo/resolvers";
import { PrepareYourJourneyStep, openDialogOrStep } from "@/utils/router";
import { useSearchParams } from "react-router-dom";
import Headline from "../components/Headline.tsx";

interface PrepareJourneyAvatarStepProps {
  roles: CustomerRoleTopology[] | undefined;
}

/**
 * Avatar Step for Prepare Your Journey Dialog
 * @param props Props
 * @param props.onClose Callback for close the dialog
 * @param props.roles Customer Roles
 */
export const PrepareJourneyAvatarStep: React.FC<
  PrepareJourneyAvatarStepProps
> = ({ roles }) => {
  const { isValid } = useFormState();
  const [, setSearchParams] = useSearchParams();
  const { trigger, watch } = useFormContext();

  /**
   *  Handle Back Button Click
   */
  const handleBack = () => {
    openDialogOrStep(setSearchParams, PrepareYourJourneyStep.First);
  };

  /**
   *  Handle Next Button Click
   */
  const handleNext = async () => {
    const result = await trigger();
    if (result) {
      openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Third);
    }
  };

  if (!roles) return <Spinner />;

  return (
    <div className="flex h-full grow flex-col items-center justify-between">
      <Headline text="Imagine you are a..." />

      <div className="flex w-[30rem] flex-col justify-start gap-3 self-center">
        <Controller
          name="roleId"
          render={({ field }) => (
            <Select
              label="Customer Role"
              autoFocus
              allowClear={false}
              id="pp_new"
              sortAlphabetically
              value={field.value}
              onChange={field.onChange}
              options={roles.map(({ id, name: label }) => ({
                value: id,
                label,
              }))}
              wrapperClassName="inline-block text-left"
            />
          )}
        />

        <Controller
          name="avatar"
          render={({ field }) => (
            <AvatarInput
              onChange={field.onChange}
              label="Avatar"
              value={field.value}
              disabled={!watch("roleId")}
            />
          )}
        />
      </div>
      <div className="mb-4 w-full">
        <Stepper
          onBackClick={handleBack}
          onNextClick={handleNext}
          isNextDisabled={!isValid}
          steps={7}
          activeStep={1}
        />
      </div>
    </div>
  );
};
