import React from "react";
import { getIconByLabel } from "../components/MotivationIcons.ts";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { MotivationTopology } from "@motius/customer-heartbeat-utils/schemas";
import { Spinner, Stepper } from "@motius/customer-heartbeat-ui";
import { PrepareYourJourneyStep, openDialogOrStep } from "@/utils/router";
import { useSearchParams } from "react-router-dom";
import Headline from "../components/Headline.tsx";
import { IxActionCard } from "@siemens/ix-react";

interface PrepareJourneyCustomerMotivationStepProps {
  motivations: MotivationTopology[] | undefined;
}

/**
 * Customer Motivation Step for Prepare Your Journey Dialog
 * @param props Props
 * @param props.motivations Motivations
 */
export const PrepareJourneyCustomerMotivationStep: React.FC<
  PrepareJourneyCustomerMotivationStepProps
> = ({ motivations }) => {
  const [, setSearchParams] = useSearchParams();

  const { isValid } = useFormState();
  const { trigger } = useFormContext();

  /**
   *  Handle Back Button Click
   */
  const handleBack = () => {
    openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Fourth);
  };

  /**
   *  Handle Next Button Click
   */
  const handleNext = async () => {
    if (await trigger()) {
      openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Sixth);
    }
  };

  if (!motivations) return <Spinner />;

  return (
    <div className="flex-column flex h-full items-center justify-between">
      <Headline text="What is your motivation?" />

      <div className="my-[1.8125rem] h-auto w-full shrink grow overflow-auto">
        <Controller
          name="motivationId"
          render={({ field }) => (
            <div className="grid w-full grid-cols-4 gap-2">
              {motivations.map(({ id, name }) => (
                <IxActionCard
                  key={id}
                  className="m-0 h-[6.9rem] min-h-0 w-auto min-w-0 !max-w-[196px]"
                  heading={name}
                  icon={getIconByLabel(name)}
                  variant="notification"
                  selected={field.value === id}
                  onClick={() => field.onChange(id)}
                />
              ))}
            </div>
          )}
        />
      </div>
      <div className="mb-4 w-full">
        <Stepper
          onBackClick={handleBack}
          onNextClick={handleNext}
          isNextDisabled={!isValid}
          steps={7}
          activeStep={4}
        />
      </div>
    </div>
  );
};
