import React from "react";
import { IxChip, IxIcon } from "@siemens/ix-react";
import useJourneyTopology from "../Navigation/useJourneyTopology";
import {
  getFilters,
  removeAuthorFilter,
  removeAuthorNameFilter,
} from "./filterUtils";
import { selectConfigs } from "./MetadataSelects";
import { usePrepareJourneyTopo } from "../PrepareYourJourney/usePrepareJourneyTopo";
import { useYourInsightsTopology } from "@/src/hooks/useYourInsigtsTopology";
import { PrepareJourneyTopology, YourInsightsTopology } from "@/utils/topology";
import { Topology } from "@motius/customer-heartbeat-utils/schemas";
import { AuthorFilter, FrontendJourneyFilter } from "./Search";
import { parseName } from "./nameUtils";
import { JourneyTypeOptions } from "../CompleteDetails/steps/CompleteDetailsJourneyTypeStep";

/**
 * Represents a combination of YourInsightsTopology, PrepareJourneyTopology, and intents
 */
export type PrepareAndInsightsTopo = Partial<YourInsightsTopology> &
  Partial<PrepareJourneyTopology> & { intents: Topology[] };

interface FilterChipsProps {
  filters: FrontendJourneyFilter;
  setFilters: (filters: FrontendJourneyFilter) => void;
}

export const FilterChips: React.FC<FilterChipsProps> = ({
  filters,
  setFilters,
}) => {
  const { channels, touchpoints, locations } = useJourneyTopology();
  const { topoData: prepareTopo } = usePrepareJourneyTopo();
  const { topology: insightsTopo } = useYourInsightsTopology(
    undefined,
    import.meta.env.VITE_TOPOLOGY_ID,
  );

  /**
   * Combines the prepare and insights topologies and flattens the intents
   * (previously they were in motivations)
   */
  const allTopos: PrepareAndInsightsTopo = {
    ...(prepareTopo?.journeyTopology || {}),
    ...(insightsTopo || {}),
    intents:
      insightsTopo?.motivations?.flatMap((motivation) => motivation.intents) ??
      [],
  };

  /**
   * Remove a filter from the filters object
   * @param key - The key of the filter to remove
   * @param id - The id of the filter to remove
   */
  const handleRemoveFilter = (key: keyof FrontendJourneyFilter, id: string) => {
    const updatedFilters = {
      ...filters,
      OR: filters.OR?.filter(
        (filter: FrontendJourneyFilter) => filter[key] !== id,
      ),
    };
    setFilters(updatedFilters);
  };

  /**
   * Handles the removal of an author filter by its gid.
   * @param gid - The gid of the author filter to remove.
   */
  const handleRemoveAuthorFilter = (gid: string) => {
    const updatedFilters = removeAuthorFilter(gid, filters);
    setFilters(updatedFilters);
  };

  const handleRemoveAuthorNameFilter = (name: string) => {
    const updatedFilters = removeAuthorNameFilter(name, filters);
    setFilters(updatedFilters);
  };

  /**
   * Renders filter chips for a given topology type
   * @param key - The filter key
   * @param topologies - Array of topology items
   * @param label - Label for the chip
   * @returns Horizontal list of IxChip with filter type and name
   */
  const renderChips = (
    key: keyof FrontendJourneyFilter,
    topologies: any[],
    label: string,
  ) => {
    return getFilters(key, filters).map((id) => {
      const item = topologies.find((t) => t.id === id);
      let chipLabel = item?.name;
      // If the item is a JourneyTypeBadge, use the label from the JourneyTypeOptions
      if (!chipLabel) {
        chipLabel =
          JourneyTypeOptions.find((option) => option.value === id)?.label || id;
      }

      return (
        <IxChip
          background="var(--theme-color-2)"
          variant="custom"
          closable
          key={id}
          onCloseChip={() =>
            handleRemoveFilter(key as keyof FrontendJourneyFilter, id)
          }
        >
          <strong>{label}:</strong> {chipLabel}
        </IxChip>
      );
    });
  };

  /**
   * Renders author chips for the filters
   * @param authorFilters - The author filters to render
   * @returns Horizontal list of IxChip with Author name
   */
  const renderAuthorChips = (authorFilters: AuthorFilter[]) => {
    return authorFilters?.map(({ gid, name }) => {
      const { firstName, lastName } = parseName(name);
      return (
        <IxChip
          background="var(--theme-color-2)"
          variant="custom"
          closable
          key={gid}
          onCloseChip={() => handleRemoveAuthorFilter(gid)}
        >
          <div className="flex items-center">
            <IxIcon name="search" />
            <strong className="ml-1">Author:&nbsp;</strong>
            {firstName} {lastName}
          </div>
        </IxChip>
      );
    });
  };

  const renderAuthorNameChips = (authorNameFilters: string[]) => {
    return authorNameFilters?.map((name) => (
      <IxChip
        background="var(--theme-color-2)"
        variant="custom"
        closable
        key={`${name}--name`}
        onCloseChip={() => handleRemoveAuthorNameFilter(name)}
      >
        <div className="flex items-center">
          <IxIcon name="search" />
          <strong className="ml-1">Author:&nbsp;</strong>
          {name}
        </div>
      </IxChip>
    ));
  };

  return (
    <div className="flex flex-wrap gap-2">
      {renderChips("channelTopologyId", channels, "Channel")}
      {renderChips("touchpointTopologyId", touchpoints, "Touchpoint")}
      {renderChips("locationTopologyId", locations, "Interaction")}
      {filters.authorGidFilters && renderAuthorChips(filters.authorGidFilters)}
      {filters.authorNameFilters &&
        renderAuthorNameChips(filters.authorNameFilters)}
      {selectConfigs.map(({ topoKey: key, dropdownLabel, filterKey }) => {
        return renderChips(
          filterKey,
          allTopos[key as keyof PrepareAndInsightsTopo] ?? [],
          dropdownLabel,
        );
      })}
    </div>
  );
};
