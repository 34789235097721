import React, { ReactNode, useState } from "react";
import { Avatar } from "../Avatar";
import { format } from "date-fns";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { Maybe } from "@motius/customer-heartbeat-utils";
import { AvatarType } from "@motius/customer-heartbeat-utils/schemas";
import { IxIcon, IxTypography } from "@siemens/ix-react";
import { getOwnerNameAndDepartment } from "../../../utils";
import { JourneyType } from "@/utils/apollo/resolvers";
import { JourneyTypePill } from "./JourneyTypePill";
import { ImageWithFallback } from "@motius/customer-heartbeat-ui";
import { FavoriteButton } from "./FavoriteButton";

/**
 *  Customer Information to Be Shown by Journey Card
 */
type CustomerLabels = {
  role: string;
  avatar: AvatarType;
  type: string;
  industry: string;
  words?: string;
  motivation: string;
  intent: string;
  region?: string;
  ownerName?: Maybe<string>;
};

/**
 *  JourneyCardProps
 */
interface JourneyCardProps {
  className?: string;
  customer: CustomerLabels;
  actions?: ReactNode;
  purple?: boolean;
  fullWidth?: boolean;
  collapsible?: boolean;
  date?: Maybe<string>;
  autoHeight?: boolean;
  commentCount?: number;
  journeyType?: Maybe<JourneyType>;
  viewCount?: number;
  favoritedByCurrentUser?: boolean;
  showFavorite?: boolean;
  journeyId: string;
  onUnfavorite?: () => void;
}

/**
 *  Journey Card to show customer information
 *
 * @param props Props
 * @param props.showMotivationAndIntent Whether to show motivation and intent in the journey card
 * @param props.className Additional css classes
 * @param props.onEdit Callback to call on edit
 * @param props.customer Customer information
 * @param props.actions Actions
 * @param props.purple Alternative color scheme
 * @param props.fullWidth Whether it uses the whole width
 * @param props.collapsible Whether the card can be shrinked into compact size
 * @param props.moreActions Actions to show as a dropdown menu
 * @param props.date Date of the journey card
 * @param props.autoHeight Whether journey card should resize
 * @param props.commentCount Number of comments
 * @param props.viewCount Number of views
 * @param props.journeyType Journey Type
 * @param props.favoritedByCurrentUser Whether the journey is favorited by the current user
 * @param props.showFavorite Whether to show favorite button
 * @param props.journeyId Journey ID
 * @param props.onUnfavorite Callback to call on unfavorite
 */
export const JourneyCard: React.FC<JourneyCardProps> = ({
  className,
  customer,
  actions,
  purple,
  date,
  commentCount,
  journeyType,
  viewCount,
  favoritedByCurrentUser,
  showFavorite,
  journeyId,
  onUnfavorite,
}) => {
  const shownDate = date ? format(new Date(date), "dd MMM yyyy") : undefined;

  const { ownerName, department } = getOwnerNameAndDepartment(
    customer.ownerName,
  );

  return (
    <div
      className={cn(
        "bg-[var(--theme-color-component-1)] relative w-full text-gray-900 rounded-sm h-[33.75rem] w-full rounded hover:bg-[var(--theme-color-component-1--hover)] active:bg-[var(--theme-color-component-1--active)]",
        {
          "bg-siemens-dark text-white theme-brand-dark": purple,
        },
        className,
      )}
    >
      <div className="flex h-full flex-col items-start leading-5">
        <div className="w-full rounded-t bg-siemens-dark">
          <ImageWithFallback
            imageSrc={`/industry_illustrations/${customer.industry}.svg`}
            fallback="/industry_illustrations/General industry02.svg"
            alt="Industry Illustration"
            className="h-40 w-full rounded-t"
          />
        </div>
        <div className="flex w-full grow flex-col gap-2 p-3">
          {(journeyType || showFavorite) && (
            <div className="flex w-full">
              {journeyType && <JourneyTypePill type={journeyType} />}
              {showFavorite && (
                <FavoriteButton
                  defaultFavorited={favoritedByCurrentUser}
                  journeyId={journeyId}
                  onUnfavorite={onUnfavorite}
                />
              )}
            </div>
          )}
          <div className="flex w-full items-start gap-3">
            <Avatar type={customer.avatar} size={56} />
            <div className="w-[calc(100%-5rem)] text-left">
              <p
                className="truncate font-bold font-semibold"
                data-testid="card-content-customer-role"
              >
                {customer.role}
              </p>
              <p className="truncate" data-testid="card-content-customer-type">
                {customer.type}
              </p>
              <p className="truncate" data-testid="card-content-industry">
                {customer.industry}
              </p>
              <p className="truncate" data-testid="card-content-industry">
                {customer?.region ? `${customer.region}` : <br />}
              </p>
            </div>
          </div>
          <div className="mt-1 flex flex-col items-start text-left">
            <div className="leading-5" data-testid="card-content-motivation">
              <span className="font-bold">Motivation: </span>
              <span>{customer.motivation}</span>
            </div>
            <div
              className="mt-1 w-full truncate leading-5"
              data-testid="card-content-intent"
            >
              <span className="font-bold">Intent: </span>
              <span>{customer.intent}</span>
            </div>
          </div>
          <div
            className="flex w-full grow flex-col justify-start pt-2"
            data-testid="card-content-tagline"
          >
            <IxTypography
              format="body"
              className="line-clamp-3 break-words text-left"
            >
              {customer.words && `“${customer.words}”`}
            </IxTypography>
          </div>
          <div className="flex h-[2.375rem] flex-col gap-2 font-bold text-xs">
            <div className="flex gap-1">
              {ownerName && (
                <IxTypography
                  format="label-sm"
                  textColor="soft"
                  className="shrink truncate text-left"
                >
                  {ownerName}
                </IxTypography>
              )}
              {(department || shownDate) && (
                <IxTypography
                  format="label-sm"
                  textColor="soft"
                  className="shrink-0 whitespace-nowrap text-left"
                >
                  {`${department ? `(${department}), ` : ""}${shownDate}`}
                </IxTypography>
              )}
            </div>
            {(viewCount !== undefined || commentCount !== undefined) && (
              <div className="flex gap-2">
                {viewCount !== undefined && (
                  <div className="flex items-center gap-0.5">
                    <IxIcon name="eye" size="16" color="color-soft-text" />
                    <IxTypography
                      format="label-sm"
                      textColor="soft"
                      className="text-left"
                    >
                      {Number(viewCount).toLocaleString()}
                    </IxTypography>
                  </div>
                )}
                {commentCount !== undefined && (
                  <div className="flex items-center gap-0.5">
                    <IxIcon
                      name="/comment.svg"
                      size="16"
                      color="color-soft-text"
                    />
                    <IxTypography
                      format="label-sm"
                      textColor="soft"
                      className="text-left"
                    >
                      {Number(commentCount ?? 0).toLocaleString()}
                    </IxTypography>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        {actions && (
          <div className="flex w-full items-end justify-between p-3 pt-0">
            <div className="mt-auto flex w-full justify-end gap-2">
              {actions}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
