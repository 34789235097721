import React from "react";
import {
  RequestMissingButton,
  RequestMissingButtonProps,
} from "./RequestMissingButton";

interface RequestMissingChannelButtonProps
  extends Omit<RequestMissingButtonProps, "type"> {}

/**
 *  Request missing channel button
 * @param props - The props
 * @param props.groups - The groups
 * @param props.journeyId - The journey id
 */
export const RequestMissingChannelButton: React.FC<
  RequestMissingChannelButtonProps
> = ({ groups, journeyId }) => {
  return (
    <RequestMissingButton
      groups={groups}
      journeyId={journeyId}
      type="Channel"
    ></RequestMissingButton>
  );
};
