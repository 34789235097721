import * as d3 from "d3";
import { MapChannel } from "./Map";

export const RECT_HEIGHT = 218;

export function drawChannel(
  svg: d3.Selection<SVGSVGElement, MapChannel[], null, undefined>,
  mapChannels: MapChannel[],
  containerRef: React.RefObject<HTMLDivElement>,
) {
  const topologyIdToYPosition: Map<string, number> = new Map();
  let uniqueChannelCount = 0;
  const revisitedTopologyIds: string[] = [];

  // Draw background rectangles
  svg
    .selectAll<SVGRectElement, MapChannel[]>("rect.background")
    .data(mapChannels)
    .enter()
    .append("rect")
    .attr("class", "background")
    .attr("x", 0)
    .attr("y", (d) => {
      if (!topologyIdToYPosition.has(d.topologyId as string)) {
        const yPosition = RECT_HEIGHT * uniqueChannelCount;
        topologyIdToYPosition.set(d.topologyId as string, yPosition);
        uniqueChannelCount++;
      } else {
        revisitedTopologyIds.push(d.fid);
      }
      return topologyIdToYPosition.get(d.topologyId as string) || 0;
    })
    .attr("width", "100%")
    .attr("height", RECT_HEIGHT)
    .attr("fill", (d) => {
      const index = Array.from(topologyIdToYPosition.keys()).indexOf(
        d.topologyId as string,
      );
      return index % 2 !== 0 ? "var(--channel-light)" : "none";
    });

  // Create channel name elements only for non-revisited channels
  const channelNameContainer = d3
    .select(containerRef.current)
    .selectAll<HTMLDivElement, MapChannel>("div.channel-name-container")
    .data(mapChannels.filter((d) => !revisitedTopologyIds.includes(d.fid)), d => d.fid);

  // Remove channel name containers that are no longer in the data
  channelNameContainer.exit().remove();

  // Update existing and add new channel name containers
  const channelNameEnter = channelNameContainer
    .enter()
    .append("div")
    .attr("class", "channel-name-container")
    .style("position", "sticky")
    .style("left", "0rem")
    .style("height", `${RECT_HEIGHT}px`)
    .style("display", "flex")
    .style("align-items", "flex-start")
    .style("padding-top", "0.7rem");

  channelNameEnter
    .append("div")
    .attr("class", "channel-name inline-block");

  const channelNameMerged = channelNameEnter.merge(channelNameContainer);

  channelNameMerged
    .style("pointer-events", "none")
    .select(".channel-name")
    .style("background-color", (d, i) => {
      return i % 2 !== 0 ? "var(--channel-light)" : "var(--channel-dark)";
    })
    .style("color", "white")
    .style("margin", "1rem 0 0 2rem")
    .style("padding", "0.1875rem 0.625rem")
    .style("border-radius", "100px")
    .style("border", "1px solid white")
    .text((d) => `${d.name} ${d.isCustom ? "(Custom Channel)" : ""}`)
    .style("pointer-events", "none")
    .style("user-select", "none");

  channelNameMerged
    .style("pointer-events", "none");

  return { topologyIdToYPosition, uniqueChannelCount };
}
