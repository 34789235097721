import { SetURLSearchParams } from "react-router-dom";

export enum PrepareYourJourneyStep {
  First = "prepare1",
  Second = "prepare2",
  Third = "prepare3",
  Fourth = "prepare4",
  Fifth = "prepare5",
  Sixth = "prepare6",
  Seventh = "prepare7",
  Eigth = "prepare8",
}

export enum CompleteDetailsStep {
  First = "complete1",
  Second = "complete2",
  Third = "complete3",
  Fourth = "complete4",
  Fifth = "complete5",
}

// TODO: Enable this if the code is running on Next.js
// /**
//  *  A function to navigate between steps using router
//  * @param router Router
//  * @param step  Step to go to
//  */
// export const openDialogOrStep = <
//   T extends PrepareYourJourneyStep | CompleteDetailsStep | "edit",
// >(
//   router: NextRouter,
//   step: T
// ) => {
//   const newQuery = { ...router.query, dialog: step };
//   const newRouter = { ...router, query: { ...newQuery } };
//   router.push(newRouter);
// };

// /**
//  * A function to reset the router query
//  * @param router Router
//  */
// export const resetRouterDialog = (router: NextRouter) => {
//   const newQuery = { ...router.query };
//   delete newQuery.dialog;
//   const newRouter = { ...router, query: { ...newQuery } };
//   router.push(newRouter, undefined, { shallow: true });
// };

// TODO: Remove this if the code is running on Next.js
/**
 *  A function to navigate between steps using router
 * @param setSearchParams Set URL search params
 * @param step  Step to go to
 */
export const openDialogOrStep = <
  T extends PrepareYourJourneyStep | CompleteDetailsStep | "edit",
>(
  setSearchParams: SetURLSearchParams,
  step: T,
) => {
  setSearchParams({ dialog: step });
};

/**
 * A function to reset the router query
 * @param setSearchParams Set URL search params
 */
export const resetRouterDialog = (setSearchParams: SetURLSearchParams) => {
  setSearchParams({});
};
