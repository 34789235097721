import { applyPatches, produce, produceWithPatches } from "immer";
import { ChannelState, Get, Set } from "./types";

export const setWithUndo = (
  currentState: ChannelState,
  set: (state: ChannelState) => void,
  recipe: (state: ChannelState) => void,
) => {
  const [nextState, patches, inversePatches] = produceWithPatches(
    currentState,
    recipe,
  );

  if (nextState) {
    set(
      produce(nextState, (state: ChannelState) => {
        state.undoStack = [
          ...state.undoStack.slice(0, state.undoStackIndex),
          { patches, inversePatches },
        ];
        state.undoStackIndex++;
      }),
    );
  }
};

/**
 *  Undo the last action
 * @param set the set function from the useChannelStore
 * @param get the get function from the useChannelStore
 */
export const undo = (set: Set, get: Get) => () => {
  const currentState = get();
  const { inversePatches } =
    currentState.undoStack[currentState.undoStackIndex - 1];
  const nextState = applyPatches(currentState, inversePatches);
  set(
    produce(nextState, (state: ChannelState) => {
      state.undoStackIndex--;
    }),
  );
};

/**
 *  Redo the last action
 * @param set the set function from the useChannelStore
 * @param get the get function from the useChannelStore
 */
export const redo = (set: Set, get: Get) => () => {
  const currentState = get();
  const { patches } = currentState.undoStack[currentState.undoStackIndex];
  const nextState = applyPatches(currentState, patches);
  set(
    produce(nextState, (state: ChannelState) => {
      state.undoStackIndex++;
    }),
  );
};
