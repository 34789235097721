import React, { useState } from "react";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import { Avatar } from "../../Avatar/index.tsx";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { PrepareYourJourneyStep, openDialogOrStep } from "@/utils/router";
import { Stepper } from "@motius/customer-heartbeat-ui";
import { useSearchParams } from "react-router-dom";
import Headline from "../components/Headline.tsx";
import { cn } from "@motius/customer-heartbeat-ui/utils";

interface PrepareJourneyTaglineStepProps {}

const PLACEHOLDER = `"I want to ..."`;

/**
 * Tagline Step for Prepare Your Journey Dialog
 * @param props Props
 * @param props.onClose Callback for close the dialog
 */
export const PrepareJourneyTaglineStep: React.FC<
  PrepareJourneyTaglineStepProps
> = () => {
  const [, setSearchParams] = useSearchParams();
  const { isValid } = useFormState();
  const [placeholder, setPlaceholder] = useState<string>(PLACEHOLDER);
  const { trigger, watch } = useFormContext();

  /**
   *  Handle Back Button Click
   */
  const handleBack = () => {
    openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Sixth);
  };

  /**
   *  Handle Next Button Click
   */
  const handleNext = async () => {
    if (await trigger()) {
      openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Eigth);
    }
  };

  return (
    <div className="flex-column flex size-full items-center justify-between">
      <Headline text="Say it in your customer's words:" />
      <div className="flex items-start gap-6">
        <Avatar type={watch("avatar")} size={120} />
        <div className="flex w-60 items-center">
          <Controller
            name="words"
            render={({ field }) => (
              <div>
                <TextareaAutosize
                  value={field.value}
                  onChange={(e) => field.onChange(e.currentTarget.value)}
                  className={cn(
                    "w-full resize-none rounded-r-[32px] rounded-tl-[32px] border-2",
                    "p-6 font-sans text-lg leading-5",
                    "!outline-offset-2",
                    "hover:bg-theme-color-secondary--hover",
                    "active:bg-theme-color-secondary--hover",
                    "focus:bg-theme-color-secondary--hover",
                  )}
                  placeholder={placeholder}
                  onFocus={() => setPlaceholder("")}
                  onBlur={() => setPlaceholder(PLACEHOLDER)}
                  maxLength={120}
                />
                <span className="font-sans text-10">
                  {field.value?.length || 0} / 120
                </span>
              </div>
            )}
          />
        </div>
      </div>
      <div className="mb-4 w-full">
        <Stepper
          onBackClick={handleBack}
          onNextClick={handleNext}
          isNextDisabled={!isValid}
          steps={7}
          activeStep={5}
        />
      </div>
    </div>
  );
};
