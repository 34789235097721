import { IxButton, ModalRef, showModal } from "@siemens/ix-react";
import React, { useRef } from "react";
import {
  RequestMissingDialog,
  requestMissingFormSchema,
  RequestMissingFormValues,
} from "./RequestMissingDialog";
import { showMessage } from "@siemens/ix";
import { Maybe } from "@motius/customer-heartbeat-utils";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";

export interface RequestMissingButtonProps {
  groups: string[];
  journeyId: Maybe<string>;
  type: "Touchpoint" | "Interaction" | "Channel";
}

export const RequestMissingButton: React.FC<RequestMissingButtonProps> = ({
  groups,
  journeyId,
  type,
}) => {
  const modalRef = useRef<ModalRef>(null);
  const methods = useForm<RequestMissingFormValues>({
    resolver: zodResolver(requestMissingFormSchema),
    shouldFocusError: false,
    shouldUseNativeValidation: true,
    mode: "onChange",
  });
  /**
   *  Closes the request missing touchpoint dialog
   */
  const handleClose = async (success?: boolean) => {
    modalRef.current?.close("Close request missing dialog");
    if (success) {
      methods.reset();
      await showMessage({
        icon: "",
        messageTitle: `Custom ${type} has been requested`,
        message: "You can now continue.",
        actions: [{ id: "okay", type: "okay", text: "Ok" }],
      });
    }
  };

  const open = () => {
    showModal({
      centered: true,
      size: "840",
      content: (
        <FormProvider {...methods}>
          <RequestMissingDialog
            ref={modalRef}
            onClose={handleClose}
            onCancel={handleCancel}
            type={type}
            groups={groups}
            journeyId={journeyId!}
            methods={methods}
          />
        </FormProvider>
      ),
    });
  };

  const handleCancel = async () => {
    (
      await showMessage({
        icon: "",
        messageTitle: `Do you want to leave the ${type} Request Form?`,
        message: "Your input will be lost if you proceed.",
        actions: [
          { id: "cancel", type: "cancel", text: "Cancel" },
          { id: "proceed", type: "okay", text: "Proceed" },
        ],
      })
    ).once((result) => {
      if (result?.actionId == null || result?.actionId === "cancel") {
        open();
      }
      if (result?.actionId === "proceed") {
        // if the result has a payload then add was clicked
        methods.reset();
        handleClose();
      }
    });
  };

  if (!journeyId) return null;

  return (
    <IxButton ghost onClick={open}>
      Request new {type}
    </IxButton>
  );
};
