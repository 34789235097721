import React from "react";
import { useFormContext, useFormState } from "react-hook-form";
import {
  CustomerDetailsFormContent,
  CustomerDetailsFormContentProps,
} from "../components/CustomerDetailsFormContent";
import { CompleteDetailsStep, openDialogOrStep } from "@/utils/router";
import { Stepper } from "@motius/customer-heartbeat-ui";
import { useSearchParams } from "react-router-dom";
import { IxTypography } from "@siemens/ix-react";

interface CompleteDetailsCustomerStepProps
  extends CustomerDetailsFormContentProps {}

/**
 *  Complete Details Customer Step
 * @param props - Props
 * @param props.topologies - Topologies
 * @param props.motivationValues - Motivation Values
 */
export const CompleteDetailsCustomerStep: React.FC<
  CompleteDetailsCustomerStepProps
> = ({ topologies, motivationValues }) => {
  const [, setSearchParams] = useSearchParams();
  const { isValid } = useFormState();

  const { trigger } = useFormContext();

  /**
   *    Handle Next Button Click
   */
  const handleNext = async () => {
    if (await trigger()) {
      openDialogOrStep(setSearchParams, CompleteDetailsStep.Second);
    }
  };

  return (
    <div className="flex size-full flex-col justify-between">
      <div className="flex justify-between">
        <IxTypography format="h2">Complete Your Customer Details</IxTypography>
      </div>

      <div>
        <CustomerDetailsFormContent
          motivationValues={motivationValues}
          topologies={topologies}
        />
      </div>
      <div className="w-full">
        <Stepper
          onNextClick={handleNext}
          nextButtonText="Next"
          isNextDisabled={!isValid}
          steps={5}
          activeStep={0}
        />
      </div>
    </div>
  );
};
