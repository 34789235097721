import { Maybe } from "@motius/customer-heartbeat-utils";
import {
  CIRCLE_RADIUS_BIG,
  CIRCLE_RADIUS_BIG_WITH_LOCATION,
  CIRCLE_RADIUS_SMALL,
  CIRCLE_RADIUS_WITH_LOCATION,
  PLACEHOLDER_TOUCHPOINT_TP_ID,
  TRANSITION_TIME,
} from "./constants";
import { JourneyOutcome, JourneyOutcomeType } from "@/utils/apollo/resolvers";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import * as d3 from "d3";
import { FrontendLocation, FrontendTouchpoint } from "@/utils/types";
import { MapChannel } from "./Map";
import { Selected } from "./MetaDataMenu/MetaDataMenu";
import { getTransform } from "./utils";
import { getTotalVisitCount } from "./visitCounts";
import { generateHtmlContent } from "./textBelowTp";

/**
 *  Type for showing the details of the hovered metadata
 */
export type HoveredMetaData = { node: MapNode; metaDataType: string };

export type MapNode = (FrontendTouchpoint | FrontendLocation) & {
  isLocation: boolean;
  hasLocation: boolean;
  active?: boolean;
  compositeId: string;
};

interface DrawTouchpointParams {
  svg: d3.Selection<SVGGElement, MapChannel, null, undefined>;
  node: MapNode;
  x: number;
  y: number;
  lightBackground: boolean;
  outcome: Maybe<JourneyOutcome>;
  handleTouchpointClick: (selection: Selected) => void;
  selectedNode: Maybe<MapNode>;
  editable: boolean;
  visitCount: number;
  onMetaDataHover?: (hovered: HoveredMetaData) => void;
}

export const drawTouchPoint = ({
  svg,
  node,
  x,
  y,
  lightBackground,
  outcome,
  handleTouchpointClick,
  selectedNode,
  editable,
  visitCount,
  onMetaDataHover,
}: DrawTouchpointParams) => {
  const totalVisitCount = getTotalVisitCount(node.compositeId);
  const isLocationOrHasLocationConnection = (n: MapNode) =>
    n.isLocation || n.hasLocation;

  const circles = svg
    .selectAll<SVGCircleElement, null>(`circle[data-id="${node.fid}"]`)
    .data([node], (d) => d!.fid);

  const enterCircles = circles
    .enter()
    .append("circle")
    .classed("touchpoint", true)
    .attr("id", (d) => d!.fid);
  enterCircles
    .merge(circles)
    .attr("cx", x)
    .attr("cy", y)
    .attr("r", (d) => {
      if (isLocationOrHasLocationConnection(d)) {
        if (d.active && outcome) {
          return CIRCLE_RADIUS_BIG_WITH_LOCATION;
        }
        return totalVisitCount <= 1
          ? CIRCLE_RADIUS_WITH_LOCATION
          : CIRCLE_RADIUS_BIG_WITH_LOCATION;
      }
      return totalVisitCount <= 1 ? CIRCLE_RADIUS_SMALL : CIRCLE_RADIUS_BIG;
    })
    .style("fill", (d) => {
      if (
        // add back visits
        d.active &&
        outcome &&
        outcome &&
        totalVisitCount > 1
      ) {
        switch (outcome.type) {
          case JourneyOutcomeType.IntentFulfilled:
            return "var(--fulfilled-background)";
          case JourneyOutcomeType.IntentUnfulfilled:
            return "var(--unfulfilled-background)";
          case JourneyOutcomeType.HandedOver:
            return "var(--ended-background)";
        }
      }
      return isLocationOrHasLocationConnection(d)
        ? "var(--touchpoint-location)"
        : "var(--node-circle)";
    });

  // Apply transitions only to the update selection
  circles.transition().duration(TRANSITION_TIME);
  circles.exit().remove();

  const getRadius = (d: MapNode) =>
    isLocationOrHasLocationConnection(d)
      ? CIRCLE_RADIUS_BIG_WITH_LOCATION
      : CIRCLE_RADIUS_BIG;

  const getTouchpointIcon = (d: MapNode) => {
    if (d.active && outcome) {
      switch (outcome.type) {
        case JourneyOutcomeType.IntentFulfilled:
          return "/outcome/fulfilledIcon.svg";
        case JourneyOutcomeType.IntentUnfulfilled:
          return "/outcome/unfulfilledIcon.svg";
        case JourneyOutcomeType.HandedOver:
          return "/outcome/handingOverIcon.svg";
      }
    }
    return "";
  };

  svg
    .selectAll<SVGImageElement, MapNode>(`image[data-id="${node.fid}"]`)
    .data([node], (d) => d.fid)
    .join(
      (enter) =>
        enter
          .append("image")
          .attr("data-id", (d) => d.fid)
          .attr("x", (d) => x - getRadius(d))
          .attr("y", (d) => y - getRadius(d))
          .attr("width", (d) => 2 * getRadius(d))
          .attr("height", (d) => 2 * getRadius(d))
          .classed("hidden", (d) => totalVisitCount > 1)
          .attr("href", getTouchpointIcon),
      (update) =>
        update
          .attr("x", (d) => x - getRadius(d))
          .attr("y", (d) => y - getRadius(d))
          .attr("width", (d) => 2 * getRadius(d))
          .attr("height", (d) => 2 * getRadius(d))
          .classed("hidden", (d) => totalVisitCount > 1)
          .attr("href", getTouchpointIcon),
      (exit) => exit.remove(),
    );

  // Add or update text labels in the touchpoint circles
  // that indicate how often the touchpoint has been visited
  svg
    .selectAll<SVGElement, MapNode>(`text[data-id="${node.fid}"]`)
    .data([node], (d) => d.fid)
    .join(
      (enter) =>
        enter
          .append("text")
          .attr("data-composite-id", (d) => d.fid)
          .attr("x", x)
          .attr("y", y)
          .attr("class", "font-bold")
          .attr("text-anchor", "middle")
          .attr("dy", "0.35em") // Center the text vertically within the circle
          .text((d) => (totalVisitCount > 1 ? visitCount.toString() : ""))
          .style("fill", (d) =>
            isLocationOrHasLocationConnection(d) ? "white" : "black",
          ),
      (update) =>
        update
          .transition()
          .duration(TRANSITION_TIME)
          .attr("x", x)
          .attr("y", y)
          .text((d) => (totalVisitCount > 1 ? visitCount.toString() : ""))
          .style("fill", (d) =>
            isLocationOrHasLocationConnection(d) ? "white" : "black",
          ),
      (exit) => exit.remove(),
    );
  drawReactions(svg, node, x, y, onMetaDataHover);
  drawTextBelowTouchpoint(
    svg,
    node,
    x,
    y,
    lightBackground,
    handleTouchpointClick,
    selectedNode,
    editable,
  );
};

export const drawReactions = (
  svg: d3.Selection<SVGGElement, MapChannel, null, undefined>,
  node: MapNode,
  x: number,
  y: number,
  onMetaDataHover?: (hovered: HoveredMetaData) => void,
) => {
  const metaDataCount =
    (node.attachments.length ? 1 : 0) +
    (node.title ? 1 : 0) +
    (node?.reaction ? 1 : 0);

  const reactions = svg
    .selectAll<SVGImageElement, string>(`.reaction[data-fid="${node.fid}"]`)
    .data(
      () => {
        const metaData: string[] = [];

        if (node.attachments.length > 0) {
          metaData.push("screenshot-metadata");
        }

        if (node.title) {
          metaData.push("title-metadata");
        }
        if (node?.reaction) {
          metaData.push(node.reaction.name);
        }

        return metaData;
      },
      (d) => d,
    );

  const entered = reactions
    .enter()
    .append("image")
    .attr("id", (d) => {
      switch (d) {
        case "screenshot-metadata":
          return "meta-data-screenshot-tooltip-anchor";
        case "title-metadata":
          return "meta-data-title-tooltip-anchor";
        default:
          return "meta-data-reaction-tooltip-anchor";
      }
    })
    .attr("aria-describedby", (d) => {
      switch (d) {
        case "screenshot-metadata":
          return "meta-data-screenshot-tooltip";
        case "title-metadata":
          return "meta-data-title-tooltip";
        default:
          return "meta-data-reaction-tooltip";
      }
    })
    .attr("class", "reaction pointer-events-auto")
    .attr("data-fid", node.fid);

  const metaDataIconSize = 18;
  const moreInfoButtonSize = 24;
  entered
    .merge(reactions)
    .attr("width", () => metaDataIconSize)
    .attr("height", moreInfoButtonSize)
    .attr("x", (d, index) => {
      const gapBetweenMetaDataElements = 4;

      const initialPosition =
        ((1 - metaDataCount) * gapBetweenMetaDataElements +
          (2 - metaDataCount) * metaDataIconSize) /
        2;
      return (
        x -
          [
            initialPosition,
            initialPosition + metaDataIconSize + gapBetweenMetaDataElements,
            initialPosition +
              2 * (metaDataIconSize + gapBetweenMetaDataElements),
          ][index] || x - initialPosition
      );
    })
    .attr("y", y - 36)
    .attr("opacity", 1)
    .attr("xlink:href", (d) => {
      return `/metadata/${d!.toLowerCase()}.svg`;
    })
    .on("mouseenter", (_, d) => {
      onMetaDataHover?.({ node, metaDataType: d });
    });

  entered.data();

  reactions.exit().remove();
};

export const drawTextBelowTouchpoint = (
  svg: d3.Selection<SVGGElement, MapChannel, null, undefined>,
  node: MapNode,
  x: number,
  y: number,
  lightBackground: boolean,
  handleTouchpointClick: (selection: Selected) => void,
  selectedNode: Maybe<MapNode>,
  editable: boolean,
) => {
  const foreignObjects = svg
    .selectAll<
      SVGForeignObjectElement,
      MapNode
    >(`foreignObject[data-id="${node.fid}"]`)
    .data([node], (d) => d.fid);

  const entered = foreignObjects
    .enter()
    .append("foreignObject")
    .attr("data-id", (d) => d.fid);

  const WIDTH = 120;

  entered
    .merge(foreignObjects)
    .attr("id", (d) => "label" + d.fid)
    .attr("x", x - WIDTH / 2)
    .attr("y", y + CIRCLE_RADIUS_BIG + 10)
    .attr("width", WIDTH)
    .attr("height", 120)
    .attr("class", (d) =>
      cn("touchpoint-label overflow-visible pointer-events-none", {
        active: d.active && editable,
        light: lightBackground,
        location: d.isLocation,
        selected: selectedNode?.fid === d.fid && editable,
        selectable: editable,
      }),
    )
    .on("click", (event, d) => {
      if (d.fid !== PLACEHOLDER_TOUCHPOINT_TP_ID) {
        const parentChannel = event.target.parentElement?.parentElement;

        if (parentChannel) {
          const transformAttr = parentChannel.getAttribute("transform");
          const [channelX, channelY] = transformAttr
            ? getTransform(transformAttr)
            : [0, 0];

          handleTouchpointClick({
            node,
            position: {
              x: channelX + x - WIDTH / 2,
              y:
                channelY +
                y +
                CIRCLE_RADIUS_BIG +
                12 +
                event.target.clientHeight,
            },
            userSelected: !d.active,
          });
        }
      }
    })
    .html((d) => generateHtmlContent(d));

  foreignObjects.exit().remove();

  // Apply transitions separately, after HTML content is updated.
  entered
    .merge(foreignObjects)
    .transition()
    .duration(TRANSITION_TIME)
    .attr("x", x - WIDTH / 2)
    .attr("y", y + CIRCLE_RADIUS_BIG + 10);
};
