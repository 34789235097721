import * as SelectPrimitive from "@radix-ui/react-select";
import React from "react";
import * as ScrollArea from "@radix-ui/react-scroll-area";
import { cn } from "../../utils";

export const SelectRoot = SelectPrimitive.Root;

interface SelectTriggerProps extends SelectPrimitive.SelectTriggerProps {
  withError?: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const SelectTrigger: React.FC<SelectTriggerProps> = ({
  className,
  autoFocus,
  placeholder = "Select",
  withError,
  disabled = false,
}) => {
  return (
    <SelectPrimitive.Trigger
      className={cn(
        "bg-white border-input-border flex h-[42px] items-center justify-between rounded-lg border p-[12px] outline-none w-[221px] text-sm",
        "focus-within:border-tertiary focus-within:border-2 focus-within:p-[11px]",
        "data-[placeholder]:text-weak",
        { "border-error bg-error-background": withError },
        { "opacity-50": disabled },
        className,
      )}
      autoFocus={autoFocus}
      disabled={disabled}
    >
      <span className="truncate">
        <SelectPrimitive.Value placeholder={placeholder} />
      </span>
      <span className="font-icon text-[20px] text-soft">expand_more</span>
    </SelectPrimitive.Trigger>
  );
};

export const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, ...props }, ref) => {
  return (
    <SelectPrimitive.Content
      className={cn(
        "bg-select-item-border animate-in fade-in-50 w-40 rounded-lg border border-select-item-border z-10 mt-[2px] shadow-select",
        className,
      )}
      position="popper"
      align="start"
      ref={ref}
      {...props}
    >
      <ScrollArea.Root type="auto" className="rounded-lg bg-transparent">
        <SelectPrimitive.Viewport
          asChild
          className=" min-w-[var(--radix-select-trigger-width)] mr-3 max-h-80"
        >
          <ScrollArea.Viewport
            style={{ overflowY: undefined }}
            className="rounded-lg"
          >
            {children}
          </ScrollArea.Viewport>
        </SelectPrimitive.Viewport>
        <ScrollArea.Scrollbar
          orientation="vertical"
          className="bg-transparent w-3 p-1"
        >
          <ScrollArea.Thumb
            className="bg-light-gray rounded"
            style={{
              width: "0.5rem",
            }}
          />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
    </SelectPrimitive.Content>
  );
});
SelectContent.displayName = SelectPrimitive.SelectContent.displayName;

export const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => {
  return (
    <SelectPrimitive.Item
      className={cn(
        "select-none bg-white data-[highlighted]:bg-hover cursor-pointer px-3 py-3 outline-none data-[highlighted]:outline-none first:rounded-t-lg last:rounded-b-lg text-sm",
        { "text-input-disabled-text cursor-default": props.disabled },
        className,
      )}
      ref={ref}
      {...props}
    >
      <SelectPrimitive.ItemText className="capitalize">
        {children}
      </SelectPrimitive.ItemText>
    </SelectPrimitive.Item>
  );
});
SelectItem.displayName = SelectPrimitive.Item.displayName;
