import React, { useEffect, useState } from "react";
import { MetaDataSubMenu } from "./MetaDataSubMenu";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { IxIconButton, IxInputGroup } from "@siemens/ix-react";
import { MapNode } from "../../drawTouchpoint";

interface TitleSubMenuProps {
  node?: MapNode;
  onClose: (isCancel?: boolean) => void;
}

export const TitleSubMenu: React.FC<TitleSubMenuProps> = ({
  node,
  onClose,
}) => {
  const { addTitle, removeTitle } = useChannelStore();
  const [text, setText] = useState<string>("");

  useEffect(() => {
    setText(node?.title ?? "");
  }, [node]);

  const handleConfirm = () => {
    const currentFid = node?.fid;
    if (currentFid) {
      if (text) {
        addTitle(currentFid, text);
      } else {
        removeTitle(currentFid);
      }
      onClose();
    }
  };

  const handleCancel = () => {
    setText("");
    onClose(true);
  };

  const handleClear = () => {
    setText("");
  };

  const currentTitle = node?.title ?? "";
  const hasChanges = currentTitle !== text;
  return (
    <MetaDataSubMenu
      label="Title"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      addDisabled={!hasChanges}
      showCloseWarning={hasChanges}
    >
      <IxInputGroup className="w-[12.5rem]">
        {text && (
          <IxIconButton
            slot="input-end"
            ghost
            icon="clear"
            size="16"
            onClick={handleClear}
          ></IxIconButton>
        )}
        <input
          placeholder="Enter Title..."
          className="pl-2 text-theme-color-std-text"
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </IxInputGroup>
    </MetaDataSubMenu>
  );
};
