import * as d3 from "d3";

export type Coordinate = {
  x: number;
  y: number;
};

export type LineDataItem = {
  start: Coordinate;
  end: Coordinate;
  offset: number;
  isDashed: boolean;
};

export const lineGenerator = d3
  .line<Coordinate>()
  .x((d) => d.x)
  .y((d) => d.y)
  .curve(d3.curveBasis);

/**
 * Creates an array of coordinates [{x1, y1}, {x2, y2}, ...]
 * Each entry in the array represents a point in the path
 *
 * The path generated goes down from start, then horizontally to end:
 *
 * start
 *   |
 *   |
 *   |
 *   +-------------------> end
 *
 * The path always goes down first, then horizontally.
 * If start.x < end.x, it goes right; otherwise, it goes left.
 * The 'offset' parameter adjusts the horizontal position of the end point.
 */
export const getChannelConnectionPointArray = ({
  start,
  end,
  offset,
  isDashed,
}: LineDataItem): Coordinate[] => {
  if (start.y > end.y) {
    return getChannelConnectionPointArray({
      start: end,
      end: start,
      offset,
      isDashed,
    });
  }
  const delta = 20;

  const xEnd = end.x + offset;
  const goingRight = xEnd > start.x;

  const array: Coordinate[] = [
    { x: start.x, y: start.y },

    // move down
    { x: start.x, y: end.y - delta },
    { x: start.x, y: end.y },
    // turn left or right
    { x: start.x + (goingRight ? delta : -delta), y: end.y },

    // move horizontally
    { x: xEnd - (goingRight ? delta : -delta), y: end.y },
    { x: xEnd, y: end.y },
    // turn up or down
    // move to the end point
    { x: xEnd, y: end.y },
  ];
  return array;
};
