import { LocationInput } from "@/utils/apollo/resolvers";
import {
  FrontendChannel,
  FrontendLocation,
  FrontendTouchpoint,
} from "@/utils/types";
import { useEffect, useState } from "react";
import { useChannelStore } from "../../hooks/useChannelStore";

export interface VisitCountedData {
  visitOrder: { [key: string]: { compositeId: string; order: number } };
}

/**
 * This hook preprocesses the channels from the journey data
 * and prepares them for text journey rendering.
 *
 * The journey data organises the data in a "list" that is chronological.
 * This hook returns the frontend ids of the multiple visited touchpoints and locations. and their visit order
 *
 */
export const useVisitCounts = (): VisitCountedData => {
  const { channels } = useChannelStore();
  const [processedData, setProcessedData] = useState<VisitCountedData>({
    visitOrder: {},
  });

  useEffect(() => {
    if (!channels) return;

    const visitedTopologyIds = new Map<string, number>();

    const checkAndAddVisitOrder = (
      parentCompositeId: string,
      touchpoint: FrontendTouchpoint | FrontendLocation,
    ) => {
      // check if the compositeId has been visited before
      // if it has, increment the visit count
      // if it hasn't, add it to the map with a visit count of 1
      const compositeId = `${parentCompositeId}__${touchpoint.topologyId}`;
      if (compositeId) {
        visitedTopologyIds.set(
          compositeId,
          (visitedTopologyIds.get(compositeId) ?? 0) + 1,
        );
        visitOrder[touchpoint.fid] = {
          compositeId: compositeId,
          order: visitedTopologyIds.get(compositeId)!,
        };
      }
    };

    const visitOrder: VisitCountedData["visitOrder"] = {};
    channels.forEach((channel: FrontendChannel) => {
      channel.touchpoints.forEach((touchpoint: FrontendTouchpoint) => {
        checkAndAddVisitOrder(channel.topologyId ?? "", touchpoint);

        touchpoint.locations.forEach((location: LocationInput) => {
          checkAndAddVisitOrder(
            `${channel.topologyId}__${touchpoint.topologyId}`,
            location as FrontendLocation,
          );
        });
      });
    });

    const filteredVisitOrder: VisitCountedData["visitOrder"] = {};

    Object.entries(visitOrder).forEach(([fid, { compositeId, order }]) => {
      if (visitedTopologyIds.get(compositeId)! > 1) {
        filteredVisitOrder[fid] = { compositeId, order };
      }
    });

    setProcessedData({ visitOrder: filteredVisitOrder });
  }, [channels]);

  return processedData;
};
