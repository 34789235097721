import React from "react";
import {
  RequestMissingButton,
  RequestMissingButtonProps,
} from "./RequestMissingButton";

interface RequestMissingTouchpointButtonProps
  extends Omit<RequestMissingButtonProps, "type"> {}

/**
 *  Request missing touchpoint button
 * @param {RequestMissingTouchpointButtonProps} props - The props
 */
export const RequestMissingTouchpointButton: React.FC<
  RequestMissingTouchpointButtonProps
> = ({ groups, journeyId }) => {
  return (
    <RequestMissingButton
      groups={groups}
      journeyId={journeyId}
      type="Touchpoint"
    ></RequestMissingButton>
  );
};
