import { Select } from "@motius/customer-heartbeat-ui";
import { useMemo } from "react";
import { usePrepareJourneyTopo } from "../PrepareYourJourney/usePrepareJourneyTopo";
import {
  MotivationTopology,
  Topology,
} from "@motius/customer-heartbeat-utils/schemas";
import { useYourInsightsTopology } from "@/src/hooks/useYourInsigtsTopology";
import { getFilters, handleFilterChange } from "./filterUtils";
import { PrepareAndInsightsTopo } from "./FilterChips";
import { FrontendJourneyFilter } from "./Search";
import { JourneyTypeOptions } from "../CompleteDetails/steps/CompleteDetailsJourneyTypeStep";

type JourneyTopology = {
  [key: string]: Topology[];
};

const createOptions = (items: Topology[]) =>
  structuredClone(items)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) => ({ value: item.id, label: item.name }));

/**
 * TopoKey references the possible options in the base options map.
 */
type TopoKey = keyof PrepareAndInsightsTopo | "journeyType";

export const selectConfigs: {
  topoKey: TopoKey;
  dropdownLabel: string;
  filterKey: keyof FrontendJourneyFilter;
}[] = [
  {
    topoKey: "customer_role",
    dropdownLabel: "Customer Role",
    filterKey: "customerRoleTopologyId",
  },
  {
    topoKey: "industry",
    dropdownLabel: "Industry",
    filterKey: "industryTopologyId",
  },
  {
    topoKey: "customer_type",
    dropdownLabel: "Customer Type",
    filterKey: "customerTypeTopologyId",
  },
  {
    topoKey: "motivations",
    dropdownLabel: "Motivation",
    filterKey: "motivationTopologyId",
  },
  {
    topoKey: "intents",
    dropdownLabel: "Intent",
    filterKey: "intentTopologyId",
  },
  {
    topoKey: "regions",
    dropdownLabel: "Region",
    filterKey: "regionTopologyId",
  },
  {
    topoKey: "buyer_types",
    dropdownLabel: "Type of Buyer",
    filterKey: "buyerTypeTopologyId",
  },
  {
    topoKey: "priorContact",
    dropdownLabel: "Customer Status",
    filterKey: "priorContactTopologyId",
  },
  {
    topoKey: "experience_drivers",
    dropdownLabel: "Experience Drivers",
    filterKey: "experienceDriverTopologyId",
  },
  {
    topoKey: "painpoints",
    dropdownLabel: "Customer Pain Points",
    filterKey: "painpointTopologyId",
  },
  {
    topoKey: "journeyType",
    dropdownLabel: "Type of Journey",
    filterKey: "journeyTypeBadge",
  },
];

interface MetadataSelectsProps {
  filters: FrontendJourneyFilter;
  setLocalFilters: React.Dispatch<React.SetStateAction<FrontendJourneyFilter>>;
}

export const MetadataSelects: React.FC<MetadataSelectsProps> = ({
  filters,
  setLocalFilters,
}) => {
  const { topoData } = usePrepareJourneyTopo();
  const { topology: insightsTopo } = useYourInsightsTopology(
    undefined,
    import.meta.env.VITE_TOPOLOGY_ID,
  );
  const regions = structuredClone(insightsTopo?.regions ?? []);
  const buyer_types = structuredClone(insightsTopo?.buyer_types ?? []);
  const priorContact = structuredClone(insightsTopo?.priorContact ?? []);
  const experience_drivers = structuredClone(
    insightsTopo?.experience_drivers ?? [],
  );
  const painpoints = structuredClone(insightsTopo?.painpoints ?? []);

  const journeyTopology: JourneyTopology = topoData?.journeyTopology ?? {};
  const intents =
    (journeyTopology.motivations as MotivationTopology[])
      ?.map((motivation) => motivation.intents)
      .flat() ?? [];

  const optionsMap = useMemo(() => {
    const baseOptions = Object.fromEntries(
      selectConfigs.map(({ topoKey: key }) => [
        key,
        createOptions(
          key === "intents" ? intents : (journeyTopology[key] ?? []),
        ),
      ]),
    );

    baseOptions.regions = createOptions(regions ?? []);
    baseOptions.buyer_types = createOptions(buyer_types ?? []);
    baseOptions.priorContact = createOptions(priorContact ?? []);
    baseOptions.experience_drivers = createOptions(experience_drivers ?? []);
    baseOptions.painpoints = createOptions(painpoints ?? []);
    baseOptions.journeyType = JourneyTypeOptions;

    return baseOptions;
  }, [journeyTopology, intents, regions, experience_drivers, painpoints]);

  return (
    <>
      {selectConfigs.map(({ topoKey, dropdownLabel, filterKey }) => (
        <Select
          key={topoKey}
          mode="multiple"
          allowClear={false}
          label={dropdownLabel}
          id={`${topoKey}-filter`}
          options={optionsMap[topoKey]}
          value={getFilters(filterKey, filters)}
          onChange={(values: string[]) => {
            setLocalFilters((prevFilters) =>
              handleFilterChange(filterKey, values, prevFilters),
            );
          }}
        />
      ))}
    </>
  );
};
