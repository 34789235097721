import { useChannelStore } from "../../../hooks/useChannelStore";
import { format } from "date-fns";
import { SummarySection } from "./SummarySection";

export const AuthorSection = () => {
  const { ownerName, lastSavedDate } = useChannelStore();
  const publishDate = lastSavedDate
    ? format(new Date(lastSavedDate), "dd. MMMM yyyy")
    : undefined;
  return (
    <SummarySection className="border-b border-b-light-gray">
      <h1 className="mb-2 font-bold text-sm">Author</h1>
      <p className="mb-1">{ownerName}</p>
      {publishDate && <p>Published on, {publishDate}</p>}
    </SummarySection>
  );
};
