// import debug from "debug";
import { useChannelStore } from "../../hooks/useChannelStore";
import {
  EditCustomerFormValues,
  InsightsFormValues,
} from "@motius/customer-heartbeat-utils/schemas";
import { JourneyInput, MotivationInput } from "@/utils/apollo/resolvers";
import { getLabelById, getChannelInput } from "@/utils/topology";
import { useMutation } from "@apollo/client";
import { STORE_JOURNEY } from "@/utils/apollo/queries";
import { removeTypename } from "@/utils/apollo";
// const log = debug("useSubmitJourney");

// a lot of the code in this file will become obsolete once we
// no longer have to keep track of the entity ids of stored metadata
export const useSubmitJourney = (publish?: boolean) => {
  const [storeJourney, { loading }] = useMutation(STORE_JOURNEY);
  const {
    journeyId,
    journeyTopologyId,
    channels,
    outcome,
    // metadata
    industry,
    customerType,
    customerRole,

    region,
    buyerType,
    priorContact,
    motivation,

    painpoints,
    experienceDrivers,
  } = useChannelStore();

  enum UpdateType {
    UPDATE_CHANNELS,
    EXCLUDE_CHANNELS,
  }
  const prepareCustomerInformation = (
    submittedValues: EditCustomerFormValues,
    updateType: UpdateType,
  ): JourneyInput => {
    /**
     * we expect the journey to already be in a valid state
     * before completing it with the your insights form data
     */
    if (
      !journeyId ||
      !customerRole?.id ||
      !motivation?.intent?.topologyId ||
      !customerType?.id ||
      !industry?.id
    ) {
      throw new Error(
        "a journey must already have been stored and mandatory fields must be set to submit insights",
      );
    }

    // currently motivations are disabled
    // but if we will enable it in the future
    // then this will be able to update them
    const updatedMotivation: MotivationInput = {
      id: motivation.id,
      name: getLabelById(submittedValues.motivationId),
      topologyId: submittedValues.motivationId,
      intent: {
        id: motivation.intent.id,
        topologyId: motivation.intent.topologyId,
        name: getLabelById(submittedValues.intentId),
      },
    };
    if (submittedValues.subIntentId) {
      updatedMotivation.intent!.intent = {
        id: motivation.intent.intent?.id,
        topologyId: motivation.intent.intent?.topologyId,
        name: getLabelById(submittedValues.subIntentId),
      };
    }

    const updatedJourney: JourneyInput = {
      id: journeyId,
      topologyId: journeyTopologyId ?? import.meta.env.VITE_TOPOLOGY_ID,
      outcome,

      avatarRef: submittedValues.avatar,
      industry: {
        id: industry.id,
        name: getLabelById(submittedValues.industryId),
        topologyId: submittedValues.industryId,
      },
      customerRole: {
        // for all values that get a backend id we
        // reuse the id that was already stored
        id: customerRole.id,
        // and combine it with label of the topology
        // that was selected in the form
        name: getLabelById(submittedValues.roleId),
        topologyId: submittedValues.roleId,
      },
      customerType: {
        id: customerType.id,
        name: getLabelById(submittedValues.typeId),
        topologyId: submittedValues.typeId,
      },

      motivation: updatedMotivation,
      description: submittedValues.words,
    };

    updatedJourney.channels = null;
    if (updateType === UpdateType.UPDATE_CHANNELS) {
      updatedJourney.channels = channels.map((ch) => getChannelInput(ch));
    }

    if (submittedValues.regionId) {
      updatedJourney.region = {
        id: region?.id,
        name: getLabelById(submittedValues.regionId),
        topologyId: submittedValues.regionId,
      };
    }

    // optional fields
    if (submittedValues.statusId) {
      updatedJourney.priorContact = {
        id: priorContact?.id,
        name: getLabelById(submittedValues.statusId),
        topologyId: submittedValues.statusId,
      };
    }
    if (submittedValues.buyerTypeId) {
      updatedJourney.buyerType = {
        id: buyerType?.id,
        name: getLabelById(submittedValues.buyerTypeId),
        topologyId: submittedValues.buyerTypeId,
      };
    }

    if (publish) {
      updatedJourney.isPublished = true;
    }
    return updatedJourney;
  };

  /**
   * this is called when the user submits the "Edit customer form".
   * it loads the current journey from the state and updates it
   * with the submitted values before storing it in the backend.
   * @param submittedValues
   */
  const submitCustomerInformation = async (
    submittedValues: EditCustomerFormValues,
  ) => {
    const updatedJourney = prepareCustomerInformation(
      submittedValues,
      UpdateType.EXCLUDE_CHANNELS,
    );

    // log("updatedJourney", updatedJourney);
    return await storeJourney({
      variables: { journey: removeTypename(updatedJourney) },
    });
  };

  /**
   * this is called when the user submits the "Your insights form".
   * it loads the current journey from the state and updates it
   * with the submitted values before storing it in the backend.
   * @param submittedValues
   */
  const submitJourney = async (submittedValues: InsightsFormValues) => {
    const updatedJourney = prepareCustomerInformation(
      submittedValues,
      UpdateType.UPDATE_CHANNELS,
    );

    // Set the values for painpoint and experience drivers
    if (submittedValues.painpoints?.length) {
      updatedJourney.painpoints = submittedValues.painpoints.map((id, i) => ({
        id: painpoints?.[i]?.id ?? undefined,
        topologyId: id,
        name: getLabelById(id),
      }));
    }
    updatedJourney.experienceDrivers = submittedValues.experienceDrivers.map(
      ({ name, rating, id, topologyId }) => ({
        name,
        rating,
        id,
        topologyId,
      }),
    );

    updatedJourney.journeyType = submittedValues.journeyType;

    // log("updatedJourney", updatedJourney);
    await storeJourney({
      variables: { journey: removeTypename(updatedJourney) },
    });
  };

  return { submitJourney, submitCustomerInformation, loading };
};
