import { JourneyOutcomeType } from "@/utils/apollo/resolvers";
import { OutcomeIcon } from "./OutcomeIcon";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { IxIconButton } from "@siemens/ix-react";

interface Props {
  outcome: JourneyOutcomeType;
  className?: string;
  onRemove?: () => void;
}

export const OutcomeText: React.FC<Props> = ({
  outcome,
  className,
  onRemove,
}) => {
  const outcomeTextByType: Record<JourneyOutcomeType, string> = {
    [JourneyOutcomeType.Ended]: "Customer has ended their journey.",
    [JourneyOutcomeType.HandedOver]: "Customer has handed over the journey.",
    [JourneyOutcomeType.IntentFulfilled]: "Customer Intent has been fulfilled.",
    [JourneyOutcomeType.IntentUnfulfilled]:
      "Customer Intent has been unfulfilled.",
  };

  const outcomeText = outcomeTextByType[outcome];

  return (
    <div className={cn("mt-4 flex justify-between", className)}>
      <div className="flex items-center gap-2">
        <OutcomeIcon outcome={outcome} />
        <span>{outcomeText}</span>
      </div>
      {onRemove && (
        <IxIconButton
          icon="close"
          ghost
          size="24"
          className="ml-1 scale-75"
          onClick={onRemove}
        />
      )}
    </div>
  );
};
