import { IxTypography } from "@siemens/ix-react";
import React from "react";

export const TouchpointTooltipContent = () => {
  return (
    <>
      <IxTypography format="body" bold>
        What are Touchpoints?
      </IxTypography>
      <IxTypography>
        Touchpoints are individual interaction points within Channels, such as
        the Siemens.com <span className="font-bold">landing page</span> or a{" "}
        <span className="font-bold">panel discussion</span> at an Expo.
      </IxTypography>
    </>
  );
};
