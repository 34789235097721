import { cn } from "@motius/customer-heartbeat-ui/utils";
import { IxTooltip } from "@siemens/ix-react";
import React from "react";
import { Reaction, ReactionIcon } from "./ReactionIcon";

interface ReactionInputProps {
  value: Reaction | undefined;
  onChange: (value: Reaction | undefined) => void;
}

export const ReactionInput: React.FC<ReactionInputProps> = ({
  value,
  onChange,
}) => {
  const reactions = [
    Reaction.ENTHUSIASTIC,
    Reaction.HAPPY,
    Reaction.SATISFIED,
    Reaction.FRUSTRATED,
    Reaction.CONFUSED,
    Reaction.ANGRY,
  ];

  /**
   *  Handle reaction change.
   * @param {Reaction} reaction - The reaction.
   */
  const handleReactionChange = (reaction: Reaction) => () => {
    if (value === reaction) {
      onChange(undefined);
      return;
    }
    onChange(reaction);
  };

  return (
    <div className="flex text-theme-color-std-text">
      {reactions.map((reaction) => (
        <div key={reaction}>
          <button
            onClick={handleReactionChange(reaction)}
            className={cn("p-[0.1875rem] opacity-40 hover:opacity-100", {
              "opacity-100": value === reaction,
            })}
            id={`reaction-icon-${reaction}`}
            aria-describedby={`reaction-icon-tooltip-${reaction}`}
          >
            <ReactionIcon reaction={reaction} />
          </button>
          <IxTooltip
            id={`reaction-icon-tooltip-${reaction}`}
            for={`#reaction-icon-${reaction}`}
            className="capitalize"
            placement="bottom"
          >
            {reaction}
          </IxTooltip>
        </div>
      ))}
    </div>
  );
};
