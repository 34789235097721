import debug from "debug";
import { produce } from "immer";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { PrepareJourneyTopology } from "@/utils/topology";
import { getLocalStorageSizeInMB } from "@/utils/localstorage";

const log = debug("usePrepareJourneyTopoStore");
const localStorageName = "prepareJourneyTopology";
export type TopologyState = {
  topologyId?: string;
  prepareJourneyTopology?: PrepareJourneyTopology;
  date: Date;
};

export type Actions = {
  savePrepareJourneyTopology: (
    id: string,
    prepareJourneyTopology: PrepareJourneyTopology,
  ) => void;
};

const currentCacheSize = getLocalStorageSizeInMB(localStorageName);
log("Current cache size: ", currentCacheSize);
if (currentCacheSize > 1.5) {
  log("Cache size exceeded 1.5 MB. Removing the whole cache.");
  localStorage.removeItem(localStorageName);
}

/**
 * Topology Store to keep the already pulled topologies in local storage
 * since they don't change often
 */
export const usePrepareJourneyTopoStore = create<TopologyState & Actions>()(
  persist(
    (set, get) => ({
      prepareJourneyTopology: undefined,
      topologyId: undefined,
      date: new Date(),

      savePrepareJourneyTopology: (id, topology) =>
        set(
          produce((state: TopologyState) => {
            const date = new Date();

            state.prepareJourneyTopology = topology;
            state.topologyId = id;
            state.date = date;
          }),
        ),
    }),
    {
      name: localStorageName, // name of the item in the storage (must be unique)
    },
  ),
);
