import { handleError } from "@/utils/error";
import { gql, useMutation } from "@apollo/client";
import { useCallback, useRef } from "react";

const DEBOUNCE_TIME = 60000;

const INCREMENT_JOURNEY_VIEWS = gql`
  mutation IncrementJourneyViews($id: ID!) {
    incrementJourneyViews(id: $id)
  }
`;

/**
 * Increment journey views
 * Will drop views if called again within DEBOUNCE_TIME seconds
 * @returns
 */
export const useIncrementJourneyViews = () => {
  const [incrementJourneyViewsMutation] = useMutation(INCREMENT_JOURNEY_VIEWS);
  const lastIncrementTimeRef = useRef<number>(0);

  const incrementViews = useCallback(
    (id: string) => {
      const now = Date.now();
      if (now - lastIncrementTimeRef.current >= DEBOUNCE_TIME) {
        lastIncrementTimeRef.current = now;
        incrementJourneyViewsMutation({ variables: { id } }).catch((error) => {
          handleError("Failed to increment journey views", error);
        });
      }
    },
    [incrementJourneyViewsMutation],
  );

  return { incrementViews };
};
