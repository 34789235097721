import React, { useState } from "react";
import { MetaDataSubMenu } from "./MetaDataSubMenu";
import { IxTypography, IxUpload } from "@siemens/ix-react";
import {
  imageCache,
  openImageInNewTab,
  openInMemoryImageInNewTab,
  uploadImage,
} from "./upload";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { Attachment } from "@/utils/apollo/resolvers";
import { AttachmentItem } from "./AttachmentItem";
import { v4 } from "uuid";
import { hideSpinner, showSpinner } from "@motius/customer-heartbeat-ui";
import { showPrivacyNoticeIfNecessary } from "./privacyNotice";
import { handleError } from "@/utils/error";
import { MapNode } from "../../drawTouchpoint";

interface ScreenshotSubMenuProps {
  node?: MapNode | undefined;
  onClose: (isCancel?: boolean) => void;
  openDirection?: "up" | "down";
}

/**
 * Files that are uploaded while this menu is open
 */
export type InMemoryAttachment = Attachment & {
  file?: File;
};

export const ScreenshotSubMenu: React.FC<ScreenshotSubMenuProps> = ({
  onClose,
  node,
}) => {
  const { getNodeById, addAttachment, removeAttachment } = useChannelStore();

  // in memory attachments that were not yet uploaded
  const [notYetUploadedImages, setNotYetUploadedImages] = useState<
    InMemoryAttachment[]
  >([]);
  const [imagesIdsToDelete, setImageIdsToDelete] = useState<string[]>([]);
  const [isShowingPrivacyMessage, setIsShowingPrivacyMessage] = useState(false);

  const removeDeleted = (files: InMemoryAttachment[]) =>
    files.filter((f) => !imagesIdsToDelete.includes(f.id));

  const nodeFid = node?.fid;
  if (!nodeFid) return null;
  const nodeData = getNodeById(nodeFid);
  // attachments that were already in the db when this menu was opened
  // or were added to the zustand store when the add button was clicked
  const attachedFiles = removeDeleted(nodeData?.attachments ?? []);

  const fileCount = attachedFiles.length + notYetUploadedImages.length;

  // this is the event handler for the upload button
  // files that are uploaded are added to the notYetUploadedImages state
  // we keep them in memory until the user clicks the confirm button
  // then we upload them to the server
  const handleFilesChanged = (event: CustomEvent<File[]>) => {
    showPrivacyNoticeIfNecessary(setIsShowingPrivacyMessage);
    const files = event.detail;
    if ((files.length ?? 0) + fileCount > 5) {
      // this should not happen because the button is disabled when 5 files are
      // selected and I can only select 1 file at a time.
      // If someone manages to select 2 files and has 4 they will see this.
      alert("max 5 files");
      return;
    } else {
      const nFiles = files.map((file) => ({ file, id: v4(), name: file.name }));
      setNotYetUploadedImages([...notYetUploadedImages, ...nFiles]);
    }
  };

  const hasChanges = () => {
    return !!(notYetUploadedImages.length || imagesIdsToDelete.length);
  };

  // this is the event handler for the confirm button
  // we now upload the files to the server
  // and add/remove the image ids from the node in Zustand
  // they will only be stored in the db when the user clicks
  // save as draft or publish
  const uploadFilesAndUpdateZustand = async () => {
    // if the user didnt do any changes we can close the menu
    if (!hasChanges()) {
      onClose();
      return;
    }

    showSpinner();

    // upload everything that is new and not deleted
    const filesToUpload: InMemoryAttachment[] = removeDeleted(
      notYetUploadedImages,
    ).map((file) => ({
      id: file.id,
      name: file.name,
      file: file.file,
    }));

    try {
      const uploadPromises = filesToUpload.map((file) => uploadImage(file));
      await Promise.all(uploadPromises);

      // after uploading all images
      // we add the new images to the node
      // and we add them to a cache.
      // the cache is neeeded to open images that
      // were not yet added to the journey in the db
      filesToUpload.forEach((file) => {
        addAttachment(file.name, file.id, nodeFid);
        imageCache.set(file.id, file);
      });

      imagesIdsToDelete.forEach((id) => deleteImage(id));
      setNotYetUploadedImages([]);
      onClose();
    } catch (error) {
      console.log(error);
      handleError(error, `Error uploading files!`);
    } finally {
      hideSpinner();
    }
  };

  // delete button handler
  const markImageAsDeleted = (id: string) => {
    setImageIdsToDelete([...imagesIdsToDelete, id]);
  };

  // this is called with all images marked for deletion
  // when the user clicks the confirm button
  const deleteImage = (id: string) => {
    if (imageCache.has(id)) {
      imageCache.delete(id);
    }
    removeAttachment(id, node.fid);
  };

  const handleCancel = () => {
    onClose(true);
  };

  return (
    <MetaDataSubMenu
      label="Screenshots or Images"
      onConfirm={uploadFilesAndUpdateZustand}
      onCancel={handleCancel}
      showCloseWarning={hasChanges()}
      isOutsideClickAllowed={isShowingPrivacyMessage}
    >
      <div className="flex w-[19rem] flex-col items-start gap-1.5">
        <IxTypography format="body" textColor="weak">
          You can add up to 5 attachments to this touchpoint to visualize your
          customer’s journey. PNG and JPG, up to 10 MB per file.
        </IxTypography>
        <IxUpload
          disabled={fileCount >= 5}
          className="w-full"
          accept="image/*"
          onFilesChanged={handleFilesChanged}
        />
        {/**
         * Files that are either coming from the DB
         * or were added to the zustand store when the add button was clicked
         */}
        {attachedFiles.map((att: Attachment) => (
          <AttachmentItem
            key={att.id}
            att={att}
            onDelete={markImageAsDeleted}
            onOpen={(att) => openImageInNewTab(att.id)}
          />
        ))}
        {/**
         * Files that were selected for upload, but the confirm button
         * was not yet clicked
         */}
        {notYetUploadedImages.map((file) => (
          <AttachmentItem
            key={file.id}
            att={file}
            onDelete={() =>
              setNotYetUploadedImages(
                notYetUploadedImages.filter((f) => f.id !== file.id),
              )
            }
            onOpen={(att) => openInMemoryImageInNewTab(att.file as File)}
          />
        ))}
      </div>
    </MetaDataSubMenu>
  );
};
