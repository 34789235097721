import { useChannelStore } from "../../../hooks/useChannelStore";
import { JourneyTypePill } from "../../Journeys/JourneyTypePill";
import { SummarySection } from "./SummarySection";

export const JourneyOriginSection = () => {
  const { journeyType } = useChannelStore();

  if (!journeyType) return null;

  return (
    <SummarySection>
      <h1 className="mb-2 font-bold text-sm">Journey Origin</h1>
      <JourneyTypePill className="my-0" type={journeyType} />
    </SummarySection>
  );
};
