import React from "react";
import { Avatar } from "./Avatar";
import { FieldError } from "react-hook-form";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { AvatarType } from "@motius/customer-heartbeat-utils/schemas";
import { InputError } from "@motius/customer-heartbeat-ui";

interface AvatarInputProps {
  value: AvatarType;
  onChange: (value: AvatarType) => void;
  disabled?: boolean;
  variant?: "normal" | "mini";
  label?: string;
  error?: FieldError;
}

export const AvatarInput: React.FC<AvatarInputProps> = ({
  value,
  onChange,
  disabled,
  variant = "normal",
  label,
  error,
}) => {
  const types = [
    AvatarType.First,
    AvatarType.Second,
    AvatarType.Third,
    AvatarType.Fourth,
    AvatarType.Fifth,
    AvatarType.Sixth,
    AvatarType.Seventh,
    AvatarType.Eighth,
    AvatarType.Ninth,
  ];

  const handleClick = (avatarType: AvatarType) => {
    if (!disabled) {
      onChange(avatarType);
    }
  };

  return (
    <div className={cn("flex flex-col gap-1")}>
      {label && <label className="font-sans">{label}</label>}
      <div
        className={cn("grid grid-cols-3 gap-2", {
          "!gap-x-[2.125rem] gap-2": variant === "mini",
          "w-[27.5rem]": variant === "normal",
        })}
      >
        {types.map((avatarType) => (
          <Avatar
            size={variant === "mini" ? 64 : 100}
            key={avatarType}
            type={avatarType}
            className={cn(
              "cursor-pointer opacity-30",
              {
                "opacity-30": disabled,
                "opacity-100": value === avatarType,
              },
              { "hover:opacity-100": !disabled },
            )}
            onClick={() => handleClick(avatarType)}
          />
        ))}
      </div>
      <InputError error={error} />
    </div>
  );
};
