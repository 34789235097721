import { IxIconButton } from "@siemens/ix-react";
import React from "react";
import { LinkSubMenu } from "./MetaDataSubMenus/LinkSubMenu";
import { TitleSubMenu } from "./MetaDataSubMenus/TitleSubMenu";
import { ReactionSubMenu } from "./MetaDataSubMenus/ReactionSubMenu";
import { ScreenshotSubMenu } from "./MetaDataSubMenus/ScreenshotSubMenu";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { MapNode } from "../drawTouchpoint";
import { notify } from "@motius/customer-heartbeat-ui";

export interface Selected {
  position: { x: number; y: number };
  node: MapNode;
  userSelected?: boolean;
}

interface MetaDataMenuProps {
  selected?: Selected;
}

enum SubMenus {
  REACTION = "REACTION",
  SCREENSHOT = "SCREENSHOT",
  LINK = "LINK",
  TITLE = "TITLE",
}

export const MetaDataMenu: React.FC<MetaDataMenuProps> = ({ selected }) => {
  const [open, setOpen] = React.useState<SubMenus | undefined>(undefined);
  const [hovered, setHovered] = React.useState<SubMenus | undefined>(undefined);
  const [openUp, setOpenUp] = React.useState(false);
  const ref = React.useRef<HTMLDivElement>(null);

  const subMenus = [
    {
      id: SubMenus.REACTION,
      icon: "/reaction.svg",
    },
    {
      id: SubMenus.TITLE,
      icon: "text",
    },
    // {
    //   id: SubMenus.LINK,
    //   icon: "link",
    // },
    {
      id: SubMenus.SCREENSHOT,
      icon: "screenshot",
    },
  ];

  /**
   * Handles the sub menu close event
   * @param isCancel - The flag to indicate if the entry is cancelled
   */
  const handleSubMenuClose = (isCancel?: boolean) => {
    setOpen(undefined);
    setHovered(undefined);
    if (isCancel) {
      notify.success("Your entry has been cancelled.");
    }
  };

  let openMenu = null;
  const currentOpen = hovered ?? open;
  switch (currentOpen) {
    case SubMenus.REACTION:
      openMenu = (
        <ReactionSubMenu onClose={handleSubMenuClose} node={selected?.node} />
      );
      break;
    case SubMenus.SCREENSHOT:
      openMenu = (
        <ScreenshotSubMenu node={selected?.node} onClose={handleSubMenuClose} />
      );
      break;
    case SubMenus.LINK:
      openMenu = <LinkSubMenu />;
      break;
    case SubMenus.TITLE:
      openMenu = (
        <TitleSubMenu onClose={handleSubMenuClose} node={selected?.node} />
      );
      break;
  }

  /**
   *  Handles the mouse enter event for the sub menu
   * @param {SubMenus} subMenu - The sub menu
   */
  const handleMouseEnter = (subMenu: SubMenus) => {
    if (
      (ref.current?.getBoundingClientRect().top ?? 0) + 232 >
      (ref.current?.parentElement?.offsetHeight ?? 0)
    ) {
      setOpenUp(true);
    } else {
      setOpenUp(false);
    }
    setHovered(subMenu);
  };
  return (
    <div
      className="theme-brand-dark absolute left-0 top-0 flex flex-col items-start gap-1 rounded-lg py-2"
      style={{
        transform: `translate(${selected?.position?.x ?? 0}px, ${selected?.position?.y ?? 0}px)`,
      }}
      ref={ref}
    >
      <div className="flex w-auto rounded bg-chb-menu-background shadow-metaDataSubMenu">
        {subMenus.map((subMenu) => (
          <IxIconButton
            variant="secondary"
            ghost
            icon={subMenu.icon}
            id={`sub-menu-button-${subMenu.id}`}
            key={subMenu.id}
            onClick={() => setOpen(subMenu.id)}
            onMouseEnter={() => handleMouseEnter(subMenu.id)}
            onMouseLeave={() => setHovered(undefined)}
          ></IxIconButton>
        ))}
      </div>
      <div className={cn({ "absolute bottom-11": openUp })}>{openMenu}</div>
    </div>
  );
};
