import React, { useState, useEffect } from "react";
import { NothingHere } from "./NothingHere";
import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import { Journey } from "@/utils/apollo/resolvers";
import { PublishedJourneys } from "../Journeys/PublishedJourneys";
import { useDeleteJourney } from "@/src/hooks/useDeleteJourney";
import { useSpinner } from "@/src/hooks/useSpinner";
import { handleError } from "@/utils/error";
import { JOURNEY_FIELDS } from "../Journeys";

const RECOMMENDED_JOURNEYS = gql`
  ${JOURNEY_FIELDS}
  query RecommendedJourneys {
    recommendedJourneys {
      ...JourneyFields
    }
  }
`;

export const RecommendedJourneys: React.FC = () => {
  const [journeys, setJourneys] = useState<Journey[]>([]);

  const { loading, error, data, refetch } = useQuery(RECOMMENDED_JOURNEYS, {
    fetchPolicy: "network-only",
  });

  const { handleDelete, isDeleting } = useDeleteJourney(refetch);

  const isSpinner = loading || isDeleting;
  useSpinner(isSpinner);

  useEffect(() => {
    if (data?.recommendedJourneys) {
      setJourneys(data.recommendedJourneys);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError(error, "Error fetching recommended journeys");
    }
  }, [error]);

  if (error) return <p>Error: {error.message}</p>;
  if (isSpinner && !journeys.length) return null;

  return (
    <>
      {journeys.length > 0 ? (
        <div className="grid size-full gap-4 overflow-auto pb-16 pt-3">
          <PublishedJourneys
            journeys={journeys}
            onDelete={handleDelete}
            onUnfavorite={refetch}
          />
        </div>
      ) : (
        <NothingHere
          iconName="hourglass"
          bodyText="Your recommendations are based on the journeys you create. Please start to create journeys so that we can recommend similar journeys to you."
        />
      )}
    </>
  );
};
