import { IxTypography } from "@siemens/ix-react";
import React from "react";

export const ChannelTooltipContent = () => {
  return (
    <>
      <IxTypography format="body" bold>
        What are Channels?
      </IxTypography>
      <IxTypography>
        Channels are platforms or websites like{" "}
        <span className="font-bold">Siemens.com, Google.com</span> or physical
        events like an <span className="font-bold">Expo.</span>
      </IxTypography>
    </>
  );
};
