import { Button, Select, Spinner } from "@motius/customer-heartbeat-ui";
import {
  IxCol,
  IxInputGroup,
  IxModalContent,
  IxModalHeader,
  IxTypography,
  Modal,
  ModalRef,
} from "@siemens/ix-react";
import React from "react";
import { Controller, UseFormReturn, useFormState } from "react-hook-form";
import { z } from "zod";
import { useRequestMissing } from "./useRequestMissing";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { handleError } from "@/utils/error";

export const requestMissingFormSchema = z.object({
  name: z.string(),
  url: z
    .string()
    .transform((val) => "https://" + val)
    .pipe(z.string().url())
    .optional()
    .or(z.literal("").transform(() => undefined)),
  comment: z.string().optional(),
  category: z.string(),
});

export type RequestMissingFormValues = z.infer<typeof requestMissingFormSchema>;

interface RequestMissingDialogProps {
  onClose: (success?: boolean) => void;
  onCancel: () => void;
  type: "Interaction" | "Touchpoint" | "Channel";
  groups: string[];
  journeyId: string;
  methods: UseFormReturn<RequestMissingFormValues>;
}

export const RequestMissingDialog = React.forwardRef<
  ModalRef,
  RequestMissingDialogProps
>(({ onClose, type, groups, journeyId, methods, onCancel }, ref) => {
  const {
    addTouchpointRequest,
    addLocationRequest,
    addChannelRequest,
    journeyTopologyId,
  } = useChannelStore();
  const { isValid } = useFormState();
  const { submitRequest, loading } = useRequestMissing(
    journeyId,
    journeyTopologyId,
  );

  const onSubmit = methods.handleSubmit(
    async (data: RequestMissingFormValues) => {
      await methods.trigger();
      try {
        const result = await submitRequest(data, type);
        if (type === "Interaction") {
          addLocationRequest(result.data.requestLocation);
        } else if (type === "Touchpoint") {
          addTouchpointRequest(result.data.requestTouchpoint);
        } else if (type === "Channel") {
          addChannelRequest(result.data.requestChannel);
        }
        onClose(true);
      } catch (e) {
        console.log(e);
        handleError(
          e,
          "An error occurred while processing the journey and storing it",
        );
      }
    },
  );

  return (
    <Modal ref={ref}>
      <>
        <IxModalHeader onCloseClick={onCancel}>Journey Editing</IxModalHeader>

        <IxModalContent className="my-auto flex h-[30rem] grow flex-col px-2 py-0">
          <div className="flex justify-between">
            <IxTypography format="h2">Request A Missing {type}</IxTypography>
          </div>
          <IxTypography format="body" className="mt-1.5 w-[27rem]">
            After you submit, the admin will be notified to process your
            request. Please note that your contact details will be shared with
            the admin.
          </IxTypography>
          <form
            className={cn("flex h-full flex-col needs-validation", {
              "was-validated": methods.formState.isSubmitted,
            })}
            onSubmit={onSubmit}
          >
            {loading ? (
              <Spinner />
            ) : (
              <div className="mt-4 flex grow flex-col justify-between gap-3">
                <div className="grid grid-cols-2 gap-2">
                  <Controller
                    name="name"
                    render={({ field }) => (
                      <div>
                        <label
                          htmlFor="request-missing-name-input"
                          className="font-sans"
                        >
                          Name of {type}
                        </label>
                        <input
                          id="request-missing-name-input"
                          placeholder="Enter Name"
                          className="h-[2.125rem] px-2"
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </div>
                    )}
                  />

                  <Controller
                    name="category"
                    render={({ field }) => (
                      <Select
                        sortAlphabetically
                        label={`Choose ${type} Group`}
                        autoFocus
                        allowClear={false}
                        id="request-missing-group-select"
                        value={field.value}
                        onChange={field.onChange}
                        options={groups.map((group) => ({
                          value: group,
                          label: group,
                        }))}
                        wrapperClassName="inline-block text-left grow"
                      />
                    )}
                  />
                </div>
                <Controller
                  name="url"
                  render={({ field }) => (
                    <IxCol size="4">
                      <label
                        htmlFor="request-missing-url-input"
                        className="font-sans"
                      >
                        URL (optional)
                      </label>
                      <IxInputGroup>
                        <span slot="input-start" className="mt-[2px]">
                          https://
                        </span>
                        <input
                          id="request-missing-url-input"
                          placeholder={"..."}
                          className={cn("h-[2.125rem]", {
                            "is-invalid": methods.formState.errors.url,
                          })}
                          value={field.value}
                          onChange={field.onChange}
                        />
                      </IxInputGroup>
                      <div className="invalid-feedback">
                        {methods.formState.errors.url?.message}
                      </div>
                    </IxCol>
                  )}
                />

                <Controller
                  name="comment"
                  render={({ field }) => (
                    <div>
                      <label
                        htmlFor="request-missing-comment-input"
                        className="font-sans"
                      >
                        Comment (optional)
                      </label>
                      <input
                        id="request-missing-comment-input"
                        placeholder="Enter Comment"
                        className="h-[2.125rem] px-2"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    </div>
                  )}
                />

                <div className="mt-auto self-end p-2">
                  <Button type="submit" disabled={!isValid}>
                    Submit
                  </Button>
                </div>
              </div>
            )}
          </form>
        </IxModalContent>
      </>
    </Modal>
  );
});
