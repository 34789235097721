export const Unauthorized = () => {
  return (
    <div
      className="w-screen h-screen flex items-center justify-center"
      role="alert"
    >
      <div className="flex flex-col gap-6 p-6 rounded-lg bg-middle-gray font-bold text-primary-contrast">
        <h1>You are not authorized to use this website.</h1>
      </div>
    </div>
  );
};
