export const FIRST_TOUCHPOINT_PADDING_LEFT = 0.6; // measured in columns

export const RECT_WIDTH = 200;
export const RECT_WIDTH_HALF = RECT_WIDTH / 2;
export const RECT_HEIGHT = 218;
// this is 129 in figma
// but the text background then leaks into the next channel background
// The value is the distance from the top of a rect to the center of
// where we draw things like avatar / TP / LOC
export const RECT_DIST_TOP = 121;

export const LINE_LENGTH = 150;

export const CIRCLE_RADIUS_BIG = 18 / 2;
export const CIRCLE_RADIUS_BIG_WITH_LOCATION = CIRCLE_RADIUS_BIG - 2;
export const CIRCLE_RADIUS_SMALL = 12 / 2;
export const CIRCLE_RADIUS_WITH_LOCATION = 3;

export const TOUCHPOINT_DIST_TOP = 123;
export const FIRST_TOUCHPOINT_X_COORD = 110;

export const AVATAR_WIDTH_HEIGHT = 50;

export const TRANSITION_TIME = 700;

export const PLACEHOLDER_TOUCHPOINT_TP_ID = "placeholderTp";

export const HORIZONTAL_SPACE_BETWEEN_TWO_LINES_ON_SAME_MAP_NODE = 8; // distance in px
