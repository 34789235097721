export const thumbsUpFilled = (className: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0007 6.00065H9.03769L9.58469 4.03145C9.96133 2.67554 8.94163 1.33398 7.53438 1.33398C6.61845 1.33398 5.80529 1.92008 5.51564 2.78901L4.4451 6.00065H1.33398V13.334H12.6673L14.0007 6.00065Z"
      fill="#000028"
      fillOpacity="0.6"
    />
    <rect x="3.5" y="7" width="1" height="5" fill="white" />
  </svg>
);

export const thumbsUp = (className: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0007 6.00065H9.03769L9.58469 4.03145C9.96133 2.67554 8.94163 1.33398 7.53438 1.33398C6.61845 1.33398 5.80529 1.92008 5.51564 2.78901L4.4451 6.00065H1.33398V13.334H12.6673L14.0007 6.00065ZM5.33398 7.33398H5.40611L6.78055 3.21065C6.88871 2.88618 7.19236 2.66732 7.53438 2.66732C8.05987 2.66732 8.44064 3.16827 8.3 3.67459L7.2835 7.33398H12.403L11.5545 12.0007H5.33398V7.33398ZM4.00065 7.33398H2.66732V12.0007H4.00065V7.33398Z"
      fill="#000028"
      fillOpacity="0.6"
    />
  </svg>
);
