/**
 *  Optional field
 */
export type Maybe<T> = T | null | undefined;

/**
 *  Returns the last element of an array or undefined if the array is empty
 * @param array
 */
export function getLastElement<T>(array?: T[]): T | undefined {
  if (!array) return undefined;
  return array.length > 0 ? array[array.length - 1] : undefined;
}

/**
 * Creates a promise that resolves after a specified number of milliseconds.
 *
 * @param {number} ms - The number of milliseconds to wait before the promise resolves.
 * @returns {Promise<void>} A promise that resolves after the specified delay.
 *
 * @example
 * async function example() {
 *   console.log('Waiting for 500 milliseconds...');
 *   await wait(500);
 *   console.log('500 milliseconds have passed.');
 * }
 *
 * example();
 */
export function wait(ms: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 *  Opens a link in a new tab
 * @param url - The URL to open in a new tab
 */
export const openLinkInNewTab = (url: string) => {
  const anchor = document.createElement("a");
  anchor.href = url;
  anchor.target = "_blank";
  anchor.rel = "noopener noreferrer";
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};

export * from "./routing";
