import "@motius/customer-heartbeat-ui/globals";
import { ApolloProvider } from "@apollo/client";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback } from "../routing/Error/ErrorFallback";
import { MsalWrapper } from "../routing/auth/MsalWrapper";
import { useApollo } from "@/utils/apollo";
import ProtectedRoute from "../routing/ProtectedRoute";
import { ViewJourney } from "../components/ViewJourney";
import { EditJourney } from "../components/EditJourney";
import Journeys from "./journeys";
import { Discover } from "./discover";
import { Statistics } from "./Statistics";
import Login from "./Login";
import { Unauthorized } from "../routing/Error/Unauthorized";
import { IxApplicationContext } from "@siemens/ix-react";
import {
  BackdropSpinner,
  NotFound,
  notify,
} from "@motius/customer-heartbeat-ui";
import { PrepareYourJourneyDialog } from "../components/PrepareYourJourney/PrepareYourJourneyDialog";
import * as Sentry from "@sentry/react";
import { useEffect } from "react";

/**
 * Main app of the application
 */
export const App = () => {
  const apolloClient = useApollo();
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  useEffect(() => {
    if (localStorage.getItem("testToast") === "true") {
      // Expose the showToast function to the window object
      // @ts-ignore
      window.notify = notify;
    }
  }, []);

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <MsalWrapper>
        <ApolloProvider client={apolloClient}>
          <Router>
            {/**
             * If the IX context is outside of Apollo and the router then
             * you cannot use the navigation and api inside dialogs.
             * Thus the IX context should be pretty low in the component tree.
             */}
            <IxApplicationContext>
              <SentryRoutes>
                <Route
                  path="/journeys/:id/view" // Route to capture UUID
                  element={
                    <ProtectedRoute>
                      <ViewJourney />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/journeys/:id" // Route to capture UUID
                  element={
                    <ProtectedRoute>
                      <EditJourney />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/prepare"
                  element={
                    <ProtectedRoute>
                      <PrepareYourJourneyDialog />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/journeys"
                  element={
                    <ProtectedRoute>
                      <Journeys />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/discover"
                  element={
                    <ProtectedRoute>
                      <Discover />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/statistics"
                  element={
                    <ProtectedRoute>
                      <Statistics />
                    </ProtectedRoute>
                  }
                />
                <Route path="/unauthorized" element={<Unauthorized />} />
                <Route path="/" element={<Login />} />
                <Route
                  path="*"
                  element={
                    <ProtectedRoute>
                      <NotFound />
                    </ProtectedRoute>
                  }
                />
              </SentryRoutes>
              <BackdropSpinner />
            </IxApplicationContext>
          </Router>
        </ApolloProvider>
      </MsalWrapper>
    </ErrorBoundary>
  );
};
