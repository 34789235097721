import React from "react";
// @ts-expect-error This import works
import IndustryImage from "../industry.svg?react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { Select, Spinner, Stepper } from "@motius/customer-heartbeat-ui";
import { PrepareYourJourneyStep, openDialogOrStep } from "@/utils/router";
import { Industry } from "@/utils/apollo/resolvers";
import { useSearchParams } from "react-router-dom";
import Headline from "../components/Headline";
import { pxToRem } from "@motius/customer-heartbeat-ui/utils";

interface PrepareJourneyIndustryStepProps {
  industries: Industry[] | undefined;
}

/**
 * Industry Step for Prepare Your Journey Dialog
 * @param props Props
 * @param props.onClose Callback for close the dialog
 * @param props.industries Industries
 */
export const PrepareJourneyIndustryStep: React.FC<
  PrepareJourneyIndustryStepProps
> = ({ industries }) => {
  const [, setSearchParams] = useSearchParams();
  const { isValid } = useFormState();
  const { trigger } = useFormContext();

  /**
   * Handle Back Button Click
   */
  const handleBack = () => {
    openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Second);
  };

  /**
   * Handle Next Button Click
   */
  const handleNext = async () => {
    if (await trigger()) {
      openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Fourth);
    }
  };

  if (!industries) return <Spinner />;

  const options = industries.map((industry) => ({
    value: industry.id,
    label: industry.name,
  }));

  return (
    <div className="flex h-full flex-col items-center justify-between">
      <Headline text="...and you are working in? " />

      <div className="flex w-[27.5rem] flex-col items-center gap-3">
        <Controller
          name="industryId"
          render={({ field }) => (
            <Select
              autoFocus
              sortAlphabetically
              label="Industry"
              value={field.value}
              allowClear={false}
              onChange={field.onChange}
              id="pp_new"
              options={options}
              wrapperClassName=""
            />
          )}
        />
        <IndustryImage width={pxToRem(554)} height={pxToRem(396)} />
      </div>
      <div className="mb-4 w-full">
        <Stepper
          onBackClick={handleBack}
          onNextClick={handleNext}
          isNextDisabled={!isValid}
          steps={7}
          activeStep={2}
        />
      </div>
    </div>
  );
};
