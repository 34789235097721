import { Maybe } from "@motius/customer-heartbeat-utils";
import { Topology } from "@motius/customer-heartbeat-utils/schemas";

/**
 * from the name stored in the journey we should be able to find the respective id
 * @param topo the topology data
 * @param name the name of the item
 * */
export const getDefaultId = (topo: Topology[], name: string) => {
  const defaultItem = topo.find((i) => i.name === name);
  if (defaultItem == null) {
    throw new Error(`The default item ${name} is not found`);
  }
  return defaultItem.id;
};

export const getOptionalDefaultId = (
  topo: Topology[],
  name: Maybe<string>,
): string => {
  if (name == null || name === "") return "";
  try {
    return getDefaultId(topo, name);
  } catch (e) {
    return "";
  }
};
