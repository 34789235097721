import React from "react";
import { Stepper } from "@motius/customer-heartbeat-ui";
// @ts-expect-error This import works
import CustomerSvg from "../customer.svg?react";
import { PrepareYourJourneyStep, openDialogOrStep } from "@/utils/router";
import { useSearchParams } from "react-router-dom";
import Headline from "../components/Headline";

interface PrepareJourneyIntroStepProps {}

/**
 *  Introduction Step for Prepare Your Journey Dialog
 * @param props Props
 * @param props.onClose Callback for close the dialog
 */
export const PrepareJourneyIntroStep: React.FC<
  PrepareJourneyIntroStepProps
> = () => {
  const [, setSearchParams] = useSearchParams();
  /**
   * Handle Next Button Click
   */
  const handleNext = () => {
    openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Second);
  };
  return (
    <div className="flex grow flex-col items-center justify-between">
      <Headline text="Put yourself in the shoes of the customer..." />
      <CustomerSvg width="38.31rem" height="29.125rem" />
      <div className="mb-4 w-full">
        <Stepper
          onNextClick={handleNext}
          nextButtonText="Start"
          isNextDisabled={false}
          steps={7}
          activeStep={0}
        />
      </div>
    </div>
  );
};
