import { cn } from "@motius/customer-heartbeat-ui/utils";
import React, { PropsWithChildren } from "react";
import { JourneyCreationMotivation } from "./NoJourney";

/**
 *  Journey Grid component
 * Shows a grid of journeys
 * @param props Props
 * @param props.children Children
 */
export const JourneyGrid: React.FC<
  PropsWithChildren<{
    fullHeight?: boolean;
    journeyCount?: number;
  }>
> = ({ children, fullHeight, journeyCount }) => {
  const ref = React.useRef<HTMLUListElement>(null);
  const [width, setWidth] = React.useState<number>(0);

  React.useEffect(() => {
    if (journeyCount !== undefined) {
      const handleResize = () => {
        if (ref.current) {
          setWidth(ref.current.parentElement?.offsetWidth ?? 0);
        }
      };
      handleResize(); // Initial call

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  const GAP = 16;
  const MIN_CARD_WIDTH = 280 + GAP;

  const { gridTemplateColumns, showMotivationCard, showMotivationAlone } =
    React.useMemo(() => {
      let gridTemplateColumns = "";

      let showMotivationCard = false;
      let showMotivationAlone = false;
      let showMotivationCardInSameRow = false;

      if (journeyCount !== undefined) {
        const maxNumberOfCardsInWidth = Math.floor(
          (width + GAP) / MIN_CARD_WIDTH,
        );
        showMotivationCard = journeyCount !== undefined && journeyCount < 4;
        showMotivationCardInSameRow =
          showMotivationCard && maxNumberOfCardsInWidth >= journeyCount + 1;
        showMotivationAlone =
          !showMotivationCardInSameRow &&
          journeyCount <= maxNumberOfCardsInWidth;
      }

      if (showMotivationCardInSameRow) {
        gridTemplateColumns = `repeat(${journeyCount}, minmax(280px, 350px)) auto`;
      } else {
        gridTemplateColumns = `repeat(auto-fill, minmax(280px, 1fr))`;
      }
      return {
        gridTemplateColumns,
        showMotivationCard,
        showMotivationAlone,
      };
    }, [journeyCount, width]);

  return (
    <ul
      className={cn("grid gap-3", { "h-full": fullHeight })}
      style={{
        gridTemplateColumns,
      }}
      ref={ref}
    >
      {children}
      {showMotivationCard && journeyCount && (
        <div
          style={{
            gridColumn: showMotivationAlone ? `1 / -1` : "auto",
          }}
        >
          <JourneyCreationMotivation numberOfJourneys={journeyCount} />
        </div>
      )}
    </ul>
  );
};
