import { useState } from "react";
import { useChannelStore } from "../../hooks/useChannelStore";
import { ProfileCard } from "../ProfileCard";
import { AvatarType } from "@motius/customer-heartbeat-utils/schemas";
import { Summary } from "./Summary/Summary";
import TextJourney from "../TextJourney/TextJourney";
import { IxTabItem, IxTabs } from "@siemens/ix-react";
import { useUserDetails } from "@motius/customer-heartbeat-utils/auth";
import { Comments } from "./Comments";
import { cn } from "@motius/customer-heartbeat-ui/utils";

export const SidePanel = () => {
  const [activeTab, setActiveTab] = useState("summary");
  const {
    industry,
    customerType,
    customerRole,
    motivation,
    avatarRef,
    description,
    region,
    isPublished,
  } = useChannelStore();

  const renderTabContent = () => {
    if (activeTab === "summary") {
      return <Summary />;
    } else if (activeTab === "journey") {
      return (
        <div className="h-full pt-3">
          <TextJourney editable={false} />
        </div>
      );
    } else {
      return <Comments />;
    }
  };

  return (
    <div
      className={cn(
        "flex flex-col items-start text-std",
        "w-[25rem]",
        "h-585:w-[30rem]",
        "h-620:w-[29rem]",
        "h-700:w-[28rem]",
      )}
    >
      <div className="w-full p-3">
        <ProfileCard
          fullWidth
          customer={{
            role: customerRole?.name ?? "",
            industry: industry?.name ?? "",
            motivation: motivation?.name ?? "",
            intent: motivation?.intent?.name ?? "",
            words: description ?? "",
            type: customerType?.name ?? "",
            avatar: avatarRef as AvatarType,
            region: region?.name ?? "",
          }}
        />
      </div>
      {isPublished ? (
        <>
          <div className="flex w-full px-3">
            <IxTabs className="flex w-full">
              <IxTabItem
                layout="stretched"
                className="grow"
                selected={activeTab === "summary"}
                onClick={() => setActiveTab("summary")}
              >
                Summary
              </IxTabItem>
              <IxTabItem
                className="grow"
                layout="stretched"
                selected={activeTab === "journey"}
                onClick={() => setActiveTab("journey")}
              >
                Journey
              </IxTabItem>
              <IxTabItem
                className="grow"
                layout="stretched"
                selected={activeTab === "comments"}
                onClick={() => setActiveTab("comments")}
              >
                Comments
              </IxTabItem>
            </IxTabs>
          </div>
          <div className={"h-64 w-full grow px-[0.75rem]"}>
            {renderTabContent()}
          </div>
        </>
      ) : (
        <div className="grow px-[0.75rem] w-full overflow-auto">
          <TextJourney editable={false} />
        </div>
      )}
    </div>
  );
};
