import { gql } from "@apollo/client";

export const LOAD_JOURNEY = gql`
  query LoadJourney($id: ID!) {
    journey(id: $id) {
      id
      topologyId
      ownerGid
      ownerName
      createdDate
      lastSavedDate
      isPublished
      industry {
        topologyId
        id
        name
      }
      customerRole {
        topologyId
        id
        name
      }
      customerType {
        topologyId
        id
        name
      }
      avatarRef
      region {
        topologyId
        id
        name
      }
      buyerType {
        topologyId
        id
        name
      }
      priorContact {
        topologyId
        id
        name
      }
      painpoints {
        topologyId
        id
        name
      }
      motivation {
        id
        topologyId
        name
        intent {
          id
          intent {
            id
            name
            topologyId
          }
          name
          topologyId
        }
      }
      experienceDrivers {
        topologyId
        id
        name
        rating
      }
      journeyType {
        badge
        description
      }
      description
      outcome {
        type
      }
      comments {
        id
        commentedBy {
          name
          gid
        }
        likedBy
        text
        createdAt
        updatedAt
      }
      channels {
        id
        topologyId
        name
        nextTransition
        prevTransition
        intro_fragment
        category
        isCustom
        touchpoints {
          attachments {
            id
            name
          }
          id
          name
          isCustom
          title
          attachments {
            id
          }
          intro_fragment
          nextTransition
          prevTransition
          topologyId
          reaction {
            name
            reason
            id
          }
          question {
            question
            answer
          }
          locations {
            attachments {
              id
              name
            }
            question {
              id
              question
              answer
            }
            topologyId
            nextTransition
            id
            isCustom
            name
            title
            reaction {
              name
              reason
              id
            }
            intro_fragment
            locations {
              attachments {
                id
                name
              }
              topologyId
              id
              name
              isCustom
              title
              intro_fragment
              reaction {
                name
                reason
                id
              }
            }
          }
        }
      }
    }
  }
`;
