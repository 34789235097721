import { cn } from "@motius/customer-heartbeat-ui/utils";

export const TextAreaStyle = cn(
  "bg-theme-color-input",
  "text-soft",
  "border !border-theme-color-weak-text",
  "placeholder:text-soft",
  "outline-blue",

  "hover:bg-theme-color-secondary--hover",
  "active:bg-theme-color-secondary--hover",
  "focus:bg-theme-color-secondary--hover",

  "w-full h-32 p-2 ml-0 mr-4 mt-1 mb-2",
  "shadow-none",
  "resize-none",
  "transition-colors ",
);
