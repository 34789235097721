import React, { useState, useEffect } from "react";
import { NothingHere } from "./NothingHere";
import { useQuery } from "@apollo/client";
import { LOAD_JOURNEYS } from "../Journeys/Journeys";
import { Journey } from "@/utils/apollo/resolvers";
import { PublishedJourneys } from "../Journeys/PublishedJourneys";
import { useDeleteJourney } from "@/src/hooks/useDeleteJourney";
import { useSpinner } from "@/src/hooks/useSpinner";
import { useUserDetails } from "@motius/customer-heartbeat-utils/auth";
import { handleError } from "@/utils/error";

/**
 * Favorites component displays the user's favorite journeys.
 */
export const Favorites: React.FC = () => {
  const [journeys, setJourneys] = useState<Journey[]>([]);
  const { gid } = useUserDetails();

  const { loading, error, data, refetch } = useQuery(LOAD_JOURNEYS, {
    variables: {
      orderBy: { algorithm: "CREATED_DATE" },
      filter: { isPublished: true, favoritedByGid: gid },
    },
    fetchPolicy: "network-only",
  });

  const { handleDelete, isDeleting } = useDeleteJourney(refetch);

  const isSpinner = loading || isDeleting;
  useSpinner(isSpinner);

  useEffect(() => {
    if (data?.journeyList?.journeys) {
      setJourneys(data.journeyList?.journeys);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError(error, "Error fetching recommended journeys");
    }
  }, [error]);

  if (error) return <p>Error: {error.message}</p>;
  if (isSpinner && !journeys.length) return null;

  return (
    <>
      {journeys.length > 0 ? (
        <div className="grid size-full gap-4 overflow-auto pb-16 pt-3">
          <PublishedJourneys
            journeys={journeys}
            onDelete={handleDelete}
            onUnfavorite={refetch}
          />
        </div>
      ) : (
        <NothingHere
          iconName="heart"
          bodyText="Fill this space by liking a journey"
        />
      )}
    </>
  );
};
