import { useState, useEffect, useRef } from "react";
import {
  Comment,
  CommentInput,
  LikeCommentInput,
} from "@/utils/apollo/resolvers";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { useMutation, gql } from "@apollo/client";
import { v4 } from "uuid";
import { useUserDetails } from "@motius/customer-heartbeat-utils/auth";
import { notify } from "@motius/customer-heartbeat-ui";
import { handleError } from "@/utils/error";

const ADD_COMMENT_MUTATION = gql`
  mutation addComment($input: CommentInput!) {
    addComment(input: $input) {
      id
    }
  }
`;

const LIKE_COMMENT_MUTATION = gql`
  mutation likeComment($input: LikeCommentInput!) {
    likeComment(input: $input)
  }
`;

const DELETE_COMMENT_MUTATION = gql`
  mutation deleteComment($deleteCommentId: ID!) {
    deleteComment(id: $deleteCommentId)
  }
`;

const UPDATE_COMMENT_MUTATION = gql`
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
      text
      updatedAt
    }
  }
`;

export const useComments = () => {
  const { name, gid } = useUserDetails();

  const { journeyId, comments } = useChannelStore();
  const [optimisticComments, setOptimisticComments] = useState<Comment[]>([]);
  const [deletedCommentIds, setDeletedCommentIds] = useState<string[]>([]);
  const [addCommentMutation] = useMutation(ADD_COMMENT_MUTATION);
  const [likeCommentMutation] = useMutation(LIKE_COMMENT_MUTATION);
  const [deleteCommentMutation] = useMutation(DELETE_COMMENT_MUTATION);
  const [likeLoading, setLikeLoading] = useState(false);
  const toggledLikeIdsRef = useRef<string[]>([]);
  const [updateCommentMutation] = useMutation(UPDATE_COMMENT_MUTATION);

  useEffect(() => {
    // Reset optimistic comments when journeyId changes
    setOptimisticComments([]);
  }, [journeyId, comments]);

  const addComment = async (text: string) => {
    if (!journeyId) {
      throw new Error("Journey ID is missing");
    }

    const commentInput: CommentInput = {
      id: v4(),
      journeyId,
      text,
    };

    const optimisticComment: Comment = {
      id: commentInput.id,
      text: commentInput.text,
      createdAt: new Date().toISOString(),
      commentedBy: { name, gid },
      likedBy: [],
      updatedAt: "",
    };

    setOptimisticComments((prev) => [...prev, optimisticComment]);

    try {
      console.log("addCommentMutation", commentInput);
      await addCommentMutation({
        variables: { input: commentInput },
      });
      notify.success("Your comment was successfully published!");
    } catch (error) {
      console.error("Error adding comment:", error);
      handleError(
        error,
        "The comment could not be published. Please try again.",
      );
      setOptimisticComments((prev) =>
        prev.filter((c) => c.id !== optimisticComment.id),
      );
    }
  };

  const toggleLike = (commentId: string) => {
    const isToggled = toggledLikeIdsRef.current.includes(commentId);
    if (isToggled) {
      toggledLikeIdsRef.current = toggledLikeIdsRef.current.filter(
        (id) => id !== commentId,
      );
    } else {
      toggledLikeIdsRef.current = [...toggledLikeIdsRef.current, commentId];
    }
  };

  const updateComment = async (commentId: string, text: string) => {
    const updatedComment = {
      id: commentId,
      text,
      updatedAt: new Date().toISOString(),
    };

    setOptimisticComments((prev) => {
      let existingCommentIndex = prev.findIndex((c) => c.id === commentId);
      if (existingCommentIndex !== -1) {
        // Replace existing comment
        return prev.map((c, index) =>
          index === existingCommentIndex
            ? { ...c, text, updatedAt: updatedComment.updatedAt }
            : c,
        );
      } else {
        // If the comment doesn't exist in optimistic comments, find it in the original comments
        const originalComment = comments.find((c) => c.id === commentId);
        if (originalComment) {
          // Add the updated version of the original comment to optimistic comments
          return [
            ...prev,
            { ...originalComment, text, updatedAt: updatedComment.updatedAt },
          ];
        }
      }
      // If the comment is not found in either optimistic or original comments, return the previous state
      return prev;
    });

    try {
      await updateCommentMutation({
        variables: { input: { id: commentId, text } },
      });
      notify.success("Your comment was successfully updated");
    } catch (error) {
      console.error("Error updating comment:", error);
      handleError(error, "Error updating comment");
      setOptimisticComments((prev) =>
        prev.map((c) =>
          c.id === commentId
            ? { ...c, text, updatedAt: new Date().toISOString() }
            : c,
        ),
      );
    }
  };

  const likeComment = async (commentId: string) => {
    if (likeLoading || !gid) {
      return;
    }
    setLikeLoading(true);
    const comment = comments.find((c) => c.id === commentId);
    const isCurrentlyLiked = comment?.likedBy?.includes(gid);
    const isToggled = toggledLikeIdsRef.current.includes(commentId);
    const newLikeState = isToggled ? !!isCurrentlyLiked : !isCurrentlyLiked;
    toggleLike(commentId);

    const likeCommentInput: LikeCommentInput = {
      id: commentId,
      isLiked: newLikeState,
    };

    try {
      await likeCommentMutation({
        variables: { input: likeCommentInput },
      });
    } catch (error) {
      toggleLike(commentId);
    } finally {
      setLikeLoading(false);
    }
  };

  const deleteComment = async (commentId: string) => {
    setDeletedCommentIds((prev) => [...prev, commentId]);

    try {
      await deleteCommentMutation({
        variables: { deleteCommentId: commentId },
      });
      notify.success("Your comment was successfully deleted");
    } catch (error) {
      console.error("Error deleting comment:", error);
      handleError(error, "Error deleting comment");
      setDeletedCommentIds((prev) => prev.filter((id) => id !== commentId));
    }
  };

  const sortedComments = comments
    .map((comment: Comment) => {
      // if an id exists in the comments and optimisticComments,
      // return the optimisticComment because that is the updated version
      const optimisticIndex = optimisticComments.findIndex(
        (oc) => oc.id === comment.id,
      );
      if (optimisticIndex !== -1) {
        return optimisticComments[optimisticIndex];
      }
      return comment;
    })
    .concat(
      optimisticComments.filter((oc) => !comments.some((c) => c.id === oc.id)),
    )
    .map((comment) => {
      if (toggledLikeIdsRef.current.includes(comment!.id)) {
        const likedBy = comment!.likedBy || [];
        if (likedBy.includes(gid)) {
          return {
            ...comment,
            likedBy: likedBy.filter((id) => id !== gid),
          };
        } else {
          return {
            ...comment,
            likedBy: [...likedBy, gid],
          };
        }
      }
      return comment;
    })
    .filter((c) => !deletedCommentIds.includes(c.id))
    .sort((a, b) => {
      const dateA = a.createdAt ? new Date(a.createdAt).getTime() : 0;
      const dateB = b.createdAt ? new Date(b.createdAt).getTime() : 0;
      return dateA - dateB;
    });

  return {
    comments: sortedComments,
    addComment,
    deleteComment,
    updateComment,
    likeComment,
    toggledLikeIds: toggledLikeIdsRef.current,
  };
};
