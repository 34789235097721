import React from "react";

export const ErrorFallback: React.FC<{ error: Error }> = ({ error }) => {
  return (
    <div
      className="w-screen h-screen flex items-center justify-center"
      role="alert"
    >
      <div className="flex flex-col gap-6 p-6 rounded-lg bg-middle-gray font-bold text-primary-contrast">
        <h1>Invalid State of the Application</h1>
        <p>{error.message}</p>
      </div>
    </div>
  );
};
