import { Maybe } from "@motius/customer-heartbeat-utils";

// Ensure TypeScript knows about structuredClone
declare function structuredClone<T>(value: T): T;

interface HasOptionalCategory {
  category?: Maybe<string>;
}

export const groupByCategory = <T extends HasOptionalCategory>(
  items: T[],
): Record<string, T[]> => {
  // Clone the array to avoid mutating the original
  const clonedItems = structuredClone(items ?? []);

  // Use reduce to group the items by category
  return clonedItems.reduce(
    (acc: Record<string, T[]>, item: T) => {
      // Use "uncategorized" if category is not present or not a string
      const category =
        typeof item.category === "string" ? item.category : "uncategorized";

      // Ensure the category key exists in the accumulator
      if (!acc[category]) {
        acc[category] = [];
      }
      // Add the current item to the appropriate category
      acc[category].push(item);

      return acc;
    },
    {}, // Initial value of the accumulator
  );
};
