import { Channel, Journey, JourneyOutcome } from "@/utils/apollo/resolvers";
import { ExpectedNextSelection } from "./useChannelStore";
import { AvatarType } from "@motius/customer-heartbeat-utils/schemas";
import { v4 } from "uuid";
import { FrontendChannel } from "@/utils/types";
import { getLastNode, getLastTouchpointId } from "./utils";
import { ChannelState, Produce, Set } from "./types";

/**
 *  Load a journey into the store
 * @param journey the journey to load
 * @param set the set function from the useChannelStore
 * @param produce the produce function from the useChannelStore
 */
export const loadJourney =
  (set: Set, produce: Produce) => (journey: Journey) => {
    set(
      produce((state: ChannelState) => {
        const hasChannels = journey.channels.length > 0;
        // Load metadata into state directly
        state.journeyId = journey.id;
        state.journeyTopologyId = journey.topologyId;
        state.industry = journey.industry;
        state.customerType = journey.customerType;
        state.customerRole = journey.customerRole;
        state.region = journey.region;
        state.buyerType = journey.buyerType;
        state.priorContact = journey.priorContact;
        state.motivation = journey.motivation;
        state.avatarRef = journey.avatarRef as AvatarType;
        state.description = journey.description;
        state.ownerName = journey.ownerName;
        state.lastSavedDate = journey.lastSavedDate;
        state.outcome = journey.outcome as JourneyOutcome;
        state.painpoints = journey.painpoints;
        state.experienceDrivers = journey.experienceDrivers;
        state.journeyType = journey.journeyType;
        state.comments = (journey.comments ?? []).filter((c) => c != null);
        state.isPublished = journey.isPublished;

        state.undoStack = [];
        state.undoStackIndex = 0;

        // here we assign a frontend id to all channels and touchpoint
        // we have frontend ids because while creating a journey
        // we do not yet have the db id. Thats why everything must has a frontend id.
        // when we load a journey we just assign a new random uuid as frontend id
        // this frontend id is used to identify objects at the frontend
        // (for equality check and other purposes)
        state.channels = journey.channels.map((channel: Channel) => {
          const channelFid = v4();
          const channelWithFid: FrontendChannel = {
            ...channel,
            fid: channelFid,
            touchpoints: channel.touchpoints.map((touchpoint) => ({
              ...touchpoint,
              fid: v4(),
              locations: touchpoint.locations.map((location) => ({
                ...location,
                fid: v4(),
              })),
            })),
          };

          if (channelWithFid.__typename) {
            delete channelWithFid.__typename;
          }

          return channelWithFid;
        });

        if (hasChannels) {
          const lastChannel = state.channels[state.channels.length - 1];
          state.expectedNextSelection = ExpectedNextSelection.SelectAction;
          state.currentChannelId = lastChannel?.fid ?? null;
          state.currentTouchpointId = getLastTouchpointId(lastChannel);

          const lastNode = getLastNode(state.channels);
          if (lastNode != null) {
            state.lastAddedNodeTopologyId = lastNode!.topologyId;
          }
        } else {
          state.expectedNextSelection = ExpectedNextSelection.Channel;
        }
      }),
    );
  };
