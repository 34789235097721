import { gql, useMutation } from "@apollo/client";
import { showMessage } from "@siemens/ix";
import { useState } from "react";
import { handleError } from "@/utils/error";

const DELETE_JOURNEY = gql`
  mutation DeleteJourney($id: ID!) {
    deleteJourney(id: $id)
  }
`;

export const useDeleteJourney = (refetchJourneys: () => Promise<any>) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteJourney] = useMutation(DELETE_JOURNEY);

  const handleDelete = async (id: string) => {
    (
      await showMessage({
        icon: "",
        messageTitle: "Do you want to delete this journey?",
        message:
          "Once you decide to delete your journey, the data and progress can not be recovered.",
        actions: [
          { id: "cancel", type: "cancel", text: "Cancel" },
          { id: "delete", type: "okay", text: "Delete Journey" },
        ],
      })
    ).once(async (result) => {
      if (result?.actionId === "delete") {
        try {
          setIsDeleting(true);
          await deleteJourney({ variables: { id } });
          await refetchJourneys();
        } catch (e) {
          console.error(e);
          handleError(e, "Deletion of the journey failed");
        } finally {
          setIsDeleting(false);
        }
      }
    });
  };

  return { handleDelete, isDeleting };
};
