import { showMessage } from "@siemens/ix";

const showPrivacyNotice = async (
  setIsShowingPrivacyMessage: (bool: boolean) => void,
) => {
  (
    await showMessage({
      icon: "",
      messageTitle: "Privacy Rules",
      message:
        "Please ensure that any screenshots uploaded do not display confidential user information.",
      actions: [{ id: "ok", type: "okay", text: "Ok" }],
    })
  ).once(() => setIsShowingPrivacyMessage(false));
};

/**
 *
 */
export const showPrivacyNoticeIfNecessary = (
  setIsShowingPrivacyMessage: (bool: boolean) => void,
) => {
  const privacyNoticeShownKey = "privacyNoticeShown";
  const hasPrivacyNoticeBeenShown = localStorage.getItem(privacyNoticeShownKey);

  if (!hasPrivacyNoticeBeenShown) {
    setIsShowingPrivacyMessage(true);
    showPrivacyNotice(setIsShowingPrivacyMessage);
    localStorage.setItem(privacyNoticeShownKey, "true");
  }
};
