import * as React from "react";
import {
  TextareaAutosize,
  TextareaAutosizeProps,
} from "@mui/base/TextareaAutosize";
import { FieldError } from "react-hook-form";
import { InputError } from "./Input";
import { cn } from "../../utils";

interface WrapperProps {
  label: string;
  rules?: string;
  children: React.ReactNode;
  error?: FieldError;
  withCounter?: boolean;
  count?: number;
  maxCount?: number;
  labelClass?: string;
}

const Wrapper: React.FC<WrapperProps> = ({
  label,
  rules,
  children,
  withCounter,
  count = 0,
  maxCount = 0,
  error,
  labelClass = "",
}) => {
  const getSubText = () => {
    if (error) return <InputError error={error} />;
    if (withCounter)
      return (
        <span className="text-[10px] font-sans">
          {count} / {maxCount}
        </span>
      );
    if (rules) return <span className="text-[10px] font-sans">{rules}</span>;
    return <span className="text-[10px] font-sans"> </span>;
  };

  return (
    <div className="flex flex-col gap-[2px]">
      <label className={cn("text-[10px] font-sans", labelClass)}>{label}</label>
      {children}
      {getSubText()}
    </div>
  );
};

const inputClassnames = [
  "flex min-h-[42px] w-full rounded-lg border border-input bg-transparent p-2 text-sm shadow-sm transition-colors placeholder:text-muted-foreground resize-none",
  "focus-visible:outline-none focus-visible:ring-1 focus-visible:border-tertiary focus-visible:ring-tertiary",
  "disabled:cursor-not-allowed disabled:opacity-50",
];

export const Input = React.forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > & { label: string; rules?: string; error?: FieldError } & {
    labelClass?: string;
  }
>(({ className, type, label, rules, error, labelClass, ...props }, ref) => {
  return (
    <Wrapper label={label} rules={rules} error={error} labelClass={labelClass}>
      <input
        type={type}
        className={cn(
          inputClassnames,
          { "border-error bg-error-background": !!error },
          className,
        )}
        ref={ref}
        {...props}
      />
    </Wrapper>
  );
});

export const MultilineInput = React.forwardRef<
  HTMLTextAreaElement,
  TextareaAutosizeProps &
    React.RefAttributes<Element> & {
      label: string;
      rules?: string;
      error?: FieldError;
      withCounter?: boolean;
      labelClass?: string;
    }
>(
  (
    {
      className,
      label,
      rules,
      error,
      value,
      maxLength,
      withCounter,
      labelClass = "",
      ...props
    },
    ref,
  ) => {
    return (
      <Wrapper
        label={label}
        labelClass={labelClass}
        rules={rules}
        count={value?.toString().length || 0}
        maxCount={maxLength}
        withCounter={withCounter}
        error={error}
      >
        <TextareaAutosize
          className={cn(
            inputClassnames,
            { "border-error bg-error-background": !!error },
            "bg-![var(--theme-color-input)]",
            className,
          )}
          minRows={2}
          ref={ref}
          maxLength={maxLength}
          value={value}
          {...props}
        />
      </Wrapper>
    );
  },
);

export { InputError } from "./InputError";
