import React from "react";

/**
 *  Angry reaction icon
 */
export const AngryReaction: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.12054e-06 9C6.86003e-07 4.02944 4.02944 3.12869e-06 9 2.69415e-06C13.9706 2.25961e-06 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 1.55508e-06 13.9706 1.12054e-06 9Z"
        fill="#FFD732"
      />
      <circle cx="5.95407" cy="6.52096" r="0.850551" fill="#000028" />
      <circle cx="12.1914" cy="6.52096" r="0.850551" fill="#000028" />
      <path
        d="M5.66992 12.7582C6.70948 11.1516 9.58245 8.90232 12.7578 12.7582"
        stroke="#000028"
        strokeLinecap="round"
      />
    </svg>
  );
};
