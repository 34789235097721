export interface TokenPayload {
  family_name?: string;
  given_name?: string;
  name: string;
  roles?: string[];
  gid?: string;
  mail?: string;
}

export const isDisableAuth = (): boolean => {
  const isDisableAuth =
    !!localStorage.isDisableAuth ||
    import.meta.env.VITE_DISABLE_AUTH === "true";
  return isDisableAuth;
};

export const authRequest = {
  scopes: ["openid", "profile"],
};
