import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { cn } from "../../utils";
import { IxIcon } from "@siemens/ix-react";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn(className)} {...props} />
));
AccordionItem.displayName = "AccordionItem";

interface TriggerProps
  extends React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> {
  leftIcon?: boolean;
  rightToDown?: boolean;
  containerClass?: string;
  style?: React.CSSProperties;
  caretCollapsedColor?: string;
  caretExpandedColor?: string;
  iconSize?: "16" | "12" | "24" | "32" | undefined;
}

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  TriggerProps
>(
  (
    {
      className,
      containerClass,
      children,
      leftIcon,
      rightToDown,
      caretExpandedColor = "inherit",
      caretCollapsedColor = "inherit",
      style = {},
      iconSize = "24",
      ...props
    },
    ref,
  ) => {
    const icon = (
      <div
        className={cn([
          "flex shrink-0 items-center justify-center transition-transform duration-200",
          containerClass,
        ])}
      >
        <IxIcon
          size={iconSize}
          style={{
            ...style,
            color: "var(--accordion-icon-color)",
          }}
          name={rightToDown ? "chevron-right-small" : "chevron-down-small"}
        />
      </div>
    );
    return (
      <AccordionPrimitive.Header className="flex items-center">
        <AccordionPrimitive.Trigger
          ref={ref}
          className={cn(
            "flex flex-1 items-center justify-between min-h-8 transition-all [&[data-state=open]>div]:rotate-180",
            "py-1 text-left leading-5",
            { "justify-start": leftIcon },
            { "[&[data-state=open]>div]:rotate-90": rightToDown },
            className,
          )}
          {...props}
        >
          {leftIcon && icon}
          {children}
          {!leftIcon && icon}
          <style>{`
            [data-state="open"] {
              --accordion-icon-color: ${caretExpandedColor};
            }
            [data-state="closed"] {
              --accordion-icon-color: ${caretCollapsedColor};
            }
          `}</style>
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Header>
    );
  },
);
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn("pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
));
AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
