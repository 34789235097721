import { IxIconButton } from "@siemens/ix-react";
import React from "react";

/**
 * Help Link
 * This is the button with link to the Siemens help for Customer Heartbeat app
 */
export const HelpLink = () => {
  return (
    <a
      href="https://siemens.sharepoint.com/teams/DXHub/SitePages/Customer-Hearbeat-How-to-Use.aspx?source=https%3A%2F%2Fsiemens.sharepoint.com%2Fteams%2FDXHub%2FSitePages%2FForms%2FAllPages.aspx"
      target="_blank"
      rel="noopener noreferrer"
    >
      <IxIconButton
        variant="primary"
        type="button"
        icon="question"
        data-testid="help-link"
        ghost
      ></IxIconButton>
    </a>
  );
};
