import React, { useMemo } from "react";
import { useReturnToTouchpoints } from "./useReturnToTouchpoints";
import { SearchableAccordionList } from "./SearchableAccordionList";
import ActionHeadline from "./ActionHeadline";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { getLastElement } from "@motius/customer-heartbeat-utils";
import {
  getChannelByChannelTopology,
  getTouchPointByTouchPointTopology,
} from "@/utils/topology";
import useTopology from "./useJourneyTopology";
import { IxIconButton } from "@siemens/ix-react";
import {
  filterAndConvertRequestListToChannelTopologyList,
  filterAndConvertRequestListToTouchpointTopologyList,
} from "../Map/utils";
import { NavigationButton } from "./NavigationButton";
import { FrontendChannelTopology } from "@/utils/types";
import { useTopologyStore } from "@/src/hooks/useTopologyStore/useTopologyStore";

export const NavigationTouchpointsReturn: React.FC = () => {
  const {
    addTouchPoint,
    addChannel,
    channels,
    undo,
    undoStackIndex,
    touchpointRequests,
    channelRequests,
    journeyTopologyId,
  } = useChannelStore();
  const { journeyTopology } = useTopology(journeyTopologyId);
  const { touchpointTopologies } = useTopologyStore();
  const processedChannels = useReturnToTouchpoints();

  const groupedTouchpoints = processedChannels.map((channel) => ({
    title: channel.name,
    items: channel.touchpoints.map((tp) => ({
      id: tp.compositeId,
      name: tp.name,
    })),
  }));

  const requestDataTouchpointTopologies = useMemo(
    () =>
      filterAndConvertRequestListToTouchpointTopologyList(
        touchpointRequests ?? [],
      ),
    [touchpointRequests],
  );

  const currentTouchpoints = useMemo(() => {
    return [
      ...(touchpointTopologies ?? []),
      ...requestDataTouchpointTopologies,
    ].map((tp) => ({
      ...tp,
    }));
  }, [touchpointTopologies, requestDataTouchpointTopologies]);

  const availableChannelTopos = useMemo(() => 
    journeyTopology?.channels.concat(
      filterAndConvertRequestListToChannelTopologyList(channelRequests),
    ),
    [journeyTopology, channelRequests]
  );

  const handleTouchpointClick = (compositeId: string) => {
    const [channelTopoId, touchpointTopoId] = compositeId.split("__");

    if (getLastElement(channels)?.topologyId !== channelTopoId) {
      if (!journeyTopology) return;
      const channelTopo: FrontendChannelTopology | undefined =
        availableChannelTopos?.find((c) => c.id === channelTopoId);
      if (!channelTopo) return;
      addChannel(
        {
          ...getChannelByChannelTopology(channelTopo),
          isCustom: channelTopo.isCustom,
        },
        [],
      );
    }

    const touchpointTopo = currentTouchpoints.find(
      (tp) => tp.id === touchpointTopoId,
    );
    if (!touchpointTopo) return;

    addTouchPoint(getTouchPointByTouchPointTopology(touchpointTopo));
  };

  return (
    <div className="flex grow flex-col overflow-hidden">
      <div className="pr-3">
        <ActionHeadline className="ml-0 mt-2">
          <div className="mx-3 flex items-center text-lg">
            {undoStackIndex > 0 && (
              <IxIconButton
                icon="arrow-left"
                variant="primary"
                ghost
                size="24"
                className="mr-2 cursor-pointer"
                onClick={undo}
              />
            )}
            Select Touchpoint
          </div>
          <NavigationButton icon="corner-arrow-up-left" className="ml-3 mt-1">
            Return to
          </NavigationButton>
        </ActionHeadline>
        <hr className="mt-2" />
      </div>
      <SearchableAccordionList
        expandAllByDefault
        searchPlaceholder={"Search Touchpoint"}
        sections={groupedTouchpoints}
        onItemClick={handleTouchpointClick}
      />
    </div>
  );
};
