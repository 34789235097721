import { cn } from "@motius/customer-heartbeat-ui/utils";
import { IxIcon } from "@siemens/ix-react";

type Props = {
  onClick?: () => void;
  icon: string;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
};

export const NavigationButton = ({
  onClick,
  icon,
  children,
  className,
  disabled = false,
}: Props) => {
  return (
    <div>
      <button
        onClick={onClick}
        className={cn([
          "flex h-5 items-center gap-[0.1rem] rounded-full px-2",
          "bg-dark-color-component-2",
          onClick && !disabled
            ? "hover:bg-dark-color-component-3 cursor-pointer"
            : "cursor-default pointer-events-none",
          disabled && "opacity-30",
          className,
        ])}
        disabled={disabled || !onClick}
      >
        <IxIcon name={icon} className="scale-[0.66]" />
        {children}
      </button>
    </div>
  );
};
