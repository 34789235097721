import React, { useEffect, useMemo } from "react";
import { useChannelStore } from "../../hooks/useChannelStore";
import { Spinner } from "@motius/customer-heartbeat-ui";
import { SearchableAccordionList } from "./SearchableAccordionList";
import ActionHeadline from "./ActionHeadline";
import { IxIconButton } from "@siemens/ix-react";
import { groupByCategory } from "./channelsByCategory";
import { RequestMissingLocationButton } from "../RequestMissingDialog/RequestMissingLocationButton";
import { filterAndConvertRequestListToLocationTopologyList } from "../Map/utils";
import { FrontendLocationTopology } from "@/utils/types";
import { SearchInput } from "./SearchInput";
import { useLocationTopologies } from "@/src/hooks/topology/useLocationTopologies";
import { LocationFragment } from "./NavigationSelectAction";
import { NavigationButton } from "./NavigationButton";
import { NavigationTooltip } from "./Tooltips/NavigationTooltipContent";
import { LocationTooltipContent } from "./Tooltips/LocationTooltipContent";
import { cn } from "@motius/customer-heartbeat-ui/utils";

interface Props {
  availableLocations: FrontendLocationTopology[];
  introFragment: LocationFragment | undefined;
}

const NavigationLocations: React.FC<Props> = ({
  availableLocations,
  introFragment,
}) => {
  const {
    addLocation,
    journeyId,
    locationRequests,
    undo,
    undoStackIndex,
    isAnswerNeeded,
  } = useChannelStore();

  const { getLocationTopos, data } = useLocationTopologies();

  const undoAndResetState = () => {
    undo();
  };

  useEffect(() => {
    const availableLocationIds = availableLocations.map(
      (location) => location.id,
    );
    getLocationTopos(availableLocationIds);
  }, [availableLocations, getLocationTopos]);

  const requestDataLocationTopologies = useMemo(
    () =>
      filterAndConvertRequestListToLocationTopologyList(locationRequests ?? []),
    [locationRequests],
  );

  const availableRequestLocations = useMemo(
    () => filterAndConvertRequestListToLocationTopologyList(locationRequests),
    [locationRequests],
  );

  const handleLocationClick = (locationId: string) => {
    const locTopo = [
      ...(data?.locationTopologies ?? []),
      ...requestDataLocationTopologies,
    ].find((l: FrontendLocationTopology) => l.id === locationId);
    if (!locTopo || !introFragment) return;
    const introText = `I ${introFragment.text.toLowerCase()}`;
    addLocation(locTopo, introText);
  };

  const groupedLocations = groupByCategory([
    ...availableLocations,
    ...availableRequestLocations.map((r) => ({
      ...r,
      name: `${r.name} ${r.isCustom ? "(Custom Interaction)" : ""}`,
    })),
  ]);

  const accordionSections = Object.entries(groupedLocations).map(
    ([introFragment, locations]) => ({
      title: introFragment || "Other",
      items: locations.map((loc) => ({
        id: loc.id,
        name: loc.name,
      })),
    }),
  );

  const groups = accordionSections.map((section) => section.title);

  if (!data?.locationTopologies) {
    return <Spinner />;
  }

  return (
    <div className="flex grow flex-col overflow-hidden">
      <div className="pr-4">
        <ActionHeadline
          className={cn("ml-0 mt-3", { "mt-[0.75rem]": undoStackIndex > 0 })}
        >
          <div className="ml-4 flex items-center text-base relative">
            {undoStackIndex > 0 && (
              <IxIconButton
                icon="arrow-left"
                variant="primary"
                ghost
                size="24"
                className="mr-2 cursor-pointer"
                onClick={undoAndResetState}
              />
            )}
            <span>Select Interaction</span>
            <NavigationTooltip
              className="ml-auto"
              tooltipClassname="font-sans"
              id="tooltip-select-interaction"
              content={<LocationTooltipContent />}
            />
          </div>
          <NavigationButton icon={introFragment!.icon} className="ml-4 mt-1">
            {introFragment?.text}...
          </NavigationButton>
        </ActionHeadline>
        <hr className="-mx-4 mt-[0.75rem]" />
      </div>
      {isAnswerNeeded ? (
        <SearchInput />
      ) : (
        <SearchableAccordionList
          sections={accordionSections}
          onItemClick={handleLocationClick}
          action={
            <RequestMissingLocationButton
              groups={groups}
              journeyId={journeyId}
            />
          }
          searchPlaceholder="Search Interaction"
        />
      )}
    </div>
  );
};

export default NavigationLocations;
