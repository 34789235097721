import React, { useState } from "react";
import { TextAreaStyle } from "./TextAreaStyle";
import { IxButton, IxTypography } from "@siemens/ix-react";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { cn } from "@motius/customer-heartbeat-ui/utils";

export const SearchInput: React.FC = () => {
  const { addAnswer } = useChannelStore();
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputValue(event.target.value);
  };

  const handleConfirmClick = () => {
    if (!inputValue) {
      alert("Please answer the question");
      return;
    }

    addAnswer(inputValue);
  };

  return (
    <div id="touchpoints" className="mx-3 flex grow flex-col">
      <IxTypography textColor="soft" className="mt-2">
        Search Bar
      </IxTypography>
      <textarea
        className={cn(
          TextAreaStyle,
          "text-white placeholder:text-theme-color-soft-text",
        )}
        placeholder="Enter Search Keyword(s)"
        value={inputValue}
        onChange={handleInputChange}
      />
      <div className="flex justify-end">
        <IxButton
          variant="primary"
          disabled={!inputValue}
          onClick={handleConfirmClick}
        >
          Confirm
        </IxButton>
      </div>
    </div>
  );
};
