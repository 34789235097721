import { TopBarBase, ProfileIcon } from "@motius/customer-heartbeat-ui";

/**
 *  TopBar component
 */
export const TopBar = () => {
  return (
    <TopBarBase>
      <ProfileIcon />
    </TopBarBase>
  );
};
