import React from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { ExperienceDriverInput } from "../components/ExperienceDriverInput";
import { Stepper } from "@motius/customer-heartbeat-ui";
import { CompleteDetailsStep, openDialogOrStep } from "@/utils/router";
import { useSearchParams } from "react-router-dom";
import { IxTypography } from "@siemens/ix-react";
import { ExperienceDriverTopology } from "@/utils/apollo/resolvers";

interface CompleteDetailsExperienceDriverStepProps {
  experienceDriverTopologies: ExperienceDriverTopology[];
}

/**
 *  Complete Details Experience Driver Step
 * @param props - Props
 * @param props.experienceDriverTopologies - Experience Driver Topologies
 * @param props.painpointTopologies - Painpoint Topologies
 */
export const CompleteDetailsExperienceDriverStep: React.FC<
  CompleteDetailsExperienceDriverStepProps
> = ({ experienceDriverTopologies }) => {
  const { isValid } = useFormState();
  const [, setSearchParams] = useSearchParams();
  const { trigger } = useFormContext();

  /**
   *  Handle Previous Button Click
   */
  const prevClick = () => {
    openDialogOrStep(setSearchParams, CompleteDetailsStep.First);
  };

  const handleNext = async () => {
    if (await trigger()) {
      openDialogOrStep(setSearchParams, CompleteDetailsStep.Third);
    }
  };

  return (
    <div className="flex size-full grow flex-col items-center justify-between">
      <div className="flex w-full flex-col justify-between">
        <IxTypography format="h2">Rate Experience Drivers</IxTypography>
        <p className="mt-1.5 leading-5">
          Select up to 3 Experience Drivers that impacted
          <br />
          your journey most. For each selected Driver, select
          <br />
          whether it had positive or negative impact.
        </p>
      </div>
      <div className="w-full grow pb-[1.875rem] pt-4">
        <Controller
          name="experienceDrivers"
          render={({ field }) => {
            return (
              <ExperienceDriverInput
                value={field.value}
                experienceDriverTopologies={experienceDriverTopologies}
                onChange={field.onChange}
              />
            );
          }}
        />
      </div>
      <div className="w-full">
        <Stepper
          onBackClick={prevClick}
          onNextClick={handleNext}
          nextButtonText="Next"
          isNextDisabled={!isValid}
          steps={5}
          activeStep={1}
        />
      </div>
    </div>
  );
};
