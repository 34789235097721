import { IxTooltip, IxTypography } from "@siemens/ix-react";
import React from "react";
import { HoveredMetaData } from "../drawTouchpoint";
import { openImageInNewTab } from "../MetaDataMenu/MetaDataSubMenus/upload";

interface ScreenshotTooltipProps {
  data?: HoveredMetaData;
}

/**
 *  Tooltip for the screenshots and images of the meta data
 * @param props - ScreenshotTooltipProps
 * @param props.data - HoveredMetaData
 */
export const ScreenshotTooltip: React.FC<ScreenshotTooltipProps> = ({
  data,
}) => {
  return (
    <IxTooltip
      className="theme-brand-dark text-white"
      id="meta-data-screenshot-tooltip"
      for="#meta-data-screenshot-tooltip-anchor"
      placement="top"
      interactive
    >
      <div className="flex flex-col">
        <IxTypography format="body" bold>
          Screenshots and Images
        </IxTypography>
        {data?.node.attachments.map((attachment) => (
          <IxTypography
            key={`screenshot-${attachment.id}`}
            className="cursor-pointer text-theme-color-primary"
            textDecoration="underline"
            format="body"
            onClick={() => openImageInNewTab(attachment.id)}
          >
            {attachment.name}
          </IxTypography>
        ))}
      </div>
    </IxTooltip>
  );
};
