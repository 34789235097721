import React, { ReactNode } from "react";
import { MsalProvider, useMsal } from "@azure/msal-react";
import { msalInstance } from "./utils";
import { InteractionStatus } from "@azure/msal-browser";
import debug from "debug";
import { isDisableAuth } from "@motius/customer-heartbeat-utils/auth";
import { Spinner } from "@motius/customer-heartbeat-ui";

interface MsalWrapperProps {
  children: ReactNode;
}

const log = debug("MsalWrapper");

export const MsalWrapper: React.FC<MsalWrapperProps> = ({ children }) => {
  const { inProgress } = useMsal();
  log("inProgress", inProgress);
  if (inProgress === InteractionStatus.Startup) {
    log("msal is initializing. show spinner");
    return <Spinner />;
  }
  if (isDisableAuth()) {
    return <>{children}</>;
  }
  return <MsalProvider instance={msalInstance}>{children}</MsalProvider>;
};
