// @ts-expect-error This import works
import BackArrow from "../../TopBar/BackArrow.svg?react";
import { showMessage } from "@siemens/ix";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { useNavigate } from "react-router-dom";
import { useSaveDraft } from "@/src/hooks/useSaveDraft/useSaveDraft";
import {
  hideSpinner,
  showSpinner,
  TopBarBase,
  ProfileIcon,
} from "@motius/customer-heartbeat-ui";
import { useEffect } from "react";
import { SaveDraftButton } from "../../SaveAsDraft/SaveAsDraft";
import { CompleteDetailsButton } from "../../CompleteDetails/components/CompleteDetailsButton";
import { IxIconButton } from "@siemens/ix-react";
import { ShareButton } from "../../ViewJourney/ShareButton";

/**
 * Top Bar
 * This is the top bar of the journey editor
 * It contains the profile icon, save draft button, complete details button and close button
 */
export const TopBar = () => {
  const { reset, undo, redo, undoStackIndex, undoStack } = useChannelStore();
  const navigate = useNavigate();
  const { saveDraft, loading } = useSaveDraft(false);
  /**
   *  Handle the close button
   * If there are changes, show the confirmation dialog
   */

  useEffect(() => {
    if (loading) {
      showSpinner();
    } else {
      hideSpinner();
    }
  }, [loading]);

  /**
   *  Handle the close button
   */
  const handleClose = async () => {
    if (undoStackIndex > 0) {
      (
        await showMessage({
          icon: "",
          messageTitle: "Do you want to save the changes made to this journey?",
          message: "Your changes will be lost if you don’t save them.",
          actions: [
            { id: "cancel", type: "cancel", text: "Discard Changes" },
            { id: "save", type: "okay", text: "Save draft" },
          ],
        })
      ).once((result) => {
        if (result?.actionId == null) return;
        if (result?.actionId === "save") {
          // if the result has a payload then save was clicked
          saveDraft();
        } else {
          handleCloseConfirm();
        }
      });
    } else {
      handleCloseConfirm();
    }
  };
  /**
   *  Handle the confirmation dialog
   * If the user confirms, reset the changes and navigate to the journeys page
   */
  const handleCloseConfirm = () => {
    reset();
    navigate("/journeys");
  };

  return (
    <TopBarBase>
      <button
        className={cn("bg-transparent", { "opacity-50": undoStackIndex === 0 })}
        onClick={undo}
        disabled={undoStackIndex === 0}
      >
        <BackArrow className="m-0 p-0" width={18} height={19} />
      </button>
      <button
        className={cn("bg-transparent -scale-x-100", {
          "opacity-50": undoStackIndex >= undoStack.length,
        })}
        onClick={redo}
        disabled={undoStackIndex >= undoStack.length}
      >
        <BackArrow className="m-0 p-0" width={18} height={19} />
      </button>
      <ProfileIcon />
      <ShareButton outline icon="copy" />
      <SaveDraftButton />
      <CompleteDetailsButton />
      <IxIconButton
        onClick={handleClose}
        icon="close"
        ghost
        size="16"
      ></IxIconButton>
    </TopBarBase>
  );
};
