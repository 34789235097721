import React, { ReactNode, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import debug from "debug";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import {
  TokenPayload,
  authRequest,
  isDisableAuth,
} from "@motius/customer-heartbeat-utils/auth";
import { Spinner } from "@motius/customer-heartbeat-ui";
import { wait } from "@motius/customer-heartbeat-utils";

const log = debug("ProtectedRoute");

type Props = {
  children: ReactNode;
};

const ProtectedRoute: React.FC<Props> = ({ children }) => {
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);
  const { instance, accounts } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const { pathname } = useLocation();

  useEffect(() => {
    const acquireToken = async () => {
      if (isDisableAuth()) {
        log("auth is disabled");
        setIsCheckingAuth(false);
        return;
      }

      if (isAuthenticated && accounts.length > 0) {
        const account = accounts[0];
        const tokenClaims = account?.idTokenClaims as TokenPayload;
        if (!tokenClaims?.roles?.includes("CHB")) {
          log("User is missing CHB role");
          navigate("/unauthorized");
          return;
        }
        try {
          log("acquiring token silently");
          await instance.acquireTokenSilent({
            ...authRequest,
            account: accounts[0],
          });
          setIsCheckingAuth(false);
        } catch (error) {
          log("Silent token acquisition failed. try SSO next", error);
          try {
            await instance.ssoSilent(authRequest);
            setIsCheckingAuth(false);
          } catch (ssoError) {
            log("SSO failed. redirect to login", ssoError);
            navigate("/", { state: { from: pathname } });
          }
        }
      } else {
        log("there is no msal account yet");
        // it can take a moment until the account is available
        // but if its not there after 300 ms, redirect to login
        // if it becomes available then the use effect will be
        // triggered again
        await wait(300);
        if (accounts?.length === 0) {
          navigate("/", { state: { from: pathname } });
        }
      }
    };

    acquireToken();
  }, [instance, accounts, isAuthenticated, navigate]);

  if (isCheckingAuth) {
    return <Spinner />;
  }

  return children;
};

export default ProtectedRoute;
