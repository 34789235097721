import { FrontendLocationTopology } from "@/utils/types";
import { useState } from "react";
import { useTopologyStore } from "../useTopologyStore/useTopologyStore";

// A hook to get location topologies
// This hook can be used to get location topologies by their ids
// It will either use the already loaded location topologies
// or load them from the network if commented code is used
export const useLocationTopologies = () => {
  // Use this one incase you need to load the channel topology from network in the future
  // const [getLocationTopos, { data }] = useLazyQuery(GET_LOCATIONS);

  const [data, setData] = useState<
    | {
        locationIds: string[];
        locationTopologies: FrontendLocationTopology[];
      }
    | undefined
  >();
  const { locationTopologies } = useTopologyStore();

  const handleLoadLocationTopologies = (ids: string[]) => {
    //  getTpTopos({ variables: { ids: availableTouchpointIds } });
    if (JSON.stringify(data?.locationIds) === JSON.stringify(ids)) return;
    const foundTps = locationTopologies.filter((loc) => ids.includes(loc.id));

    setData({
      locationIds: ids,
      locationTopologies: foundTps,
    });
  };

  return {
    getLocationTopos: handleLoadLocationTopologies,
    data,
  };
};
