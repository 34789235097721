import { MapNode } from "./drawTouchpoint";
import { PLACEHOLDER_TOUCHPOINT_TP_ID } from "./constants";
import DOMPurify from "dompurify";

const MAX_ANSWER_LENGTH = 12;

const ellipsis = (str: string) =>
  str.length > MAX_ANSWER_LENGTH
    ? str.slice(0, MAX_ANSWER_LENGTH) + "..."
    : str;

export // Reusable function for generating HTML content
// uses template literals to allow for css syntax highlighting
const generateHtmlContent = (node: MapNode) => /* html */ `
  <body xmlns="http://www.w3.org/1999/xhtml" style="margin: 0; display: flex; align-items: center; justify-content: center; height: 100%;">
    <style type="text/css">
      .touchpoint-label {
        display: flex;
        flex-direction: column;
      }
      .touchpoint-label p {
        background: var(--channel-dark);
        border: 1px solid transparent;
      }
      .touchpoint-label.location p {
        background: var(--channel-dark);
        color: var(--location-label);
      }
      .touchpoint-label.light p {
        background: var(--channel-light);
      }

      @keyframes pulseShadow {
        0%, 100% {
          box-shadow: 0 0 0px 0px #03FFB966;
        }
        45% {
          box-shadow: 0 0 0px 4px #03FFB966;
        }
        55% {
          box-shadow: 0 0 0px 4px #03FFB966;
        }
      }

      @keyframes pulseShadowSelected {
        0%, 100% {
          box-shadow: 0 0 0px 0px #ffffff66;
        }
        45% {
          box-shadow: 0 0 0px 4px #ffffff66;
        }
        55% {
          box-shadow: 0 0 0px 4px #ffffff66;
        }
      }

      .touchpoint-label.selectable p {
        cursor: ${node.fid !== PLACEHOLDER_TOUCHPOINT_TP_ID ? "pointer" : "default"} 
      }
      
      .touchpoint-label.active p,
      .touchpoint-label.selected p,
      .touchpoint-label.selectable p:hover {
        min-height: 2.1rem;
        border: 1px solid white;
        color: white;
        overflow: visible;
        animation: pulseShadowSelected 2s;
        animation-iteration-count: infinite;
        font-family: var(--font-siemens-bold), sans-serif;
        font-weight: 700;
      }

      .touchpoint-label.active p {
        animation: pulseShadow 2s;
        animation-iteration-count: infinite;
        border: 1px solid var(--active-node-circle);
      }
    </style>
    <p class="flex flex-col font-sans w-full break-words text-touchpoint-label text-sm text-center p-2 rounded-lg pointer-events-auto">
    <span class="pointer-events-none">${node.name.replace(
      /\bDetails\b/,
      "Search",
    )}
    </span>
    ${
      node.isCustom
        ? `<span class="text-10 pointer-events-none">
      (Custom ${node.isLocation ? "Interaction" : "Touchpoint"})
    </span>`
        : ""
    }
    ${
      node.question
        ? `<span class="font-italic pointer-events-none">
        ${DOMPurify.sanitize(
          node.question?.answer ? `"${ellipsis(node.question.answer)}"` : "",
        )}
        </span>`
        : ""
    }
    </p>
    
  </body>
`;
