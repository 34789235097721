import { useEffect } from 'react';
import { hideSpinner, showSpinner } from "@motius/customer-heartbeat-ui";

/**
 * Hook for managing spinner state
 * @param isLoading - Indicates whether the spinner should be displayed
 */
export const useSpinner = (isLoading: boolean) => {
  useEffect(() => {
    if (isLoading) {
      showSpinner();
    } else {
      hideSpinner();
    }
  }, [isLoading]);
};
