import { gql } from "@apollo/client";

export const GET_LOCATIONS = gql`
  query Locations($ids: [ID!]!) {
    locationTopologies(ids: $ids) {
      category
      id
      name
      intro_fragment
      question {
        id
        question
      }
      locations {
        question {
          id
          question
        }
        category
        id
        name
        intro_fragment
      }
    }
  }
`;

export const GET_TOUCHPOINTS = gql`
  query Touchpoints($ids: [ID!]!) {
    touchpointTopologies(ids: $ids) {
      category
      question {
        question
      }
      id
      intro_fragment
      name
      locations {
        question {
          id
          question
        }
        category
        id
        name
        intro_fragment
      }
    }
  }
`;

export const GET_REQUESTS = gql`
  query listTopologyRequests($journeyId: ID) {
    listTopologyRequests(journeyId: $journeyId) {
      ... on TouchpointRequest {
        id
        name
        category
        url
        comment
      }
      ... on LocationRequest {
        id
        name
        category
        url
        comment
      }
      ... on ChannelRequest {
        id
        name
        category
        url
        comment
      }
    }
  }
`;
