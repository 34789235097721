import React, { useState, useEffect } from "react";
import { IxTypography } from "@siemens/ix-react";
import { FilterChips } from "./FilterChips";
import { Journey, JourneyFilter } from "@/utils/apollo/resolvers";
import { useQuery } from "@apollo/client";
import { PublishedJourneys } from "../Journeys/PublishedJourneys";
import { LOAD_JOURNEYS } from "../Journeys/Journeys";
import { NothingHere } from "./NothingHere";
import { hideSpinner, showSpinner } from "@motius/customer-heartbeat-ui";
import { useDeleteJourney } from "@/src/hooks/useDeleteJourney";
import { FrontendJourneyFilter } from "./Search";
import { hasFilters } from "./filterUtils";

interface FilterResultsProps {
  filters: FrontendJourneyFilter;
  setFilters: React.Dispatch<React.SetStateAction<FrontendJourneyFilter>>;
}

export const FilterResults: React.FC<FilterResultsProps> = ({
  filters,
  setFilters,
}) => {
  const [journeys, setJourneys] = useState<Journey[]>([]);

  // Utility function to recursively remove 'authorFilters' from the object
  const removeAuthorFilters = (obj: any): any => {
    if (Array.isArray(obj)) {
      return obj.map(removeAuthorFilters);
    } else if (obj !== null && typeof obj === "object") {
      const newObj: any = {};
      for (const key of Object.keys(obj)) {
        if (key !== "authorGidFilters" && key !== "authorNameFilters") {
          newObj[key] = removeAuthorFilters(obj[key]);
        }
      }
      return newObj;
    } else {
      return obj;
    }
  };

  const backendJourneyFilters: JourneyFilter = {
    isPublished: true,
    ...removeAuthorFilters(filters),
  };

  let authorGidFilters: Array<JourneyFilter> = [];
  if (filters.authorGidFilters?.length) {
    authorGidFilters = filters.authorGidFilters.map((a) => ({
      ownerGid: a.gid,
    }));
    backendJourneyFilters.AND = [
      {
        OR: authorGidFilters,
      },
    ];
  }

  if (filters.authorNameFilters?.length) {
    backendJourneyFilters.AND = [
      {
        OR: [
          ...authorGidFilters,
          ...filters.authorNameFilters.map((a) => ({
            ownerName: a,
          })),
        ],
      },
    ];
  }

  if (Array.isArray(filters?.OR) && !filters.OR?.length) {
    delete backendJourneyFilters.OR;
  }

  const { loading, error, data, refetch } = useQuery(LOAD_JOURNEYS, {
    variables: {
      filter: hasFilters(filters)
        ? backendJourneyFilters
        : { isPublished: true },
      orderBy: { algorithm: "LAST_SAVED_DATE" },
    },
    fetchPolicy: "network-only",
  });
  const { handleDelete, isDeleting } = useDeleteJourney(refetch);

  useEffect(() => {
    if (loading || isDeleting) {
      showSpinner();
    } else {
      hideSpinner();
      if (data?.journeyList?.journeys) {
        setJourneys(data.journeyList?.journeys);
      }
    }
  }, [loading, isDeleting, data]);

  useEffect(() => {
    if (filters) {
      refetch();
    }
  }, [filters, refetch]);

  if (error) return <p>Error: {error.message}</p>;

  return (
    <div className="flex h-full flex-col">
      <div className="mb-3">
        <div className="flex gap-1">
          <IxTypography format="h4">Results</IxTypography>
          <IxTypography format="h4" className="text-soft">
            ({journeys.length})
          </IxTypography>
        </div>
      </div>
      <FilterChips filters={filters} setFilters={setFilters} />
      <div className="mr-2 flex-1 overflow-y-auto pr-2">
        {journeys.length > 0 ? (
          <div className="grid gap-4 pb-16 pt-3">
            <PublishedJourneys journeys={journeys} onDelete={handleDelete} />
          </div>
        ) : (
          <div className="flex h-full items-center justify-center">
            <NothingHere
              iconName="filter-filled"
              headline="No journeys available"
              bodyText="Please change your filters or reset your search."
            />
          </div>
        )}
      </div>
    </div>
  );
};
