import { FC } from "react";
import { Spinner } from "./Spinner";

export const BackdropSpinner: FC = () => (
  <div
    id="backdrop-spinner"
    className="fixed inset-0 flex size-full items-center justify-center z-[1040]"
    style={{ backgroundColor: "rgba(50, 50, 50, 0.7)", display: "none" }}
  >
    <Spinner />
  </div>
);

export const showSpinner = () => {
  const spinnerElement = document.getElementById("backdrop-spinner");
  if (spinnerElement) {
    spinnerElement.style.display = "flex";
  }
};

export const hideSpinner = () => {
  const spinnerElement = document.getElementById("backdrop-spinner");
  if (spinnerElement) {
    spinnerElement.style.display = "none";
  }
};
