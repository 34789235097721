import { TransitionType } from "@/utils/apollo/resolvers";
import { putLocationsForId } from "@/utils/navigation";
import { FrontendTouchpointTopology } from "@/utils/types";
import { Spinner } from "@motius/customer-heartbeat-ui";
import { IxIconButton } from "@siemens/ix-react";
import { cloneDeep } from "lodash";
import { useMemo } from "react";
import { useChannelStore } from "../../hooks/useChannelStore";
import { RequestMissingTouchpointButton } from "../RequestMissingDialog/RequestMissingTouchpointButton";
import { getTouchPointByTouchPointTopology } from "../TextJourney/TopologyUtils";
import { filterAndConvertRequestListToTouchpointTopologyList } from "../Map/utils";
import ActionHeadline from "./ActionHeadline";
import { groupByCategory } from "./channelsByCategory";
import { SearchableAccordionList } from "./SearchableAccordionList";
import { useReturnToTouchpoints } from "./useReturnToTouchpoints";
import { useTopologyStore } from "@/src/hooks/useTopologyStore/useTopologyStore";
import { NavigationButton } from "./NavigationButton";
import { NavigationTooltip } from "./Tooltips/NavigationTooltipContent";
import { TouchpointTooltipContent } from "./Tooltips/TouchpointTooltipContent";
import { cn } from "@motius/customer-heartbeat-ui/utils";

const NavigationTouchpoints = () => {
  const {
    addTouchPoint,
    lastAddedNodeTopologyId,
    journeyId,
    touchpointRequests,
    setTransition,
    channels,
    undo,
    undoStackIndex,
  } = useChannelStore();

  const { touchpointTopologies } = useTopologyStore();

  const processedChannels = useReturnToTouchpoints();

  const requestDataTouchpointTopologies = useMemo(
    () =>
      filterAndConvertRequestListToTouchpointTopologyList(
        touchpointRequests ?? [],
      ),
    [touchpointRequests],
  );

  const currentTouchpoints = useMemo(() => {
    return [
      ...(touchpointTopologies ?? []),
      ...requestDataTouchpointTopologies,
    ].map((tp) => ({
      ...tp,
    }));
  }, [touchpointRequests, requestDataTouchpointTopologies]);

  const availableTouchpointTopos = useMemo(() => {
    return (
      currentTouchpoints?.filter((t) => t.id !== lastAddedNodeTopologyId) ?? []
    );
  }, [currentTouchpoints, lastAddedNodeTopologyId]);

  const handleTouchpointClick = (touchpointId: string) => {
    /**
     * if the touchpointId (composite id) is already in the
     * processedChannels then setTransition to TransitionType.RETURN
     */
    const currentChanelTopoId = channels[channels.length - 1].topologyId;
    const currentCompositeId = `${currentChanelTopoId}__${touchpointId}`;
    const isTpAlreadyPresent = processedChannels.some((channel) =>
      channel.touchpoints.some((tp) => tp.compositeId === currentCompositeId),
    );
    if (isTpAlreadyPresent) {
      setTransition(TransitionType.Return);
    }

    const topo = availableTouchpointTopos.find(
      (tp: FrontendTouchpointTopology) => tp.id === touchpointId,
    );

    if (!topo) return;
    const tp = cloneDeep(topo);
    const locations = tp.locations;
    const t = getTouchPointByTouchPointTopology(tp);
    putLocationsForId(t.fid, locations);
    addTouchPoint(t);
  };

  const groupedTouchpoints = groupByCategory(availableTouchpointTopos);

  const accordionSections = Object.entries(groupedTouchpoints).map(
    ([category, touchpoints]) => ({
      title: category || "Other",
      items: touchpoints.map((tp) => ({
        id: tp.id,
        name: `${tp.name} ${tp.isCustom ? "(Custom Touchpoint)" : ""}`,
      })),
    }),
  );

  const groups = accordionSections.map((section) => section.title);

  if (!availableTouchpointTopos?.length) return <Spinner />;

  return (
    <div className="flex grow flex-col overflow-hidden">
      <div className="pr-4">
        <ActionHeadline
          className={cn("ml-0 mt-3", { "mt-[0.75rem]": undoStackIndex > 0 })}
        >
          <div className={cn("ml-4 flex items-center text-base")}>
            {undoStackIndex > 0 && (
              <IxIconButton
                icon="arrow-left"
                variant="primary"
                ghost
                size="24"
                className="mr-2 cursor-pointer"
                onClick={undo}
              />
            )}
            Select Touchpoint
            <NavigationTooltip
              className="ml-auto"
              tooltipClassname="font-sans"
              id="tooltip-select-touchpoint"
              content={<TouchpointTooltipContent />}
            />
          </div>
          <NavigationButton icon={"arrow-right"} className="ml-3 mt-1">
            Proceed to...
          </NavigationButton>
        </ActionHeadline>
        <hr className="-mx-4 mt-[0.75rem]" />
      </div>
      <SearchableAccordionList
        searchPlaceholder={"Search Touchpoint"}
        sections={accordionSections}
        onItemClick={handleTouchpointClick}
        action={
          <RequestMissingTouchpointButton
            groups={groups}
            journeyId={journeyId}
          />
        }
      />
    </div>
  );
};

export default NavigationTouchpoints;
