import { IxIcon } from "@siemens/ix-react";
import React from "react";
import { TextJourneyMetaData } from "./TextJourneyMetaData";
import { useChannelStore } from "@/src/hooks/useChannelStore";

interface TextJourneyTitleProps {
  title?: string | null;
  fid: string;
  editable: boolean;
}

export const TextJourneyTitle: React.FC<TextJourneyTitleProps> = ({
  title,
  fid,
  editable,
}) => {
  const { removeTitle } = useChannelStore();
  if (!title) return null;

  const handleDelete = () => {
    removeTitle(fid);
  };

  return (
    <TextJourneyMetaData
      icon={<IxIcon name="text" size="16" />}
      onDelete={handleDelete}
      type="title"
      editable={editable}
    >
      {title}
    </TextJourneyMetaData>
  );
};
