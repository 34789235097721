import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Any: { input: any; output: any; }
};

/** A screenshot, document, or other image that the user has uploaded to illustrate their journey. */
export type Attachment = {
  __typename?: 'Attachment';
  /**
   * The ID of the attachment.
   *
   * This is a UUID chosen by the client when uploading the attachment.
   */
  id: Scalars['ID']['output'];
  /**
   * The name of the attachment.
   *
   * This is a name set by the client when uploading the attachment.
   */
  name: Scalars['String']['output'];
};

export type AttachmentInput = {
  /**
   * The ID of the attachment.
   *
   * This is a UUID chosen by the client when uploading the attachment.
   */
  id: Scalars['ID']['input'];
  /**
   * The name of the attachment.
   *
   * This is a name set by the client when uploading the attachment.
   */
  name: Scalars['String']['input'];
};

/** Owner of journey's type as a buyer. */
export type BuyerType = {
  __typename?: 'BuyerType';
  id: Scalars['ID']['output'];
  /** Example: "Risk-Averse Buyer" */
  name: Scalars['String']['output'];
  topologyId?: Maybe<Scalars['ID']['output']>;
};

/** Owner of journey's type as a buyer. Input type def */
export type BuyerTypeInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Example: "Risk-Averse Buyer" */
  name: Scalars['String']['input'];
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Available types of a buyer for Journey creation. */
export type BuyerTypeTopology = {
  __typename?: 'BuyerTypeTopology';
  id: Scalars['ID']['output'];
  /** Example: "Risk-Averse Buyer" */
  name: Scalars['String']['output'];
};

export type Channel = Reactive & {
  __typename?: 'Channel';
  /** Example: 'Siemens Internal Websites' */
  category?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /**
   * Example: 'I browse'
   *
   * If non-empty, the last character of this string is not whitespace.
   */
  intro_fragment?: Maybe<Scalars['String']['output']>;
  /**
   * Whether this channel was requested by the user and does not
   * (yet) exist in the topology.
   */
  isCustom: Scalars['Boolean']['output'];
  /** Example: 'Siemens.com' */
  name: Scalars['String']['output'];
  /**
   * The type of transition to the next node in the hierarchy.
   *
   * For example, if the user navigated to the next channel by clicking "I switch to
   * [Channel]", this will be SWITCH.
   */
  nextTransition?: Maybe<TransitionType>;
  /**
   * The type of transition from the previous node in the hierarchy.
   *
   * For example, if the user navigated to this channel by clicking "I switch to
   * [Channel]", then this will be SWITCH.
   */
  prevTransition?: Maybe<TransitionType>;
  reaction?: Maybe<Reaction>;
  /** ID of the ChannelTopology node on which this Channel was based. */
  topologyId?: Maybe<Scalars['ID']['output']>;
  /**
   * Touchpoints visited during the journey.
   *
   * Touchpoints are listed in the order they were visited and can appear multiple times if
   * the user visited multiple times.
   */
  touchpoints: Array<Touchpoint>;
};

export type ChannelInput = {
  /** Example: 'Siemens Internal Websites' */
  category?: InputMaybe<Scalars['String']['input']>;
  /**
   * ID of the channel.
   *
   * Set to null to create a new channel and to a non-null value to update a channel.
   */
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Example: 'I browse'
   *
   * If non-empty, the last character of this string is not whitespace.
   */
  intro_fragment?: InputMaybe<Scalars['String']['input']>;
  /**
   * Whether this channel was requested by the user and does not
   * (yet) exist in the topology.
   *
   * Defaults to false.
   */
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Example: 'Siemens.com' */
  name: Scalars['String']['input'];
  /**
   * The type of transition to the next node in the hierarchy.
   *
   * For example, if the user navigated to the next channel by clicking "I switch to
   * [Channel]", this will be SWITCH.
   */
  nextTransition?: InputMaybe<TransitionType>;
  /**
   * The type of transition from the previous node in the hierarchy.
   *
   * For example, if the user navigated to this channel by clicking "I switch to
   * [Channel]", then this will be SWITCH.
   */
  prevTransition?: InputMaybe<TransitionType>;
  /**
   * If present, indicates that there is a reaction, and create it for the object
   * (this)-[:REACTED]->(r:Reaction)
   */
  reaction?: InputMaybe<ReactionInput>;
  /** ID of the ChannelTopology node on which this Channel was based. */
  topologyId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Touchpoints visited during the journey.
   *
   * Touchpoints are listed in the order they were visited and can appear multiple times if
   * the user visited multiple times.
   */
  touchpoints: Array<TouchpointInput>;
};

export type ChannelRequest = {
  __typename?: 'ChannelRequest';
  /**
   * The category that the new channel should be included in.
   *
   * Example: "Siemens internal websites".
   */
  category: Scalars['String']['output'];
  /** A comment that explains why the user would like to add this channel. */
  comment?: Maybe<Scalars['String']['output']>;
  /**
   * When the channel request was created.
   *
   * Represented as an ISO 8601 string, e.g. '2024-03-13T13:40:33.963Z'.
   */
  createdAt: Scalars['String']['output'];
  /**
   * The journey topology that this request relates to.
   *
   * i.e. to which journey topology should the channel be added?
   */
  forJourneyTopology?: Maybe<JourneyTopology>;
  /** The id of the channel request. */
  id: Scalars['ID']['output'];
  /** The name of the channel that the user would like to add to the topology. */
  name: Scalars['String']['output'];
  /** The user who requested this channel */
  requestedBy: User;
  /** A URL that indicates the channel the user is thinking about. */
  url?: Maybe<Scalars['String']['output']>;
};

/** All possible paths inside a channel that a user can take during a user journey. */
export type ChannelTopology = ReactiveTopology & {
  __typename?: 'ChannelTopology';
  /** Example: ['Happy', 'Inspired', ...] */
  available_reactions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Example: 'Siemens Internal Websites' */
  category?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /**
   * Example: 'I browse'
   *
   * If non-empty, the last character of this string is not whitespace.
   */
  intro_fragment?: Maybe<Scalars['String']['output']>;
  /** Example: 'Siemens.com' */
  name: Scalars['String']['output'];
  /**
   * Optional free-form tags that describe properties of the channel.
   *
   * These tags are not processed in the backend, but are displayed in the frontend and used for features like filtering.
   *
   * Examples of tag values (i.e. possible entries in the list): 'Physical', 'Virtual', 'Media'.
   */
  tags: Array<Maybe<Scalars['String']['output']>>;
  /** Possible touchpoints inside this channel. */
  touchpoints: Array<TouchpointTopology>;
};

export type ChannelTopologyInput = {
  /** Example: 'Siemens Internal Websites' */
  category: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  /**
   * Example: 'I browse'
   *
   * If non-empty, the last character of this string is not whitespace.
   */
  intro_fragment?: InputMaybe<Scalars['String']['input']>;
  /** ID of the journey topology to which the channel topology should be linked to */
  journeyTopologyId: Scalars['String']['input'];
  /** Example: 'Siemens.com' */
  name: Scalars['String']['input'];
  /**
   * Free-form tags that describe properties of the channel.
   *
   * These tags are not processed in the backend, but are displayed in the frontend and used for features like filtering.
   * The tags are optional while creating the new nodes.
   *
   * Examples of tag values (i.e. possible entries in the list): 'Physical', 'Virtual', 'Media'.
   */
  tags: Array<InputMaybe<Scalars['String']['input']>>;
};

export type Comment = {
  __typename?: 'Comment';
  /** User who added the comment */
  commentedBy: User;
  /** Created time of the comment in ISO 8601 string format, e.g. '2024-07-31T13:40:33.963Z' */
  createdAt: Scalars['String']['output'];
  /** The id of the comment. */
  id: Scalars['ID']['output'];
  /** List of GIDs of the users who has liked the comment */
  likedBy: Array<Maybe<Scalars['String']['output']>>;
  /** The comment text added by the user. */
  text: Scalars['String']['output'];
  /** Updated time of the comment in ISO 8601 string format, e.g. '2024-07-31T13:40:33.963Z' */
  updatedAt: Scalars['String']['output'];
};

export type CommentInput = {
  /** The id of the comment. */
  id: Scalars['ID']['input'];
  /** Journey Id on which the comment is added */
  journeyId: Scalars['String']['input'];
  /** The comment text added by the user. */
  text: Scalars['String']['input'];
};

export type CommentsConnection = {
  __typename?: 'CommentsConnection';
  /** Total number of comments(non-deleted) added on the journey */
  totalComments: Scalars['Int']['output'];
};

/** Owner of journey's role in their organization. */
export type CustomerRole = {
  __typename?: 'CustomerRole';
  id: Scalars['ID']['output'];
  /** Example: "Project Manager" */
  name: Scalars['String']['output'];
  topologyId?: Maybe<Scalars['ID']['output']>;
};

/** Owner of journey's role in their organization. */
export type CustomerRoleInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Example: "Project Manager" */
  name: Scalars['String']['input'];
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Owner of journey's role in their organization. */
export type CustomerRoleTopology = {
  __typename?: 'CustomerRoleTopology';
  id: Scalars['ID']['output'];
  /** Example: "Project Manager" */
  name: Scalars['String']['output'];
};

/** Owner of journey's organization type. */
export type CustomerType = {
  __typename?: 'CustomerType';
  id: Scalars['ID']['output'];
  /** Example: "Solution Partner" */
  name: Scalars['String']['output'];
  topologyId?: Maybe<Scalars['ID']['output']>;
};

/** Owner of journey's organization type. */
export type CustomerTypeInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Example: "Solution Partner" */
  name: Scalars['String']['input'];
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Owner of journey's organization type. */
export type CustomerTypeTopology = {
  __typename?: 'CustomerTypeTopology';
  id: Scalars['ID']['output'];
  /** Example: "Solution Partner" */
  name: Scalars['String']['output'];
};

/** What the owner of the journey expects from their experience (e.g. "Availability"). */
export type ExperienceDriver = {
  __typename?: 'ExperienceDriver';
  id: Scalars['ID']['output'];
  /** Example: "Availability" */
  name: Scalars['String']['output'];
  /**
   * Whether this experience driver positively or negatively impacted the customer's journey.
   *
   * When null, indicates that the experience driver was not rated.
   */
  rating?: Maybe<ExperienceDriverRating>;
  topologyId?: Maybe<Scalars['ID']['output']>;
};

/** What the owner of the journey expects from their experience (e.g. "Availability"). */
export type ExperienceDriverInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Example: "Availability" */
  name: Scalars['String']['input'];
  /**
   * Whether this experience driver positively or negatively impacted the customer's journey.
   *
   * When null, indicates that the experience driver was not rated.
   */
  rating?: InputMaybe<ExperienceDriverRating>;
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Whether an experience driver positively or negatively impacted the customer's journey. */
export enum ExperienceDriverRating {
  Bad = 'BAD',
  Good = 'GOOD'
}

/**
 * Possible experience drivers for a customer.
 * It indicates expectation of customer
 */
export type ExperienceDriverTopology = {
  __typename?: 'ExperienceDriverTopology';
  id: Scalars['ID']['output'];
  /** Example: "Accessibility" */
  name: Scalars['String']['output'];
  /**
   * The tooltip shown to the user for this experience driver
   *
   * Example: "I could readily access what I needed, when I needed it"
   */
  tooltip: Scalars['String']['output'];
};

export type FavoriteJourneyInput = {
  /** ID of the journey to mark/clear as favorite journey */
  id: Scalars['ID']['input'];
  /** Boolean denoting if the journey should be marked as favorite or clear it. */
  isFavorite: Scalars['Boolean']['input'];
};

/** Owner of journey's organization industry. */
export type Industry = {
  __typename?: 'Industry';
  id: Scalars['ID']['output'];
  /** Example: "Aerospace" */
  name: Scalars['String']['output'];
  topologyId?: Maybe<Scalars['ID']['output']>;
};

/** Owner of journey's organization industry. */
export type IndustryInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Example: "Aerospace" */
  name: Scalars['String']['input'];
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Owner of journey's organization industry. */
export type IndustryTopology = {
  __typename?: 'IndustryTopology';
  id: Scalars['ID']['output'];
  /** Example: "Aerospace" */
  name: Scalars['String']['output'];
};

/**
 * What the customer wishes to achieve.
 *
 * Some intents have sub-intents (referred to as "intent attributes" in the spreadsheet).
 */
export type Intent = {
  __typename?: 'Intent';
  id: Scalars['ID']['output'];
  /**
   * A possible sub-intent, e.g. "Solution Partner" or "Development Partner"
   * inside the intent "Connect with a Partner".
   */
  intent?: Maybe<Intent>;
  /** Example: "Connect with a Partner" */
  name: Scalars['String']['output'];
  /** ID of the topology node on which this intent was based. */
  topologyId?: Maybe<Scalars['ID']['output']>;
};

/**
 * What the customer wishes to achieve.
 *
 * Some intents have sub-intents (referred to as "intent attributes" in the spreadsheet).
 */
export type IntentInput = {
  /**
   * ID of the intent.
   *
   * Set to null when creating a new intent.
   */
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * A possible sub-intent, e.g. "Solution Partner" or "Development Partner"
   * inside the intent "Connect with a Partner".
   */
  intent?: InputMaybe<IntentInput>;
  /** Example: "Connect with a Partner" */
  name: Scalars['String']['input'];
  /** ID of the topology node on which this intent was based. */
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

/**
 * What the customer wishes to achieve.
 *
 * Some intents have sub-intents (referred to as "intent attributes" in the spreadsheet).
 */
export type IntentTopology = {
  __typename?: 'IntentTopology';
  id: Scalars['ID']['output'];
  /**
   * Intents subordinate to this intent, e.g. "Solution Partner" or "Development Partner"
   * inside the intent "Connect with a Partner".
   */
  intents: Array<IntentTopology>;
  /** Example: "Connect with a Partner" */
  name: Scalars['String']['output'];
};

/** A user's journey. */
export type Journey = {
  __typename?: 'Journey';
  /** Stores a reference to avatar image */
  avatarRef?: Maybe<Scalars['String']['output']>;
  /**
   * Type of the buyer
   *
   * Example: "Risk-Averse Buyer"
   */
  buyerType?: Maybe<BuyerType>;
  /**
   * Channels visited during the journey.
   *
   * Channels are listed in the order they were visited and can appear multiple times if
   * the user visited multiple times.
   */
  channels: Array<Channel>;
  /** Comments added by the users once the journey is published. */
  comments?: Maybe<Array<Maybe<Comment>>>;
  /** Information related to the connection between journey and comments added on it. */
  commentsConnection?: Maybe<CommentsConnection>;
  /**
   * When the journey was created.
   *
   * In ISO-8601 format, e.g. '2024-03-13T13:40:33.963Z'
   */
  createdDate?: Maybe<Scalars['String']['output']>;
  /**
   * Owner of journey's role in their organization.
   *
   * Example: "Project Manager"
   */
  customerRole?: Maybe<CustomerRole>;
  /**
   * Owner of journey's organization type.
   *
   * Example: "Solution Partner"
   */
  customerType?: Maybe<CustomerType>;
  /** If non-empty, the node is soft-deleted */
  deleted_date?: Maybe<Scalars['Any']['output']>;
  /**
   * Stores a description for the journey
   * (say it in your customer words)
   */
  description?: Maybe<Scalars['String']['output']>;
  /**
   * What the owner of the journey expects from their experience
   *
   * Example: "Availability"
   */
  experienceDrivers: Array<ExperienceDriver>;
  /** Returns a boolean specifying if the current logged-in user has selected this journey as favorite */
  favoritedByCurrentUser: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  /**
   * Owner of journey's organization industry.
   *
   * Example: "Aerospace"
   */
  industry?: Maybe<Industry>;
  /**
   * Indicates if the journey is published or draft
   * If it is a draft journey, only its owner can access it
   * If the journey is published, it cannot be edited
   */
  isPublished?: Maybe<Scalars['Boolean']['output']>;
  /** The source of data that was used to create the journey. */
  journeyType?: Maybe<JourneyType>;
  /**
   * When the journey was most recently saved.
   *
   * In ISO-8601 format, e.g. '2024-03-13T13:40:33.963Z'
   */
  lastSavedDate?: Maybe<Scalars['String']['output']>;
  /** The motivation & intent behind the customer's journey. */
  motivation?: Maybe<Motivation>;
  /**
   * The eventual outcome of the journey.
   *
   * If null, then the journey has not yet been finalized.
   */
  outcome?: Maybe<JourneyOutcome>;
  /**
   * GID of owner of the journey
   * Only the owner of the journey can edit it
   */
  ownerGid?: Maybe<Scalars['String']['output']>;
  /** Name of owner of the journey */
  ownerName?: Maybe<Scalars['String']['output']>;
  /** Difficulties that the customer encountered during their journey. */
  painpoints: Array<Painpoint>;
  /** How well the customer knows Siemens (aka "Customer status"). */
  priorContact?: Maybe<PriorContact>;
  /**
   * Selected Region of the journey.
   *
   * Example: "Americas: Brazil"
   */
  region?: Maybe<Region>;
  /** ID of the JourneyTopology node on which this Journey was based. */
  topologyId?: Maybe<Scalars['ID']['output']>;
  /**
   * Number of times the journey is viewed by all users. This count is calculated based on number of
   * times "incrementJourneyViews" is called. Also, the views are only calculated for published journeys.
   */
  views: Scalars['Int']['output'];
};

/**
 * Filter journeys by various criteria.
 *
 * Specifying multiple fields in the same filter object is equivalent to using the
 * "AND" operation. For example, specifying both `ownerName` and `isPublished`
 * in the same filter object will return only journeys that have the specified
 * owner name and are published.
 */
export type JourneyFilter = {
  /** Combines multiple JourneyFilters with an "AND" operation. */
  AND?: InputMaybe<Array<JourneyFilter>>;
  /** Negate multiple JourneyFilters with a "NOT" operation. */
  NOT?: InputMaybe<JourneyFilter>;
  /** Combines multiple JourneyFilters with an "OR" operation. */
  OR?: InputMaybe<Array<JourneyFilter>>;
  /** Return journeys whose buyer type has the given topology ID. */
  buyerTypeTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /** Return journeys that contain a channel with the given topology ID. */
  channelTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /** Return journeys whose customer role has the given topology ID. */
  customerRoleTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /** Return journeys whose customer type has the given topology ID. */
  customerTypeTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /** Return journeys whose experience driver has the given topology ID. */
  experienceDriverTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /** Return journeys favorited by the user with given GID. */
  favoritedByGid?: InputMaybe<Scalars['ID']['input']>;
  /** Return journeys whose industry has the given topology ID. */
  industryTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Return journeys whose intent has the given topology ID.
   *
   * This does not match sub-intents. When using this together with an AND
   * filter and motivationTopologyId, be careful that the intent is a child of the
   * specified motivation.
   */
  intentTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /** Return journeys who are published / draft. */
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  /** Return journeys whose journey type has the given badge. */
  journeyTypeBadge?: InputMaybe<JourneyTypeBadge>;
  /** Return journeys that contain a location with the given topology ID. */
  locationTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Return journeys whose motivation has the given topology ID.
   *
   * When using this together with an AND filter and intentTopologyId, be
   * careful that the motivation is a parent of the specified intent.
   */
  motivationTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /** Return journeys whose owner has the given GID. */
  ownerGid?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Return journeys whose owner name matches the given name.
   * This is a case-insensitive sub string search.
   * Filtering only works if the search text has at least 3 characters.
   */
  ownerName?: InputMaybe<Scalars['String']['input']>;
  /** Return journeys whose painpoint has the given topology ID. */
  painpointTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /** Return journeys whose prior contact has the given topology ID. */
  priorContactTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /** Return journeys whose region has the given topology ID. */
  regionTopologyId?: InputMaybe<Scalars['ID']['input']>;
  /** Return journeys that contain a touchpoint with the given topology ID. */
  touchpointTopologyId?: InputMaybe<Scalars['ID']['input']>;
};

/** A user's journey. */
export type JourneyInput = {
  /** Stores a reference to avatar image */
  avatarRef?: InputMaybe<Scalars['String']['input']>;
  /** Type of the buyer. */
  buyerType?: InputMaybe<BuyerTypeInput>;
  /**
   * Channels visited during the journey.
   *
   * Channels are listed in the order they were visited and can appear multiple times if
   * the user visited multiple times.
   *
   * This field is nullable. If null is provided instead of a list, then the channels will
   * not be updated. This allows updating the journey metadata without providing the entire
   * list of nodes that appear inside the journey.
   */
  channels?: InputMaybe<Array<ChannelInput>>;
  /** Owner of journey's role in their organization. */
  customerRole?: InputMaybe<CustomerRoleInput>;
  /**
   * Owner of journey's organization type.
   *
   * Example: "Solution Partner"
   */
  customerType?: InputMaybe<CustomerTypeInput>;
  /**
   * Stores a description for the journey
   * (say it in your customer words)
   */
  description?: InputMaybe<Scalars['String']['input']>;
  /**
   * What the owner of the journey expects from their experience
   * Example: "Availability"
   */
  experienceDrivers?: InputMaybe<Array<ExperienceDriverInput>>;
  /**
   * ID of the journey.
   *
   * Set to null to create a new journey and to a non-null value to update a journey.
   */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Owner of journey's organization industry. */
  industry?: InputMaybe<IndustryInput>;
  /**
   * Indicates if the journey is published or draft
   * If it is a draft journey, only its owner can access it
   * If the journey is published, it cannot be edited
   * Beware, A published journey cannot be reconverted to be a draft
   */
  isPublished?: InputMaybe<Scalars['Boolean']['input']>;
  /** The source of data that was used to create the journey. */
  journeyType?: InputMaybe<JourneyTypeInput>;
  /** The motivation & intent behind the customer's journey. */
  motivation?: InputMaybe<MotivationInput>;
  /**
   * The eventual outcome of the journey
   *
   * If null, then the journey has not yet been finalized.
   */
  outcome?: InputMaybe<JourneyOutcomeInput>;
  /** Difficulties that the customer encountered during their journey. */
  painpoints?: InputMaybe<Array<PainpointInput>>;
  /** How well the customer knows Siemens (aka "Customer status"). */
  priorContact?: InputMaybe<PriorContactInput>;
  /** Region of the journey */
  region?: InputMaybe<RegionInput>;
  /** ID of the JourneyTopology node on which this Journey was based. */
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

export type JourneyOrder = {
  /** Indicates the sorting algorithm to use when sorting the results. */
  algorithm: JourneyOrderAlgorithm;
};

export enum JourneyOrderAlgorithm {
  /** Sorts by number of comments added on the journey in descending order. */
  CommentsCount = 'COMMENTS_COUNT',
  /**
   * Sorts by the date the journey was created, with the most recently
   * created first.
   */
  CreatedDate = 'CREATED_DATE',
  /**
   * Sorts by the date the journey was most recently saved, with the
   * most recently saved first.
   */
  LastSavedDate = 'LAST_SAVED_DATE'
}

/**
 * The eventual outcome of a journey.
 *
 * TODO: add fields to store information about handing the journey over to someone else.
 */
export type JourneyOutcome = {
  __typename?: 'JourneyOutcome';
  /** How the journey ended. */
  type: JourneyOutcomeType;
};

/**
 * The eventual outcome of the journey.
 *
 * TODO: add fields to handle handing the journey over to someone else.
 */
export type JourneyOutcomeInput = {
  /** The outcome of the journey (e.g. intent fulfilled, journey handed over) */
  type: JourneyOutcomeType;
};

/** How the journey ended. */
export enum JourneyOutcomeType {
  /** The journey was ended (without further information about how it ended). */
  Ended = 'ENDED',
  /** The journey was handed over to another person to continue. */
  HandedOver = 'HANDED_OVER',
  /** The user achieved their stated intent by the end of the journey. */
  IntentFulfilled = 'INTENT_FULFILLED',
  /** The user did not achieve their stated intent by the end of the journey. */
  IntentUnfulfilled = 'INTENT_UNFULFILLED'
}

/** The possible structures of a journey that a user can make. */
export type JourneyTopology = {
  __typename?: 'JourneyTopology';
  /** The available types of a buyer */
  buyer_types: Array<BuyerTypeTopology>;
  /** Possible channels in the journey. */
  channels: Array<ChannelTopology>;
  /** The role of the contact who owns the journey. */
  customer_role: Array<CustomerRoleTopology>;
  /** Customer's type, related to Siemens */
  customer_type: Array<CustomerTypeTopology>;
  /**
   * List of possible experience drivers for a customer.
   * It indicates expectation of customer
   * (e.g. "Availability").
   */
  experience_drivers: Array<ExperienceDriverTopology>;
  id: Scalars['ID']['output'];
  /** Customer's industry that it operates in */
  industry: Array<IndustryTopology>;
  /** Possible motivations & intents that the customer could have. */
  motivations: Array<MotivationTopology>;
  /** Difficulties that the customer might encounter during their journey. */
  painpoints: Array<PainpointTopology>;
  /**
   * How well the customer knows Siemens.
   *
   * In the spreadsheet, this is called "Customer Status".
   */
  priorContact: Array<PriorContactTopology>;
  /** Journey's channel regions */
  regions: Array<RegionTopology>;
};

/** The source of data that was used to create the journey. */
export type JourneyType = {
  __typename?: 'JourneyType';
  badge?: Maybe<JourneyTypeBadge>;
  /**
   * User-provided information about the journey type.
   *
   * Intended to be used when the badge is OTHER.
   */
  description?: Maybe<Scalars['String']['output']>;
};

/** The different sources of data that could be used to create a journey. */
export enum JourneyTypeBadge {
  ClickPathAnalysis = 'CLICK_PATH_ANALYSIS',
  CustomerResearch = 'CUSTOMER_RESEARCH',
  CustomerSupportFeedback = 'CUSTOMER_SUPPORT_FEEDBACK',
  DirectCustomerFeedback = 'DIRECT_CUSTOMER__FEEDBACK',
  Other = 'OTHER',
  ProductReviewsAndRatings = 'PRODUCT_REVIEWS_AND_RATINGS'
}

/** The source of data that was used to create the journey. */
export type JourneyTypeInput = {
  badge?: InputMaybe<JourneyTypeBadge>;
  /**
   * User-provided information about the journey type.
   *
   * Intended to be used when the badge is OTHER.
   */
  description?: InputMaybe<Scalars['String']['input']>;
};

/**
 * List of filtered, sorted and paginated journeys visible to the user.
 * Also, includes total items count of the query before pagination is applied
 */
export type JourneysConnection = {
  __typename?: 'JourneysConnection';
  /** List of filtered, ordered and paginated journeys. Currently only returns first 100 journeys. */
  journeys: Array<Journey>;
  /** Total available journeys count after applying the given filter */
  totalItems: Scalars['Int']['output'];
};

export type LikeCommentInput = {
  /** The id of the comment to like/unlike */
  id: Scalars['ID']['input'];
  /** Boolean representing the comment like status */
  isLiked: Scalars['Boolean']['input'];
};

/**
 * FKA Attribute.
 *
 * Some touchpoints have sub-locations that can be navigated to. For example, from the
 * "Homepage" touchpoint on the "Siemens.com" channel, the user might navigate to the
 * "Account Management" location.
 */
export type Location = Reactive & {
  __typename?: 'Location';
  /**
   * Screenshots, documents, etc. that are attached to this location.
   *
   * Users can upload files to illustrate their experience of the location or to indicate
   * more precisely what they are talking about.
   *
   * See the documentation of `attachments` on the `Touchpoint` type for important information
   * about how to modify and use this value.
   */
  attachments: Array<Attachment>;
  id: Scalars['ID']['output'];
  /**
   * Example: 'I look at'
   *
   * If non-empty, the last character of this string is not whitespace.
   */
  intro_fragment?: Maybe<Scalars['String']['output']>;
  /**
   * Whether this location was requested by the user and does not
   * (yet) exist in the topology.
   */
  isCustom: Scalars['Boolean']['output'];
  /**
   * Locations that the user visited inside this location.
   *
   * Locations are listed in the order they were visited and can appear multiple times if
   * the user visited multiple times.
   *
   * FKA as "Attributes". Locations are included recursively, so level 1 locations are
   * those directly included in this type, level 2 locations are the locations included
   * in a location included in this type, etc.
   */
  locations: Array<Location>;
  /**
   * Name of the location.
   * This is a fixed name that is taken from the topology and can't be chosen by the user
   * Example: 'Account Management'
   */
  name: Scalars['String']['output'];
  /**
   * The type of transition to the next node in the hierarchy.
   *
   * For example, if the user switched to a different channel after this location, then
   * this will be SWITCH.
   */
  nextTransition?: Maybe<TransitionType>;
  /** The Follow-up question asked after this location is selected and the user's answer to it. */
  question?: Maybe<Question>;
  reaction?: Maybe<Reaction>;
  /**
   * Custom title that can be set by the user.
   * Example: "Circular Spare Parts"
   */
  title?: Maybe<Scalars['String']['output']>;
  /** ID of the LocationTopology node on which this Location was based. */
  topologyId?: Maybe<Scalars['ID']['output']>;
};

/**
 * FKA Attribute.
 *
 * Some touchpoints have sub-locations that can be navigated to. For example, from the
 * "Homepage" touchpoint on the "Siemens.com" channel, the user might navigate to the
 * "Account Management" location.
 */
export type LocationInput = {
  /**
   * Screenshots, documents, etc. that are attached to this location.
   *
   * Users can upload files to illustrate their experience of the location or to indicate
   * more precisely what they are talking about.
   *
   * See the documentation of `attachments` on the `TouchpointInput` type for important
   * information about how to modify and use this value.
   */
  attachments: Array<AttachmentInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Example: 'I look at'
   *
   * If non-empty, the last character of this string is not whitespace.
   */
  intro_fragment?: InputMaybe<Scalars['String']['input']>;
  /**
   * Whether this location was requested by the user and does not
   * (yet) exist in the topology.
   *
   * Defaults to false.
   */
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Locations that the user visited inside this location.
   *
   * Locations are listed in the order they were visited and can appear multiple times if
   * the user visited multiple times.
   *
   * FKA as "Attributes". Locations are included recursively, so level 1 locations are
   * those directly included in this type, level 2 locations are the locations included
   * in a location included in this type, etc.
   */
  locations: Array<LocationInput>;
  /**
   * Name of the location.
   * This is a fixed name that is taken from the topology and can't be chosen by the user
   * Example: 'Account Management'
   */
  name: Scalars['String']['input'];
  /**
   * The type of transition to the next node in the hierarchy.
   *
   * For example, if the user switched to a different channel after this location, then
   * this will be SWITCH.
   */
  nextTransition?: InputMaybe<TransitionType>;
  /** The Follow-up question asked after this location is selected and the user's answer to it. */
  question?: InputMaybe<QuestionInput>;
  /**
   * If present, indicates that there is a reaction, and create it for the object
   * (this)-[:REACTED]->(r:Reaction)
   */
  reaction?: InputMaybe<ReactionInput>;
  /**
   * Custom title that can be set by the user.
   * Example: "Circular Spare Parts"
   */
  title?: InputMaybe<Scalars['String']['input']>;
  /** ID of the LocationTopology node on which this Location was based. */
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

export type LocationRequest = {
  __typename?: 'LocationRequest';
  /**
   * The category that the new location should be included in.
   *
   * Example: "Siemens internal websites".
   */
  category: Scalars['String']['output'];
  /** A comment that explains why the user would like to add this location. */
  comment?: Maybe<Scalars['String']['output']>;
  /**
   * When the location request was created.
   *
   * Represented as an ISO 8601 string, e.g. '2024-03-13T13:40:33.963Z'.
   */
  createdAt: Scalars['String']['output'];
  /**
   * The journey topology that this request relates to.
   *
   * i.e. to which journey topology should the location be added?
   */
  forJourneyTopology?: Maybe<JourneyTopology>;
  /** The id of the location request. */
  id: Scalars['ID']['output'];
  /** The name of the location that the user would like to add to the topology. */
  name: Scalars['String']['output'];
  /** The user who requested this location */
  requestedBy: User;
  /** A URL that indicates the location the user is thinking about. */
  url?: Maybe<Scalars['String']['output']>;
};

/**
 * FKA Attributes.
 *
 * Some touchpoints have sub-locations that can be navigated to. For example, from the
 * "Homepage" touchpoint on the "Siemens.com" channel, the user might navigate to the
 * "Account Management" location.
 */
export type LocationTopology = {
  __typename?: 'LocationTopology';
  available_reactions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * Category/Grouping that the location belongs to
   * Example: 'Detailed Product/ Solution Description ', 'Certificates'
   */
  category?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /**
   * Example: 'I look at'
   *
   * If non-empty, the last character of this string is not whitespace.
   */
  intro_fragment?: Maybe<Scalars['String']['output']>;
  /**
   * Possible locations inside this location.
   *
   * Locations are included recursively, so level 1 locations are those directly included
   * in a TouchpointLocation, level 2 locations are the locations included in a location
   * included in a TouchpointLocation, etc.
   *
   * This list is empty if the location has no locations inside it.
   */
  locations: Array<LocationTopology>;
  /** Example: 'Account Management' */
  name: Scalars['String']['output'];
  /**
   * If present, indicates that there is a follow-up question after this location is
   * selected.
   *
   * Example: 'What is your search term?'
   */
  question?: Maybe<QuestionTopology>;
  /**
   * Free-form tags that describe properties of the location.
   *
   * These tags are not processed in the backend, but are displayed in the frontend and used for features like filtering.
   *
   * Examples of tag values (i.e. possible entries in the list): 'File', 'Text Content', 'Media'.
   */
  tags: Array<Scalars['String']['output']>;
};

/**
 * What inspires the customer.
 *
 * Each motivation has an intent, that describes more concretely what the customer wishes
 * to achieve.
 */
export type Motivation = {
  __typename?: 'Motivation';
  id: Scalars['ID']['output'];
  /** What the customer wishes to achieve. */
  intent?: Maybe<Intent>;
  /** Example: "Contact" */
  name: Scalars['String']['output'];
  /** ID of the topology node on which this motivation was based. */
  topologyId?: Maybe<Scalars['ID']['output']>;
};

/**
 * What inspires the customer.
 *
 * Each motivation has an intent, that describes more concretely what the customer wishes
 * to achieve.
 */
export type MotivationInput = {
  /**
   * ID of the motivation.
   *
   * Set to null when creating a new motivation.
   */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** What the customer wishes to achieve. */
  intent?: InputMaybe<IntentInput>;
  /** Example: "Contact" */
  name: Scalars['String']['input'];
  /** ID of the topology node on which this motivation was based. */
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

/** What inspires the customer. */
export type MotivationTopology = {
  __typename?: 'MotivationTopology';
  id: Scalars['ID']['output'];
  /** Intentions that a customer with this motivation might have. */
  intents: Array<IntentTopology>;
  /** Example: "Contact" */
  name: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Adding a new comment on a published journey */
  addComment: Comment;
  /**
   * Deletes a comment specified by ID and returns the deleted comment's ID on successful deletion.
   * Returns an error if the deletion was unsuccessful (e.g. comment does not exist)
   */
  deleteComment: Scalars['ID']['output'];
  /**
   * Deletes a journey with the given ID.
   *
   * It is an error to provide a journey ID that does not exist in the database. Returns
   * the ID of the deleted journey.
   */
  deleteJourney?: Maybe<Scalars['ID']['output']>;
  /**
   * Mutation used to record a journey view by a user. This only records the view count for published journeys
   * and returns the updated total view count. For non-published journeys it will return 0.
   */
  incrementJourneyViews: Scalars['Int']['output'];
  /**
   * Updates the like status of the comment and returns the updated comment.
   * Returns an error if updating like status was unsuccessful (e.g. comment does not exist)
   */
  likeComment: Scalars['ID']['output'];
  /**
   * Mutation used to mark or clear a specific journey as favorite for the logged in user.
   *
   * Returns the journey ID if the operation was successful.
   */
  markJourneyAsFavorite: Scalars['ID']['output'];
  /** Requests that a new channel be added to the topology. */
  requestChannel: ChannelRequest;
  /** Requests that a new location be added to the topology. */
  requestLocation: LocationRequest;
  /** Requests that a new touchpoint be added to the topology. */
  requestTouchpoint: TouchpointRequest;
  /**
   * Updates the comment text specified by ID and returns the updated comment.
   * Returns an error if update was unsuccessful (e.g. comment does not exist)
   */
  updateComment: Comment;
  /**
   * Create or update ChannelTopology nodes for a given JourneyTopology.
   * It returns the ID of the ChannelTopology that was created or updated, if the operation was successful.
   */
  upsertChannelTopology: Scalars['ID']['output'];
  /**
   * Create or update a user's journey.
   *
   * If the journey's ID is null, then a new journey is inserted into the database. If the
   * journey's ID is non-null, then the journey with that ID is created. It is an error to
   * provide a journey ID that does not exist in the database.
   */
  upsertJourney?: Maybe<Journey>;
};


export type MutationAddCommentArgs = {
  input: CommentInput;
};


export type MutationDeleteCommentArgs = {
  id: Scalars['ID']['input'];
};


export type MutationDeleteJourneyArgs = {
  id: Scalars['ID']['input'];
};


export type MutationIncrementJourneyViewsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationLikeCommentArgs = {
  input: LikeCommentInput;
};


export type MutationMarkJourneyAsFavoriteArgs = {
  input: FavoriteJourneyInput;
};


export type MutationRequestChannelArgs = {
  input: RequestChannelInput;
};


export type MutationRequestLocationArgs = {
  input: RequestLocationInput;
};


export type MutationRequestTouchpointArgs = {
  input: RequestTouchpointInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationUpsertChannelTopologyArgs = {
  channelTopology?: InputMaybe<ChannelTopologyInput>;
};


export type MutationUpsertJourneyArgs = {
  journey?: InputMaybe<JourneyInput>;
};

/** Difficulties that the customer encountered during their journey. */
export type Painpoint = {
  __typename?: 'Painpoint';
  id: Scalars['ID']['output'];
  /** Example: "High use of Siemens specific language" */
  name: Scalars['String']['output'];
  topologyId?: Maybe<Scalars['ID']['output']>;
};

/** Difficulties that the customer encountered during their journey. */
export type PainpointInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Example: "High use of Siemens specific language" */
  name: Scalars['String']['input'];
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Possible difficulties that the customer could during their journey. */
export type PainpointTopology = {
  __typename?: 'PainpointTopology';
  id: Scalars['ID']['output'];
  /** Example: "High use of Siemens specific language" */
  name: Scalars['String']['output'];
};

/** How well the customer knows Siemens (aka Customer Status). */
export type PriorContact = {
  __typename?: 'PriorContact';
  id: Scalars['ID']['output'];
  /** Examples: "Existing Customer" or "Prospect" */
  name: Scalars['String']['output'];
  topologyId?: Maybe<Scalars['ID']['output']>;
};

/** How well the customer knows Siemens (aka Customer Status). */
export type PriorContactInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Examples: "Existing Customer" or "Prospect" */
  name: Scalars['String']['input'];
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

/** How well the customer knows Siemens (aka Customer Status). */
export type PriorContactTopology = {
  __typename?: 'PriorContactTopology';
  id: Scalars['ID']['output'];
  /** Examples: "Existing Customer" or "Prospect" */
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  /**
   * Get channel topology by ID.
   *
   * If the ID does not correspond to a channel topology in the database, returns null.
   */
  channelTopology?: Maybe<ChannelTopology>;
  /** Get Journey by ID. */
  journey?: Maybe<Journey>;
  /**
   * Get all journeys visible to the user.
   *
   * If the filter is not specified, then it defaults to returning only journeys
   * that are owned by the user making the request.
   *
   * If the ordering is not specified, then it defaults to returning journeys
   * in order of the time of their last update, with the most recently updated
   * first.
   */
  journeyList: JourneysConnection;
  /**
   * Get journey topology by ID.
   * If the ID is not mentioned, it will return the latest created journey topology.
   */
  journeyTopology?: Maybe<JourneyTopology>;
  /**
   * Lists all channel, touchpoint  or location requests that are visible to the user.
   *
   * If journeyId is provided, then only topology requests that were created
   * while editing that journey will be returned.
   */
  listTopologyRequests: Array<TopologyRequest>;
  /** Get location topologies by ID. */
  locationTopologies: Array<Maybe<LocationTopology>>;
  /** Retrieves recommended journeys for the user */
  recommendedJourneys: Array<Journey>;
  /** Get touchpoint topologies by ID. */
  touchpointTopologies: Array<Maybe<TouchpointTopology>>;
  /**
   * List users matching a query.
   *
   * Returns at most 20 users.
   */
  users?: Maybe<Array<Maybe<User>>>;
};


export type QueryChannelTopologyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryJourneyArgs = {
  id: Scalars['ID']['input'];
};


export type QueryJourneyListArgs = {
  filter?: InputMaybe<JourneyFilter>;
  orderBy?: InputMaybe<JourneyOrder>;
};


export type QueryJourneyTopologyArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryListTopologyRequestsArgs = {
  journeyId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryLocationTopologiesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryTouchpointTopologiesArgs = {
  ids: Array<Scalars['ID']['input']>;
};


export type QueryUsersArgs = {
  filter: UserFilter;
};

export type Question = {
  __typename?: 'Question';
  /** The free-text answer that the user provided. */
  answer?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /** The text of the question that should be asked. */
  question: Scalars['String']['output'];
  topologyId?: Maybe<Scalars['ID']['output']>;
};

export type QuestionInput = {
  /** The free-text answer that the user provided. */
  answer?: InputMaybe<Scalars['String']['input']>;
  /**
   * ID of the question.
   *
   * Set to null to create a new question and to a non-null value to update a question.
   */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** The text of the question that should be asked. */
  question: Scalars['String']['input'];
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

export type QuestionTopology = {
  __typename?: 'QuestionTopology';
  id: Scalars['ID']['output'];
  /** The text of the question that should be asked. */
  question: Scalars['String']['output'];
};

export type Reaction = {
  __typename?: 'Reaction';
  id: Scalars['ID']['output'];
  /** Name of reaction */
  name: Scalars['String']['output'];
  /** The reason for the reaction */
  reason?: Maybe<Scalars['String']['output']>;
  topologyId?: Maybe<Scalars['ID']['output']>;
};

export type ReactionInput = {
  /**
   * ID of the reaction.
   *
   * Set to null to create a new reaction and to a non-null value to update a reaction.
   */
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Name of reaction */
  name: Scalars['String']['input'];
  /** The reason for the reaction */
  reason?: InputMaybe<Scalars['String']['input']>;
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

/** The possible reactions */
export type ReactionTopology = {
  __typename?: 'ReactionTopology';
  id: Scalars['ID']['output'];
  /** Name of reaction */
  name: Scalars['String']['output'];
};

export type Reactive = {
  /**
   * Shows reaction to the object
   *     MATCH (this)-[:REACTED {visible: true}]->(r:Reaction:Visible)
   *     RETURN properties(r) LIMIT 1
   */
  reaction?: Maybe<Reaction>;
};

export type ReactiveTopology = {
  /**
   * Lists available reactions
   *     MATCH (r:ReactionTopology)
   *     RETURN r.name
   */
  available_reactions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

/** Selected Region of the journey. */
export type Region = {
  __typename?: 'Region';
  id: Scalars['ID']['output'];
  /** Example: "Americas: Brazil" */
  name: Scalars['String']['output'];
  topologyId?: Maybe<Scalars['ID']['output']>;
};

/** Selected Region of the journey. Type def for input */
export type RegionInput = {
  id?: InputMaybe<Scalars['ID']['input']>;
  /** Example: "Americas: Brazil" */
  name: Scalars['String']['input'];
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

/** Region options for journey creation. */
export type RegionTopology = {
  __typename?: 'RegionTopology';
  id: Scalars['ID']['output'];
  /** Example: "Americas: Brazil" */
  name: Scalars['String']['output'];
};

export type RequestChannelInput = {
  /**
   * The category that the new channel should be included in.
   *
   * Example: "Siemens internal websites".
   */
  category: Scalars['String']['input'];
  /** A comment that explains why the user would like to add this channel. */
  comment?: InputMaybe<Scalars['String']['input']>;
  /**
   * ID of the journey that this request relates to.
   *
   * Until approved, the channel will only visible inside this journey.
   */
  forJourneyId: Scalars['ID']['input'];
  /**
   * ID of the journey topology that this request relates to.
   *
   * i.e. to which journey topology should the channel be added?
   */
  forJourneyTopologyId: Scalars['ID']['input'];
  /** The id of the channel request. */
  id: Scalars['ID']['input'];
  /** The name of the channel that the user would like to add to the topology. */
  name: Scalars['String']['input'];
  /** A URL that indicates the channel the user is thinking about. */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type RequestLocationInput = {
  /**
   * The category that the new location should be included in.
   *
   * Example: "Siemens internal websites".
   */
  category: Scalars['String']['input'];
  /** A comment that explains why the user would like to add this location. */
  comment?: InputMaybe<Scalars['String']['input']>;
  /**
   * ID of the journey that this request relates to.
   *
   * Until approved, the touchpoint will only visible inside this journey.
   */
  forJourneyId: Scalars['ID']['input'];
  /**
   * ID of the journey topology that this request relates to.
   *
   * i.e. to which journey topology should the location be added?
   */
  forJourneyTopologyId: Scalars['ID']['input'];
  /** The id of the location request. */
  id: Scalars['ID']['input'];
  /** The name of the location that the user would like to add to the topology. */
  name: Scalars['String']['input'];
  /** A URL that indicates the location the user is thinking about. */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type RequestTouchpointInput = {
  /**
   * The category that the new touchpoint should be included in.
   *
   * Example: "Siemens internal websites".
   */
  category: Scalars['String']['input'];
  /** A comment that explains why the user would like to add this touchpoint. */
  comment?: InputMaybe<Scalars['String']['input']>;
  /**
   * ID of the journey that this request relates to.
   *
   * Until approved, the touchpoint will only visible inside this journey.
   */
  forJourneyId: Scalars['ID']['input'];
  /**
   * ID of the journey topology that this request relates to.
   *
   * i.e. to which journey topology should the touchpoint be added?
   */
  forJourneyTopologyId: Scalars['ID']['input'];
  /** The id of the touchpoint request. */
  id: Scalars['ID']['input'];
  /** The name of the touchpoint that the user would like to add to the topology. */
  name: Scalars['String']['input'];
  /** A URL that indicates the touchpoint the user is thinking about. */
  url?: InputMaybe<Scalars['String']['input']>;
};

export type StringMatches = {
  /**
   * The string to match.
   *
   * `containsSubstring` must appear as an actual substring of the target
   * string; this is not a regular expression or fuzzy match.
   */
  containsSubstring?: InputMaybe<Scalars['String']['input']>;
};

export type TopologyRequest = ChannelRequest | LocationRequest | TouchpointRequest;

export type Touchpoint = Reactive & {
  __typename?: 'Touchpoint';
  /**
   * Screenshots, documents, etc. that are attached to this touchpoint.
   *
   * Users can upload files to illustrate their experience of the touchpoint or to indicate
   * more precisely what they are talking about.
   *
   * The client should use the /viewImage endpoint to request a link to view or download an
   * attachment (this allows us to enforce that only signed-in users can view uploaded images).
   * Note that it's possible to call /requestImageUpload (and hence cause data to be written
   * to the database) without then using the returned pre-signed URL to upload an image. This
   * means that we can't guarantee that all of the attachments in this list will have
   * corresponding files stored on S3.
   */
  attachments: Array<Attachment>;
  id: Scalars['ID']['output'];
  /**
   * Example: 'I navigate to'
   *
   * If non-empty, the last character of this string is not whitespace.
   */
  intro_fragment?: Maybe<Scalars['String']['output']>;
  /**
   * Whether this touchpoint was requested by the user and does not
   * (yet) exist in the topology.
   */
  isCustom: Scalars['Boolean']['output'];
  /**
   * Locations that the user visited inside this touchpoint.
   *
   * Locations are listed in the order they were visited and can appear multiple times if
   * the user visited multiple times.
   *
   * FKA as "Attributes". Locations are included recursively, so level 1 locations are
   * those directly included in this type, level 2 locations are the locations included
   * in a location included in this type, etc.
   */
  locations: Array<Location>;
  /**
   * Name of the touchpoint.
   * This is a fixed name that is taken from the topology and can't be chosen by the user
   * Example: 'Search result page'
   */
  name: Scalars['String']['output'];
  /**
   * The type of transition to the next node in the hierarchy.
   *
   * For example, if the user switched to a different channel after this touchpoint, then
   * this will be SWITCH.
   */
  nextTransition?: Maybe<TransitionType>;
  /**
   * The type of transition from the previous node in the hierarchy.
   *
   * For example, if the user navigated to this touchpoint by clicking "I return to
   * [Touchpoint]", then this will be RETURN.
   */
  prevTransition?: Maybe<TransitionType>;
  /**
   * If present, indicates that there is a follow-up question after this touchpoint is
   * selected.
   *
   * Example: 'What is your search term?'
   */
  question?: Maybe<Question>;
  reaction?: Maybe<Reaction>;
  /**
   * Custom title that can be set by the user.
   * Example: "Circular Spare Parts"
   */
  title?: Maybe<Scalars['String']['output']>;
  /** ID of the TouchpointTopology node on which this Touchpoint was based. */
  topologyId?: Maybe<Scalars['ID']['output']>;
};

export type TouchpointInput = {
  /**
   * Screenshots, documents, etc. that are attached to this touchpoint.
   *
   * Users can upload files to illustrate their experience of the touchpoint or to indicate
   * more precisely what they are talking about.
   *
   * Attachments must first be uploaded using the /requestImageUpload endpoint. After that,
   * the client needs to save the journey, including the attachment's metadata in the
   * `attachments` field of the node to which the attachment is attached.
   */
  attachments: Array<AttachmentInput>;
  /**
   * ID of the touchpoint.
   *
   * Set to null to create a new touchpoint and to a non-null value to update a touchpoint.
   */
  id?: InputMaybe<Scalars['ID']['input']>;
  /**
   * Example: 'I navigate to'
   *
   * If non-empty, the last character of this string is not whitespace.
   */
  intro_fragment?: InputMaybe<Scalars['String']['input']>;
  /**
   * Whether this touchpoint was requested by the user and does not
   * (yet) exist in the topology.
   *
   * Defaults to false.
   */
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  /**
   * Locations that the user visited inside this touchpoint.
   *
   * Locations are listed in the order they were visited and can appear multiple times if
   * the user visited multiple times.
   *
   * FKA as "Attributes". Locations are included recursively, so level 1 locations are
   * those directly included in this type, level 2 locations are the locations included
   * in a location included in this type, etc.
   */
  locations: Array<LocationInput>;
  /**
   * Name of the touchpoint.
   * This is a fixed name that is taken from the topology and can't be chosen by the user
   * Example: 'Search result page'
   */
  name: Scalars['String']['input'];
  /**
   * The type of transition to the next node in the hierarchy.
   *
   * For example, if the user switched to a different channel after this touchpoint, then
   * this will be SWITCH.
   */
  nextTransition?: InputMaybe<TransitionType>;
  /**
   * The type of transition from the previous node in the hierarchy.
   *
   * For example, if the user navigated to this touchpoint by clicking "I return to
   * [Touchpoint]", then this will be RETURN.
   */
  prevTransition?: InputMaybe<TransitionType>;
  /**
   * If present, indicates that there is a follow-up question after this touchpoint is
   * selected.
   *
   * Example: 'What is your search term?'
   */
  question?: InputMaybe<QuestionInput>;
  /**
   * If present, indicates that there is a reaction, and create it for the object
   * (this)-[:REACTED]->(r:Reaction)
   */
  reaction?: InputMaybe<ReactionInput>;
  /**
   * Custom title that can be set by the user.
   * Example: "Circular Spare Parts"
   */
  title?: InputMaybe<Scalars['String']['input']>;
  /** ID of the TouchpointTopology node on which this Touchpoint was based. */
  topologyId?: InputMaybe<Scalars['ID']['input']>;
};

export type TouchpointRequest = {
  __typename?: 'TouchpointRequest';
  /**
   * The category that the new touchpoint should be included in.
   *
   * Example: "Siemens internal websites".
   */
  category: Scalars['String']['output'];
  /** A comment that explains why the user would like to add this touchpoint. */
  comment?: Maybe<Scalars['String']['output']>;
  /**
   * When the touchpoint request was created.
   *
   * Represented as an ISO 8601 string, e.g. '2024-03-13T13:40:33.963Z'.
   */
  createdAt: Scalars['String']['output'];
  /**
   * The journey topology that this request relates to.
   *
   * i.e. to which journey topology should the touchpoint be added?
   */
  forJourneyTopology?: Maybe<JourneyTopology>;
  /** The id of the touchpoint request. */
  id: Scalars['ID']['output'];
  /** The name of the touchpoint that the user would like to add to the topology. */
  name: Scalars['String']['output'];
  /** The user who requested this touchpoint */
  requestedBy: User;
  /** A URL that indicates the touchpoint the user is thinking about. */
  url?: Maybe<Scalars['String']['output']>;
};

/** All possible paths inside a touchpoint that a user can take during a user journey. */
export type TouchpointTopology = {
  __typename?: 'TouchpointTopology';
  available_reactions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /**
   * Category/Grouping that the touchpoint belongs to
   * Example: 'Basic', 'Marketing'
   */
  category?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  /**
   * Example: 'I navigate to'
   *
   * If non-empty, the last character of this string is not whitespace.
   */
  intro_fragment?: Maybe<Scalars['String']['output']>;
  /**
   * Possible locations inside this touchpoint.
   *
   * FKA as "Attributes". Locations are included recursively, so level 1 locations are
   * those directly included in this type, level 2 locations are the locations included
   * in a location included in this type, etc.
   *
   * This list is empty if the touchpoint has no locations.
   */
  locations: Array<LocationTopology>;
  /** Example: 'Search result page' */
  name: Scalars['String']['output'];
  /**
   * If present, indicates that there is a follow-up question after this touchpoint is
   * selected.
   *
   * Example: 'What is your search term?'
   */
  question?: Maybe<QuestionTopology>;
  /**
   * Free-form tags that describe properties of the touchpoint.
   *
   * These tags are not processed in the backend, but are displayed in the frontend and used for features like filtering.
   *
   * Examples of tag values (i.e. possible entries in the list): 'Physical', 'Virtual', 'Media'.
   */
  tags: Array<Scalars['String']['output']>;
};

/**
 * When the user navigates to a node that is not a child of the current node, this enum
 * describes the different types of transitions that could occur.
 */
export enum TransitionType {
  /**
   * Labelled with 'I proceed to [Touchpoint]'
   *
   * The user navigates to a touchpoint they have not previously visited.
   */
  Proceed = 'Proceed',
  /**
   * Labelled with 'I get redirected to [Channel]'
   *
   * The user is sent to a channel by some other person or process.
   */
  Redirect = 'Redirect',
  /**
   * Labelled with 'I return to [Touchpoint]'
   *
   * The user navigates to a touchpoint they have previously visited.
   */
  Return = 'Return',
  /**
   * Labelled with 'I switch to [Channel]'
   *
   * The user chooses to go to a new channel by themselves.
   */
  Switch = 'Switch'
}

export type UpdateCommentInput = {
  /** The id of the comment to update. */
  id: Scalars['ID']['input'];
  /** Updated comment text. */
  text: Scalars['String']['input'];
};

export type User = {
  __typename?: 'User';
  /** The GID of the user. This is a Siemens-wide unique identifier for users. */
  gid?: Maybe<Scalars['ID']['output']>;
  /** The name of the user. */
  name?: Maybe<Scalars['String']['output']>;
};

export type UserFilter = {
  /** Queries for users with a particular name. */
  name?: InputMaybe<StringMatches>;
};

export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;

export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = ResolversObject<{
  TopologyRequest: ( ChannelRequest ) | ( LocationRequest ) | ( TouchpointRequest );
}>;

/** Mapping of interface types */
export type ResolversInterfaceTypes<_RefType extends Record<string, unknown>> = ResolversObject<{
  Reactive: ( Channel ) | ( Location ) | ( Touchpoint );
  ReactiveTopology: ( ChannelTopology );
}>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  Any: ResolverTypeWrapper<Scalars['Any']['output']>;
  Attachment: ResolverTypeWrapper<Attachment>;
  AttachmentInput: AttachmentInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  BuyerType: ResolverTypeWrapper<BuyerType>;
  BuyerTypeInput: BuyerTypeInput;
  BuyerTypeTopology: ResolverTypeWrapper<BuyerTypeTopology>;
  Channel: ResolverTypeWrapper<Channel>;
  ChannelInput: ChannelInput;
  ChannelRequest: ResolverTypeWrapper<ChannelRequest>;
  ChannelTopology: ResolverTypeWrapper<ChannelTopology>;
  ChannelTopologyInput: ChannelTopologyInput;
  Comment: ResolverTypeWrapper<Comment>;
  CommentInput: CommentInput;
  CommentsConnection: ResolverTypeWrapper<CommentsConnection>;
  CustomerRole: ResolverTypeWrapper<CustomerRole>;
  CustomerRoleInput: CustomerRoleInput;
  CustomerRoleTopology: ResolverTypeWrapper<CustomerRoleTopology>;
  CustomerType: ResolverTypeWrapper<CustomerType>;
  CustomerTypeInput: CustomerTypeInput;
  CustomerTypeTopology: ResolverTypeWrapper<CustomerTypeTopology>;
  ExperienceDriver: ResolverTypeWrapper<ExperienceDriver>;
  ExperienceDriverInput: ExperienceDriverInput;
  ExperienceDriverRating: ExperienceDriverRating;
  ExperienceDriverTopology: ResolverTypeWrapper<ExperienceDriverTopology>;
  FavoriteJourneyInput: FavoriteJourneyInput;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  Industry: ResolverTypeWrapper<Industry>;
  IndustryInput: IndustryInput;
  IndustryTopology: ResolverTypeWrapper<IndustryTopology>;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Intent: ResolverTypeWrapper<Intent>;
  IntentInput: IntentInput;
  IntentTopology: ResolverTypeWrapper<IntentTopology>;
  Journey: ResolverTypeWrapper<Journey>;
  JourneyFilter: JourneyFilter;
  JourneyInput: JourneyInput;
  JourneyOrder: JourneyOrder;
  JourneyOrderAlgorithm: JourneyOrderAlgorithm;
  JourneyOutcome: ResolverTypeWrapper<JourneyOutcome>;
  JourneyOutcomeInput: JourneyOutcomeInput;
  JourneyOutcomeType: JourneyOutcomeType;
  JourneyTopology: ResolverTypeWrapper<JourneyTopology>;
  JourneyType: ResolverTypeWrapper<JourneyType>;
  JourneyTypeBadge: JourneyTypeBadge;
  JourneyTypeInput: JourneyTypeInput;
  JourneysConnection: ResolverTypeWrapper<JourneysConnection>;
  LikeCommentInput: LikeCommentInput;
  Location: ResolverTypeWrapper<Location>;
  LocationInput: LocationInput;
  LocationRequest: ResolverTypeWrapper<LocationRequest>;
  LocationTopology: ResolverTypeWrapper<LocationTopology>;
  Motivation: ResolverTypeWrapper<Motivation>;
  MotivationInput: MotivationInput;
  MotivationTopology: ResolverTypeWrapper<MotivationTopology>;
  Mutation: ResolverTypeWrapper<{}>;
  Painpoint: ResolverTypeWrapper<Painpoint>;
  PainpointInput: PainpointInput;
  PainpointTopology: ResolverTypeWrapper<PainpointTopology>;
  PriorContact: ResolverTypeWrapper<PriorContact>;
  PriorContactInput: PriorContactInput;
  PriorContactTopology: ResolverTypeWrapper<PriorContactTopology>;
  Query: ResolverTypeWrapper<{}>;
  Question: ResolverTypeWrapper<Question>;
  QuestionInput: QuestionInput;
  QuestionTopology: ResolverTypeWrapper<QuestionTopology>;
  Reaction: ResolverTypeWrapper<Reaction>;
  ReactionInput: ReactionInput;
  ReactionTopology: ResolverTypeWrapper<ReactionTopology>;
  Reactive: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['Reactive']>;
  ReactiveTopology: ResolverTypeWrapper<ResolversInterfaceTypes<ResolversTypes>['ReactiveTopology']>;
  Region: ResolverTypeWrapper<Region>;
  RegionInput: RegionInput;
  RegionTopology: ResolverTypeWrapper<RegionTopology>;
  RequestChannelInput: RequestChannelInput;
  RequestLocationInput: RequestLocationInput;
  RequestTouchpointInput: RequestTouchpointInput;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StringMatches: StringMatches;
  TopologyRequest: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['TopologyRequest']>;
  Touchpoint: ResolverTypeWrapper<Touchpoint>;
  TouchpointInput: TouchpointInput;
  TouchpointRequest: ResolverTypeWrapper<TouchpointRequest>;
  TouchpointTopology: ResolverTypeWrapper<TouchpointTopology>;
  TransitionType: TransitionType;
  UpdateCommentInput: UpdateCommentInput;
  User: ResolverTypeWrapper<User>;
  UserFilter: UserFilter;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Any: Scalars['Any']['output'];
  Attachment: Attachment;
  AttachmentInput: AttachmentInput;
  Boolean: Scalars['Boolean']['output'];
  BuyerType: BuyerType;
  BuyerTypeInput: BuyerTypeInput;
  BuyerTypeTopology: BuyerTypeTopology;
  Channel: Channel;
  ChannelInput: ChannelInput;
  ChannelRequest: ChannelRequest;
  ChannelTopology: ChannelTopology;
  ChannelTopologyInput: ChannelTopologyInput;
  Comment: Comment;
  CommentInput: CommentInput;
  CommentsConnection: CommentsConnection;
  CustomerRole: CustomerRole;
  CustomerRoleInput: CustomerRoleInput;
  CustomerRoleTopology: CustomerRoleTopology;
  CustomerType: CustomerType;
  CustomerTypeInput: CustomerTypeInput;
  CustomerTypeTopology: CustomerTypeTopology;
  ExperienceDriver: ExperienceDriver;
  ExperienceDriverInput: ExperienceDriverInput;
  ExperienceDriverTopology: ExperienceDriverTopology;
  FavoriteJourneyInput: FavoriteJourneyInput;
  ID: Scalars['ID']['output'];
  Industry: Industry;
  IndustryInput: IndustryInput;
  IndustryTopology: IndustryTopology;
  Int: Scalars['Int']['output'];
  Intent: Intent;
  IntentInput: IntentInput;
  IntentTopology: IntentTopology;
  Journey: Journey;
  JourneyFilter: JourneyFilter;
  JourneyInput: JourneyInput;
  JourneyOrder: JourneyOrder;
  JourneyOutcome: JourneyOutcome;
  JourneyOutcomeInput: JourneyOutcomeInput;
  JourneyTopology: JourneyTopology;
  JourneyType: JourneyType;
  JourneyTypeInput: JourneyTypeInput;
  JourneysConnection: JourneysConnection;
  LikeCommentInput: LikeCommentInput;
  Location: Location;
  LocationInput: LocationInput;
  LocationRequest: LocationRequest;
  LocationTopology: LocationTopology;
  Motivation: Motivation;
  MotivationInput: MotivationInput;
  MotivationTopology: MotivationTopology;
  Mutation: {};
  Painpoint: Painpoint;
  PainpointInput: PainpointInput;
  PainpointTopology: PainpointTopology;
  PriorContact: PriorContact;
  PriorContactInput: PriorContactInput;
  PriorContactTopology: PriorContactTopology;
  Query: {};
  Question: Question;
  QuestionInput: QuestionInput;
  QuestionTopology: QuestionTopology;
  Reaction: Reaction;
  ReactionInput: ReactionInput;
  ReactionTopology: ReactionTopology;
  Reactive: ResolversInterfaceTypes<ResolversParentTypes>['Reactive'];
  ReactiveTopology: ResolversInterfaceTypes<ResolversParentTypes>['ReactiveTopology'];
  Region: Region;
  RegionInput: RegionInput;
  RegionTopology: RegionTopology;
  RequestChannelInput: RequestChannelInput;
  RequestLocationInput: RequestLocationInput;
  RequestTouchpointInput: RequestTouchpointInput;
  String: Scalars['String']['output'];
  StringMatches: StringMatches;
  TopologyRequest: ResolversUnionTypes<ResolversParentTypes>['TopologyRequest'];
  Touchpoint: Touchpoint;
  TouchpointInput: TouchpointInput;
  TouchpointRequest: TouchpointRequest;
  TouchpointTopology: TouchpointTopology;
  UpdateCommentInput: UpdateCommentInput;
  User: User;
  UserFilter: UserFilter;
}>;

export interface AnyScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Any'], any> {
  name: 'Any';
}

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BuyerTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuyerType'] = ResolversParentTypes['BuyerType']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type BuyerTypeTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['BuyerTypeTopology'] = ResolversParentTypes['BuyerTypeTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['Channel'] = ResolversParentTypes['Channel']> = ResolversObject<{
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intro_fragment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isCustom?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextTransition?: Resolver<Maybe<ResolversTypes['TransitionType']>, ParentType, ContextType>;
  prevTransition?: Resolver<Maybe<ResolversTypes['TransitionType']>, ParentType, ContextType>;
  reaction?: Resolver<Maybe<ResolversTypes['Reaction']>, ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  touchpoints?: Resolver<Array<ResolversTypes['Touchpoint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChannelRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelRequest'] = ResolversParentTypes['ChannelRequest']> = ResolversObject<{
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  forJourneyTopology?: Resolver<Maybe<ResolversTypes['JourneyTopology']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requestedBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ChannelTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChannelTopology'] = ResolversParentTypes['ChannelTopology']> = ResolversObject<{
  available_reactions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intro_fragment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tags?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  touchpoints?: Resolver<Array<ResolversTypes['TouchpointTopology']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Comment'] = ResolversParentTypes['Comment']> = ResolversObject<{
  commentedBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  likedBy?: Resolver<Array<Maybe<ResolversTypes['String']>>, ParentType, ContextType>;
  text?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CommentsConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommentsConnection'] = ResolversParentTypes['CommentsConnection']> = ResolversObject<{
  totalComments?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerRoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerRole'] = ResolversParentTypes['CustomerRole']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerRoleTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerRoleTopology'] = ResolversParentTypes['CustomerRoleTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerType'] = ResolversParentTypes['CustomerType']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type CustomerTypeTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['CustomerTypeTopology'] = ResolversParentTypes['CustomerTypeTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExperienceDriverResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExperienceDriver'] = ResolversParentTypes['ExperienceDriver']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  rating?: Resolver<Maybe<ResolversTypes['ExperienceDriverRating']>, ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ExperienceDriverTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExperienceDriverTopology'] = ResolversParentTypes['ExperienceDriverTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  tooltip?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IndustryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Industry'] = ResolversParentTypes['Industry']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IndustryTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['IndustryTopology'] = ResolversParentTypes['IndustryTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IntentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Intent'] = ResolversParentTypes['Intent']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intent?: Resolver<Maybe<ResolversTypes['Intent']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type IntentTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntentTopology'] = ResolversParentTypes['IntentTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intents?: Resolver<Array<ResolversTypes['IntentTopology']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JourneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Journey'] = ResolversParentTypes['Journey']> = ResolversObject<{
  avatarRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  buyerType?: Resolver<Maybe<ResolversTypes['BuyerType']>, ParentType, ContextType>;
  channels?: Resolver<Array<ResolversTypes['Channel']>, ParentType, ContextType>;
  comments?: Resolver<Maybe<Array<Maybe<ResolversTypes['Comment']>>>, ParentType, ContextType>;
  commentsConnection?: Resolver<Maybe<ResolversTypes['CommentsConnection']>, ParentType, ContextType>;
  createdDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerRole?: Resolver<Maybe<ResolversTypes['CustomerRole']>, ParentType, ContextType>;
  customerType?: Resolver<Maybe<ResolversTypes['CustomerType']>, ParentType, ContextType>;
  deleted_date?: Resolver<Maybe<ResolversTypes['Any']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  experienceDrivers?: Resolver<Array<ResolversTypes['ExperienceDriver']>, ParentType, ContextType>;
  favoritedByCurrentUser?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  industry?: Resolver<Maybe<ResolversTypes['Industry']>, ParentType, ContextType>;
  isPublished?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  journeyType?: Resolver<Maybe<ResolversTypes['JourneyType']>, ParentType, ContextType>;
  lastSavedDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  motivation?: Resolver<Maybe<ResolversTypes['Motivation']>, ParentType, ContextType>;
  outcome?: Resolver<Maybe<ResolversTypes['JourneyOutcome']>, ParentType, ContextType>;
  ownerGid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  painpoints?: Resolver<Array<ResolversTypes['Painpoint']>, ParentType, ContextType>;
  priorContact?: Resolver<Maybe<ResolversTypes['PriorContact']>, ParentType, ContextType>;
  region?: Resolver<Maybe<ResolversTypes['Region']>, ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  views?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JourneyOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JourneyOutcome'] = ResolversParentTypes['JourneyOutcome']> = ResolversObject<{
  type?: Resolver<ResolversTypes['JourneyOutcomeType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JourneyTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['JourneyTopology'] = ResolversParentTypes['JourneyTopology']> = ResolversObject<{
  buyer_types?: Resolver<Array<ResolversTypes['BuyerTypeTopology']>, ParentType, ContextType>;
  channels?: Resolver<Array<ResolversTypes['ChannelTopology']>, ParentType, ContextType>;
  customer_role?: Resolver<Array<ResolversTypes['CustomerRoleTopology']>, ParentType, ContextType>;
  customer_type?: Resolver<Array<ResolversTypes['CustomerTypeTopology']>, ParentType, ContextType>;
  experience_drivers?: Resolver<Array<ResolversTypes['ExperienceDriverTopology']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  industry?: Resolver<Array<ResolversTypes['IndustryTopology']>, ParentType, ContextType>;
  motivations?: Resolver<Array<ResolversTypes['MotivationTopology']>, ParentType, ContextType>;
  painpoints?: Resolver<Array<ResolversTypes['PainpointTopology']>, ParentType, ContextType>;
  priorContact?: Resolver<Array<ResolversTypes['PriorContactTopology']>, ParentType, ContextType>;
  regions?: Resolver<Array<ResolversTypes['RegionTopology']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JourneyTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JourneyType'] = ResolversParentTypes['JourneyType']> = ResolversObject<{
  badge?: Resolver<Maybe<ResolversTypes['JourneyTypeBadge']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type JourneysConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['JourneysConnection'] = ResolversParentTypes['JourneysConnection']> = ResolversObject<{
  journeys?: Resolver<Array<ResolversTypes['Journey']>, ParentType, ContextType>;
  totalItems?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = ResolversObject<{
  attachments?: Resolver<Array<ResolversTypes['Attachment']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intro_fragment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isCustom?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  locations?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextTransition?: Resolver<Maybe<ResolversTypes['TransitionType']>, ParentType, ContextType>;
  question?: Resolver<Maybe<ResolversTypes['Question']>, ParentType, ContextType>;
  reaction?: Resolver<Maybe<ResolversTypes['Reaction']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationRequest'] = ResolversParentTypes['LocationRequest']> = ResolversObject<{
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  forJourneyTopology?: Resolver<Maybe<ResolversTypes['JourneyTopology']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requestedBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type LocationTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['LocationTopology'] = ResolversParentTypes['LocationTopology']> = ResolversObject<{
  available_reactions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intro_fragment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locations?: Resolver<Array<ResolversTypes['LocationTopology']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  question?: Resolver<Maybe<ResolversTypes['QuestionTopology']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MotivationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Motivation'] = ResolversParentTypes['Motivation']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intent?: Resolver<Maybe<ResolversTypes['Intent']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MotivationTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['MotivationTopology'] = ResolversParentTypes['MotivationTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intents?: Resolver<Array<ResolversTypes['IntentTopology']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  addComment?: Resolver<ResolversTypes['Comment'], ParentType, ContextType, RequireFields<MutationAddCommentArgs, 'input'>>;
  deleteComment?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationDeleteCommentArgs, 'id'>>;
  deleteJourney?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType, RequireFields<MutationDeleteJourneyArgs, 'id'>>;
  incrementJourneyViews?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationIncrementJourneyViewsArgs, 'id'>>;
  likeComment?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationLikeCommentArgs, 'input'>>;
  markJourneyAsFavorite?: Resolver<ResolversTypes['ID'], ParentType, ContextType, RequireFields<MutationMarkJourneyAsFavoriteArgs, 'input'>>;
  requestChannel?: Resolver<ResolversTypes['ChannelRequest'], ParentType, ContextType, RequireFields<MutationRequestChannelArgs, 'input'>>;
  requestLocation?: Resolver<ResolversTypes['LocationRequest'], ParentType, ContextType, RequireFields<MutationRequestLocationArgs, 'input'>>;
  requestTouchpoint?: Resolver<ResolversTypes['TouchpointRequest'], ParentType, ContextType, RequireFields<MutationRequestTouchpointArgs, 'input'>>;
  updateComment?: Resolver<ResolversTypes['Comment'], ParentType, ContextType, RequireFields<MutationUpdateCommentArgs, 'input'>>;
  upsertChannelTopology?: Resolver<ResolversTypes['ID'], ParentType, ContextType, Partial<MutationUpsertChannelTopologyArgs>>;
  upsertJourney?: Resolver<Maybe<ResolversTypes['Journey']>, ParentType, ContextType, Partial<MutationUpsertJourneyArgs>>;
}>;

export type PainpointResolvers<ContextType = any, ParentType extends ResolversParentTypes['Painpoint'] = ResolversParentTypes['Painpoint']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PainpointTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['PainpointTopology'] = ResolversParentTypes['PainpointTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PriorContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriorContact'] = ResolversParentTypes['PriorContact']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type PriorContactTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['PriorContactTopology'] = ResolversParentTypes['PriorContactTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  channelTopology?: Resolver<Maybe<ResolversTypes['ChannelTopology']>, ParentType, ContextType, RequireFields<QueryChannelTopologyArgs, 'id'>>;
  journey?: Resolver<Maybe<ResolversTypes['Journey']>, ParentType, ContextType, RequireFields<QueryJourneyArgs, 'id'>>;
  journeyList?: Resolver<ResolversTypes['JourneysConnection'], ParentType, ContextType, Partial<QueryJourneyListArgs>>;
  journeyTopology?: Resolver<Maybe<ResolversTypes['JourneyTopology']>, ParentType, ContextType, Partial<QueryJourneyTopologyArgs>>;
  listTopologyRequests?: Resolver<Array<ResolversTypes['TopologyRequest']>, ParentType, ContextType, Partial<QueryListTopologyRequestsArgs>>;
  locationTopologies?: Resolver<Array<Maybe<ResolversTypes['LocationTopology']>>, ParentType, ContextType, RequireFields<QueryLocationTopologiesArgs, 'ids'>>;
  recommendedJourneys?: Resolver<Array<ResolversTypes['Journey']>, ParentType, ContextType>;
  touchpointTopologies?: Resolver<Array<Maybe<ResolversTypes['TouchpointTopology']>>, ParentType, ContextType, RequireFields<QueryTouchpointTopologiesArgs, 'ids'>>;
  users?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType, RequireFields<QueryUsersArgs, 'filter'>>;
}>;

export type QuestionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Question'] = ResolversParentTypes['Question']> = ResolversObject<{
  answer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type QuestionTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuestionTopology'] = ResolversParentTypes['QuestionTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  question?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReactionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reaction'] = ResolversParentTypes['Reaction']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReactionTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReactionTopology'] = ResolversParentTypes['ReactionTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type ReactiveResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reactive'] = ResolversParentTypes['Reactive']> = ResolversObject<{
  __resolveType: TypeResolveFn<'Channel' | 'Location' | 'Touchpoint', ParentType, ContextType>;
  reaction?: Resolver<Maybe<ResolversTypes['Reaction']>, ParentType, ContextType>;
}>;

export type ReactiveTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReactiveTopology'] = ResolversParentTypes['ReactiveTopology']> = ResolversObject<{
  __resolveType: TypeResolveFn<'ChannelTopology', ParentType, ContextType>;
  available_reactions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
}>;

export type RegionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Region'] = ResolversParentTypes['Region']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type RegionTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['RegionTopology'] = ResolversParentTypes['RegionTopology']> = ResolversObject<{
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TopologyRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['TopologyRequest'] = ResolversParentTypes['TopologyRequest']> = ResolversObject<{
  __resolveType: TypeResolveFn<'ChannelRequest' | 'LocationRequest' | 'TouchpointRequest', ParentType, ContextType>;
}>;

export type TouchpointResolvers<ContextType = any, ParentType extends ResolversParentTypes['Touchpoint'] = ResolversParentTypes['Touchpoint']> = ResolversObject<{
  attachments?: Resolver<Array<ResolversTypes['Attachment']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intro_fragment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isCustom?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  locations?: Resolver<Array<ResolversTypes['Location']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  nextTransition?: Resolver<Maybe<ResolversTypes['TransitionType']>, ParentType, ContextType>;
  prevTransition?: Resolver<Maybe<ResolversTypes['TransitionType']>, ParentType, ContextType>;
  question?: Resolver<Maybe<ResolversTypes['Question']>, ParentType, ContextType>;
  reaction?: Resolver<Maybe<ResolversTypes['Reaction']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  topologyId?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TouchpointRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['TouchpointRequest'] = ResolversParentTypes['TouchpointRequest']> = ResolversObject<{
  category?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  comment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  forJourneyTopology?: Resolver<Maybe<ResolversTypes['JourneyTopology']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  requestedBy?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type TouchpointTopologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['TouchpointTopology'] = ResolversParentTypes['TouchpointTopology']> = ResolversObject<{
  available_reactions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  intro_fragment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locations?: Resolver<Array<ResolversTypes['LocationTopology']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  question?: Resolver<Maybe<ResolversTypes['QuestionTopology']>, ParentType, ContextType>;
  tags?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  gid?: Resolver<Maybe<ResolversTypes['ID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;

export type Resolvers<ContextType = any> = ResolversObject<{
  Any?: GraphQLScalarType;
  Attachment?: AttachmentResolvers<ContextType>;
  BuyerType?: BuyerTypeResolvers<ContextType>;
  BuyerTypeTopology?: BuyerTypeTopologyResolvers<ContextType>;
  Channel?: ChannelResolvers<ContextType>;
  ChannelRequest?: ChannelRequestResolvers<ContextType>;
  ChannelTopology?: ChannelTopologyResolvers<ContextType>;
  Comment?: CommentResolvers<ContextType>;
  CommentsConnection?: CommentsConnectionResolvers<ContextType>;
  CustomerRole?: CustomerRoleResolvers<ContextType>;
  CustomerRoleTopology?: CustomerRoleTopologyResolvers<ContextType>;
  CustomerType?: CustomerTypeResolvers<ContextType>;
  CustomerTypeTopology?: CustomerTypeTopologyResolvers<ContextType>;
  ExperienceDriver?: ExperienceDriverResolvers<ContextType>;
  ExperienceDriverTopology?: ExperienceDriverTopologyResolvers<ContextType>;
  Industry?: IndustryResolvers<ContextType>;
  IndustryTopology?: IndustryTopologyResolvers<ContextType>;
  Intent?: IntentResolvers<ContextType>;
  IntentTopology?: IntentTopologyResolvers<ContextType>;
  Journey?: JourneyResolvers<ContextType>;
  JourneyOutcome?: JourneyOutcomeResolvers<ContextType>;
  JourneyTopology?: JourneyTopologyResolvers<ContextType>;
  JourneyType?: JourneyTypeResolvers<ContextType>;
  JourneysConnection?: JourneysConnectionResolvers<ContextType>;
  Location?: LocationResolvers<ContextType>;
  LocationRequest?: LocationRequestResolvers<ContextType>;
  LocationTopology?: LocationTopologyResolvers<ContextType>;
  Motivation?: MotivationResolvers<ContextType>;
  MotivationTopology?: MotivationTopologyResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  Painpoint?: PainpointResolvers<ContextType>;
  PainpointTopology?: PainpointTopologyResolvers<ContextType>;
  PriorContact?: PriorContactResolvers<ContextType>;
  PriorContactTopology?: PriorContactTopologyResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Question?: QuestionResolvers<ContextType>;
  QuestionTopology?: QuestionTopologyResolvers<ContextType>;
  Reaction?: ReactionResolvers<ContextType>;
  ReactionTopology?: ReactionTopologyResolvers<ContextType>;
  Reactive?: ReactiveResolvers<ContextType>;
  ReactiveTopology?: ReactiveTopologyResolvers<ContextType>;
  Region?: RegionResolvers<ContextType>;
  RegionTopology?: RegionTopologyResolvers<ContextType>;
  TopologyRequest?: TopologyRequestResolvers<ContextType>;
  Touchpoint?: TouchpointResolvers<ContextType>;
  TouchpointRequest?: TouchpointRequestResolvers<ContextType>;
  TouchpointTopology?: TouchpointTopologyResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
}>;

