import {
  PrepareJourneyIntroStep,
  PrepareJourneySummaryStep,
  PrepareJourneyAvatarStep,
  PrepareJourneyIndustryStep,
  PrepareJourneyCustomerTypeStep,
  PrepareJourneyCustomerMotivationStep,
  PrepareJourneyTaglineStep,
} from "./steps";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AnyZodObject } from "zod";
import {
  CustomerFormValues,
  customerFormSchema,
} from "@motius/customer-heartbeat-utils/schemas";
import { useMutation } from "@apollo/client";
import { getLabelById } from "@/utils/topology";
import { PageWithTopBar, Spinner, notify } from "@motius/customer-heartbeat-ui";
import { STORE_JOURNEY } from "@/utils/apollo/queries";
import {
  IntentInput,
  JourneyInput,
  MotivationInput,
} from "@/utils/apollo/resolvers";
import { PrepareYourJourneyStep } from "@/utils/router";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TopBar } from "./TopBar";
import { usePrepareJourneyTopo } from "./usePrepareJourneyTopo";
import { PrepareJourneyCustomerIntentStep } from "./steps/PrepareJourneyCustomerIntentStep";
import { handleError } from "@/utils/error";
import { useEffect } from "react";

export const PrepareYourJourneyDialog = () => {
  const [storeJourney, { loading: isSubmitInProgress }] =
    useMutation(STORE_JOURNEY);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { topoData } = usePrepareJourneyTopo();

  const validationSchema: { [key: string]: AnyZodObject } = {
    prepare2: customerFormSchema.pick({ roleId: true, avatar: true }),
    prepare3: customerFormSchema.pick({ industryId: true }),
    prepare4: customerFormSchema.pick({ typeId: true }),
    prepare5: customerFormSchema.pick({ motivationId: true }),
    prepare6: customerFormSchema.pick({ intent: true }),
    prepare7: customerFormSchema.pick({ words: true }),
    prepare8: customerFormSchema,
  };

  const dialogParam = searchParams.get("dialog") as string;

  // When the component is mounted the step should be the first one
  // After when user changes the dialog param the component is not mounted again so this doesn't effect the behavior
  useEffect(() => {
    if (dialogParam !== PrepareYourJourneyStep.First) {
      setSearchParams({ dialog: PrepareYourJourneyStep.First });
    }
  }, []);

  const methods = useForm<CustomerFormValues>({
    resolver: zodResolver(validationSchema[dialogParam]),
  });

  const onSubmit = methods.handleSubmit(async (data: CustomerFormValues) => {
    const {
      avatar: avatarRef,
      industryId,
      roleId,
      typeId,
      words,
      intent,
    } = data;
    const motivation = {
      topologyId: data.motivationId,
      name: getLabelById(data.motivationId),
    } as MotivationInput;
    // we currently only support one recursion for intents
    // but this can easily be extended
    const parent = intent?.parents?.[0];
    const intentId = intent?.intentId;
    if (intentId) {
      if (parent) {
        motivation.intent = {
          topologyId: parent,
          name: getLabelById(parent),
          intent: {
            topologyId: intentId,
            name: getLabelById(intentId),
          },
        } as IntentInput;
      } else {
        motivation.intent = {
          topologyId: intentId,
          name: getLabelById(intentId),
        };
      }
    }

    const journey: JourneyInput = {
      avatarRef,
      industry: { name: getLabelById(industryId), topologyId: industryId },
      customerRole: { name: getLabelById(roleId), topologyId: roleId },
      customerType: { name: getLabelById(typeId), topologyId: typeId },
      motivation,
      channels: [],
      topologyId: import.meta.env.VITE_TOPOLOGY_ID,
    };
    if (words) journey.description = words;
    // log(journey);

    let resultId;
    try {
      resultId = (
        await storeJourney({
          variables: { journey },
        })
      ).data?.upsertJourney.id;

      // we could write the result into the apollo cache here
      // so that the read query on the journey page does not
      // need a request. This is not important but will increase
      // the speed of the transition to the journey page a bit

      navigate(`/journeys/${resultId}`);
    } catch (e) {
      console.error(e);
      handleError(e, "The storing of the journey failed");
    }
  });

  const step = dialogParam ?? "";

  const latestTopology = topoData?.journeyTopology;

  /**
   *  Selects the correct step component based on the current step
   */
  const renderStepComponent = () => {
    // step1 does not require topo Data
    // and we can load it in the background while already
    // showing the first step
    if (step !== PrepareYourJourneyStep.First && !topoData)
      return (
        <div className="pb-8">
          <Spinner />
        </div>
      );
    switch (step as PrepareYourJourneyStep) {
      case PrepareYourJourneyStep.First:
        return <PrepareJourneyIntroStep />;
      case PrepareYourJourneyStep.Second:
        return (
          <PrepareJourneyAvatarStep roles={latestTopology?.customer_role} />
        );
      case PrepareYourJourneyStep.Third:
        return (
          <PrepareJourneyIndustryStep industries={latestTopology?.industry} />
        );
      case PrepareYourJourneyStep.Fourth:
        return (
          <PrepareJourneyCustomerTypeStep
            customerTypes={latestTopology?.customer_type}
          />
        );
      case PrepareYourJourneyStep.Fifth:
        return (
          <PrepareJourneyCustomerMotivationStep
            motivations={latestTopology?.motivations}
          />
        );
      case PrepareYourJourneyStep.Sixth:
        return (
          <PrepareJourneyCustomerIntentStep
            motivations={latestTopology?.motivations}
          />
        );
      case PrepareYourJourneyStep.Seventh:
        return <PrepareJourneyTaglineStep />;
      case PrepareYourJourneyStep.Eigth:
        return isSubmitInProgress ? (
          <Spinner />
        ) : (
          <PrepareJourneySummaryStep onClose={() => {}} />
        );
      default:
        return null;
    }
  };

  return (
    <PageWithTopBar>
      <TopBar />
      <div className="flex w-full grow items-center justify-center h-[calc(100%-2.75rem)]">
        <div className="h-full w-[50rem]">
          <FormProvider {...methods}>
            <form
              onSubmit={onSubmit}
              className="flex h-full flex-col items-stretch justify-center"
            >
              {renderStepComponent()}
            </form>
          </FormProvider>
        </div>
      </div>
    </PageWithTopBar>
  );
};
