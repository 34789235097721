import { Journey } from "@/utils/apollo/resolvers";
import { gql, useQuery } from "@apollo/client";
import {
  Button,
  Footer,
  hideSpinner,
  showSpinner,
} from "@motius/customer-heartbeat-ui";
import {
  IxApplication,
  IxTabItem,
  IxTabs,
  IxTypography,
} from "@siemens/ix-react";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TopBar } from "../TopBar/TopBar";
import { PublishedJourneys } from "./PublishedJourneys";
import { SideMenu } from "./SideMenu";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import useTopology from "../Navigation/useJourneyTopology";
import { useUserDetails } from "@motius/customer-heartbeat-utils/auth";
import { DraftJourneysContainer } from "./DraftJourneysContainer";
import { useDeleteJourney } from "@/src/hooks/useDeleteJourney";

export const JOURNEY_FIELDS = gql`
  fragment JourneyFields on Journey {
    id
    createdDate
    favoritedByCurrentUser
    views
    lastSavedDate
    journeyType {
      badge
      description
    }
    industry {
      id
      name
    }
    customerRole {
      id
      name
    }
    customerType {
      id
      name
    }
    region {
      id
      name
    }
    description
    avatarRef
    motivation {
      topologyId
      name
      intent {
        name
        topologyId
      }
    }
    outcome {
      type
    }
    isPublished
    ownerName
    ownerGid
    comments {
      id
    }
  }
`;

export const DELETE_JOURNEY = gql`
  mutation DeleteJourney($id: ID!) {
    deleteJourney(id: $id)
  }
`;

export const LOAD_JOURNEYS = gql`
  ${JOURNEY_FIELDS}
  query LoadJourney($filter: JourneyFilter!, $orderBy: JourneyOrder!) {
    journeyList(filter: $filter, orderBy: $orderBy) {
      journeys {
        ...JourneyFields
      }
    }
  }
`;

/**
 *  Journeys Page
 */
export const Journeys = () => {
  const { gid } = useUserDetails();
  const { error, data, refetch, loading } = useQuery(LOAD_JOURNEYS, {
    variables: {
      filter: { ownerGid: gid },
      orderBy: { algorithm: "LAST_SAVED_DATE" },
    },
    fetchPolicy: "network-only",
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { reset } = useChannelStore();
  const { handleDelete, isDeleting } = useDeleteJourney(refetch);
  // Get the journey topology on journeys page
  useTopology();

  // Get the current tab from the URL
  const selectedTab =
    Number(new URLSearchParams(location.search).get("tab")) || 0;

  /**
   * Changes the tab
   * @param tabId - the tab id
   */
  const changeTab = (tabId: number) => {
    navigate(`?tab=${tabId}`);
  };

  useEffect(() => {
    if (isDeleting || loading) {
      showSpinner();
    } else {
      hideSpinner();
    }
  }, [isDeleting, loading]);

  useEffect(() => {
    // resetting the state when loading this helps
    // to prevent mixing up state when navigating back
    // and forth between a specific journey and the
    // journeys overview. On the overview page
    // the journey id for example should not be set.
    reset();
  }, []);

  if (error) return <p>Error :{error.message}</p>;

  /**
   * opens the Prepare your journey wizard
   */
  const openPrepareYourJourney = () => {
    // Update the URL with new query params
    navigate("/prepare?dialog=prepare1");
  };

  const draftJourneys =
    data?.journeyList?.journeys.filter(
      (journey: Journey) => !journey.isPublished,
    ) ?? [];

  const publishedJourneys =
    data?.journeyList?.journeys.filter(
      (journey: Journey) => journey.isPublished,
    ) ?? [];

  return (
    <IxApplication>
      <TopBar />
      <SideMenu />
      <div
        data-testid="journeys-content"
        className="flex h-full flex-col gap-2 overflow-auto bg-white p-3 text-black"
      >
        <div className="flex w-full justify-between">
          <IxTypography format="h2">Your Space</IxTypography>
          <Button
            icon="plus"
            onClick={openPrepareYourJourney}
            className="self-start"
          >
            Create New Journey
          </Button>
        </div>
        <IxTabs selected={selectedTab} className="pb-2">
          <IxTabItem className="font-bold" onClick={() => changeTab(0)}>
            Your Drafts{" "}
            <span className="opacity-30">({draftJourneys.length})</span>
          </IxTabItem>
          <IxTabItem
            className="font-bold"
            disabled={publishedJourneys.length === 0}
            onClick={() => changeTab(1)}
          >
            Your Published Journeys{" "}
            <span className="opacity-30">({publishedJourneys.length})</span>
          </IxTabItem>
        </IxTabs>
        <div className="flex grow flex-col">
          {selectedTab === 0 ? (
            <DraftJourneysContainer
              journeys={draftJourneys}
              onDelete={handleDelete}
            />
          ) : (
            <PublishedJourneys
              journeys={publishedJourneys}
              onDelete={handleDelete}
            />
          )}
        </div>
      </div>
    </IxApplication>
  );
};
