import { showMessage } from "@siemens/ix";
import { IxButton, IxTypography } from "@siemens/ix-react";
import React, { PropsWithChildren } from "react";
import { useOnClickOutside } from "usehooks-ts";

export interface MetaDataMenuProps extends PropsWithChildren {
  label: string;
  onConfirm: () => void;
  onCancel: () => void;
  addDisabled?: boolean;
  showCloseWarning?: boolean;
  isOutsideClickAllowed?: boolean;
}

export const MetaDataSubMenu: React.FC<MetaDataMenuProps> = ({
  label,
  onConfirm,
  onCancel,
  children,
  addDisabled,
  showCloseWarning,
  isOutsideClickAllowed = false,
}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [warningOpen, setWarningOpen] = React.useState(false);

  /**
   *  Shows a warning dialog before closing the menu
   *  @param {string} message - The message to show in the dialog
   */
  const showWarning = async (message: string) => {
    if (showCloseWarning) {
      setWarningOpen(true);
      (
        await showMessage({
          icon: "",
          messageTitle: message,
          message: "Your changes will be lost if you proceed.",
          actions: [
            { id: "cancel", type: "cancel", text: "Cancel" },
            { id: "proceed", type: "okay", text: "Proceed" },
          ],
        })
      ).once((result) => {
        setWarningOpen(false);
        if (result?.actionId == null) return;
        if (result?.actionId === "proceed") {
          // if the result has a payload then add was clicked
          onCancel();
        }
      });
    } else {
      onCancel();
    }
  };
  /**
   *  Closes the menu when clicking outside of the menu
   * @param {MouseEvent | TouchEvent | FocusEvent} e - The event object
   */
  const handleClickOutside = async (
    e: MouseEvent | TouchEvent | FocusEvent,
  ) => {
    if (isOutsideClickAllowed) return;
    const target: Element = e.target as Element;
    if (
      !target.classList.contains("text-touchpoint-label") &&
      target.id.indexOf("sub-menu-button") === -1 &&
      !warningOpen
    ) {
      await showWarning("Do you really want to quit editing?");
    }
  };

  /**
   *  Handles the delete button click
   * Shows a warning dialog before deleteing
   */
  const handleCancel = async () => {
    await showWarning("Do you really want to delete your changes?");
  };

  useOnClickOutside(ref, handleClickOutside);

  return (
    <div
      className="flex flex-col gap-1.5 rounded bg-chb-menu-background p-2 shadow-metaDataSubMenu"
      ref={ref}
    >
      <IxTypography format="body" className="leading-4" textColor="contrast">
        {label}
      </IxTypography>
      {children}
      <div className="flex justify-end gap-1.5">
        <IxButton outline variant="secondary" onClick={handleCancel}>
          Cancel
        </IxButton>
        <IxButton outline onClick={onConfirm} disabled={addDisabled}>
          Confirm
        </IxButton>
      </div>
    </div>
  );
};
