import { cn } from "@motius/customer-heartbeat-ui/utils";
import React, { ReactNode } from "react";

interface ActionHeadlineProps {
  children: ReactNode;
  className?: string;
}

const ActionHeadline: React.FC<ActionHeadlineProps> = ({
  children,
  className,
}) => {
  return (
    <div className={cn("mt-3 text-[14px] font-bold", className)}>
      {children}
    </div>
  );
};

export default ActionHeadline;
