// This file is derived from https://github.com/vercel/next.js/blob/canary/examples/api-routes-apollo-server-and-client/apollo/schema.ts
import { useMemo } from "react";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { authLink } from "@/src/authLink";

let apolloClient: ApolloClient<any>;

const backendUrl = import.meta.env.VITE_BACKEND_URL;
const httpLink = new HttpLink({
  uri: `${backendUrl}/graphql`,
});

/**
 *  Apollo Client
 */
function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === "undefined",
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });
}

/**
 * Initialize apollo client with given state
 * @param initialState Initial State
 */
export function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // TODO: Currently this merge method doesn't work and creates duplicate data, enable it when the solution is found
    // Get existing cache, loaded during client side data fetching
    /* 
    const existingCache = _apolloClient.extract();
    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache);
    // Restore the cache with the merged data
    */

    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

/**
 *  Hook to use apollo
 * @param initialState  Initial state of apollo
 */
export function useApollo(initialState?: any) {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}

/**
 * apollo adds __typename to objects it loads from the backend.
 * Sometimes when we send objects back to the backend, we need
 * to remove the __typename field because its not declared as Input
 * field in the mutation we call. This makes it inconvient
 * to feed back an object we loaded from the backend into a mutation.
 *
 * This function will iterate through arrays and recurse through objects
 * and remove every __typename field it finds. This makes it easier to
 * for example load a complete journey and then store it shortly after
 * without having to manually remove the __typename fields.
 *
 * additionally this removes the category field which we retrieve
 * for locations, touchpoints and channels but don't need to send back
 *
 * @param property the object or array to remove the __typename fields from
 */
export function removeTypename(property: any): any {
  if (Array.isArray(property)) {
    return property.map(removeTypename);
  } else if (property !== null && typeof property === "object") {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { __typename, category, ...rest } = property;
    return Object.keys(rest).reduce((obj, key) => {
      return { ...obj, [key]: removeTypename(rest[key]) };
    }, {});
  }
  return property;
}
