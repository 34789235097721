import { IxTypography } from "@siemens/ix-react";
import React, { useEffect, useState } from "react";
import { MetaDataSubMenu } from "./MetaDataSubMenu";
import { ReactionInput } from "./Reactions/ReactionInput";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { Reaction } from "./Reactions/ReactionIcon";
import { MapNode } from "../../drawTouchpoint";

interface ReactionSubMenuProps {
  node?: MapNode;
  onClose: (isCancel?: boolean) => void;
}

/**
 *  ReactionSubMenu component.
 * @param {ReactionSubMenuProps} props - The props of the component.
 * @returns {React.ReactElement} The ReactionSubMenu component.
 */
export const ReactionSubMenu: React.FC<ReactionSubMenuProps> = ({
  onClose,
  node,
}) => {
  const { addReaction, removeReaction } = useChannelStore();
  const [text, setText] = useState<string | undefined>(undefined);
  const count = text?.toString().length || 0;
  const maxCount = 120;
  const [reaction, setReaction] = useState<Reaction | undefined>(undefined);

  useEffect(() => {
    const reaction = node?.reaction;
    setReaction(reaction?.name as Reaction | undefined);
    setText(reaction?.reason || undefined);
  }, [node]);

  /**
   * Handles the confirm button click event.
   */
  const handleConfirm = () => {
    const currentFid = node?.fid;
    if (currentFid) {
      if (reaction) {
        addReaction(currentFid, reaction, text ?? "");
      } else {
        removeReaction(currentFid);
      }
      onClose();
    }
  };

  /**
   *  Handles the cancel button click event.
   */
  const handleCancel = () => {
    setReaction(undefined);
    setText("");
    onClose(true);
  };

  const currentReaction = node?.reaction;
  const hasChanges =
    currentReaction?.name !== reaction || currentReaction?.reason !== text;

  return (
    <MetaDataSubMenu
      label="Reaction"
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      addDisabled={
        (!currentReaction && !reaction) || (reaction && !text) || !hasChanges
      }
      showCloseWarning={hasChanges}
    >
      <div className="flex w-[19rem] flex-col items-start gap-1.5">
        <ReactionInput value={reaction} onChange={setReaction} />
        <div className="h-px w-full bg-theme-color-weak-bdr" />
        <textarea
          value={text}
          rows={2}
          className="px-2 py-1.5 text-theme-color-std-text"
          onChange={(e) => setText(e.target.value)}
          placeholder={`I feel ${reaction ?? "..."}, because …`}
          maxLength={120}
        />
        <IxTypography format="body-sm" textColor="weak">
          {count} / {maxCount}
        </IxTypography>
      </div>
    </MetaDataSubMenu>
  );
};
