import { IxIcon, IxTypography } from "@siemens/ix-react";
import React from "react";
import { TextJourneyMetaData } from "./TextJourneyMetaData";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { AttachmentInput } from "@/utils/apollo/resolvers";
import { openImageInNewTab } from "../Map/MetaDataMenu/MetaDataSubMenus/upload";

interface TextJourneyAttachmentProps {
  attachment: AttachmentInput;
  fid: string;
  editable: boolean;
}

export const TextJourneyAttachment: React.FC<TextJourneyAttachmentProps> = ({
  attachment,
  fid,
  editable,
}) => {
  const { removeAttachment } = useChannelStore();

  /**
   *  Handles the delete button click
   */
  const handleDelete = () => {
    removeAttachment(attachment.id, fid);
  };

  /**
   *  Opens the image in a new tab
   */
  const handleOpen = () => {
    openImageInNewTab(attachment.id);
  };

  return (
    <TextJourneyMetaData
      icon={<IxIcon name="screenshot" size="16" />}
      onDelete={handleDelete}
      truncate
      type="attachment"
      editable={editable}
    >
      <IxTypography
        className="cursor-pointer text-theme-color-primary"
        textDecoration="underline"
        format="body"
        onClick={handleOpen}
      >
        {attachment.name}
      </IxTypography>
    </TextJourneyMetaData>
  );
};
