import React from "react";
import { JourneyType, JourneyTypeBadge } from "@/utils/apollo/resolvers";
import { IxPill } from "@siemens/ix-react";
import { cn } from "@motius/customer-heartbeat-ui/utils";

interface JourneyTypePillProps {
  type: JourneyType;
  className?: string;
}

/**
 *  Journey Type Pill
 * @param props - Props
 * @param props.type - Journey Type
 * @param props.className - Class Name
 */
export const JourneyTypePill: React.FC<JourneyTypePillProps> = ({
  type,
  className,
}) => {
  let pill: React.ReactNode | null = null;
  switch (type.badge) {
    case JourneyTypeBadge.ClickPathAnalysis:
      pill = (
        <IxPill
          background="var(--journey-type-click-path-analysis)"
          variant="custom"
        >
          Click Path Analysis
        </IxPill>
      );
      break;
    case JourneyTypeBadge.DirectCustomerFeedback:
      pill = (
        <IxPill
          background="var(--journey-type-direct-customer-feedback)"
          variant="custom"
        >
          Direct Customer Feedback
        </IxPill>
      );
      break;
    case JourneyTypeBadge.CustomerSupportFeedback:
      pill = (
        <IxPill
          background="var(--journey-type-customer-support)"
          variant="custom"
        >
          Customer Support Feedback
        </IxPill>
      );
      break;
    case JourneyTypeBadge.CustomerResearch:
      pill = (
        <IxPill
          background="var(--journey-type-customer-research)"
          variant="custom"
        >
          Customer Research
        </IxPill>
      );
      break;
    case JourneyTypeBadge.ProductReviewsAndRatings:
      pill = (
        <IxPill
          background="var(--journey-type-product-reviews)"
          variant="custom"
        >
          Product Reviews and Ratings
        </IxPill>
      );
      break;
    case JourneyTypeBadge.Other:
      pill = (
        <IxPill background="var(--journey-type-other)" variant="custom">
          Other
        </IxPill>
      );
      break;
  }

  return (
    <div
      className={cn(
        "theme-brand-dark text-siemens-dark my-1.5 -ml-1",
        className,
      )}
    >
      {pill}
    </div>
  );
};
