import {
  LocationInput,
  ReactionInput,
  TransitionType,
} from "@/utils/apollo/resolvers";
import {
  FrontendChannel,
  FrontendLocation,
  FrontendTouchpoint,
} from "@/utils/types";
import { getLastElement } from "@motius/customer-heartbeat-utils";

export function getLastTouchpointId(
  channel: FrontendChannel | undefined,
): string | null {
  return getLastElement<FrontendTouchpoint>(channel?.touchpoints)?.fid ?? null;
}

type Node = {
  nextTransition: TransitionType;
  topologyId: string;
  reaction?: ReactionInput;
  title?: string | null;
};

export const getLastNode = (channels: FrontendChannel[]): Node | null => {
  if (channels.length === 0) return null;

  const lastChannel = channels[channels.length - 1];

  if (!lastChannel?.touchpoints?.length) return lastChannel as Node;

  const lastTouchpoint =
    lastChannel.touchpoints[lastChannel.touchpoints.length - 1];

  if (!lastTouchpoint?.locations?.length) return lastTouchpoint as Node;

  const lastLocation = lastTouchpoint.locations[
    lastTouchpoint.locations.length - 1
  ] as Node;

  return lastLocation;
};

export const getNodeById = (
  id: string,
  channels: FrontendChannel[],
): Node | null => {
  if (channels.length === 0) return null;

  let foundNode: Node | null = null;

  channels.forEach((channel) => {
    if (foundNode) return;

    const touchpoints: FrontendTouchpoint[] = channel.touchpoints;
    touchpoints.forEach((touchpoint) => {
      if (foundNode) return;

      if (touchpoint.fid === id) {
        foundNode = touchpoint as Node;
        return;
      }

      touchpoint.locations.forEach((location) => {
        if (foundNode) return;
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { fid } = location as FrontendLocation;
        if (fid === id) {
          foundNode = location as Node;
          return;
        }
      });
    });
  });

  return foundNode;
};

export enum NODE_TYPE {
  None = "none",
  Channel = "channel",
  Touchpoint = "touchpoint",
  Location = "location",
}
export const getTypeOfLastNode = (channels: FrontendChannel[]): NODE_TYPE => {
  if (!channels?.length) {
    return NODE_TYPE.None;
  }

  const lastChannel = getLastElement<FrontendChannel>(channels);
  const lastTouchpoint = getLastElement<FrontendTouchpoint>(
    lastChannel?.touchpoints,
  );
  const lastLocation = getLastElement<LocationInput>(
    lastTouchpoint?.locations ?? [],
  );

  if (lastLocation) return NODE_TYPE.Location;
  if (lastTouchpoint) return NODE_TYPE.Touchpoint;

  return NODE_TYPE.Channel;
};
