import { FieldError } from "react-hook-form";
import { InputError } from "../Input";
import { cn } from "../../utils";
import { IxSelect, IxSelectItem } from "@siemens/ix-react";

interface BaseSelectComponentProps {
  label: string;
  id: string;
  defaultValue?: string;
  options: { value: string; label: string }[];
  wrapperClassName?: string;
  onBlur?: () => void;
  autoFocus?: boolean;
  error?: FieldError;
  allowClear?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  editable?: boolean;
  sortAlphabetically?: boolean;
}

interface SingleSelectProps extends BaseSelectComponentProps {
  mode?: "single";
  value: string | undefined;
  onChange: (value: string) => void;
}

interface MultipleSelectProps extends BaseSelectComponentProps {
  mode: "multiple";
  value: string[] | undefined;
  onChange: (value: string[]) => void;
}

/**
 * Select component props
 */
type SelectComponentProps = SingleSelectProps | MultipleSelectProps;

export const Select: React.FC<SelectComponentProps> = ({
  label,
  id,
  value = undefined,
  onChange,
  defaultValue = "",
  options,
  wrapperClassName = "",
  autoFocus,
  onBlur,
  error,
  allowClear = true,
  disabled = false,
  children,
  editable,
  sortAlphabetically = false,
  mode = "single",
}) => {
  /**
   * Extracts the value from the event and calls the onChange handler.
   * @param e The custom event object
   */
  const handleChange = (e: CustomEvent) => {
    const val = e.detail;
    onChange(val);
  };

  const sortedOptions = sortAlphabetically
    ? [...options].sort((a, b) => a.label.localeCompare(b.label))
    : options;

  return (
    <div
      className={cn(
        "flex flex-col items-start gap-[2px] w-full",
        wrapperClassName,
      )}
    >
      <label id={`label_${id}`} htmlFor={id} className={cn("font-sans")}>
        {label}
      </label>
      <div className="flex w-full gap-2">
        <IxSelect
          className="w-full"
          id={id}
          value={value}
          onValueChange={handleChange}
          defaultValue={defaultValue}
          disabled={disabled}
          autoFocus={autoFocus}
          onBlur={onBlur}
          mode={mode}
          allowClear={allowClear}
          editable={editable}
          i18nPlaceholder="Select"
          aria-placeholder="Select"
        >
          {sortedOptions.map((option) => (
            <IxSelectItem
              label={option.label}
              value={option.value}
              key={option.value}
            />
          ))}
        </IxSelect>
        {children}
      </div>
      <InputError error={error} />
    </div>
  );
};
