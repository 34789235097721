import { JourneyOutcomeType } from "@/utils/apollo/resolvers";
import React from "react";
import { OutcomeText } from "../Outcome/OutcomeText";

interface Props {
  outcome: JourneyOutcomeType;
  onRemove?: () => void;
}

const TextJourneyCardFinalisation: React.FC<Props> = ({
  outcome,
  onRemove,
}) => {
  return (
    <div className="relative m-0 p-0">
      <div className="my-0 ml-[-2px] flex flex-col text-left text-gray-900">
        <OutcomeText outcome={outcome} className="mt-2" onRemove={onRemove} />
      </div>
    </div>
  );
};

export default TextJourneyCardFinalisation;
