import { useChannelStore } from "@/src/hooks/useChannelStore";
import { EditCustomerDialog } from "./EditCustomerDialog";
import { useEffect, useRef, useState } from "react";
import { useYourInsightsTopology } from "@/src/hooks/useYourInsigtsTopology";
import { getDefaultId, getOptionalDefaultId } from "@/utils/topology";
import { useSearchParams } from "react-router-dom";
import { ModalRef, showModal } from "@siemens/ix-react";
import { resetRouterDialog } from "@/utils/router";

interface EditCustomerDialogContainerProps {}

export const EditCustomerDialogContainer: React.FC<
  EditCustomerDialogContainerProps
> = () => {
  const {
    avatarRef,
    industry: storedIndustry,
    customerRole: storedCustomerRole,
    customerType: storedCustomerType,
    description: storedCustomerDescription,
    motivation: storedMotivation,
    region: storedRegion,
    buyerType: storedBuyerType,
    priorContact: storedPriorContact,
    journeyId,
    journeyTopologyId,
  } = useChannelStore();
  const modalRef = useRef<ModalRef>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [waitingToOpen, setWaitingToOpen] = useState(false);

  const { topology } = useYourInsightsTopology(journeyId, journeyTopologyId);

  useEffect(() => {
    const dialogParam = searchParams.get("dialog") as string;
    const willOpen = dialogParam === "edit";
    setWaitingToOpen(willOpen);
  }, [searchParams]);

  useEffect(() => {
    // still loading data that we need
    if (waitingToOpen && topology != null && journeyId != null) {
      const variables = {
        avatarRef,
        storedIndustry,
        storedCustomerRole,
        storedCustomerType,
        storedMotivation,
        journeyId,
      };

      Object.entries(variables).forEach(([key, value]) => {
        if (value == null) {
          throw new Error(`The value of ${key} is null`);
        }
      });
      show();
    }
  }, [
    waitingToOpen,
    topology,
    journeyId,
    avatarRef,
    storedIndustry,
    storedCustomerRole,
    storedCustomerType,
    storedMotivation,
  ]);

  /**
   *  Closes the complete details dialog
   */
  const handleClose = () => {
    setWaitingToOpen(false);
    modalRef.current?.close("Close edit customer dialog");
  };

  /**
   * Opens the edit customer dialog
   */
  async function show() {
    if (topology == null || journeyId == null || !waitingToOpen) {
      return;
    }

    await showModal({
      size: "840",
      centered: true,
      beforeDismiss: () => {
        if (document.location.search.includes("dialog")) {
          resetRouterDialog(setSearchParams);
        }
        handleClose();
        return false;
      },
      content: (
        <EditCustomerDialog
          open={waitingToOpen}
          ref={modalRef}
          onClose={handleClose}
          topologies={topology}
          defaultValues={{
            avatar: avatarRef!,
            industryId: getDefaultId(topology.industry, storedIndustry!.name),
            roleId: getDefaultId(
              topology.customer_role,
              storedCustomerRole!.name,
            ),
            typeId: getDefaultId(
              topology.customer_type,
              storedCustomerType!.name,
            ),
            words: storedCustomerDescription ?? "",
            motivationId: storedMotivation!.topologyId!,
            intentId: storedMotivation!.intent!.topologyId!,
            subIntentId:
              storedMotivation!.intent?.intent?.topologyId ?? undefined,
            regionId: getOptionalDefaultId(
              topology.regions,
              storedRegion?.name,
            ),
            buyerTypeId: getOptionalDefaultId(
              topology.buyer_types,
              storedBuyerType?.name,
            ),
            statusId: getOptionalDefaultId(
              topology.priorContact,
              storedPriorContact?.name,
            ),
          }}
        />
      ),
    });
  }

  return null;
};
