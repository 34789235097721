import { CompleteDetailsStep, openDialogOrStep } from "@/utils/router";
import { Stepper } from "@motius/customer-heartbeat-ui";
import { IxTypography } from "@siemens/ix-react";
import { useSearchParams } from "react-router-dom";
import Lottie from "lottie-react";
import { ANIMATION } from "./animation";

interface CompleteDetailsSuccessStepProps {
  handleClose: () => void;
}

export const CompleteDetailsSuccessStep: React.FC<
  CompleteDetailsSuccessStepProps
> = ({ handleClose }) => {
  const [, setSearchParams] = useSearchParams();

  const prevClick = () => {
    openDialogOrStep(setSearchParams, CompleteDetailsStep.Fourth);
  };

  return (
    <div className="flex h-full flex-col items-center justify-between">
      <div className="flex w-full flex-col justify-between">
        <IxTypography format="h2">
          Thank you for sharing your insights!
        </IxTypography>
        <p className="mt-2">
          Your efforts help shape and improve our customer <br></br>
          interactions.
        </p>
      </div>
      <div className="relative flex size-full flex-col items-center justify-center">
        <Lottie
          animationData={ANIMATION}
          loop={true}
          className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
        />
      </div>
      <div className="w-full">
        <Stepper
          onBackClick={prevClick}
          nextButtonText="Done"
          isNextDisabled={false}
          nextButtonType="submit"
          onNextClick={handleClose}
          steps={5}
          activeStep={4}
        />
      </div>
    </div>
  );
};
