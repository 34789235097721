import React from "react";

import Channel from "./Channel";
import TextJourneyCardFinalisation from "./TextJourneyCardFinalization";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { useVisitCounts } from "./useVisitCountedChannels";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
} from "@motius/customer-heartbeat-ui";
import { cn } from "@motius/customer-heartbeat-ui/utils";

interface Props {
  editable?: boolean;
}

const TextJourney: React.FC<Props> = ({ editable = true }) => {
  const { channels, outcome, removeOutcome } = useChannelStore();
  const { visitOrder } = useVisitCounts();
  const channel = channels?.[0];
  const hasChannel = channel != null;

  return (
    <div className="flex h-full flex-col overflow-auto px-1">
      <div>
        {!hasChannel ? (
          <div className="mb-3 text-left">
            <Accordion type="single" collapsible defaultValue="item-1">
              <AccordionItem value="item-1">
                <AccordionTrigger
                  leftIcon
                  rightToDown
                  className={cn([
                    "[&[data-state=closed]]:bg-theme-color-2",
                    "[&[data-state=closed]]:hover:bg-theme-color-component-1--hover",
                    "[&[data-state=open]]:bg-theme-color-2",
                    "[&[data-state=open]]:hover:bg-theme-color-component-1--hover",
                    "rounded",
                    "mb-[0.75rem]",
                    "[&>div]:px-2",
                    "py-0",
                  ])}
                  style={{
                    color: "var(--theme-color-primary)",
                  }}
                  caretCollapsedColor="var(--theme-color-primary)"
                  caretExpandedColor="var(--theme-color-dynamic)"
                  containerClass="h-12"
                >
                  I enter
                </AccordionTrigger>
              </AccordionItem>
            </Accordion>
          </div>
        ) : (
          channels.map((channel, i) => (
            <Channel
              key={channel.fid}
              ch={channel}
              outcome={i === channels.length - 1 ? outcome : undefined}
              visitOrder={visitOrder}
              editable={editable}
              last={i === channels.length - 1}
            />
          ))
        )}
      </div>
      {!!outcome && (
        <TextJourneyCardFinalisation
          outcome={outcome.type}
          onRemove={editable ? removeOutcome : undefined}
        />
      )}
    </div>
  );
};

export default TextJourney;
