import { IxTypography } from "@siemens/ix-react";
import React from "react";
import { cn } from "../../utils";

interface FooterProps {
  className?: string;
}

// TODO: Show correct app version
/**
 *  Footer component
 *  Shows the footer with the app version and legal information
 * @param props - FooterProps
 * @param props.className - Additional classes to be added to the footer
 */
export const Footer: React.FC<FooterProps> = ({ className }) => {
  const year = new Date().getFullYear();
  return (
    <div
      className={cn(
        "text-siemens-dark flex h-10 items-end justify-start gap-[1.25rem] opacity-30 shrink-0 pb-[0.375rem]",
        className,
      )}
    >
      <IxTypography format="body-sm">
        Siemens Customer Heartbeat © 2022-{year}
      </IxTypography>
      <IxTypography format="body-sm">App Version: 1.0.0</IxTypography>
      <IxTypography format="body-sm">Legal</IxTypography>
    </div>
  );
};
