import { LocationInput, Touchpoint } from "@/utils/apollo/resolvers";
import { v4 as uuidv4 } from "uuid";
import {
  FrontendChannel,
  FrontendLocation,
  FrontendTouchpoint,
  FrontendTouchpointTopology,
  FrontendChannelTopology,
} from "@/utils/types";

/**
 * We create a map where we can look up the Topology of a node
 * by passing the frontend id (fid) of the node.
 *
 * This is handy when we for example need to look up to which
 * Touchpoints we can go to from a channel
 */
const topologyById: Record<string, FrontendChannelTopology> = {};
/**
 * Create a frontend channel (not yet stored)
 * when clicking a topology channel in the navigation
 * @param channelTopology the channel topology that was clicked
 */
export const getChannelByChannelTopology = (
  channelTopology: FrontendChannelTopology,
): FrontendChannel => {
  const fid = uuidv4();
  topologyById[channelTopology.id] = channelTopology;
  return {
    fid,
    topologyId: channelTopology.id,
    intro_fragment: channelTopology.intro_fragment,
    name: channelTopology.name,
    touchpoints: [],
  };
};

export const getChannelTopologyByChannelId = (
  id: string,
): FrontendChannelTopology => {
  return topologyById[id];
};

/**
 * when a touchpoint topo is clicked we need to create
 * a new touchpoint based on that topo
 */
export const getTouchPointByTouchPointTopology = (
  touchPointTopology: FrontendTouchpointTopology,
): FrontendTouchpoint => {
  return {
    ...(touchPointTopology as unknown as Touchpoint),
    topologyId: touchPointTopology.id,
    id: null,
    locations: [],
    fid: uuidv4(),
    attachments: [],
  };
};

/**
 * before storing channels we need to convert it from
 * a FrontendChannel to a ChannelInput
 */
export const getChannelInput = (channel: FrontendChannel) => {
  // remove the fid property from the channel
  // in the cloned object feChannel

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { fid, ...channelInput } = channel;
  return {
    ...channelInput,
    touchpoints: channelInput.touchpoints.map(
      (touchpoint: FrontendTouchpoint) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { __typename, fid, tags, ...tpInput } = touchpoint;
        return {
          ...tpInput,
          locations: tpInput.locations.map((loc) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { __typename, fid, tags, ...locInput } =
              loc as FrontendLocation;
            if (locInput.locations?.length) {
              locInput.locations = [];
            }
            return locInput as LocationInput;
          }),
        };
      },
    ),
  };
};
