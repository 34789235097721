import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { LOAD_JOURNEY } from "./query";
import { getLastChannelOfJourney } from "./utils";
import { useChannelStore } from "../useChannelStore";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { GET_REQUESTS } from "@/src/components/TextJourney/api";
import {
  LocationRequest,
  TopologyRequest,
  TouchpointRequest,
  ChannelRequest,
} from "@/utils/apollo/resolvers";
import { useChannelTopologies } from "../topology/useChannelTopologies";
import { useUserDetails } from "@motius/customer-heartbeat-utils/auth";

export const useJourney = (id?: string, edit?: boolean) => {
  const userDetails = useUserDetails();
  const {
    data: journeyData,
    loading,
    error,
  } = useQuery(LOAD_JOURNEY, {
    variables: { id: id },
    skip: !id, // Skip the query if id is not provided
  });

  const {
    data: requestData,
    loading: requestDataLoading,
    error: requestDataError,
  } = useQuery<{ listTopologyRequests: TopologyRequest[] }>(GET_REQUESTS, {
    variables: { journeyId: id },
    skip: !id, // Skip the query if id is not provided
  });

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const { loadChannelTopo, data: lastChannelInJourneyTopo } =
    useChannelTopologies();

  const {
    loadJourney,
    setTouchpointTopos,
    journeyId,
    setTouchpointRequests,
    setLocationRequests,
    setChannelRequests,
  } = useChannelStore();

  useEffect(() => {
    if (edit && journeyData && location.pathname !== `/journeys/${id}/view`) {
      if (journeyData.journey.isPublished) {
        if (!searchParams.get("dialog")) {
          navigate(`/journeys/${id}/view`);
        }
      } else if (userDetails.gid !== journeyData.journey.ownerGid) {
        navigate(`/journeys/${id}/view`);
      }
    }
  }, [edit, id, journeyData, navigate, searchParams, userDetails.gid]);

  useEffect(() => {
    if (!journeyData?.journey) return;

    // if the journey has channels then load channelTopology for last channel
    //       => set available touchpoints for active channel
    // load available locations for active location (if any)
    const lastChannel = getLastChannelOfJourney(journeyData.journey);
    if (lastChannel) {
      loadChannelTopo(lastChannel.topologyId);
    }
  }, [journeyData, loadChannelTopo]);

  // if a channel topo was loaded then set the touchpoints
  // in the store
  useEffect(() => {
    const topo = lastChannelInJourneyTopo?.channelTopology;
    if (topo) {
      setTouchpointTopos(topo.touchpoints);
    }
  }, [lastChannelInJourneyTopo, setTouchpointTopos]);

  // if a journey was in the URL and finished loading
  // then conpy it into the store
  useEffect(() => {
    // if we already have a journeyId in the state
    // then this code was already triggered.
    // Somehow the journeyData dependency of the useEffect
    // can retrigger this later. I don't know why. But we only want
    // to copy the loaded journey into the state initially
    if (journeyData?.journey && journeyId !== journeyData.journey.id) {
      loadJourney(journeyData.journey);
    }
  }, [journeyData, loadJourney, journeyId]);

  // if a journey was in the URL and finished loading
  // then copy its requests to the store
  useEffect(() => {
    // if we already have a journeyId in the state
    // then this code was already triggered.
    // Somehow the journeyData dependency of the useEffect
    // can retrigger this later. I don't know why. But we only want
    // to copy the loaded journey into the state initially
    if (journeyData?.journey && journeyId !== journeyData.journey.id) {
      const touchpointRequests: TouchpointRequest[] = (
        requestData?.listTopologyRequests ?? []
      )
        .filter((req) => req.__typename === "TouchpointRequest")
        .map((req) => req as TouchpointRequest);
      const locationRequests: LocationRequest[] = (
        requestData?.listTopologyRequests ?? []
      )
        .filter((req) => req.__typename === "LocationRequest")
        .map((req) => req as LocationRequest);
      const channelRequests: ChannelRequest[] = (
        requestData?.listTopologyRequests ?? []
      )
        .filter((req) => req.__typename === "ChannelRequest")
        .map((req) => req as ChannelRequest);
      setTouchpointRequests(touchpointRequests);
      setLocationRequests(locationRequests);
      setChannelRequests(channelRequests);
    }
  }, [journeyData, requestData, loadJourney, journeyId]);

  return {
    loading: loading || requestDataLoading,
    error: error || requestDataError,
  };
};
