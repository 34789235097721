import { Journey } from "@/utils/apollo/resolvers";
import { Button, notify } from "@motius/customer-heartbeat-ui";
import { AvatarType } from "@motius/customer-heartbeat-utils/schemas";
import { Link } from "react-router-dom";
import { JourneyCard } from "./JourneyCard";
import { IxDropdownButton, IxDropdownItem } from "@siemens/ix-react";
import { JourneyGrid } from "./JourneyGrid";
import React from "react";

export interface DraftJourneysProps {
  journeys: Journey[];
  onDelete: (id: string) => void;
}

export const DraftJourneys: React.FC<DraftJourneysProps> = ({
  journeys,
  onDelete,
}) => {
  /**
   * @param id of the journey that will be shared
   */
  const handleCopyLink = async (id: string) => {
    await navigator.clipboard.writeText(
      `${window.location.host}/journeys/${id}/view`,
    );
    notify.success("Link copied!");
  };
  return (
    <JourneyGrid
      fullHeight={journeys.length === 0}
      journeyCount={journeys.length}
    >
      {[...journeys].map((journey: any) => (
        <li key={journey.id} className="grow">
          <JourneyCard
            journeyId={journey.id}
            customer={{
              industry: journey.industry?.name ?? "",
              role: journey.customerRole?.name ?? "",
              type: journey?.customerType?.name ?? "",
              motivation: journey.motivation?.name ?? "",
              intent: journey?.motivation?.intent?.name ?? "",
              avatar: (journey.avatarRef ?? AvatarType.Second) as AvatarType,
              words: journey.description ?? "",
              region: journey?.region?.name,
              ownerName: journey.ownerName,
            }}
            date={journey.lastSavedDate}
            actions={
              <>
                <IxDropdownButton label="More" outline>
                  <IxDropdownItem
                    onClick={() => handleCopyLink(journey.id)}
                    label="Share"
                    icon="share"
                    className="w-44"
                  ></IxDropdownItem>
                  <IxDropdownItem
                    onClick={() => onDelete(journey.id)}
                    label="Delete"
                    icon="trashcan"
                  ></IxDropdownItem>
                </IxDropdownButton>
                <Link to={`/journeys/${journey.id}`}>
                  <Button outline>Edit</Button>
                </Link>
              </>
            }
          />
        </li>
      ))}
    </JourneyGrid>
  );
};
