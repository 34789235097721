import { useChannelStore } from "../../hooks/useChannelStore";
import { TopBarBase } from "@motius/customer-heartbeat-ui";
import { ProfileIcon } from "@motius/customer-heartbeat-ui";
import { useLocation, useNavigate } from "react-router-dom";
import { IxIconButton } from "@siemens/ix-react";
import { ShareButton } from "./ShareButton";

export const TopBar = () => {
  const { reset } = useChannelStore();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClose = () => {
    reset();
    if (location?.state?.prevLocation) {
      navigate(location?.state?.prevLocation);
    } else {
      navigate("/journeys");
    }
  };

  return (
    <TopBarBase>
      <ProfileIcon />
      <ShareButton />
      <IxIconButton
        onClick={handleClose}
        icon="close"
        ghost
        size="16"
      ></IxIconButton>
    </TopBarBase>
  );
};
