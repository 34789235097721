import { Maybe } from "@motius/customer-heartbeat-utils";

export const getOwnerNameAndDepartment = (
  ownerName: Maybe<string>,
): { ownerName: string; department: string } => {
  if (!ownerName) {
    return {
      ownerName: "",
      department: "",
    };
  }

  const closingParanthesesIndex = ownerName.lastIndexOf(")");
  const openParantheseIndex = ownerName.lastIndexOf(
    "(",
    closingParanthesesIndex,
  );

  return {
    ownerName: ownerName.substring(0, openParantheseIndex).trim(),
    department: ownerName.substring(
      openParantheseIndex + 1,
      closingParanthesesIndex,
    ),
  };
};
