import React from "react";
import { Button, Select } from "@motius/customer-heartbeat-ui";
import { Topology } from "@motius/customer-heartbeat-utils/schemas";
import { IxIcon } from "@siemens/ix-react";
import { wait } from "@motius/customer-heartbeat-utils";
import { findInputsInElementWithId } from "./search";

interface PainpointInputProps {
  value: string[];
  painpointTopologies: Topology[];
  onChange: (value: string[]) => void;
}

export const PainpointInput: React.FC<PainpointInputProps> = ({
  value,
  onChange,
  painpointTopologies,
}) => {
  const handleSelect = (selectedPainpoint: string, index: number) => {
    const newPainpoints = [...value];
    if (!newPainpoints.includes(selectedPainpoint)) {
      newPainpoints[index] = selectedPainpoint;
    }
    onChange(newPainpoints);
  };

  const handleSelectWithLastInput = async (selectedPainpoint: string) => {
    onChange([...value, selectedPainpoint]);

    /**
     * CJM-378 describes an issue where if you click around
     * fast in the painpoint input, the ix select component
     * sometimes shows a selected value in the last select
     * EVEN THOUGH we pass empty string as value and it should
     * be empty.
     *
     * After each value change we set the value to undefined
     * and then empty string again with a short delay.
     * with a short delay. This seems to fix the issue.
     * This makes the issue occur much much less often.
     * @param selectedPainpoint
     */
    await wait(30);
    const emptySelectInput = findInputsInElementWithId("pp_new");
    if (emptySelectInput?.[0]?.value?.length > 0) {
      console.log("Setting select value to empty string");
      findInputsInElementWithId("pp_new")[0].value = "";
    }
  };

  // list of all available painpoints
  const painpoints = painpointTopologies.map((topology) => ({
    value: topology.id,
    label: topology.name,
  }));

  // list of painpoints that are not selected
  const options = painpoints.filter(
    (painpoint) => !value.includes(painpoint.value),
  );

  return (
    <div className="h-80 w-full overflow-auto">
      {value.map((pp, i) => (
        <div className="flex items-center gap-4" key={`${pp}-${i}`}>
          <Select
            sortAlphabetically
            label={`Painpoint ${i + 1}`}
            value={pp}
            onChange={(painpoint: string) => {
              handleSelect(painpoint, i);
            }}
            id={`pp_${pp}`}
            options={painpoints}
            wrapperClassName="flex-grow"
            allowClear={false}
          >
            <Button
              onClick={() => onChange(value.filter((_, index) => index !== i))}
              className="mx-2 basis-32 items-center justify-center"
            >
              <div className="flex items-center justify-center">
                <IxIcon name="trashcan" className="mr-1" size="24" />
                <span className="relative align-middle">Remove</span>
              </div>
            </Button>
          </Select>
        </div>
      ))}
      {/*/
      The select below should be rendered if there are options
      available to add new painpoints.
      It should never have a selected value. Because if the user
      selects one then we add that to the list above and make 
      this select empty again
       */}
      {options.length > 0 ? (
        <div className="flex w-full flex-col gap-3">
          <Select
            sortAlphabetically
            label={`Painpoint ${value.length + 1}`}
            id="pp_new"
            value={undefined} // should never have a selected value
            onChange={handleSelectWithLastInput}
            options={options}
            wrapperClassName="flex-grow"
          >
            <Button
              disabled={true}
              className="mx-2 basis-32 items-center justify-center"
            >
              <div className="flex items-center justify-center">
                <IxIcon name="trashcan" className="mr-1" size="24" />
                <span className="relative align-middle">Remove</span>
              </div>
            </Button>
          </Select>
        </div>
      ) : null}
    </div>
  );
};
