import { Maybe, TransitionType } from "@/utils/apollo/resolvers";

/**
 *
 */
type TransitionComponentProps = {
  transition: Maybe<TransitionType> | undefined;
  intro_fragment: string;
  hasAndPrefix: boolean;
};

const removeBeginning = (str: string): string => {
  let result = str;
  if (str.startsWith(`I `)) {
    result = str.replace(`I `, "");
  }
  if (result.startsWith(`enter`)) {
    result = result.replace(`enter`, "");
  }
  return result;
};

export const TransitionText: React.FC<TransitionComponentProps> = ({
  transition,
  intro_fragment,
  hasAndPrefix,
}) => {
  const prefix = hasAndPrefix ? `and ` : ``;
  if (transition == null) {
    return <>{`${prefix}${intro_fragment ?? ""}`}</>;
  }

  const shortenedFragment = removeBeginning(intro_fragment);

  /**
   * Get the transition label based on the transition type
   * @param transition
   */
  const getTransitionLabel = (transition: TransitionType): string => {
    switch (transition) {
      case TransitionType.Proceed: // happens only in touchpoints
        return `${prefix}I proceed to ${shortenedFragment} `;
      case TransitionType.Return: // happens only in touchpoints
        return `${prefix}I return to ${shortenedFragment} `;

      case TransitionType.Redirect: // happens only in channels
        return `I get redirected to`;
      case TransitionType.Switch: // happens only in channels
        return `I switch to`;

      // should never happen
      default:
        return "";
    }
  };

  // Render the component
  return <span>{getTransitionLabel(transition)}</span>;
};
