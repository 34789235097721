import React, { useState, useEffect } from "react";
import { TopBarBase, ProfileIcon } from "@motius/customer-heartbeat-ui";
import {
  IxApplication,
  IxButton,
  IxContentHeader,
  IxTabItem,
  IxTabs,
} from "@siemens/ix-react";
import { SideMenu } from "../components/Journeys/SideMenu";
import { Filters } from "../components/JourneyCommunity/Filters";
import { FilterResults } from "../components/JourneyCommunity/FilterResults";
import { RecentlyAdded } from "../components/JourneyCommunity/RecentlyAdded";
import { Favorites } from "../components/JourneyCommunity/Favorites";
import {
  FrontendJourneyFilter,
  Search,
} from "../components/JourneyCommunity/Search";
import { RecommendedJourneys } from "../components/JourneyCommunity/RecommendedJourneys";
import { useNavigate, useLocation } from "react-router-dom";
import {
  deserializeUrlParamsToFilter,
  serializeFilterToUrlParams,
} from "../components/JourneyCommunity/serializeFilters";
import { hasFilters } from "../components/JourneyCommunity/filterUtils";

/**
 * A page that allows users to discover journeys
 * by applying filters and searching for them
 * or by browsing categories like for example recently
 * added or favorite journeys.
 */
export const Discover = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Initialize filters from URL query string
  const [filters, setFilters] = useState<FrontendJourneyFilter>(() => {
    const params = new URLSearchParams(location.search);
    const filterString = deserializeUrlParamsToFilter(params.toString());
    if (!filterString) return { authorGidFilters: [] };
    filterString.authorGidFilters = filterString.authorGidFilters || [];
    return filterString;
  });

  // Initialize selectedTab from URL query string
  const [selectedTab, setSelectedTab] = useState<number>(() => {
    const params = new URLSearchParams(location.search);
    return Number(params.get("tab")) || 0;
  });

  // Update URL when filters or selectedTab change
  useEffect(() => {
    const params = new URLSearchParams();

    if (selectedTab !== 0) {
      params.set("tab", selectedTab.toString());
    }

    const filterParamsString = serializeFilterToUrlParams(filters);
    const filterParams = new URLSearchParams(filterParamsString);

    for (const [key, value] of filterParams.entries()) {
      params.set(key, value);
    }

    const newQueryString = params.toString();
    const currentQueryString = location.search.startsWith("?")
      ? location.search.substring(1)
      : location.search;

    if (newQueryString !== currentQueryString) {
      const newLocal = `${location.pathname}?${newQueryString}`;
      navigate(newLocal, { replace: true });
    }
  }, [filters, selectedTab, navigate, location.pathname, location.search]);

  // Update filters and selectedTab when URL changes
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentTab = Number(params.get("tab")) || 0;
    params.delete("tab");
    const currentFilters = deserializeUrlParamsToFilter(params.toString()) || {
      authorGidFilters: [],
    };
    if (!currentFilters.authorGidFilters) {
      currentFilters.authorGidFilters = [];
    }

    if (currentTab !== selectedTab) {
      setSelectedTab(currentTab);
    }

    if (JSON.stringify(currentFilters) !== JSON.stringify(filters)) {
      setFilters(currentFilters);
    }
  }, [location.search]); // Only runs when the URL changes

  /**
   * Changes the tab
   * @param tabId - the tab id (number)
   */
  const changeTab = (tabId: number) => () => {
    setSelectedTab(tabId);
  };

  /**
   * Toggle filter sidebar visibility
   */
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  return (
    <IxApplication>
      <TopBarBase>
        <ProfileIcon />
      </TopBarBase>
      <SideMenu />
      <div
        className="flex size-full flex-col p-3 text-std"
        data-testid="discover-content"
      >
        <div className="size-full">
          <div className="mb-3 flex w-full items-center justify-between">
            <IxContentHeader
              has-back-button={hasFilters(filters)}
              header-title="Journey Community"
              onBackButtonClick={() => setFilters({ authorGidFilters: [] })}
            />
            <div className="flex gap-3">
              <Search setFilters={setFilters}>
                <IxButton
                  icon="filter"
                  variant="primary"
                  onClick={toggleSidebar}
                  className="min-w-[8.22rem]"
                >
                  Show Filters
                </IxButton>
              </Search>
            </div>
          </div>

          {/* Sidebar for filters */}
          {isSidebarOpen && (
            <Filters
              filters={filters}
              setFilters={setFilters}
              handleClose={toggleSidebar}
            />
          )}

          {hasFilters(filters) ? (
            <FilterResults filters={filters} setFilters={setFilters} />
          ) : (
            <>
              <IxTabs selected={selectedTab}>
                <IxTabItem className="font-bold" onClick={changeTab(0)}>
                  Recently Added
                </IxTabItem>
                <IxTabItem className="font-bold" onClick={changeTab(1)}>
                  Recommended Journeys
                </IxTabItem>
                <IxTabItem className="font-bold" onClick={changeTab(2)}>
                  Your Favorites
                </IxTabItem>
              </IxTabs>
              <div className="flex size-full grow flex-col items-center justify-center pb-20">
                {selectedTab === 0 && <RecentlyAdded />}
                {selectedTab === 1 && <RecommendedJourneys />}
                {selectedTab === 2 && <Favorites />}
              </div>
            </>
          )}
        </div>
      </div>
    </IxApplication>
  );
};
