import { useChannelStore } from "@/src/hooks/useChannelStore";
import { FrontendChannel } from "@/utils/types";

interface Touchpoint {
  compositeId: string;
  name: string;
}

interface TouchpointsByChannel {
  name: string;
  touchpoints: Touchpoint[];
}

/**
 *
 * if the last node of the last channel is a tp then remove that tp
 * because we can not go back to the same tp
 * @returns
 */
export const useReturnToTouchpoints = (): TouchpointsByChannel[] => {
  const channels: FrontendChannel[] = useChannelStore(
    (state) => state.channels,
  );

  const returnToTouchpoints: { [key: string]: TouchpointsByChannel } = {};
  let lastCompositeId: string | null = null;

  channels.forEach((channel, channelIndex) => {
    if (!returnToTouchpoints[channel.name]) {
      returnToTouchpoints[channel.name] = {
        name: channel.name,
        touchpoints: [],
      };
    }

    channel.touchpoints.forEach((touchpoint, touchpointIndex) => {
      const compositeId = `${channel.topologyId}__${touchpoint.topologyId}`;

      // Skip adding the last touchpoint of the last channel
      if (
        channelIndex === channels.length - 1 &&
        touchpointIndex === channel.touchpoints.length - 1
      ) {
        lastCompositeId = compositeId;
        return;
      }

      if (
        !returnToTouchpoints[channel.name].touchpoints.some(
          (tp) => tp.compositeId === compositeId,
        )
      ) {
        returnToTouchpoints[channel.name].touchpoints.push({
          compositeId,
          name: touchpoint.name,
        });
      }
    });
  });

  // Remove all touchpoints matching lastCompositeId from all channels
  if (lastCompositeId) {
    Object.values(returnToTouchpoints).forEach((group) => {
      group.touchpoints = group.touchpoints.filter(
        (tp) => tp.compositeId !== lastCompositeId,
      );
    });
  }

  // Remove groups without touchpoints
  const filteredReturnToTouchpoints = Object.values(returnToTouchpoints).filter(
    (group) => group.touchpoints.length > 0,
  );

  return filteredReturnToTouchpoints;
};
