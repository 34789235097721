import { useChannelStore } from "../../../hooks/useChannelStore";
import { SummarySection } from "./SummarySection";

export const PainpointSection = () => {
  const { painpoints } = useChannelStore();
  return (
    <SummarySection className="border-b-0">
      <h1 className="mb-2 font-bold text-sm">Pain points</h1>
      <div>
        {painpoints && painpoints.length > 0 ? (
          <ol className="list-decimal pl-4">
            {painpoints?.map((painpoint: any) => (
              <li key={painpoint.id}>{painpoint.name}</li>
            ))}
          </ol>
        ) : (
          <p className="text-weak">No pain points have been collected</p>
        )}
      </div>
    </SummarySection>
  );
};
