import React from "react";
import { IxLinkButton, IxTypography } from "@siemens/ix-react";

/**
 * Imprint
 * This is the tab of the About and Legal page
 * This tab contains the details about the Customer Heartbeat application
 */
export const Imprint = () => {
  return (
    <div className="flex flex-col gap-3" data-testid="imprint-content">
      <IxTypography format="body">
        Customer Heartbeat
        <br />© Siemens 2020–2024
      </IxTypography>
      <IxTypography format="body">
        Customer Heartbeat is a web application to crowd source customer
        insights across
        <br /> organizations – and more importantly – across the end-to-end
        customer journey.
      </IxTypography>
      <IxLinkButton
        url="https://www.siemens.com/digital-id"
        className="mt-2"
        target="_blank"
        rel="noopener noreferrer"
      >
        Siemens Digital ID
      </IxLinkButton>
    </div>
  );
};
