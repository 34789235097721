import React, { useEffect, useMemo, useState } from "react";
import { useChannelStore } from "../../hooks/useChannelStore";
import ActionHeadline from "./ActionHeadline";
import { JourneyTopology, TransitionType } from "@/utils/apollo/resolvers";
import { FrontendChannel, FrontendChannelTopology } from "@/utils/types";
import {
  getLastElement,
  resetUrlParams,
} from "@motius/customer-heartbeat-utils";
import { Spinner } from "@motius/customer-heartbeat-ui";
import { getChannelByChannelTopology } from "../TextJourney/TopologyUtils";
import { groupByCategory } from "./channelsByCategory";
import { SearchableAccordionList } from "./SearchableAccordionList";
import { IxIconButton, IxTypography } from "@siemens/ix-react";
import debug from "debug";
import { NavigationButton } from "./NavigationButton";
import { NavigationTooltip } from "./Tooltips/NavigationTooltipContent";
import { ChannelTooltipContent } from "./Tooltips/ChannelTooltipContent";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { RequestMissingChannelButton } from "../RequestMissingDialog/RequestMissingChannelButton";
import { filterAndConvertRequestListToChannelTopologyList } from "../Map/utils";

const log = debug("NavigationChannel:topology");

interface NavigationChannelsProps {
  journeyTopology: JourneyTopology | undefined;
}

const NavigationChannels: React.FC<NavigationChannelsProps> = ({
  journeyTopology,
}) => {
  const {
    undo,
    undoStackIndex,
    addChannel,
    channels,
    currentTransition,
    journeyId,
    channelRequests,
  } = useChannelStore();
  const [clickedChannel, setClickedChannel] =
    useState<FrontendChannelTopology | null>(null);

  const requestDataChannelTopologies = useMemo(
    () =>
      filterAndConvertRequestListToChannelTopologyList(channelRequests ?? []),
    [channelRequests],
  );

  useEffect(() => {
    log("useEffect triggered: journeyTopology or clickedChannel changed", {
      journeyTopology,
      clickedChannel,
    });

    if (journeyTopology && clickedChannel) {
      const channelTopologies: FrontendChannelTopology[] =
        journeyTopology.channels;
      log("Finding clicked channel in journeyTopology", {
        journeyTopologyChannels: channelTopologies,
        clickedChannelId: clickedChannel.id,
      });
      const clickedChannelTopo: FrontendChannelTopology | undefined = [
        ...channelTopologies,
        ...requestDataChannelTopologies,
      ].find((ch: FrontendChannelTopology) => ch.id === clickedChannel.id);

      log("Clicked channel topology found", { clickedChannelTopo });

      if (clickedChannelTopo) {
        console.log("Adding channel", clickedChannelTopo);
        addChannel(
          getChannelByChannelTopology(clickedChannelTopo),
          clickedChannel.touchpoints,
        );
      } else {
        log("journeyTopology or clickedChannel is null", {
          journeyTopology,
          clickedChannel,
        });
      }
    }
  }, [
    journeyTopology,
    requestDataChannelTopologies,
    addChannel,
    clickedChannel,
  ]);

  if (!journeyTopology?.channels?.length)
    return (
      <div className="flex w-full justify-center pt-16">
        <Spinner />
      </div>
    );

  const channelTopologies: FrontendChannelTopology[] = journeyTopology.channels;

  const channelTopos =
    [...channelTopologies, ...requestDataChannelTopologies]?.filter(
      (t) => t.id !== getLastElement<FrontendChannel>(channels)?.topologyId,
    ) ?? [];
  log("ChannelTopos after filtering", channelTopos);

  const groupedChannels = groupByCategory(channelTopos);

  const accordionSections = Object.entries(groupedChannels).map(
    ([category, categoryChannels]) => ({
      title: category,
      items: categoryChannels.map((channel) => ({
        id: channel.id,
        name: `${channel.name} ${channel.isCustom ? "(Custom Channel)" : ""}`,
      })),
    }),
  );

  const groups = accordionSections.map((section) => section.title);

  const isSwitch = currentTransition === TransitionType.Switch;
  resetUrlParams();

  const isFirstChannel = channels.length === 0;

  return (
    <div className="flex grow flex-col overflow-hidden">
      <div className="pr-4">
        <ActionHeadline
          className={cn("ml-0 mt-3", { "mt-[0.75rem]": undoStackIndex > 0 })}
        >
          <div className="ml-4 flex items-center text-base">
            {undoStackIndex > 0 && (
              <IxIconButton
                icon="arrow-left"
                variant="primary"
                ghost
                size="24"
                className="mr-2 cursor-pointer"
                onClick={undo}
              />
            )}
            <span>Select Channel</span>
            <NavigationTooltip
              className="ml-auto"
              tooltipClassname="font-sans"
              id="tooltip-select-channel"
              content={<ChannelTooltipContent />}
            />
          </div>
          {isFirstChannel ? (
            <IxTypography
              format="body-sm"
              bold
              className="ml-4 mt-2 w-[13.25rem] text-[var(--theme-color-soft-text)] "
            >
              What Channel does your customer enter?
            </IxTypography>
          ) : (
            <NavigationButton
              icon={isSwitch ? "sort" : "open_external"}
              className="ml-4 mt-1"
            >
              {isSwitch ? "Switch to..." : "Redirect to..."}
            </NavigationButton>
          )}
        </ActionHeadline>
        <hr className="-mx-4 mt-[0.75rem]" />
      </div>

      <SearchableAccordionList
        searchPlaceholder="Search Channel"
        sections={accordionSections}
        initiallyExpandedTitle={
          accordionSections.length === 1
            ? accordionSections[0].title
            : undefined
        }
        onItemClick={(itemId) => {
          const clickedChannel = channelTopos.find(
            (channel) => channel.id === itemId,
          );
          if (clickedChannel) {
            setClickedChannel(clickedChannel);
          }
        }}
        action={
          <RequestMissingChannelButton groups={groups} journeyId={journeyId} />
        }
      />
    </div>
  );
};

export default NavigationChannels;
