import { msalInstance } from "./routing/auth/utils";
import { setContext } from "@apollo/client/link/context";
import { error } from "../../../packages/ui/src/components/Notification";
import { isDisableAuth } from "@motius/customer-heartbeat-utils/auth";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import debug from "debug";

const log = debug("auth:authLink");

export const authRequest = {
  scopes: ["openid", "profile"],
};

function getUserToken() {
  const token = window.prompt("Please enter your authentication token:", "");
  return token ? token : null;
}

function backendContainsLocalhost() {
  const backendUrl = import.meta.env.VITE_BACKEND_URL;
  return backendUrl && backendUrl.includes("localhost");
}

function decodeToken(token: string): any {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );
  return JSON.parse(jsonPayload);
}

function formatTimestamp(timestamp: number): string {
  return new Date(timestamp * 1000).toLocaleString();
}

function logTokenValidity(idToken: string, accessToken: string): void {
  const idTokenClaims = decodeToken(idToken);
  const accessTokenClaims = decodeToken(accessToken);

  log(
    `ID Token valid from: ${formatTimestamp(idTokenClaims.nbf)} to: ${formatTimestamp(idTokenClaims.exp)}`,
  );
  log(
    `Access Token valid from: ${formatTimestamp(accessTokenClaims.nbf)} to: ${formatTimestamp(accessTokenClaims.exp)}`,
  );
}

function isTokenExpired(token: string): boolean {
  if (!token) {
    return true;
  }
  try {
    const claims = decodeToken(token);
    const now = Math.floor(Date.now() / 1000);
    return claims.exp < now;
  } catch (e) {
    console.error(e);
  }
  return true;
}

async function acquireToken(account: any): Promise<any> {
  try {
    return await msalInstance.acquireTokenSilent({
      ...authRequest,
      account: account,
    });
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      console.log(e);
      return await msalInstance.ssoSilent({
        ...authRequest,
        account: account,
      });
    } else {
      throw e;
    }
  }
}

export async function getMsalToken() {
  const account = msalInstance.getAllAccounts()[0];
  let response = await acquireToken(account);

  if (response) {
    const idTokenExpired = isTokenExpired(response.idToken);
    const accessTokenExpired = isTokenExpired(response.accessToken);

    if (idTokenExpired && !accessTokenExpired) {
      // if idToken is expired but accessToken is valid
      // then MSAL will not refresh the idToken automatically
      // so we need to force a refresh (see CJM-461)
      try {
        console.log("Forcing token refresh");
        response = await msalInstance.acquireTokenSilent({
          ...authRequest,
          account: account,
          forceRefresh: true,
        });
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
          console.log(e);
          response = await msalInstance.loginPopup();
        } else {
          throw e;
        }
      }
    }

    if (response?.idToken && response?.accessToken) {
      logTokenValidity(response.idToken, response.accessToken);
      return response.idToken;
    }
  }

  try {
    const res = await msalInstance.loginPopup();
    logTokenValidity(res.idToken, res.accessToken);
    return res.idToken;
  } catch (e) {
    console.error(e);
    throw e;
  }
}

let token = localStorage.token;

export const authLink = setContext(async (_, { headers }) => {
  if (!import.meta.env.VITE_BACKEND_URL) {
    error("didn't find VITE_APP_BACKEND_URL");
    throw new Error("VITE_APP_BACKEND_URL not set");
  }

  if (isDisableAuth()) {
    if (!backendContainsLocalhost() && !token) {
      token = (await getUserToken()) ?? "";
      localStorage.token = token;
    }
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  }

  const msalToken = await getMsalToken();
  return {
    headers: {
      ...headers,
      authorization: msalToken,
    },
  };
});
