import React from "react";

/**
 *  Enthusiastic reaction icon
 */
export const EnthusiasticReaction: React.FC<React.SVGProps<SVGSVGElement>> = (
  props,
) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.12054e-06 9C6.86003e-07 4.02944 4.02944 3.12869e-06 9 2.69415e-06C13.9706 2.25961e-06 18 4.02944 18 9C18 13.9706 13.9706 18 9 18C4.02944 18 1.55508e-06 13.9706 1.12054e-06 9Z"
        fill="#FFD732"
      />
      <circle cx="5.95407" cy="6.52096" r="0.850551" fill="#000028" />
      <circle cx="12.1914" cy="6.52096" r="0.850551" fill="#000028" />
      <path
        d="M12.7582 11.6243H5.38672C5.57573 12.5693 6.57749 14.4594 9.07244 14.4594C11.5674 14.4594 12.5691 12.5693 12.7582 11.6243Z"
        fill="#000028"
        stroke="#000028"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
