import { gql } from "@apollo/client";

export const GET_YOUR_INSIGHTS_TOPO = gql`
  query GET_YOUR_INSIGHTS_TOPO($id: ID!) {
    journeyTopology(id: $id) {
      customer_role {
        id
        name
      }
      customer_type {
        id
        name
      }
      industry {
        id
        name
      }
      motivations {
        intents {
          name
          id
          intents {
            id
            name
          }
        }
        name
        id
      }
      buyer_types {
        id
        name
      }
      regions {
        id
        name
      }
      priorContact {
        id
        name
      }
      experience_drivers {
        id
        name
        tooltip
      }
      painpoints {
        id
        name
      }
    }
  }
`;
