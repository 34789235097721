import React, { useState } from "react";
import {
  IxButton,
  IxModalContent,
  IxModalHeader,
  IxTypography,
  Modal,
  ModalRef,
} from "@siemens/ix-react";
// @ts-expect-error This import works
import EndJourney from "./endJourney.svg?react";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { JourneyOutcomeType } from "@/utils/apollo/resolvers";

interface EndJourneyDialogProps {
  onClose: () => void;
}

export const EndJourneyDialog = React.forwardRef<
  ModalRef,
  EndJourneyDialogProps
>(({ onClose }, ref) => {
  const [intentFulfilled, setIntentFulfilled] = useState<boolean | null>(null);
  const { motivation, addOutcome } = useChannelStore();

  const handleConfirm = () => {
    if (intentFulfilled) {
      addOutcome({ type: JourneyOutcomeType.IntentFulfilled });
    } else {
      addOutcome({ type: JourneyOutcomeType.IntentUnfulfilled });
    }
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <Modal ref={ref}>
      <IxModalHeader onCloseClick={onClose}>End of Journey</IxModalHeader>
      <IxModalContent className="flex flex-col px-2">
        <IxTypography format="h2" className="font-bold">
          Congrats, You have reached the end of your journey – <br />
          Let’s evaluate your intent.
        </IxTypography>
        <div className="my-3 flex flex-col items-start gap-1 text-left">
          <IxTypography format="body">
            <span className="font-bold">Motivation: </span>
            <span>{motivation?.name ?? "-"}</span>
          </IxTypography>
          <IxTypography format="body">
            <span className="font-bold">Intent: </span>
            <span>{motivation?.intent?.name ?? "-"}</span>
          </IxTypography>
        </div>

        <IxTypography format="body" className="font-bold">
          Was your intent fulfilled?
        </IxTypography>
        <div className="mt-2 flex flex-col space-y-2">
          <div>
            <input
              id="intent_fulfilled"
              name="intent_group"
              type="radio"
              checked={intentFulfilled === true}
              onChange={() => setIntentFulfilled(true)}
            />
            <label htmlFor="intent_fulfilled" className="!items-center">
              Yes, my intent was fulfilled.
            </label>
          </div>
          <div>
            <input
              id="intent_unfulfilled"
              name="intent_group"
              type="radio"
              checked={intentFulfilled === false}
              onChange={() => setIntentFulfilled(false)}
            />
            <label htmlFor="intent_unfulfilled" className="!items-center">
              No, my intent was unfulfilled.
            </label>
          </div>
          <div className="flex justify-center">
            <EndJourney />
          </div>
        </div>
        <div className="mt-4 flex justify-end space-x-2">
          <IxButton outline variant="primary" onClick={handleCancel}>
            Cancel
          </IxButton>
          <IxButton
            variant="primary"
            onClick={handleConfirm}
            disabled={intentFulfilled === null}
          >
            Confirm
          </IxButton>
        </div>
      </IxModalContent>
    </Modal>
  );
});
