import { IxApplicationHeader } from "@siemens/ix-react";
import { PropsWithChildren } from "react";
import { SiemensLogo } from "../Logo";
import { HelpLink } from "./HelpLink";

interface TopBarBaseProps extends PropsWithChildren {
  isAdmin?: boolean;
}

/**
 *  Base component for the top bar.
 * It contains the application header and the logo.
 * @param props - The props of the component.
 * @param props.children - The children to render inside the top bar.
 * @param {boolean} props.isAdmin - Whether the platform is the admin.
 */
export const TopBarBase: React.FC<TopBarBaseProps> = ({
  children,
  isAdmin,
}) => {
  return (
    <IxApplicationHeader
      name={`Customer Heartbeat${isAdmin ? " — Admin View" : ""}`}
    >
      <SiemensLogo />

      <div className="flex items-center gap-2">
        <HelpLink />
        <div className="h-4 w-px bg-theme-color-weak-bdr"></div>
        {children}
      </div>
    </IxApplicationHeader>
  );
};
