import { Selection } from "d3-selection";
import { getChannelConnectionPointArray, lineGenerator } from "./line";
import { TransitionType } from "@/utils/apollo/resolvers";
import { MapChannel } from "./Map";

/**
 * Draws vertical connections between the last and first visited touchpoint
 * when switching to a new channel.
 *
 * The "last" visited touchpoint can be the the first or any other tp in
 * channel because users can jump between touchpoints in a channel before
 * switching to a new channel.
 */
export const drawTransitions = (
  svg: Selection<SVGSVGElement, MapChannel[], null, undefined>,
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  type: TransitionType,
) => {
  const lineId = `channel-switch-connection-${x1}-${y1}-${x2}-${y2}`;

  // Select the existing line or append a new one if it does not exist
  let path = svg.select<SVGPathElement>(`path#${lineId}`);
  if (path.empty()) {
    path = svg.append("path").attr("id", lineId).classed("transition", true);
  }

  path
    .attr("class", "transition")
    .attr("d", () =>
      lineGenerator(
        getChannelConnectionPointArray({
          start: { x: x1, y: y1 },
          end: { x: x2, y: y2 },
          isDashed: true,
          offset: 0,
        }),
      ),
    )
    .attr("fill", "none")
    .attr("stroke", "var(--line)")
    .classed("transition", true)
    .attr("stroke-width", "2")
    .attr("stroke-dasharray", () =>
      // if the transition is a switch then draw a dashed line
      type === TransitionType.Switch ? "6,6" : "none",
    );
};
