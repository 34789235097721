import { IxTooltip, IxTypography } from "@siemens/ix-react";
import React from "react";
import { HoveredMetaData } from "../drawTouchpoint";

interface ReactionTooltipProps {
  data?: HoveredMetaData;
}

/**
 *  Tooltip for the reaction of the meta data
 * @param props - ReactionTooltipProps
 * @param props.data - HoveredMetaData
 */
export const ReactionTooltip: React.FC<ReactionTooltipProps> = ({ data }) => {
  return (
    <IxTooltip
      className="theme-brand-dark text-white"
      id="meta-data-reaction-tooltip"
      for="#meta-data-reaction-tooltip-anchor"
      placement="top"
      interactive
    >
      <div className="flex flex-col">
        <IxTypography format="body" bold>
          Reaction
        </IxTypography>
        <IxTypography format="body">
          {data?.node.reaction?.reason
            ? data.node.reaction.reason
            : "No reaction"}
        </IxTypography>
      </div>
    </IxTooltip>
  );
};
