import { getMsalToken } from "@/src/authLink";
import { InMemoryAttachment } from "./ScreenshotSubMenu";
import { hideSpinner, showSpinner } from "@motius/customer-heartbeat-ui";
import { openLinkInNewTab } from "@motius/customer-heartbeat-utils";

const HOSTNAME = import.meta.env.VITE_BACKEND_URL;

// imageCache contains a mapping of imageId to the InMemoryAttachment
// we need this because the image might be uploaded but not saved as draft
// we can only view it from the db if it was attached to a node
// ie saved as draft.
export const imageCache = new Map<string, InMemoryAttachment>();

interface PresignParams {
  bearerToken: string;
  imageId: string;
  hostname: string;
  name: string;
}

interface UploadResponse {
  url: string;
  fields: Record<string, string>;
  error: string | null;
}

export async function getPresignedUrl({
  bearerToken,
  imageId,
  hostname,
  name,
}: PresignParams): Promise<UploadResponse> {
  const url = `${hostname}/requestImageUpload`;
  const headers = new Headers({
    "Content-Type": "application/json",
    Authorization: bearerToken,
  });
  const body = JSON.stringify({
    imageId,
    name: name,
  });

  try {
    const response = await fetch(url, {
      method: "POST",
      headers,
      body,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data: UploadResponse = await response.json();
    return data;
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
}

export async function uploadImageToPresignedUrl(
  file: InMemoryAttachment,
  uploadResponse: UploadResponse,
): Promise<void> {
  const { url, fields } = uploadResponse;

  const formData = new FormData();
  Object.entries(fields).forEach(([key, value]) => {
    formData.append(key, value as string);
  });
  formData.append("Content-Type", "image/jpg");
  formData.append("file", file.file as File, file.name);

  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    imageCache.set(file.id, file);
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
}

export async function uploadImage(file: InMemoryAttachment): Promise<void> {
  try {
    const token = await getMsalToken();
    const uploadResponse = await getPresignedUrl({
      bearerToken: token,
      imageId: file.id,
      hostname: HOSTNAME,
      name: file.name,
    });

    await uploadImageToPresignedUrl(file, uploadResponse);
  } catch (error) {
    console.error("Error uploading image:", error);
    throw error;
  }
}

export const openDbImageInNewTab = async (imageId: string) => {
  try {
    showSpinner();
    const response = await fetch(`${HOSTNAME}/viewImage/${imageId}`, {
      method: "GET",
      headers: {
        Authorization: await getMsalToken(),
      },
    });

    if (response.ok) {
      const data = await response.json();
      const url = data.url; // Extracting URL from the JSON response
      openLinkInNewTab(url);
    } else {
      console.error("Error fetching image:", response.statusText);
    }
  } catch (error) {
    console.error("Error fetching image:", error);
  } finally {
    hideSpinner();
  }
};

export const openInMemoryImageInNewTab = (file: File) => {
  const url = URL.createObjectURL(file);
  openLinkInNewTab(url);
};

export const openImageInNewTab = (imageId: string) => {
  const cachedFile = imageCache.get(imageId)?.file;
  if (cachedFile != null) {
    // image has been uploaded but possibly not saved as draft
    openInMemoryImageInNewTab(cachedFile);
  } else {
    // if its not in the cache it must alaready be in the db
    openDbImageInNewTab(imageId);
  }
};
