import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import {
  YourInsightsTopology,
  processYourInsightsTopoData,
} from "@/utils/topology";
import { GET_YOUR_INSIGHTS_TOPO } from "./query";
import { Maybe } from "@motius/customer-heartbeat-utils";
import { useYourInsightsTopologyStore } from "./useYourInsightsTopologyStore";

export type YourInsightsQueryResult = {
  journeyTopology: YourInsightsTopology;
};

export const useYourInsightsTopology = (
  journeyId: Maybe<string>,
  journeyTopologyId?: Maybe<string>,
) => {
  const [getTopo] = useLazyQuery<YourInsightsQueryResult>(
    GET_YOUR_INSIGHTS_TOPO,
    {
      variables: { id: journeyTopologyId ?? import.meta.env.VITE_TOPOLOGY_ID },
    },
  );

  const {
    topologyId: currentTopologyId,
    currentTopology,
    savedTopologies,
    switchTopology,
    saveTopology,
  } = useYourInsightsTopologyStore();

  const getTopoData = async () => {
    const topologyId = journeyTopologyId ?? import.meta.env.VITE_TOPOLOGY_ID;
    let topologyToProcess;
    if (currentTopologyId === topologyId) {
      topologyToProcess = currentTopology;
    } else if (savedTopologies[topologyId]) {
      topologyToProcess = savedTopologies[topologyId].state.currentTopology;
      switchTopology(topologyId);
    } else {
      const topologyData = await getTopo();
      const topoResult = topologyData?.data;
      if (topoResult?.journeyTopology) {
        const { painpoints, ...rest } = topoResult.journeyTopology;
        const sortedPainpoints = [...painpoints].sort((a, b) =>
          a.name.localeCompare(b.name),
        );
        const sortedTopo: YourInsightsTopology = {
          ...rest,
          painpoints: sortedPainpoints,
        };
        saveTopology(topologyId, sortedTopo);
        topologyToProcess = sortedTopo;
      }
    }

    if (topologyToProcess) {
      processYourInsightsTopoData(topologyToProcess);
    }
  };

  useEffect(() => {
    getTopoData();
  }, [journeyId, getTopo]);

  return { topology: currentTopology };
};
