import { IxIcon, IxIconButton, IxTypography } from "@siemens/ix-react";
import { InMemoryAttachment } from "./ScreenshotSubMenu";

type Props = {
  att: InMemoryAttachment;
  onDelete: (id: string) => void;
  onOpen: (att: InMemoryAttachment) => void;
};

/**
 *
 */
export const AttachmentItem = ({ att, onDelete, onOpen }: Props) => {
  return (
    <div className="flex h-6 w-full items-center justify-between gap-2">
      <IxIcon name="screenshot" size="16" />
      <IxTypography
        className="cursor-pointer truncate text-theme-color-primary"
        textDecoration="underline"
        onClick={() => onOpen(att)}
      >
        {att.name}
      </IxTypography>
      <IxIconButton
        className="ml-auto scale-75"
        variant="primary"
        ghost
        size="24"
        icon="trashcan"
        onClick={() => onDelete(att.id)}
      />
    </div>
  );
};
