import React, { useEffect, useState } from "react";
import {
  IxInputGroup,
  IxIcon,
  IxDropdown,
  IxDropdownItem,
} from "@siemens/ix-react";
import { useUserSearch } from "./useUserSearch";
import { JourneyFilter, Maybe, User } from "@/utils/apollo/resolvers";
import { addAuthorFilter } from "./filterUtils";
import { handleErrorMessage } from "@/utils/error";
import { UserSearchDropdownItem } from "./UserSearchDropdownItems";

interface SearchProps {
  setFilters: React.Dispatch<React.SetStateAction<FrontendJourneyFilter>>;
  children?: React.ReactNode;
}

/**
 * The author filter object contains the gid and name of the user.
 */
export type AuthorFilter = {
  gid: string;
  name: string;
};

/**
 * The filter object only contains the gid of the user.
 * This allows an easy lookup of the name of the user.
 */
export type FrontendJourneyFilter = Omit<JourneyFilter, "ownerGid"> & {
  authorGidFilters?: AuthorFilter[];
  authorNameFilters?: string[];
  AND?: Maybe<Array<FrontendJourneyFilter>>;
  OR?: Maybe<Array<FrontendJourneyFilter>>;
};

/**
 * Search component for filtering content
 * @param props - The props object
 * @param props.setFilters - Function to set filters
 */
export const Search: React.FC<SearchProps> = ({ setFilters, children }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { users, loading, error } = useUserSearch(searchTerm);
  const [blurTimeoutId, setBlurTimeoutId] = useState<number | null>(null);

  useEffect(() => {
    setIsDropdownOpen(searchTerm.length > 2);
  }, [searchTerm]);

  /**
   * Handles the click event for a user
   * @param user - The user object
   */
  const handleClick = (user: User) => {
    if (!user.gid || !user.name) {
      handleErrorMessage("User gid and name should not be empty");
      return;
    }
    // todo: user gid should not be optional
    // follow up with backend
    const gid = user.gid;
    const name = user.name;

    setFilters((prevFilters) => {
      const newFilters = structuredClone(prevFilters);
      newFilters.authorGidFilters = newFilters.authorGidFilters || [];
      return addAuthorFilter({ gid, name }, newFilters);
    });
    setIsDropdownOpen(false);
    setSearchTerm("");
    // Clear the blur timeout if it exists
    if (blurTimeoutId) {
      clearTimeout(blurTimeoutId);
      setBlurTimeoutId(null);
    }
  };

  /**
   * Handles the key down event
   * If Enter is pressed, add the search term to authorNameFilters
   * If Enter is pressed with a single user result, select that user
   * @param e - The key down event
   */
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchTerm.trim().length > 2) {
        setFilters((prevFilters) => {
          const newFilters = structuredClone(prevFilters);
          newFilters.authorNameFilters = newFilters.authorNameFilters || [];
          if (!newFilters.authorNameFilters.includes(searchTerm.trim())) {
            newFilters.authorNameFilters.push(searchTerm.trim());
          }
          return newFilters;
        });
        setSearchTerm("");
      }
    }
  };

  const handleBlur = () => {
    // Use setTimeout to delay closing the dropdown
    // (otherwise dropdown closes before click is registered)
    const timeoutId = window.setTimeout(() => {
      setIsDropdownOpen(false);
    }, 200);
    setBlurTimeoutId(timeoutId);
  };

  return (
    <div className="relative flex gap-3">
      <form
        autoComplete="off"
        onSubmit={(e) => e.preventDefault()}
        className="relative flex !w-[26.1rem] gap-3"
      >
        <IxInputGroup className="flex gap-3">
          <span slot="input-start">
            <IxIcon className="scale-[133.3%]" name="search" size="12" />
          </span>
          <input
            id="input-string"
            placeholder="Search Author"
            type="string"
            onFocus={() => setIsDropdownOpen(searchTerm.length > 2)}
            onBlur={handleBlur}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            value={searchTerm}
          />
          {searchTerm.length > 0 && (
            <span slot="input-end">
              <IxIcon
                className="scale-[133.3%] cursor-pointer"
                onClick={() => {
                  setSearchTerm("");
                  setIsDropdownOpen(false);
                }}
                name="clear"
                size="12"
              />
            </span>
          )}
          <IxDropdown
            show={isDropdownOpen}
            anchor="input-string"
            closeBehavior="inside"
            className="w-[26.1rem]"
            positioningStrategy="absolute"
          >
            {!users.length && (
              <IxDropdownItem
                key="no-results"
                disabled
                className="min-w-[12.5rem]"
              >
                {loading ? "Loading..." : "No results"}
              </IxDropdownItem>
            )}
            {users.map((user) => (
              <UserSearchDropdownItem
                key={user.gid}
                onClick={handleClick}
                user={user}
                searchTerm={searchTerm}
              />
            ))}
          </IxDropdown>
        </IxInputGroup>
        {children}
      </form>
      {error && <p>Error: {error.message}</p>}
    </div>
  );
};
