import React, { useState, useEffect } from "react";
import {
  IxAvatar,
  IxButton,
  IxDropdown,
  IxDropdownItem,
  IxIcon,
  IxIconButton,
  IxTypography,
} from "@siemens/ix-react";
import { TextAreaStyle } from "../Navigation/TextAreaStyle";
import { useUserDetails } from "@motius/customer-heartbeat-utils/auth";
import { useComments } from "./useComments";
import { formatTime } from "./utils";
import { thumbsUp, thumbsUpFilled } from "./thumbsUpIcons";
import { showMessage } from "@siemens/ix"; // Add this import

interface CommentsProps {}

export const Comments: React.FC<CommentsProps> = ({}) => {
  const {
    initials: initialsOfCurrentUser,
    name: username,
    gid,
  } = useUserDetails();
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const [comment, setComment] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const { comments, addComment, likeComment, deleteComment, updateComment } =
    useComments();
  const [idThatIsCurrentlyEdited, setIdThatIsCurrentlyEdited] = useState("");
  const [textareaHeight, setTextareaHeight] = useState(3.25); // 3.25rem initial height
  // in some cases the computed height was not enough so it adds
  // a buffer
  const bufferHeight = 0.25;

  useEffect(() => {
    if (textareaRef.current) {
      const textarea = textareaRef.current;
      textarea.style.height = "3.25rem"; // Reset to minimum height
      const newHeight = Math.min(
        12.5, // 12.5rem = 200px at 16px base font size
        Math.max(3.25, textarea.scrollHeight / 16 + bufferHeight),
      );
      setTextareaHeight(newHeight);
      textarea.style.height = `${newHeight}rem`;
    }
  }, [comment]);

  const handleCommentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setComment(event.target.value);
  };

  const handleSubmitComment = () => {
    if (comment.trim()) {
      if (idThatIsCurrentlyEdited) {
        updateComment(idThatIsCurrentlyEdited, comment);
        setIdThatIsCurrentlyEdited("");
      } else {
        addComment(comment);
      }
      setComment("");
      setIsFocused(false);
    }
  };

  const handleUpdateComment = (commentId: string, text: string) => {
    setIdThatIsCurrentlyEdited(commentId);
    setComment(text);
    textareaRef.current?.focus();
  };

  const handleCancel = () => {
    setComment("");
    setIdThatIsCurrentlyEdited("");
    textareaRef.current?.focus();
  };

  const handleBlur = () => {
    if (!comment.trim()) {
      setIsFocused(false);
    }
  };

  const handleDeleteComment = async (commentId: string) => {
    (
      await showMessage({
        icon: "",
        messageTitle: "Do you really want to delete your comment?",
        message: "The comment will be lost if you proceed.",
        actions: [
          { id: "cancel", type: "cancel", text: "Cancel" },
          { id: "proceed", type: "okay", text: "Proceed" },
        ],
      })
    ).once((result) => {
      if (result?.actionId === "proceed") deleteComment(commentId);
    });
  };

  return (
    <div className="flex h-full flex-col">
      <div className="flex grow flex-col gap-2 overflow-auto py-2">
        {comments.length > 0 ? (
          comments.map((comment) => {
            if (!comment) return null;
            const isMyComment = comment.commentedBy?.gid === gid;
            const isLikedByCurrentUser = comment.likedBy.includes(gid);
            const name = comment.commentedBy?.name || "";
            const initialsOfLoadedComment = name
              .split(" ")
              .slice(0, 2)
              .map((n) => n[0])
              .reverse()
              .join("")
              .toUpperCase();

            const thumbsUpIcon = isLikedByCurrentUser
              ? thumbsUpFilled
              : thumbsUp;

            return (
              <div
                key={comment.id}
                className="mb-1 flex w-full items-start text-theme-color-std-text"
              >
                <IxAvatar
                  className="mt-1"
                  initials={initialsOfLoadedComment}
                  username={name}
                />
                <div className="ml-2 mr-3 w-full">
                  {/** name and date */}
                  <p className="mb-1 font-semibold">
                    <span className="font-bold">{name}</span>
                  </p>
                  <p className="mb-2 text-sm text-weak">
                    {formatTime(
                      new Date(comment.updatedAt || comment.createdAt),
                    )}
                  </p>

                  {/** text and icon */}
                  <div className="flex justify-between">
                    <p>
                      {comment.text}
                      {comment.updatedAt &&
                        comment.updatedAt !== comment.createdAt && (
                          <span className="ml-1 text-weak">(edited)</span>
                        )}
                    </p>
                    {isMyComment && (
                      <>
                        <IxIconButton
                          size="16"
                          variant="primary"
                          ghost
                          icon="context-menu"
                          id={`actions-${comment.id}`}
                        ></IxIconButton>
                        <IxDropdown trigger={`actions-${comment.id}`}>
                          <IxDropdownItem
                            onClick={() =>
                              handleUpdateComment(comment.id, comment.text)
                            }
                            label="Edit"
                            icon="pen"
                          ></IxDropdownItem>
                          <IxDropdownItem
                            onClick={() => handleDeleteComment(comment.id)}
                            label="Delete"
                            icon="trashcan"
                          ></IxDropdownItem>
                        </IxDropdown>
                      </>
                    )}
                  </div>

                  {/** like button */}
                  <div className="ml-2 mt-2 flex items-center">
                    <button
                      className="flex items-center"
                      type="button"
                      onClick={() => likeComment(comment.id)}
                    >
                      {thumbsUpIcon("fill-black opacity-[0.7]")}
                    </button>
                    <span className="ml-1 text-[0.75rem] text-soft inline-block text-center h-[1rem]">
                      {(comment?.likedBy?.length ?? 0) > 0 &&
                        comment.likedBy.length}
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex grow flex-col items-center justify-center gap-4 p-3">
            <IxIcon
              name="notification"
              size="32"
              className="scale-[200%] pb-1 opacity-40"
            />
            <IxTypography format="body-lg">
              There is nothing here yet ...
            </IxTypography>
            <IxTypography format="body-sm" className="text-center text-soft">
              Click below to start commenting and raise a discussion about this
              journey.
            </IxTypography>
          </div>
        )}
      </div>
      <hr />
      <div className="mt-2 flex flex-col pb-2">
        <div className="flex items-start">
          <IxAvatar initials={initialsOfCurrentUser} username={username} />
          <div className="ml-2 grow">
            <textarea
              ref={textareaRef}
              className={`${TextAreaStyle} min-h-[3.25rem] w-full`}
              placeholder="Add a comment..."
              value={comment}
              onChange={handleCommentChange}
              onFocus={() => setIsFocused(true)}
              onBlur={handleBlur}
              rows={1}
              style={{
                height: `${textareaHeight}rem`,
                maxHeight: "12.5rem",
                overflowY: "hidden",
              }}
            />
          </div>
        </div>
        {isFocused && (
          <div className="mt-2 flex justify-end gap-2">
            <IxButton
              className="ml-2"
              onClick={handleCancel}
              outline
              variant="primary"
            >
              Cancel
            </IxButton>
            <IxButton
              onClick={handleSubmitComment}
              disabled={!comment.trim()}
              variant="primary"
            >
              Comment
            </IxButton>
          </div>
        )}
      </div>
    </div>
  );
};
