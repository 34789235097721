import { Journey } from "@/utils/apollo/resolvers";
import { AvatarType } from "@motius/customer-heartbeat-utils/schemas";
import { ChannelState } from "./types";

/**
 * after storing a journey we take the result of the store
 * operation which contains the ids of the stored objects
 * we then update the ids in the state so that in case
 * we store again we only update and do not insert
 * @param result the result of the store operation
 * @param set the set function from the useChannelStore
 * @param produce the produce function from the useChannelStore
 */
export const updateJourneyIds =
  (set: any, produce: any) => (result: Partial<Journey>) => {
    set(
      produce((state: ChannelState) => {
        if (!result.id) {
          throw new Error("Journey id not found");
        }
        state.journeyId = result.id;

        state.undoStack = [];
        state.undoStackIndex = 0;

        (result.channels ?? []).forEach((channel, i) => {
          if (state.channels[i]) {
            state.channels[i].id = channel.id;

            (channel.touchpoints ?? []).forEach((touchpoint, j) => {
              if (state.channels[i].touchpoints[j]) {
                state.channels[i].touchpoints[j].id = touchpoint.id;

                (touchpoint.locations ?? []).forEach((location, k) => {
                  if (state.channels[i].touchpoints[j].locations[k]) {
                    state.channels[i].touchpoints[j].locations[k].id =
                      location.id;
                  }
                });
              }
            });
          }
        });
      }),
    );
  };

/**
 * after storing a journey we take the result of the store
 * operation which contains the ids of the stored objects
 * we then update the ids in the state so that in case
 * we store again we only update and do not insert
 * @param result the result of the store operation
 * @param set the set function from the useChannelStore
 * @param produce the produce function from the useChannelStore
 */
export const updateCustomerIds =
  (set: any, produce: any) => (result: Partial<Journey>) => {
    set(
      produce((state: ChannelState) => {
        if (!result.id) {
          throw new Error("Journey id not found");
        }
        state.journeyId = result.id;

        state.industry = result.industry;
        state.customerType = result.customerType;
        state.customerRole = result.customerRole;
        state.region = result.region;
        state.buyerType = result.buyerType;
        state.priorContact = result.priorContact;
        state.motivation = result.motivation;
        state.avatarRef = result.avatarRef as AvatarType;
        state.description = result.description;
      }),
    );
  };
