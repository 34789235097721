import React from "react";
import { Controller } from "react-hook-form";
import { AvatarInput } from "../../Avatar/AvatarInput";
import { MultilineInput, Select } from "@motius/customer-heartbeat-ui";
import { MotivationInput, MotivationValues } from "./MotivationInput";
import { Topology } from "@motius/customer-heartbeat-utils/schemas";
import { YourInsightsTopology } from "@/utils/topology";
// import debug from "debug";

// const log = debug("CustomerDetailsFormContent");

export interface CustomerDetailsFormContentProps {
  motivationValues: MotivationValues;
  topologies: YourInsightsTopology;
}

const getOptionsByTopology = (topologies: Topology[]) =>
  topologies.map((topology) => ({ value: topology.id, label: topology.name }));

export const CustomerDetailsFormContent: React.FC<
  CustomerDetailsFormContentProps
> = ({ motivationValues, topologies }) => {
  const dropdownOptions = {
    customerRole: getOptionsByTopology(topologies.customer_role),
    customerType: getOptionsByTopology(topologies.customer_type),
    industry: getOptionsByTopology(topologies.industry),
    region: getOptionsByTopology(topologies.regions),
    buyerTypes: getOptionsByTopology(topologies.buyer_types),
    customerStatus: getOptionsByTopology(topologies.priorContact),
  };
  // log("dropdownOptions", dropdownOptions);

  /**
   * When the clear button is pressed in an IX select
   * then the value is set to an empty array.
   *
   * The backend and the validation however expect an empty string
   *
   * This function takes an onChange handler function for a select
   * and patches it so that it replaces null or an empty array with ""
   * @param originalOnChangeFunction The onChange handler
   * @returns A patched onChange handler
   */
  const normalizeSelectValues =
    (originalOnChangeFunction: Function) =>
    (fieldValue: any, ...args: any[]): any => {
      if (
        (Array.isArray(fieldValue) && fieldValue.length === 0) ||
        fieldValue === null
      ) {
        originalOnChangeFunction("", ...args);
      } else {
        originalOnChangeFunction(fieldValue, ...args);
      }
    };

  return (
    <div className="mt-2 grid grid-cols-3 gap-8">
      <div className="flex flex-col gap-3">
        <Controller
          name="avatar"
          render={({ field, fieldState }) => (
            <AvatarInput
              label="Avatar"
              variant="mini"
              value={field.value}
              onChange={field.onChange}
              error={fieldState.error}
            />
          )}
        />

        <Controller
          name="roleId"
          render={({ field, fieldState }) => (
            <Select
              sortAlphabetically
              label="Customer Role"
              id="detailsRole"
              error={fieldState.error}
              value={field.value}
              onChange={field.onChange}
              options={dropdownOptions.customerRole}
              allowClear={false}
            />
          )}
        />
        <Controller
          name="industryId"
          render={({ field, fieldState }) => (
            <Select
              sortAlphabetically
              label="Industry"
              id="industry"
              error={fieldState.error}
              value={field.value}
              onChange={field.onChange}
              options={dropdownOptions.industry}
              allowClear={false}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-3">
        <Controller
          name="typeId"
          render={({ field, fieldState }) => (
            <Select
              sortAlphabetically
              label="Customer Type"
              id="type"
              error={fieldState.error}
              value={field.value}
              onChange={normalizeSelectValues(field.onChange)}
              options={dropdownOptions.customerType}
              allowClear={false}
            />
          )}
        />
        <Controller
          name="regionId"
          render={({ field, fieldState }) => {
            return (
              <Select
                sortAlphabetically
                label="Region"
                id="region"
                error={fieldState.error}
                value={field.value}
                onChange={normalizeSelectValues(field.onChange)}
                autoFocus={!field.value}
                options={dropdownOptions.region}
                allowClear={false}
              />
            );
          }}
        />

        <Controller
          name="buyerTypeId"
          render={({ field, fieldState }) => (
            <Select
              sortAlphabetically
              label="Type of Buyer (optional)"
              id="buyerTypeId"
              error={fieldState.error}
              value={field.value}
              onChange={normalizeSelectValues(field.onChange)}
              options={dropdownOptions.buyerTypes}
            />
          )}
        />
        <Controller
          name="statusId"
          render={({ field, fieldState }) => (
            <Select
              sortAlphabetically
              label="Customer Status (optional)"
              id="status"
              error={fieldState.error}
              value={field.value}
              onChange={normalizeSelectValues(field.onChange)}
              options={dropdownOptions.customerStatus}
            />
          )}
        />
      </div>
      <div className="flex flex-col gap-3">
        <MotivationInput
          motivationTopology={topologies.motivations}
          defaultValues={motivationValues}
        />
        <Controller
          name="words"
          render={({ field, fieldState }) => (
            <MultilineInput
              labelClass="text-[0.875rem]"
              label="Customer Tagline"
              defaultValue=""
              rules="Max. 120 characters"
              maxLength={120}
              value={field.value}
              onChange={field.onChange}
              error={fieldState.error}
              withCounter
            />
          )}
        />
      </div>
    </div>
  );
};
