import { useEffect } from "react";

type PatchFunction = (element: Element) => void;

export const usePatchIx = (
  outerId: string,
  innerId: string,
  patchFunction: PatchFunction,
) => {
  useEffect(() => {
    let observer: MutationObserver | undefined;

    const applyPatchWithDelay = () => {
      setTimeout(() => {
        const outerElement = document.getElementById(outerId);
        if (outerElement) {
          const applyPatch = (root: Element | ShadowRoot) => {
            const innerElements = root.querySelectorAll(innerId);
            innerElements.forEach(patchFunction);

            root.querySelectorAll("*").forEach((element) => {
              if (element.shadowRoot) {
                applyPatch(element.shadowRoot);
              }
            });
          };

          // Run immediately
          applyPatch(outerElement);

          // Set up observer for future changes
          observer = new MutationObserver(() => applyPatch(outerElement));
          observer.observe(outerElement, { childList: true, subtree: true });
        }
      }, 0);
    };

    applyPatchWithDelay();

    return () => {
      observer?.disconnect();
    };
  }, [outerId, innerId, patchFunction]);
};
