import React, { PropsWithChildren } from "react";

export const PageWithTopBar: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="size-full overflow-hidden bg-white font-sans text-gray-900">
      <div className="m-0 mx-auto flex h-screen flex-col p-0">
        {children}
      </div>
    </div>
  );
};
