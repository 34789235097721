import React, { useState, useEffect } from "react";
import { NothingHere } from "./NothingHere";
import { IxButton } from "@siemens/ix-react";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { LOAD_JOURNEYS } from "../Journeys/Journeys";
import { Journey } from "@/utils/apollo/resolvers";
import { PublishedJourneys } from "../Journeys/PublishedJourneys";
import { useDeleteJourney } from "@/src/hooks/useDeleteJourney";
import { useSpinner } from "@/src/hooks/useSpinner";
import { handleError } from "@/utils/error";

export const RecentlyAdded: React.FC = () => {
  const navigate = useNavigate();
  const [journeys, setJourneys] = useState<Journey[]>([]);

  const { loading, error, data, refetch } = useQuery(LOAD_JOURNEYS, {
    variables: {
      orderBy: { algorithm: "CREATED_DATE" },
      filter: { isPublished: true },
    },
    fetchPolicy: "network-only",
  });

  const { handleDelete, isDeleting } = useDeleteJourney(refetch);

  const isSpinner = loading || isDeleting;
  useSpinner(isSpinner);

  useEffect(() => {
    if (data?.journeyList?.journeys) {
      setJourneys(data.journeyList.journeys);
    }
  }, [data]);

  useEffect(() => {
    if (error) {
      handleError(error, "Error fetching recommended journeys");
    }
  }, [error]);

  if (error) return <p>Error: {error.message}</p>;
  if (isSpinner && !journeys.length) return null;

  return (
    <>
      {journeys.length > 0 ? (
        <div className="grid size-full gap-4 overflow-auto pb-16 pt-3">
          <PublishedJourneys journeys={journeys} onDelete={handleDelete} />
        </div>
      ) : (
        <div className="flex flex-col pb-16">
          <NothingHere
            iconName="upload"
            bodyText="Be the first to upload a journey"
          />
          <IxButton
            variant="primary"
            className="mt-4"
            onClick={() => navigate("/prepare?dialog=prepare1")}
          >
            Create Journey
          </IxButton>
        </div>
      )}
    </>
  );
};
