import { CIRCLE_RADIUS_BIG, RECT_WIDTH, TRANSITION_TIME } from "./constants";
import { MapNode } from "./drawTouchpoint";
import { MapChannel } from "./Map";

export const drawLine = (
  svg: d3.Selection<SVGGElement, MapChannel, null, undefined>,
  node: MapNode,
  x: number,
  y: number,
  id: string = "",
) => {
  const isLocation = node.isLocation;
  const lines = svg
    .selectAll<SVGLineElement, MapNode>(`line[data-id="${node.fid}"]`)
    // cast can be removed once topo Id is mandatory
    // already all nodes contain it. so the cast is safe
    .data([node], (d) => d.fid);

  const enter = lines
    .enter()
    .append("line")
    .classed("connection", true)
    .attr("data-composite-id", (d) => d.fid)
    .style("stroke", isLocation ? "white" : "var(--line)")
    .style("stroke-width", isLocation ? CIRCLE_RADIUS_BIG * 2 : 2)
    .attr("stroke-linecap", "round");

  enter
    .merge(lines)
    .attr("x1", x)
    .attr("y1", y)
    .attr("x2", x + RECT_WIDTH)
    .attr("y2", y)
    .transition()
    .duration(TRANSITION_TIME);

  lines.exit().remove();
};
