import React from "react";
import { IxTypography } from "@siemens/ix-react";

/**
 * Terms of Use
 * This is the tab of the About and Legal page
 * This tab contains Terms of Use for Customer Heartbeat application
 */
export const TermsOfUse = () => {
  return (
    <div
      className="max-w-[40rem] !text-black"
      data-testid="terms-of-use-content"
    >
      <IxTypography format="body-sm">
        These Terms of Use ("Terms") govern Users’ access to and use of Customer
        Heartbeat ("the Platform"), owned and operated by Siemens AG. By
        accessing or using the Platform, Siemens’ employees (each a “User”)
        agree to be bound by these Terms.
      </IxTypography>
      <ol className="list-inside list-decimal">
        <IxTypography format="body-lg" className="mt-4 list-item">
          Purpose of and access to the Platform:
        </IxTypography>
        <IxTypography format="body-sm">
          The Platform is designed to document customer journeys and facilitate
          the improvement of such customer journeys. The Platform may only be
          used by employees of Siemens AG or its affiliates (“Siemens”). Access
          is granted through use of the MyID login. <br />
          <br />
          By creating customer journeys (“Content”) on the Platform User agrees
          that their name may be displayed in relation to such Content.
        </IxTypography>
        <IxTypography format="body-lg" className="mt-4 list-item">
          Conditions of Use:
        </IxTypography>
        <IxTypography format="body-sm">
          In addition to all other obligations herein, User agrees as follows:
          <ol style={{ listStyleType: "lower-alpha" }} className="list-inside">
            <li>
              User shall not upload any personal data of another Siemens
              employee or a third party;
            </li>
            <li>
              User’s Content shall not be unlawful, harmful, threatening,
              abusive, harassing, tortious, defamatory, vulgar, obscene,
              libelous, racially, ethnically, or otherwise objectionable, and
              shall not concern minors;
            </li>
            <li>
              User has obtained the consent of all persons whose image is
              uploaded;
            </li>
            <li>
              User’s access and use of the Platform are subject to Siemens’
              policies and applicable laws;
            </li>
            <li>
              User shall not upload or input Content that infringes any patent,
              trademark, trade secret, copyright, rights of privacy or
              publicity, or other proprietary rights of any third party or
              Siemens;
            </li>
            <li>User shall not promote illegal activities on the Site;</li>
            <li>
              User shall not breach confidentiality obligations User has with
              Siemens or any third party;
            </li>
            <li>
              User shall avoid any actions that may compromise Siemens’ security
              measures for the Site;
            </li>
          </ol>
        </IxTypography>
        <IxTypography format="body-lg" className="mt-4 list-item">
          Data Privacy:
        </IxTypography>
        <IxTypography format="body-sm">
          All information concerning the processing of User’s personal data can
          be found in the{" "}
          <a
            className="underline"
            href="https://siemens.sharepoint.com/sites/FooterContent-internandguest/SitePages/de/Datenschutzerklaerung.aspx"
            target="_blank"
            rel="noopener noreferrer"
          >
            Siemens Intranet Data Privacy Notice
          </a>
        </IxTypography>
      </ol>
    </div>
  );
};
