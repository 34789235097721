import { JourneyTopology } from "@/utils/apollo/resolvers";
import { getLocalStorageSizeInMB } from "@/utils/localstorage";
import {
  FrontendChannelTopology,
  FrontendLocationTopology,
  FrontendTouchpointTopology,
} from "@/utils/types";
import debug from "debug";
import { produce } from "immer";
import { create } from "zustand";
import { persist } from "zustand/middleware";

const log = debug("useTopologyStore");
const localStorageName = "topology";

export type TopologyState = {
  topologyId?: string;
  journeyTopology: JourneyTopology | undefined;
  channelTopologies: FrontendChannelTopology[];
  touchpointTopologies: FrontendTouchpointTopology[];
  locationTopologies: FrontendLocationTopology[];
  savedTopologies: {
    [key: string]: {
      state: Omit<TopologyState, "savedTopologies">;
      date: Date;
    };
  };
};

export type Actions = {
  saveTopology: (
    id: string,
    journeyTopology: JourneyTopology,
    touchpoints: FrontendTouchpointTopology[],
    locations: FrontendLocationTopology[],
  ) => void;
  switchTopology: (id: string) => void;
};

const currentCacheSize = getLocalStorageSizeInMB(localStorageName);
log("Current cache size: ", currentCacheSize);
if (currentCacheSize > 1.5) {
  log("Cache size exceeded 1.5 MB. Removing the whole cache.");
  localStorage.removeItem(localStorageName);
}

/**
 * Topology Store to keep the already pulled topologies in local storage
 * since they don't change often
 */
export const useTopologyStore = create<TopologyState & Actions>()(
  persist(
    (set, get) => ({
      journeyTopology: undefined,
      channelTopologies: [],
      touchpointTopologies: [],
      locationTopologies: [],
      savedTopologies: {},
      topologyId: undefined,

      saveTopology: (id, topology, touchpoints, locations) =>
        set(
          produce((state: TopologyState) => {
            const date = new Date();
            const newSavedTopology = {
              journeyTopology: topology,
              channelTopologies: topology.channels,
              touchpointTopologies: touchpoints,
              locationTopologies: locations,
              topologyId: id,
            };
            state.topologyId = id;
            state.journeyTopology = {
              ...newSavedTopology.journeyTopology,
              channels: newSavedTopology.journeyTopology.channels.map((ch) => {
                return {
                  ...ch,
                  touchpoints: newSavedTopology.touchpointTopologies,
                };
              }),
            };
            state.channelTopologies = newSavedTopology.channelTopologies;
            state.touchpointTopologies = newSavedTopology.touchpointTopologies;
            state.locationTopologies = newSavedTopology.locationTopologies;
            state.savedTopologies[id] = {
              state: newSavedTopology,
              date: date,
            };
          }),
        ),
      switchTopology: (id) => {
        set(
          produce((state: TopologyState) => {
            state.topologyId = id;
            const savedState = state.savedTopologies[id].state;
            state.journeyTopology = savedState.journeyTopology && {
              ...savedState.journeyTopology,
              channels: savedState.journeyTopology.channels.map((ch) => {
                return {
                  ...ch,
                  touchpoints: savedState.touchpointTopologies,
                };
              }),
            };
            state.channelTopologies = savedState.channelTopologies;
            state.touchpointTopologies = savedState.touchpointTopologies;
            state.locationTopologies = savedState.locationTopologies;
          }),
        );
      },
    }),
    {
      name: localStorageName, // name of the item in the storage (must be unique)
      partialize: (state) => ({ savedTopologies: state.savedTopologies }),
    },
  ),
);
