import { useChannelStore } from "@/src/hooks/useChannelStore";
import { ReactionInput } from "@/utils/apollo/resolvers";
import React from "react";
import {
  Reaction,
  ReactionIcon,
} from "../Map/MetaDataMenu/MetaDataSubMenus/Reactions/ReactionIcon";
import { TextJourneyMetaData } from "./TextJourneyMetaData";
interface ReactionProps {
  reaction?: ReactionInput | null | undefined;
  fid: string;
  editable: boolean;
}

export const TextJourneyReaction: React.FC<ReactionProps> = ({
  reaction,
  fid,
  editable,
}) => {
  const { removeReaction } = useChannelStore();
  if (!reaction) {
    return null;
  }

  const handleDelete = () => {
    removeReaction(fid);
  };

  return (
    <TextJourneyMetaData
      icon={<ReactionIcon reaction={reaction.name as Reaction} />}
      onDelete={handleDelete}
      type="reaction"
      editable={editable}
    >
      {reaction.reason ?? ""}
    </TextJourneyMetaData>
  );
};
