import { IxTypography } from "@siemens/ix-react";
import React from "react";
import { Button } from "../Button";

interface LoginContentProps {
  onLogin: () => void;
  footer: React.ReactNode;
}

export const LoginContent: React.FC<LoginContentProps> = ({
  onLogin,
  footer,
}) => {
  return (
    <div className="flex h-[calc(100%-2.75rem)] w-full bg-theme-color-2">
      <div className="relative flex flex-col justify-center pl-16 pr-12">
        <div className="flex w-[26rem] flex-col items-start justify-center">
          <IxTypography format="h1" className="mb-4">
            Map it. Share it. Take action.
          </IxTypography>
          <IxTypography format="body" className="mb-12">
            Customer Heartbeat is your Siemens tool to document customer
            journeys and drive experience innovation.
          </IxTypography>
          <Button onClick={onLogin}>Login</Button>
        </div>
        {footer}
      </div>
      <div
        className="h-screen grow bg-center bg-no-repeat"
        style={{ backgroundImage: "url('/Intro.svg')" }}
      >
        &nbsp;
        {/* Content */}
      </div>
    </div>
  );
};
