import { useChannelStore } from "@/src/hooks/useChannelStore";
import * as d3 from "d3";
import { useEffect, useRef, useState } from "react";
import { drawAvatar } from "./drawAvatar";
import { FrontendChannel } from "@/utils/types";
import { drawTouchPoint, HoveredMetaData, MapNode } from "./drawTouchpoint";
import { computeVisitCounts, getVisitCount } from "./visitCounts";
import {
  addPlaceholderTpIfNeeded,
  channelToMapChannel,
  getGroupTransform,
} from "./utils";
import { drawLine } from "./drawLine";
import { drawTransitions } from "../Map/drawTransitions";
import { TransitionType } from "@/utils/apollo/resolvers";
import { drawChannel } from "./drawChannel";
import {
  HORIZONTAL_SPACE_BETWEEN_TWO_LINES_ON_SAME_MAP_NODE,
  PLACEHOLDER_TOUCHPOINT_TP_ID,
  RECT_DIST_TOP,
  RECT_HEIGHT,
  RECT_WIDTH,
  RECT_WIDTH_HALF,
} from "./constants";
import { MetaDataMenu, Selected } from "./MetaDataMenu/MetaDataMenu";
import EmptyVisualJourney from "./EmptyVisualJourney";
import { ReactionTooltip } from "./MetaDataTooltip/ReactionTooltip";
import { TitleTooltip } from "./MetaDataTooltip/TitleTooltip";
import { ScreenshotTooltip } from "./MetaDataTooltip/ScreenshotTooltip";

interface Props {
  editable?: boolean;
}

export interface MapChannel extends Omit<FrontendChannel, "touchpoints"> {
  nodes: MapNode[];
}

export const VisualJourneyMap: React.FC<Props> = ({ editable = true }) => {
  const [maxY, setMaxY] = useState(0);
  const { channels, avatarRef, outcome } = useChannelStore();
  const svgRef = useRef<SVGSVGElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [activeOnSelection, setActiveOnSelection] = useState<
    string | undefined
  >(undefined);
  const [selected, setSelected] = useState<Selected | undefined>(undefined);
  const [hoveredMetaData, setHoveredMetaData] = useState<
    HoveredMetaData | undefined
  >(undefined);

  /**
   *  Handles the click event on touchpoints
   *  @param newSelected - the selected touchpoint
   */
  const handleTouchpointClick = (newSelected?: Selected) => {
    if (editable) {
      if (selected?.node.active) {
        setActiveOnSelection(selected.node.fid);
      }
      setSelected(newSelected);
    }
  };

  // We do a little bit of prepreocessing here
  // mapChannels dont differentiate much between TPs and Locs
  // which makes computations and drawing easier.
  // Addtionally we add a placeholder TP if needed for channels with no TPs
  const mapChannels =
    addPlaceholderTpIfNeeded(channels).map(channelToMapChannel);

  // set the last node as active so that it is highlighted
  const lastChannel = mapChannels[mapChannels.length - 1];
  const lastChannelNode = lastChannel?.nodes[
    lastChannel.nodes.length - 1
  ] as MapNode;
  if (lastChannelNode) {
    lastChannelNode.active = true;
  }

  // Calculate the total width for each channel
  const channelWidths = mapChannels.map((c) =>
    c.nodes.reduce((sum) => sum + RECT_WIDTH, 0),
  );
  // Calculates the sum of the widths of all channels
  // which is the widths of the whole SVG
  // ADDs 1 rect width for the avatar
  // and another for padding
  const totalWidth = channelWidths.reduce(
    (sum, width) => sum + width,
    RECT_WIDTH * 2,
  );

  useEffect(() => {
    let xDeltaByChannel = new Map<string, number>();
    if (!svgRef.current) return;

    computeVisitCounts(mapChannels);
    const svg = d3.select<SVGSVGElement, MapChannel[]>(svgRef.current);

    // Clear existing content
    // This is slow but I see no difference in performance to our previous
    // approach that used ids and updated element positions
    // and this can be optimized later.
    svg.selectAll("*").remove();

    drawAvatar(svg, RECT_WIDTH_HALF, RECT_DIST_TOP, avatarRef);

    // Here we draw the background rects with alternating colors
    // The topologyIdToYPosition is a map of the y position of each channel
    // The top pos of a channel is NOT the cannel index * RECT_HEIGHT
    // because if a channel is visited twice it will still only
    // be drawn once.
    const { topologyIdToYPosition, uniqueChannelCount } = drawChannel(
      svg,
      mapChannels,
      containerRef,
    );

    // Here we draw the channel groups
    // Channelgroups are essentially a cooridinate system
    // for each channel. So you can append TPs / Locs
    // without worrying about where the x/y of the channel is
    const channelGroups = svg
      .selectAll<SVGGElement, MapChannel>("g.channel")
      .data(mapChannels)
      .enter()
      .append("g")
      .attr("class", "channel pointer-events-none")
      .attr("transform", (d, i) => {
        const yPosition =
          topologyIdToYPosition.get(d.topologyId as string) || 0;
        const xPosition = channelWidths
          .slice(0, i)
          .reduce(
            (sum, width) => sum + width,
            RECT_WIDTH - RECT_WIDTH_HALF * i,
          );

        return `translate(${xPosition}, ${yPosition})`;
      });

    // This is the main loop where we draw the TPs and Locs
    // In a MapChannel TPs and Locs are flattened into a single array
    // called nodes.
    channelGroups.each(function (channel, channelIndex) {
      const channelGroup = d3.select(this) as d3.Selection<
        SVGGElement,
        MapChannel,
        null,
        undefined
      >;

      let currentX = RECT_WIDTH / 2;
      channel.nodes.forEach((mapNode: MapNode, nodeIndex) => {
        if (nodeIndex > 0) {
          currentX += RECT_WIDTH;
        } else if (channelIndex > 0) {
          // Draw a line from the last Touchpoint of the previous channel to here
          const prevChannelGroup = d3.select<SVGGElement, MapChannel>(
            channelGroups.nodes()[channelIndex - 1],
          );
          const currentChannelGroup = d3.select<SVGGElement, MapChannel>(this);

          const [prevChannelX, prevChannelY] =
            getGroupTransform(prevChannelGroup);
          const [currentChannelX, currentChannelY] =
            getGroupTransform(currentChannelGroup);

          const x1 =
            prevChannelX + channelWidths[channelIndex - 1] - RECT_WIDTH_HALF;
          const y1 = prevChannelY + RECT_DIST_TOP;

          const x2 = currentChannelX + currentX;
          const y2 = currentChannelY + RECT_DIST_TOP;

          /**
           * This next part is complicated and needs to be refactored
           * It computes if a node has 2 channel transitions both coming in
           * from the bottom. If so it moves the first one a bit to the left
           * and the second one to the right.
           */
          const isLinetoThisComingFromDown = y1 > y2;
          const hasThisChannelOnlyOneNode = channel.nodes.length === 1;
          const hasNextChannel = channelIndex < mapChannels.length - 1;

          let x2Delta = 0;

          // if we find a value in the map then that means that we moved
          // the previous line to the left
          // that means we already established that the line we are
          // about to draw now needs to be moved to the right
          let x1Delta =
            xDeltaByChannel.get(prevChannelGroup.datum()?.fid || "") || 0;
          if (
            isLinetoThisComingFromDown &&
            hasThisChannelOnlyOneNode &&
            hasNextChannel
          ) {
            const nextChannelGroup = d3.select<SVGGElement, MapChannel>(
              channelGroups.nodes()[channelIndex + 1],
            );
            const [, nextChannelY] = getGroupTransform(nextChannelGroup);
            const isNextChannelGoingDown = nextChannelY > currentChannelY;

            if (isNextChannelGoingDown) {
              x2Delta =
                HORIZONTAL_SPACE_BETWEEN_TWO_LINES_ON_SAME_MAP_NODE * -0.5;

              // if we move this line to the left
              // we will need to move the line that is drawn in the next mapNode
              // ie in the next loop iteration to the right
              // we store this xDelta in a map with the fid of the current channel.
              xDeltaByChannel.set(
                channel.fid as string,
                HORIZONTAL_SPACE_BETWEEN_TWO_LINES_ON_SAME_MAP_NODE * 0.5,
              );
            }
          }
          /**
           * End of complicated part to compute the xDeltas
           */

          // Here we draw the channel transition from the previous
          // node to this node
          drawTransitions(
            svg,
            x1 + x1Delta,
            y1 + (isLinetoThisComingFromDown ? 0 : 20),
            x2 + x2Delta,
            y2 + (isLinetoThisComingFromDown ? 20 : 0),
            channel.prevTransition ?? TransitionType.Switch,
          );
        }

        const visitCount = getVisitCount(mapNode);
        // this can draw a TP or a LOC
        drawTouchPoint({
          svg: channelGroup,
          node: mapNode,
          x: currentX,
          y: RECT_DIST_TOP,
          lightBackground:
            // This is a bit of a hack to alternate the background color
            ((topologyIdToYPosition.get(
              channel.topologyId as string,
            ) as number) /
              RECT_HEIGHT) %
              2 !==
            0,
          outcome,
          handleTouchpointClick,
          selectedNode: selected?.node,
          editable,
          visitCount,
          onMetaDataHover: setHoveredMetaData,
        });

        // Update the node information kept for the menu only if the node topology id is the same with selected and the content is different
        if (
          selected?.node?.fid === mapNode.fid &&
          JSON.stringify(selected.node) !== JSON.stringify(mapNode)
        ) {
          setSelected({
            ...selected,
            node: mapNode,
          });
        }

        // Find the last added node and select it in following conditions
        // 1. There is an active node topology id and either of the following conditions are met
        // 1. There isn't already a node selected by the user
        // 2. The current selected node is not manually selected and is not the already active topology
        // 3. The current selected node is manually selected and the node before user selected it is not the same as current active node
        if (mapNode.active) {
          if (
            (!selected?.userSelected && selected?.node.fid !== mapNode.fid) ||
            (selected?.userSelected && activeOnSelection !== mapNode.fid)
          ) {
            const foundElement = document.getElementById("label" + mapNode.fid);
            const elementX = foundElement?.getAttribute("x");
            const elementY = foundElement?.getAttribute("y");

            // Find the label element and get its height
            const labelElement = foundElement?.getElementsByTagName("p")[0];
            const currentChannelGroup = d3.select<SVGGElement, MapChannel>(
              this,
            );

            const [currentChannelX, currentChannelY] =
              getGroupTransform(currentChannelGroup);

            if (labelElement?.clientHeight && elementX && elementY) {
              setSelected({
                position: {
                  x: +elementX + currentChannelX,
                  y: +elementY + labelElement?.clientHeight + currentChannelY,
                },
                node: mapNode,
                userSelected: false,
              });
            }
          }
        }

        // if its not the last node in this channel
        // draw a horizontal line from this node to the next node
        if (nodeIndex < channel.nodes.length - 1) {
          drawLine(
            channelGroup,
            channel.nodes[nodeIndex + 1],
            currentX,
            0 + RECT_DIST_TOP,
          );
        }
      });
    });

    svg.selectAll<SVGGElement, MapChannel>(".transition").raise();
    svg.selectAll<SVGGElement, MapChannel>("g").raise();
    svg.selectAll<SVGGElement, MapChannel>("circle").raise();
    svg.selectAll<SVGGElement, MapChannel>("text").raise();
    svg.selectAll<SVGGElement, MapChannel>("image").raise();
    setMaxY(RECT_HEIGHT * uniqueChannelCount);
  }, [channels, totalWidth, avatarRef, outcome, selected]);

  if (!mapChannels.length) {
    return <EmptyVisualJourney />;
  }

  return (
    <div
      ref={containerRef}
      className="bg-channel-dark relative size-full overflow-auto"
    >
      <svg
        ref={svgRef}
        width={totalWidth ? `calc(max(${totalWidth}px, 100%))` : "100%"}
        height={maxY ? `${maxY}px` : "100%"}
        id="map"
        className="absolute fill-channel-dark"
      >
        <rect x={0} y={0} width="100%" height="100%" />
      </svg>
      {selected &&
        !selected.node.fid.includes(PLACEHOLDER_TOUCHPOINT_TP_ID) &&
        editable && <MetaDataMenu selected={selected} />}
      <ReactionTooltip data={hoveredMetaData} />
      <TitleTooltip data={hoveredMetaData} />
      <ScreenshotTooltip data={hoveredMetaData} />
    </div>
  );
};
