import {
  FrontendChannel,
  FrontendChannelTopology,
  FrontendLocation,
  FrontendLocationTopology,
  FrontendTouchpoint,
  FrontendTouchpointTopology,
} from "@/utils/types";
import { MapChannel } from "./Map";
import * as d3 from "d3";
import { PLACEHOLDER_TOUCHPOINT_TP_ID } from "./constants";
import {
  Channel,
  ChannelRequest,
  Journey,
  LocationRequest,
  TouchpointRequest,
} from "@/utils/apollo/resolvers";

// In the visualiziation we use MapChannel which is similar to a channel
// but we flatten the TPs and Locs into a single array called nodes.
export const channelToMapChannel = (channel: FrontendChannel): MapChannel => {
  return {
    ...channel,
    nodes: (channel.touchpoints as FrontendTouchpoint[]).flatMap(
      (touchpoint) => [
        {
          ...touchpoint,
          isLocation: false,
          hasLocation: touchpoint.locations.length > 0,
          channelTopologyId: channel.topologyId as string,
          compositeId: `${channel.topologyId}-${touchpoint.topologyId}`,
        },
        ...(touchpoint.locations as FrontendLocation[]).map((location) => ({
          ...location,
          isLocation: true,
          hasLocation: false,
          compositeId: `${channel.topologyId}-${touchpoint.topologyId}-${location.topologyId}`,
        })),
      ],
    ),
  };
};

export const getTransform = (transform: string): [number, number] => {
  const match = transform.match(/translate\(([^,]+),\s*([^)]+)\)/);
  return match ? [parseFloat(match[1]), parseFloat(match[2])] : [0, 0];
};

export const getGroupTransform = (
  group: d3.Selection<SVGGElement, MapChannel, null, undefined>,
): [number, number] => {
  const transform = group.attr("transform");
  return getTransform(transform);
};

/**
 * Mutates the passed channels array by adding a placeholder touchpoint if needed
 * @param channels
 * @returns mutated channels
 */
export const addPlaceholderTpIfNeeded = (
  channels: FrontendChannel[],
): FrontendChannel[] => {
  const mutableChannels = structuredClone(channels);
  if (mutableChannels.length === 0) return mutableChannels;
  const lastChannel = mutableChannels[mutableChannels.length - 1];
  if (lastChannel.touchpoints.length === 0) {
    lastChannel.touchpoints.push({
      id: PLACEHOLDER_TOUCHPOINT_TP_ID,
      name: "",
      topologyId: PLACEHOLDER_TOUCHPOINT_TP_ID,
      attachments: [],
      locations: [],
      fid: PLACEHOLDER_TOUCHPOINT_TP_ID,
    });
  }
  return mutableChannels;
};

export const getLastChannelOfJourney = (journey: Journey): Channel => {
  return journey.channels[journey.channels.length - 1];
};

export type GraphNode = {
  topologyId: string;
  locations?: GraphNode[];
};

/**
 *  Filters out the touchpoint requests from the list of requests and converts them to touchpoint topologies
 * @param requests List of touchpoint and location requests
 */
export const filterAndConvertRequestListToTouchpointTopologyList = (
  requests: (ChannelRequest | TouchpointRequest | LocationRequest)[],
) =>
  filterAndConvertRequestListToTopologyList<FrontendTouchpointTopology>(
    requests,
    "TouchpointRequest",
  );

/**
 * Filters out the location requests from the list of requests
 * @param requests List of touchpoint and location requests
 */
export const filterAndConvertRequestListToLocationTopologyList = (
  requests: (ChannelRequest | TouchpointRequest | LocationRequest)[],
) =>
  filterAndConvertRequestListToTopologyList<FrontendLocationTopology>(
    requests,
    "LocationRequest",
  );

/**
 * Filters out the location requests from the list of requests
 * @param requests List of touchpoint and location requests
 */
export const filterAndConvertRequestListToChannelTopologyList = (
  requests: (ChannelRequest | TouchpointRequest | LocationRequest)[],
) =>
  filterAndConvertRequestListToTopologyList<FrontendChannelTopology>(
    requests,
    "ChannelRequest",
  );

/**
 * Generic function to base the filtering functions for location, touchpoint and channel requests to their respective topologies
 * @param requests List of channel, touchpoint and location requests
 * @param requestTypename The typename of the request
 */
const filterAndConvertRequestListToTopologyList = <
  T extends
    | FrontendChannelTopology
    | FrontendLocationTopology
    | FrontendTouchpointTopology,
>(
  requests: (ChannelRequest | TouchpointRequest | LocationRequest)[],
  requestTypename: "ChannelRequest" | "TouchpointRequest" | "LocationRequest",
): T[] => {
  const topologies: (
    | FrontendChannelTopology
    | FrontendLocationTopology
    | FrontendTouchpointTopology
  )[] = [
    ...requests
      .filter((req) => req.__typename === requestTypename)
      .map((req) => {
        let genericTopology = {
          id: req.id,
          name: `${req.name}`,
          category: req.category,
          intro_fragment: "I attend",
          tags: [],
          isCustom: true,
        };
        switch (requestTypename) {
          case "ChannelRequest":
            return {
              ...genericTopology,
              touchpoints: [],
              __typename: "ChannelTopology",
            } as FrontendChannelTopology;
          case "TouchpointRequest":
            return {
              ...genericTopology,
              locations: [],
              __typename: "TouchpointTopology",
            } as FrontendTouchpointTopology;
          case "LocationRequest":
            return {
              ...genericTopology,
              locations: [],
              __typename: "LocationTopology",
            } as FrontendLocationTopology;
        }
      }),
  ];
  return topologies as T[];
};
