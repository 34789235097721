import { JourneyOutcome } from "@/utils/apollo/resolvers";
import {
  FrontendChannel,
  FrontendLocation,
  FrontendTouchpoint,
} from "@/utils/types";
import React from "react";
import { TransitionText } from "./TransitionText";
import TextJourneyCard from "./TextJourneyCard";
import { TextJourneyReaction } from "./TextJourneyReaction";
import { TextJourneyAttachment } from "./TextJourneyAttachment";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@motius/customer-heartbeat-ui";
import { TextJourneyTitle } from "./TextJourneyTitle";
import { VisitCountedData } from "./useVisitCountedChannels";
import { IxTypography } from "@siemens/ix-react";
import { RemoveLastButton } from "./RemoveLastButton";

interface Props {
  ch: FrontendChannel;
  outcome: JourneyOutcome | null | undefined;
  last?: boolean;
  visitOrder?: VisitCountedData["visitOrder"];
  editable: boolean;
}

/**
 *
 * This renders a channel and its touchpoints/locations
 * in the Text journey
 * @param props - the channel and outcome
 * @param props.ch - the channel
 * @param props.outcome - the outcome
 * @param props.visitOrder - the visit order of the touchpoints
 * @param props.editable - whether the channel is editable
 * @param props.last - whether the channel is the last channel
 * @param props.onRemoveLast - the function to remove the last touchpoint
 */
const Channel: React.FC<Props> = ({
  ch,
  visitOrder,
  editable,
  last,
  outcome,
}) => {
  return (
    <Accordion type="single" collapsible defaultValue="item-1">
      <AccordionItem value="item-1">
        <AccordionTrigger
          leftIcon
          rightToDown
          className={cn([
            "[&[data-state=closed]]:bg-theme-color-2",
            "[&[data-state=closed]]:hover:bg-theme-color-component-1--hover",
            "[&[data-state=open]]:bg-theme-color-2",
            "[&[data-state=open]]:hover:bg-theme-color-component-1--hover",
            "rounded",
            "mb-[0.75rem]",
            "[&>div]:px-2",
            "py-0",
          ])}
          style={{
            color: "var(--theme-color-primary)",
          }}
          caretCollapsedColor="var(--theme-color-primary)"
          caretExpandedColor="var(--theme-color-dynamic)"
          containerClass="h-12"
        >
          <span className="block text-left leading-6">
            {ch.prevTransition ? (
              <TransitionText
                transition={ch.prevTransition}
                intro_fragment={ch.intro_fragment ?? ""}
                hasAndPrefix={false}
              />
            ) : (
              "I enter"
            )}
            &nbsp;
            <span className="font-bold">
              {ch.name}
              {ch.isCustom && (
                <span className="font-sans text-theme-color-soft-text">
                  {` (Custom Channel)`}
                </span>
              )}
            </span>
          </span>
        </AccordionTrigger>
        <AccordionContent>
          <div>
            {ch.touchpoints.map((tp: FrontendTouchpoint, i: number) => (
              <TextJourneyCard
                key={tp.fid}
                isLastCardInList={i === ch.touchpoints?.length - 1}
              >
                <div className="leading-6 mb-[0.75rem]">
                  {tp.question ? (
                    `search for ${tp.question.question}`
                  ) : (
                    <div className="flex flex-col gap-1">
                      {visitOrder?.[tp.fid] && (
                        <IxTypography format="label-xs" bold color="soft">
                          Visit {visitOrder[tp.fid].order}
                        </IxTypography>
                      )}
                      <div className="flex justify-between">
                        <div>
                          <TransitionText
                            transition={tp.prevTransition}
                            intro_fragment={`${tp.intro_fragment ?? ""} `}
                            hasAndPrefix={false}
                          />

                          <span className="font-bold">
                            {tp.name}
                            {tp.isCustom && (
                              <span className="font-sans text-theme-color-soft-text">
                                {` (Custom Touchpoint)`}
                              </span>
                            )}
                          </span>
                        </div>
                        <RemoveLastButton
                          type="touchpoint"
                          show={
                            editable &&
                            last &&
                            outcome == null &&
                            i === ch.touchpoints.length - 1 &&
                            tp.locations.length === 0
                          }
                        />
                      </div>
                    </div>
                  )}
                  <TextJourneyReaction
                    reaction={tp.reaction}
                    fid={tp.fid}
                    editable={editable}
                  />
                  <TextJourneyTitle
                    title={tp.title}
                    fid={tp.fid}
                    editable={editable}
                  />

                  {tp.attachments.map((attachment) => (
                    <TextJourneyAttachment
                      fid={tp.fid}
                      attachment={attachment}
                      key={`textJourneyAttachment-${attachment.id}`}
                      editable={editable}
                    />
                  ))}
                </div>
                {tp.locations.map((loc, locIndex) => (
                  <div
                    key={`${tp.id}-${loc.name}`}
                    className="mb-[0.75rem] pl-2 leading-6"
                  >
                    <div className="flex flex-col gap-1">
                      {visitOrder?.[(loc as FrontendLocation).fid] && (
                        <IxTypography format="label-xs" bold color="soft">
                          Visit{" "}
                          {visitOrder[(loc as FrontendLocation).fid].order}
                        </IxTypography>
                      )}
                      <div className="flex justify-between">
                        <div>
                          {loc.intro_fragment ? `${loc.intro_fragment} ` : ""}
                          <span className="font-bold">
                            {loc.name}
                            {(loc as FrontendLocation).isCustom && (
                              <span className="font-sans text-theme-color-soft-text">
                                {` (Custom Interaction)`}
                              </span>
                            )}
                          </span>
                          {loc?.question?.question && (
                            <>
                              {" "}
                              and I <span className="font-bold">
                                search
                              </span>{" "}
                              for{" "}
                              <span className="font-bold">{`"${loc.question.answer ?? "..."}"`}</span>
                            </>
                          )}
                        </div>
                        <RemoveLastButton
                          type="interaction"
                          show={
                            editable &&
                            last &&
                            outcome == null &&
                            i === ch.touchpoints.length - 1 &&
                            locIndex === tp.locations.length - 1
                          }
                        />
                      </div>
                    </div>
                    <TextJourneyReaction
                      reaction={loc.reaction}
                      fid={(loc as FrontendLocation).fid}
                      editable={editable}
                    />
                    <TextJourneyTitle
                      title={loc.title}
                      fid={(loc as FrontendLocation).fid}
                      editable={editable}
                    />
                    {loc.attachments.map((attachment) => (
                      <TextJourneyAttachment
                        fid={(loc as FrontendLocation).fid}
                        attachment={attachment}
                        key={`textJourneyAttachment-${attachment.id}`}
                        editable={editable}
                      />
                    ))}
                  </div>
                ))}
              </TextJourneyCard>
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default Channel;
