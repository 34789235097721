import { gql, useQuery } from "@apollo/client";
import { useState, useEffect } from "react";

const SEARCH_USERS = gql`
  query SearchUsers($filter: UserFilter!) {
    users(filter: $filter) {
      gid
      name
    }
  }
`;

export interface User {
  gid: string;
  name: string;
}

export const useUserSearch = (searchTerm: string) => {
  const [users, setUsers] = useState<User[]>([]);

  const { data, loading, error } = useQuery(SEARCH_USERS, {
    variables: {
      filter: {
        name: {
          containsSubstring: searchTerm,
        },
      },
    },
    skip: searchTerm.length < 3,
  });

  useEffect(() => {
    if (data && data.users) {
      setUsers(data.users);
    }
  }, [data]);

  return { users, loading, error };
};
