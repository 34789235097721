import {
  ExperienceDriver,
  ExperienceDriverRating,
} from "@/utils/apollo/resolvers";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { IxTooltip } from "@siemens/ix-react";
import React, { useRef } from "react";

export const thumbsUp = (className: any) => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 4.99992H8.03704L8.58404 3.03072C8.96068 1.67481 7.94098 0.333252 6.53373 0.333252C5.6178 0.333252 4.80463 0.919349 4.51499 1.78828L3.44444 4.99992H0.333333V12.3333H11.6667L13 4.99992ZM4.33333 6.33325H4.40546L5.7799 2.20991C5.88806 1.88544 6.19171 1.66659 6.53373 1.66659C7.05922 1.66659 7.43999 2.16754 7.29935 2.67386L6.28285 6.33325H11.4024L10.5539 10.9999H4.33333V6.33325ZM3 6.33325H1.66667V10.9999H3V6.33325Z"
    />
  </svg>
);

export const thumbsDown = (className: any) => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.33333 10.6668L6.2963 10.6668L5.7493 12.636C5.37265 13.9919 6.39235 15.3335 7.7996 15.3335C8.71553 15.3335 9.5287 14.7474 9.81834 13.8785L10.8889 10.6668L14 10.6668L14 3.33349L2.66667 3.33349L1.33333 10.6668ZM10 9.33349L9.92788 9.33349L8.55343 13.4568C8.44527 13.7813 8.14163 14.0002 7.7996 14.0002C7.27411 14.0002 6.89334 13.4992 7.03399 12.9929L8.05049 9.33349L2.93095 9.33349L3.77944 4.66683L10 4.66683L10 9.33349ZM11.3333 9.33349L12.6667 9.3335L12.6667 4.66683L11.3333 4.66683L11.3333 9.33349Z"
    />
  </svg>
);

interface ExperienceDriverCardProps {
  onUp: (driver: ExperienceDriver) => void;
  onDown: (driver: ExperienceDriver) => void;
  driver: ExperienceDriver;
  currentDrivers: ExperienceDriver[];
  tooltip: string;
}

export const ExperienceDriverCard: React.FC<ExperienceDriverCardProps> = ({
  onUp,
  onDown,
  driver,
  currentDrivers,
  tooltip,
}) => {
  const ref = useRef<HTMLParagraphElement>(null);
  const labelReplaceList: Record<string, string> = {
    // can be used like shown below to fix typos
    //Proactivety: "Proactivity",
  };

  /**
   *  Get Label
   * @param label Label
   */
  const getLabel = (label: string): string => {
    return labelReplaceList[label] ?? label;
  };

  const isGood = driver.rating === ExperienceDriverRating.Good;
  const isBad = driver.rating === ExperienceDriverRating.Bad;
  const isDisabled = currentDrivers.length >= 3 && driver.rating === undefined;

  const textColor = (isActive: boolean) =>
    cn({
      "!fill-theme-btn-outline-secondary--color--disabled": isDisabled,
      "!fill-white": isActive,
      "fill-[#00002899]": !isActive,
    });

  const sharedButtonCss = cn(
    "!border-theme-color-weak-bdr",
    "border-theme-btn--border-thickness",
    "min-w-7 min-h-7 flex justify-center items-center relative",
  );

  return (
    <div
      className={cn("rounded bg-theme-color-ghost--hover px-2", {
        "hover:bg-theme-color-component-1--hover": !isDisabled,
      })}
      onMouseEnter={() => {
        ref.current?.focus();
      }}
      onMouseLeave={() => {
        ref.current?.blur();
      }}
    >
      <div className="flex h-full items-center justify-between font-bold">
        <p
          tabIndex={0}
          className={cn("outline-none", {
            "!text-theme-btn-outline-secondary--color--disabled": isDisabled,
          })}
          id={`experience-driver-${driver.id}`}
          aria-describedby={`experience-driver-tooltip-${driver.id}`}
          ref={ref}
        >
          {getLabel(driver.name)}
        </p>
        <div className="flex">
          <button
            type="button"
            disabled={isDisabled}
            className={cn(sharedButtonCss, {
              "bg-theme-color-ghost hover:bg-theme-color-ghost--hover active:bg-theme-color-ghost--active":
                !isGood && !isDisabled,
              "bg-theme-color-success hover:bg-theme-color-success--hover active:bg-theme-color-success--active":
                isGood && !isDisabled,
            })}
            onClick={() => onUp(driver)}
          >
            {thumbsUp(textColor(isGood))}
          </button>
          <button
            disabled={isDisabled}
            type="button"
            className={cn(sharedButtonCss, "border-l-0", {
              "bg-theme-color-ghost hover:bg-theme-color-ghost--hover active:bg-theme-color-ghost--active":
                !isBad && !isDisabled,
              "bg-theme-color-alarm hover:bg-theme-color-alarm--hover active:bg-theme-color-alarm--active":
                isBad && !isDisabled,
            })}
            onClick={() => onDown(driver)}
          >
            {thumbsDown(textColor(isBad))}
          </button>
        </div>
      </div>
      <IxTooltip
        id={`experience-driver-tooltip-${driver.id}`}
        for={`#experience-driver-${driver.id}`}
        placement="bottom"
        interactive
      >
        {tooltip}
      </IxTooltip>
    </div>
  );
};
