import { Journey } from "@/utils/apollo/resolvers";
import React from "react";
import { DraftJourneys, DraftJourneysProps } from "./DraftJourneys";
import { JourneyCreationMotivation } from "./NoJourney";

interface DraftJourneysContainerProps extends DraftJourneysProps {
  journeys: Journey[];
}

export const DraftJourneysContainer: React.FC<DraftJourneysContainerProps> = ({
  journeys,
  onDelete,
}) => {
  if (journeys.length === 0) {
    return <JourneyCreationMotivation numberOfJourneys={0} />;
  }
  return <DraftJourneys journeys={journeys} onDelete={onDelete} />;
};
