import { useEffect } from "react";
import { gql, useLazyQuery } from "@apollo/client";
import { cloneDeep } from "lodash";
import { JourneyTopology } from "@/utils/apollo/resolvers";
import {
  FrontendChannelTopology,
  FrontendTouchpointTopology,
} from "@/utils/types";
import { GET_TOUCHPOINTS } from "../TextJourney/api";
import debug from "debug";
import { Maybe } from "@motius/customer-heartbeat-utils";
import { useTopologyStore } from "@/src/hooks/useTopologyStore/useTopologyStore";
import { GET_CHANNEL_TOPO } from "@/utils/apollo/queries";

const log = debug("useJourneyTopology:topology");

const GET_TOPOLOGY = gql`
  query JourneyTopology($id: ID!) {
    journeyTopology(id: $id) {
      channels {
        name
        id
        category
        intro_fragment
        name
      }
    }
  }
`;

/**
 *
 * We load the topology from the backend while the user is
 * navigating channels/touchpoints etc.
 *
 *
 * The results of queries loading parts of the topology will be
 * cached by apollo but all these parts will be frozen with Object.freeze
 * This is why we need to deep clone it
 */
function useTopology(journeyTopologyId?: Maybe<string>) {
  // TODO: Replace this with Apollo persistent cache later
  /**
   * Gets all the channels, touchpoints and locations that are in the local storage and memory
   */
  const {
    channelTopologies: currentChannelTopologies,
    touchpointTopologies: currentTouchpointTopologies,
    locationTopologies: currentLocationTopologies,
    journeyTopology: currentJourneyTopology,
    savedTopologies,
    topologyId: currentTopologyId,
    saveTopology,
    switchTopology,
  } = useTopologyStore();

  const [getTpTopos, { loading: touchpointsLoading, error: touchpointsError }] =
    useLazyQuery<{
      touchpointTopologies: FrontendTouchpointTopology[];
    }>(GET_TOUCHPOINTS);

  const [loadChannelTopo, { loading: channelLoading, error: channelError }] =
    useLazyQuery<{
      channelTopology: FrontendChannelTopology;
    }>(GET_CHANNEL_TOPO);

  // load the root of the topology (all channels)
  const [getChannelTopos, { loading: channelsLoading, error }] = useLazyQuery<{
    journeyTopology: JourneyTopology;
  }>(GET_TOPOLOGY, {});

  const getAndSetTopology = async (topologyId: string) => {
    log("useEffect triggered: application opened id:", topologyId);

    const { data: topologyData } = await getChannelTopos({
      variables: {
        id: topologyId,
      },
    });

    log("Journey topology set:", topologyData);
    if (!topologyData?.journeyTopology) {
      throw new Error("No topology data found");
    }

    const { data: channelData } = await loadChannelTopo({
      variables: {
        id: topologyData?.journeyTopology.channels[0].id,
      },
    });

    if (!channelData?.channelTopology) {
      throw new Error("No channel data found");
    }

    const touchpoint = await getTpTopos({
      variables: {
        ids: [channelData.channelTopology.touchpoints[0].id],
      },
    });

    // TODO: Check if this is still necessary
    const clonedTopology = cloneDeep(topologyData?.journeyTopology);
    if (clonedTopology?.__typename) delete clonedTopology.__typename;

    saveTopology(
      topologyId,
      clonedTopology,
      channelData.channelTopology.touchpoints ?? [],
      touchpoint.data?.touchpointTopologies[0].locations ?? [],
    );
  };

  let returnData = {
    journeyTopology: currentJourneyTopology,
    channels: currentChannelTopologies,
    touchpoints: currentTouchpointTopologies,
    locations: currentLocationTopologies,
    loading: channelsLoading || channelLoading || touchpointsLoading,
    error: error || channelError || touchpointsError,
  };

  // when the root of the topology is loaded
  // set it. This contains just the top level data
  // of all channels
  useEffect(() => {
    const topologyIdToCheck =
      journeyTopologyId ?? import.meta.env.VITE_TOPOLOGY_ID;

    if (topologyIdToCheck && topologyIdToCheck !== currentTopologyId) {
      if (savedTopologies[topologyIdToCheck]) {
        log("Old topology found");
        switchTopology(topologyIdToCheck);
      } else {
        getAndSetTopology(topologyIdToCheck);
      }
    }
  }, []);

  return returnData;
}

export default useTopology;
