import { IxTooltip, IxTypography } from "@siemens/ix-react";
import React from "react";
import { HoveredMetaData } from "../drawTouchpoint";

interface TitleTooltipProps {
  data?: HoveredMetaData;
}

/**
 *  Tooltip for the title of the meta data
 * @param props - TitleTooltipProps
 * @param props.data - HoveredMetaData
 */
export const TitleTooltip: React.FC<TitleTooltipProps> = ({ data }) => {
  return (
    <IxTooltip
      className="theme-brand-dark text-white"
      id="meta-data-title-tooltip"
      for="#meta-data-title-tooltip-anchor"
      placement="top"
      interactive
    >
      <div className="flex flex-col">
        <IxTypography format="body" bold>
          Title
        </IxTypography>
        <IxTypography format="body">{data?.node.title}</IxTypography>
      </div>
    </IxTooltip>
  );
};
