import React from "react";
import {
  RequestMissingButton,
  RequestMissingButtonProps,
} from "./RequestMissingButton";

interface RequestMissingLocationButtonProps
  extends Omit<RequestMissingButtonProps, "type"> {}

/**
 *  Request missing location button
 * @param {RequestMissingLocationButtonProps} props - The props
 */
export const RequestMissingLocationButton: React.FC<
  RequestMissingLocationButtonProps
> = ({ groups, journeyId }) => {
  return (
    <RequestMissingButton
      groups={groups}
      journeyId={journeyId}
      type="Interaction"
    ></RequestMissingButton>
  );
};
