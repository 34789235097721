import { IxAvatar, IxDropdownItem } from "@siemens/ix-react";
import { useUserDetails } from "@motius/customer-heartbeat-utils/auth";
import { MouseEventHandler, useLayoutEffect, useRef } from "react";
import { useMsal } from "@azure/msal-react";
import { querySelectorAllShadows } from "../../utils";

/**
 *  Profile Icon
 */
export const ProfileIcon = () => {
  const { initials, username } = useUserDetails();
  const { instance } = useMsal();
  const containerRef = useRef<HTMLDivElement>(null);
  /**
   * This redirects to the Microsoft Identity Platform to log out the user
   * After that it redirects the user to the '/' page which will render the login page
   */
  const logout: MouseEventHandler = () => {
    instance.logout();
  };

  useLayoutEffect(() => {
    if (!containerRef.current) return;
    const userInfo = querySelectorAllShadows(
      ".user-info",
      containerRef.current,
    );
    userInfo.forEach((node) => {
      const userInfoNode = node as HTMLElement;
      userInfoNode.style.setProperty("min-width", "initial");
      userInfoNode.style.setProperty("width", "auto");
      userInfoNode.style.setProperty("max-width", "16rem");
    });
    const user = querySelectorAllShadows(".user", containerRef.current);
    user.forEach((node) => {
      const userNode = node as HTMLElement;
      userNode.style.setProperty("min-width", "initial");
      userNode.style.setProperty("width", "auto");
      userNode.style.setProperty("max-width", "14rem");
    });
  }, [containerRef.current]);

  return (
    <div ref={containerRef}>
      <IxAvatar initials={initials} username={username} data-testid="initials">
        <IxDropdownItem
          label="Log out"
          icon="Log out"
          onClick={logout}
          style={{ width: "auto", maxWidth: "20rem", minWidth: "initial" }}
        />
      </IxAvatar>
    </div>
  );
};
