import { useJourney } from "@/src/hooks/useJourney";
import React from "react";
import { CompleteDetailsDialogContainer } from "../CompleteDetails/CompleteDetailsDialogContainer";
import { EditCustomerDialogContainer } from "../CompleteDetails/EditCustomer/EditCustomerDialogContainer";
import Wizard from "../Wizard";
import { VisualJourneyMap } from "../Map/Map";
import { useParams } from "react-router-dom";
import { TopBar } from "../Journeys/TopBar/TopBar";
import { PageWithTopBar } from "@motius/customer-heartbeat-ui";
import { useSpinner } from "@/src/hooks/useSpinner";

interface EditJourneyPageProps {}

/**
 *  Edit Journey Page
 * @param props Props
 * @param props.params Params
 * @param props.params.id Id of the journey
 */
export const EditJourney: React.FC<EditJourneyPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, error } = useJourney(id, true);

  useSpinner(loading);

  if (error) return <div>Error! {error.message}</div>;

  return (
    <PageWithTopBar>
      <TopBar />
      <div className="relative flex flex-1 overflow-hidden">
        <Wizard />
        <VisualJourneyMap editable={true} />
      </div>
      <CompleteDetailsDialogContainer />
      <EditCustomerDialogContainer />
    </PageWithTopBar>
  );
};
