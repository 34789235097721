import React, { SVGProps } from "react";
// @ts-expect-error This import works
import Avatar1 from "../../../public/Avatar1.svg?react";
// @ts-expect-error This import works
import Avatar2 from "../../../public/Avatar2.svg?react";
// @ts-expect-error This import works
import Avatar3 from "../../../public/Avatar3.svg?react";
// @ts-expect-error This import works
import Avatar4 from "../../../public/Avatar4.svg?react";
// @ts-expect-error This import works
import Avatar5 from "../../../public/Avatar5.svg?react";
// @ts-expect-error This import works
import Avatar6 from "../../../public/Avatar6.svg?react";
// @ts-expect-error This import works
import Avatar7 from "../../../public/Avatar7.svg?react";
// @ts-expect-error This import works
import Avatar8 from "../../../public/Avatar8.svg?react";
// @ts-expect-error This import works
import Avatar9 from "../../../public/Avatar9.svg?react";
import { AvatarType } from "@motius/customer-heartbeat-utils/schemas";
import { pxToRem } from "@motius/customer-heartbeat-ui/utils";

interface AvatarProps extends SVGProps<SVGSVGElement> {
  type: AvatarType;
  size?: number;
}

export const Avatar: React.FC<AvatarProps> = ({
  type,
  size = 180,
  ...rest
}) => {
  switch (type) {
    case AvatarType.First:
      return <Avatar1 width={pxToRem(size)} height={pxToRem(size)} {...rest} />;
    case AvatarType.Second:
      return <Avatar2 width={pxToRem(size)} height={pxToRem(size)} {...rest} />;
    case AvatarType.Third:
      return <Avatar3 width={pxToRem(size)} height={pxToRem(size)} {...rest} />;
    case AvatarType.Fourth:
      return <Avatar4 width={pxToRem(size)} height={pxToRem(size)} {...rest} />;
    case AvatarType.Fifth:
      return <Avatar5 width={pxToRem(size)} height={pxToRem(size)} {...rest} />;
    case AvatarType.Sixth:
      return <Avatar6 width={pxToRem(size)} height={pxToRem(size)} {...rest} />;
    case AvatarType.Seventh:
      return <Avatar7 width={pxToRem(size)} height={pxToRem(size)} {...rest} />;
    case AvatarType.Eighth:
      return <Avatar8 width={pxToRem(size)} height={pxToRem(size)} {...rest} />;
    case AvatarType.Ninth:
      return <Avatar9 width={pxToRem(size)} height={pxToRem(size)} {...rest} />;
    default:
      return <Avatar1 width={pxToRem(size)} height={pxToRem(size)} {...rest} />;
  }
};
