import { IxTypography } from "@siemens/ix-react";
import React from "react";

export const LocationTooltipContent = () => {
  return (
    <>
      <IxTypography format="body" bold>
        What are Interactions?
      </IxTypography>
      <IxTypography format="body">
        Interactions refer to activities within Touchpoints, such as viewing a
        <span className="font-bold"> specific site section,</span> clicking on a
        <span className="font-bold"> button</span> on a landing page or
        receiving a <span className="font-bold"> sales call</span> after an
        event.
      </IxTypography>
    </>
  );
};
