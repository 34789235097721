import React from "react";
import { useSaveDraft } from "@/src/hooks/useSaveDraft/useSaveDraft";
import { Button } from "@motius/customer-heartbeat-ui";
import { useChannelStore } from "../../hooks/useChannelStore";

interface SaveDraftButtonProps {
  primary?: boolean;
  navigateOnSave?: boolean;
}

export const SaveDraftButton: React.FC<SaveDraftButtonProps> = ({
  primary,
  navigateOnSave,
}) => {
  const { channels } = useChannelStore();
  const { saveDraft, loading } = useSaveDraft(navigateOnSave);

  const hasTp = channels.some((ch) => ch.touchpoints.length > 0);
  if (primary) {
    return (
      <Button disabled={loading || !hasTp} onClick={saveDraft}>
        {loading ? "Storing..." : "Save as Draft"}
      </Button>
    );
  }

  return (
    <Button onClick={saveDraft} disabled={loading} outline>
      {loading ? "Storing..." : "Save as Draft"}
    </Button>
  );
};
