function findInputsInNode(node: Node): HTMLInputElement[] {
  let inputs: HTMLInputElement[] = [];

  // If the node is an input element, add it to the inputs array
  if (
    node.nodeType === Node.ELEMENT_NODE &&
    (node as HTMLElement).tagName.toLowerCase() === "input"
  ) {
    inputs.push(node as HTMLInputElement);
  }

  // Recursively check child nodes
  if (node.nodeType === Node.ELEMENT_NODE) {
    for (let child of Array.from(node.childNodes)) {
      inputs = inputs.concat(findInputsInNode(child));
    }

    // Check shadow DOM if it exists
    const element = node as HTMLElement;
    if (element.shadowRoot) {
      for (let child of Array.from(element.shadowRoot.childNodes)) {
        inputs = inputs.concat(findInputsInNode(child));
      }
    }
  }

  return inputs;
}

export const findInputsInElementWithId = (id: string): HTMLInputElement[] => {
  const element = document.getElementById(id);
  if (!element) {
    console.log(`Element with id "${id}" not found.`);
    return [];
  }
  return findInputsInNode(element);
};
