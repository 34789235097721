export function getLocalStorageSizeInMB(key: string) {
  if (typeof key !== "string") {
    throw new Error("Key must be a string.");
  }

  const data = localStorage.getItem(key);

  if (data == null) {
    return 0;
  }

  const sizeInBytes = new Blob([data]).size;
  const sizeInMB = sizeInBytes / (1024 * 1024);
  return sizeInMB;
}
