import React from "react";
import { MetaDataSubMenu } from "./MetaDataSubMenu";

export const LinkSubMenu = () => {
  return (
    <MetaDataSubMenu label="Link" onConfirm={() => {}} onCancel={() => {}}>
      <input placeholder="https://" type="url" className="w-[12.5rem] px-2" />
    </MetaDataSubMenu>
  );
};
