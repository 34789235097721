import { notify } from "@motius/customer-heartbeat-ui";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { showMessage } from "@siemens/ix";
import { IxIcon, IxIconButton } from "@siemens/ix-react";
import React, { PropsWithChildren } from "react";

interface TextJourneyMetaDataProps extends PropsWithChildren {
  icon: React.ReactNode;
  onDelete: () => void;
  truncate?: boolean;
  type: "title" | "attachment" | "reaction";
  editable: boolean;
}

export const TextJourneyMetaData: React.FC<TextJourneyMetaDataProps> = ({
  icon,
  onDelete,
  children,
  truncate,
  type,
  editable,
}) => {
  /**
   *  Handles the delete button click
   * Shows a warning dialog before deleteing
   */
  const handleDelete = async () => {
    (
      await showMessage({
        icon: "",
        messageTitle: `Do you really want to delete your ${type}?`,
        message: "Your changes will be lost if you proceed.",
        actions: [
          { id: "cancel", type: "cancel", text: "Cancel" },
          { id: "proceed", type: "okay", text: "Proceed" },
        ],
      })
    ).once((result) => {
      if (result?.actionId == null) return;
      if (result?.actionId === "proceed") {
        // if the result has a payload then save was clicked
        onDelete();
        notify.success(`Your ${type} has been deleted.`);
      }
    });
  };

  return (
    <div className="my-3 flex pl-2 leading-5">
      <div className="flex h-6 w-6 shrink-0 items-center justify-center">
        <IxIcon name="arrow-down-right" size="16" color="color-weak-text" />
      </div>
      <div className="mr-1 flex h-6 w-6 shrink-0 items-center justify-center">
        {icon}
      </div>
      <span
        className={cn("grow leading-5 mt-[0.125rem]", { truncate: truncate })}
      >
        {children}
      </span>
      {editable && (
        <IxIconButton
          className="ml-2 shrink-0"
          icon="trashcan"
          size="16"
          ghost
          onClick={handleDelete}
        />
      )}
    </div>
  );
};
