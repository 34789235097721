import React, { forwardRef, ReactNode } from "react";
import { IxButton } from "@siemens/ix-react";

/**
 *  ButtonProps
 */
export interface ButtonProps {
  children: ReactNode;
  variant?: "primary" | "secondary";
  color?: "normal" | "inverted";
  className?: string;
  outline?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "submit";
  icon?: string;
}

/**
 *  Generic button
 * @param props Props
 * @param props.children Children
 * @param props.variant Variant of the button
 * @param props.className Additional custom classes
 * @param props.onClick On click function
 * @param props.color Color variant of the button
 */
export const Button = forwardRef<HTMLIxButtonElement, ButtonProps>(
  (
    { children, variant = "primary", onClick = () => {}, disabled, ...rest },
    ref,
  ) => {
    return (
      <IxButton
        data-testid="button"
        onClick={onClick}
        variant={variant}
        disabled={disabled}
        ref={ref}
        {...rest}
      >
        {children}
      </IxButton>
    );
  },
);

Button.displayName = "Button";
