import { useCallback } from "react";
import { useChannelStore } from "../../hooks/useChannelStore";
import { useMutation } from "@apollo/client";
import { STORE_JOURNEY } from "@/utils/apollo/queries";
import { Journey } from "@/utils/apollo/resolvers";
import { removeTypename } from "@/utils/apollo";
import { getChannelInput } from "@/src/components/TextJourney/TopologyUtils";
import { checkIfSaveBlockedShowReason } from "./utils";
import { useNavigate } from "react-router-dom";
import { imageCache } from "@/src/components/Map/MetaDataMenu/MetaDataSubMenus/upload";
import { handleError } from "@/utils/error";
import { notify } from "@motius/customer-heartbeat-ui";

export const useSaveDraft = (navigateOnSave = false) => {
  const {
    journeyId,
    journeyTopologyId,
    channels,
    outcome,
    updateJourneyIds,
    industry,
    customerType,
    customerRole,
    region,
    buyerType,
    priorContact,
    motivation,
    painpoints,
    experienceDrivers,
    avatarRef,
    description,
    expectedNextSelection,
  } = useChannelStore();
  const [storeJourney, { loading }] = useMutation(STORE_JOURNEY);
  const navigate = useNavigate();

  const save = useCallback(async () => {
    if (journeyId) {
      const channelInputs = channels.map((ch) => getChannelInput(ch));
      try {
        const result = (
          await storeJourney(
            removeTypename({
              variables: {
                journey: {
                  channels: channelInputs,
                  id: journeyId,
                  avatarRef,
                  customerRole,
                  motivation,
                  customerType,
                  description,
                  industry,
                  outcome,
                  region,
                  buyerType,
                  priorContact,
                  painpoints,
                  experienceDrivers,
                  topologyId:
                    journeyTopologyId || import.meta.env.VITE_TOPOLOGY_ID,
                },
              },
            }),
          )
        ).data?.upsertJourney;
        updateJourneyIds(result as Partial<Journey>);
        imageCache.clear();
      } catch (e) {
        console.error(e);
        handleError(e, "Saving journey has failed");
      }
    } else {
      throw new Error("journeyId not set yet");
    }
  }, [
    journeyId,
    channels,
    storeJourney,
    avatarRef,
    customerRole,
    motivation,
    customerType,
    description,
    industry,
    outcome,
    region,
    buyerType,
    priorContact,
    painpoints,
    experienceDrivers,
    updateJourneyIds,
    journeyTopologyId,
  ]);

  const saveDraft = async () => {
    if (checkIfSaveBlockedShowReason(expectedNextSelection, channels)) {
      return;
    }
    await save();
    notify.success("Your journey is saved as Draft.");
    if (navigateOnSave) {
      navigate("/journeys");
    }
  };

  return { save, saveDraft, loading };
};
