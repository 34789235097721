import * as Sentry from "@sentry/react";
import { notify } from "@motius/customer-heartbeat-ui";

const FLUSH_TIMEOUT = 1000;

/**
 *  Handle error and notify user with message
 * @param e Error
 * @param message Message
 * @param hideAllClose Hide all close buttons on active toasts
 */
export const handleError = (
  e: unknown,
  message: string,
  hideAllClose?: boolean,
) => {
  notify.error(message, hideAllClose);
  Sentry.captureException(e);
  Sentry.flush(FLUSH_TIMEOUT);
};

/**
 * Handle error and notify user with message
 * @param message The error message to display to the user
 */
export const handleErrorMessage = (message: string, hideAllClose?: boolean) => {
  notify.error(message, hideAllClose);
  Sentry.captureMessage(message);
  Sentry.flush(FLUSH_TIMEOUT);
};
