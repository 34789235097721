import React, { useMemo } from "react";
import { Select } from "@motius/customer-heartbeat-ui";
import { usePatchIx } from "./usePatchIx";
import { getFilters, handleFilterChange } from "./filterUtils";
import { FrontendJourneyFilter } from "./Search";

type TopologyItem = {
  id: string;
  name: string;
  category?: string;
};

type NodeSelectProps = {
  label: string;
  id: string;
  items: TopologyItem[];
  filterKey: keyof FrontendJourneyFilter;
  localFilters: FrontendJourneyFilter;
  setLocalFilters: React.Dispatch<React.SetStateAction<FrontendJourneyFilter>>;
};

type SelectOption = {
  value: string;
  label: string;
  disabled?: boolean;
};

export const NodeSelect: React.FC<NodeSelectProps> = ({
  label,
  id,
  items,
  filterKey,
  localFilters,
  setLocalFilters,
}) => {
  const options = useMemo(() => groupOptions(items), [items]);

  usePatchIx(id, "button", patchSelectButtons(options));

  return (
    <Select
      mode="multiple"
      label={label}
      id={id}
      options={options}
      allowClear={false}
      value={getFilters(filterKey, localFilters)}
      onChange={(values: string | string[]) => {
        setLocalFilters(
          handleFilterChange(filterKey, values as string[], localFilters),
        );
      }}
    />
  );
};

const groupOptions = (items: TopologyItem[]): SelectOption[] => {
  const grouped = items.reduce(
    (acc, item) => {
      const category = item.category ?? "";
      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(item);
      return acc;
    },
    {} as Record<string, TopologyItem[]>,
  );

  return Object.entries(grouped)
    .sort(([a], [b]) => a.localeCompare(b)) // Sort groups alphabetically
    .flatMap(([category, itemsInCategory]) => [
      { value: category, label: category, disabled: true },
      ...itemsInCategory
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((item) => ({ value: item.id, label: item.name })),
    ]);
};

const patchSelectButtons = (options: SelectOption[]) => (element: Element) => {
  const button = element as HTMLButtonElement;
  if (
    options.some(
      (option) => option.disabled && option.label === button.textContent,
    )
  ) {
    button.style.color = "var(--theme-color-soft-text)";
    button.style.cursor = "default";
    button.disabled = true;
    button.style.borderTop = "1px solid var(--theme-color-component-1)";
  }
};
