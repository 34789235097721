import React, { useRef } from "react";
import {
  ExpectedNextSelection,
  useChannelStore,
} from "../../hooks/useChannelStore";
import ActionHeadline from "./ActionHeadline";
import { TransitionType } from "@/utils/apollo/resolvers";
import Section from "./Section";
import { NavigationButton } from "./NavigationButton";
import { useReturnToTouchpoints } from "./useReturnToTouchpoints";
import { IxButton, IxTypography, ModalRef, showModal } from "@siemens/ix-react";
import { EndJourneyDialog } from "./EndJourneyDialog";
import { ChannelTooltipContent } from "./Tooltips/ChannelTooltipContent";
import { TouchpointTooltipContent } from "./Tooltips/TouchpointTooltipContent";
import { LocationTooltipContent } from "./Tooltips/LocationTooltipContent";

type Props = {
  setLocationIntroFragment: (fragment: LocationFragment) => void;
};

/**
 * Location fragments are the actions that can be taken on a location.
 * For example: "I read..."
 */
export type LocationFragment = {
  icon: string;
  text: string;
};
const locationFragments: LocationFragment[] = [
  { icon: "eye", text: "View" },
  { icon: "user-reading", text: "Read" },
  { icon: "mouse-click", text: "Engage with" },
  { icon: "pen", text: "Fill out" },
  { icon: "upload", text: "Submit" },
  { icon: "download", text: "Download" },
  { icon: "location-outline", text: "Attend" },
  { icon: "inquiry-mail", text: "Request" },
];

const NavigationSelectAction: React.FC<Props> = ({
  setLocationIntroFragment,
}) => {
  const { setExpectedNextSelection, channels } = useChannelStore();
  const returnToTouchpoints = useReturnToTouchpoints();
  const isLastElementChannel =
    channels.length > 0 && channels.at(-1)?.touchpoints?.length === 0;

  let touchpointNavigation = ExpectedNextSelection.Touchpoint;

  const selectLocation = (fragment: LocationFragment) => {
    setLocationIntroFragment(fragment);
    setExpectedNextSelection(ExpectedNextSelection.Location);
  };

  const selectTouchpoint = (transition: TransitionType) => () => {
    setExpectedNextSelection(touchpointNavigation, transition);
  };

  const modalRef = useRef<ModalRef>(null);

  const openEndJourneyDialog = () => {
    showModal({
      content: (
        <EndJourneyDialog
          ref={modalRef}
          onClose={() => modalRef.current?.close("Close end journey dialog")}
        />
      ),
      size: "840",
      centered: true,
    });
  };

  return (
    <div className="mx-3 flex size-full flex-col">
      <div className="grow">
        <ActionHeadline>
          <div className="text-base">Next Action</div>
          <IxTypography
            format="body-sm"
            bold
            className="mt-2 text-[var(--theme-color-soft-text)]"
          >
            What’s your customer doing next?
          </IxTypography>
        </ActionHeadline>

        <hr className="-mx-4 my-[0.75rem] text-[#e8e8e326] opacity-100" />
        <Section
          hasSeparator={false}
          headline="Channel"
          headlineDescription={<ChannelTooltipContent />}
        >
          <NavigationButton
            icon="open_external"
            onClick={() =>
              setExpectedNextSelection(
                ExpectedNextSelection.Channel,
                TransitionType.Redirect,
              )
            }
            disabled={isLastElementChannel}
          >
            <span>Redirect to...</span>
          </NavigationButton>
          <NavigationButton
            icon="sort"
            onClick={() =>
              setExpectedNextSelection(
                ExpectedNextSelection.Channel,
                TransitionType.Switch,
              )
            }
            disabled={isLastElementChannel}
          >
            <span>Switch to...</span>
          </NavigationButton>
        </Section>

        <Section
          headline="Touchpoint"
          headlineDescription={<TouchpointTooltipContent />}
        >
          <NavigationButton
            icon="arrow-right"
            onClick={selectTouchpoint(TransitionType.Proceed)}
          >
            <span>Proceed to...</span>
          </NavigationButton>
          <NavigationButton
            icon="corner-arrow-up-left"
            onClick={selectTouchpoint(TransitionType.Return)}
            disabled={returnToTouchpoints.length === 0 || isLastElementChannel}
          >
            <span>Return to...</span>
          </NavigationButton>
        </Section>

        <Section
          headline="Interaction"
          headlineDescription={<LocationTooltipContent />}
        >
          {locationFragments.map((fragment) => (
            <NavigationButton
              key={fragment.text}
              icon={fragment.icon}
              onClick={() => selectLocation(fragment)}
              disabled={isLastElementChannel}
            >
              <span>{fragment.text}...</span>
            </NavigationButton>
          ))}
        </Section>
      </div>

      <div className="mb-3 flex justify-end">
        <IxButton
          variant="primary"
          outline
          onClick={openEndJourneyDialog}
          disabled={isLastElementChannel}
        >
          End Journey
        </IxButton>
      </div>
    </div>
  );
};

export default NavigationSelectAction;
