import { useMsal } from "@azure/msal-react";
import { TokenPayload, isDisableAuth } from "../utils";

interface UserDetails {
  initials: string;
  username: string;
  name: string;
  gid: string;
}

const defaultDetails: UserDetails = {
  initials: "JD",
  username: "John Doe",
  name: "Doe, John (ext) (DI IT RH CRM)",
  gid: "1234567890",
};

/**
 *  Get user details from Azure AD
 * @returns initials and username
 */
const useAzureDetails = () => {
  const { accounts } = useMsal();
  if (accounts.length > 0) {
    const account = accounts[0];
    const tokenClaims = account?.idTokenClaims as TokenPayload;
    if (tokenClaims.given_name && tokenClaims.family_name) {
      return {
        initials: `${tokenClaims.given_name[0]}${tokenClaims.family_name[0]}`,
        username: `${tokenClaims.mail}`,
        name: `${tokenClaims.name}`,
        gid: `${tokenClaims.gid}`,
      };
    }
  }
  return defaultDetails;
};

/** shown during local dev */
const useHardcodedDetails = (): UserDetails => {
  return defaultDetails;
};

export const useUserDetails = isDisableAuth()
  ? useHardcodedDetails
  : useAzureDetails;
