import React, { useRef, useState } from "react";
import {
  IxButton,
  IxModalContent,
  IxModalFooter,
  IxModalHeader,
  IxTypography,
  Modal,
  ModalRef,
} from "@siemens/ix-react";
// @ts-expect-error This import works
import EndJourney from "./endJourney.svg?react";

export const PublishSurveyDialog = ({}) => {
  const ref = useRef<ModalRef>(null);

  return (
    <Modal ref={ref}>
      <IxModalHeader>User Survey</IxModalHeader>
      <IxModalContent className="flex flex-col px-2 pt-0 pb-[1.25rem]">
        <IxTypography format="h2" className="font-bold">
          We want to hear your thoughts!
        </IxTypography>
        <div className="my-1.5 flex flex-col items-start gap-1 text-left w-96">
          <IxTypography format="body">
            As a user of Customer Heartbeat, we’d love your feedback. Your input
            will help us improve your experience. Please complete this short
            survey. It will only take 3 minutes, promise!
          </IxTypography>
        </div>

        <div className="mt-28 flex flex-col space-y-2">
          <div className="flex justify-center">
            <EndJourney />
          </div>
        </div>
      </IxModalContent>
      <IxModalFooter>
        <a
          href="https://surveys.hotjar.com/5e212653-4455-4042-a899-e3d6c822c0d2"
          rel="noopener noreferrer"
          target="_blank"
        >
          <IxButton
            type="button"
            onClick={() => ref.current?.close("Survey modal closed")}
          >
            Start Survey
          </IxButton>
        </a>
      </IxModalFooter>
    </Modal>
  );
};
