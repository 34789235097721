import {
  PageWithTopBar,
  ProfileIcon,
  TopBarBase,
} from "@motius/customer-heartbeat-ui";
import { IxButton, IxTypography } from "@siemens/ix-react";
import React from "react";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <PageWithTopBar>
      <TopBarBase>
        <ProfileIcon />
      </TopBarBase>
      <div className="flex flex-1 items-center justify-center gap-4 flex-col p-8">
        <IxTypography
          bold
          className="from-theme-color-primary to-theme-color-dynamic bg-gradient-to-r bg-clip-text text-[15rem] leading-[12rem] text-transparent"
        >
          404
        </IxTypography>
        <div className="flex flex-col gap-2 items-center max-w-[29rem]">
          <IxTypography format="display-xl">Not found</IxTypography>
          <IxTypography className="text-center" format="body-lg">
            Sorry, we’re not able to find that page. It may have been deleted or
            moved.
          </IxTypography>
        </div>
        <Link to="/" className="text-theme-color-primary">
          <IxButton type="button">Go to Home</IxButton>
        </Link>
      </div>
    </PageWithTopBar>
  );
};
