import React, { useState, useEffect } from "react";
import { IxIconButton, IxTypography, IxButton } from "@siemens/ix-react";
import useTopology from "../Navigation/useJourneyTopology";
import { MetadataSelects } from "./MetadataSelects";
import { NodeSelect } from "./NodeSelect";
import { FrontendJourneyFilter } from "./Search";

/**
 * Props for the Filters component.
 * @param handleClose - Function to close the filters.
 */
type Props = {
  handleClose: () => void;
  filters: FrontendJourneyFilter;
  setFilters: (filters: FrontendJourneyFilter) => void;
};

/**
 * Filters component for the Journey Community.
 * @param handleClose.handleClose
 * @param handleClose - Function to close the filters.
 * @param handleClose.filters
 * @param handleClose.setFilters
 * @returns The rendered Filters component.
 */
export const Filters = ({ handleClose, filters, setFilters }: Props) => {
  const [localFilters, setLocalFilters] =
    useState<FrontendJourneyFilter>(filters);
  const [isVisible, setIsVisible] = useState(false);
  const hasLocalFilters = !!localFilters?.OR?.length;
  useEffect(() => {
    setLocalFilters(filters);
  }, [filters]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  /**
   *
   */
  const closeFilters = () => {
    setIsVisible(false);
    setTimeout(handleClose, 300); // Wait for the animation to complete before calling handleClose
  };

  const { channels, touchpoints, locations } = useTopology();

  /**
   *
   */
  const handleApplyFilters = () => {
    setFilters(structuredClone(localFilters));
    closeFilters();
  };

  /**
   *
   */
  const handleResetFilters = () => {
    setLocalFilters({ authorGidFilters: [] });
    setFilters({ authorGidFilters: [] });
  };

  return (
    <div
      className={`absolute right-0 top-0 z-50 h-full w-[30rem] bg-white shadow-lg transition-transform duration-300 ease-in-out ${
        isVisible ? "translate-x-0" : "translate-x-full"
      } flex flex-col`}
      data-testid="filter-panel"
    >
      <div className="flex items-center justify-between p-4">
        <IxTypography
          format="body-lg"
          className="font-bold"
          data-testid="filter-panel-title"
        >
          Filter by
        </IxTypography>
        <IxIconButton
          onClick={closeFilters}
          icon="close"
          ghost
          size="16"
        ></IxIconButton>
      </div>
      <div className="flex-1 overflow-y-auto pb-3">
        <div className="flex flex-col gap-2 px-3">
          <NodeSelect
            label="Channel"
            id="channel-filter"
            items={channels.map((ch) => ({
              ...ch,
              category: ch.category ?? undefined,
            }))}
            filterKey="channelTopologyId"
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
          />
          <NodeSelect
            label="Touchpoint"
            id="touchpoint-filter"
            items={touchpoints.map((tp) => ({
              ...tp,
              category: tp.category ?? undefined,
            }))}
            filterKey="touchpointTopologyId"
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
          />
          <NodeSelect
            label="Interaction"
            id="location-filter"
            items={locations.map((loc) => ({
              ...loc,
              category: loc.category ?? undefined,
            }))}
            filterKey="locationTopologyId"
            localFilters={localFilters}
            setLocalFilters={setLocalFilters}
          />
          <hr className="my-2" />
          <MetadataSelects
            filters={localFilters}
            setLocalFilters={setLocalFilters}
          />
        </div>
      </div>
      <div className="flex justify-end gap-2 border-t p-4">
        <IxButton
          variant="secondary"
          disabled={!hasLocalFilters}
          onClick={handleResetFilters}
        >
          Reset
        </IxButton>
        <IxButton
          variant="primary"
          disabled={!hasLocalFilters}
          onClick={handleApplyFilters}
        >
          Apply filters
        </IxButton>
      </div>
    </div>
  );
};
