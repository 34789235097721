import {
  IntentTopology,
  MotivationTopology,
  Topology,
} from "@motius/customer-heartbeat-utils/schemas";
import { ExperienceDriverTopology } from "../apollo/resolvers";

export type PrepareJourneyTopology = {
  customer_role: Topology[];
  customer_type: Topology[];
  industry: Topology[];
  motivations: MotivationTopology[];
};

export type YourInsightsTopology = PrepareJourneyTopology & {
  regions: Topology[];
  buyer_types: Topology[];
  priorContact: Topology[];
  experience_drivers: ExperienceDriverTopology[];
  painpoints: Topology[];
};

const labelById: { [key: string]: string } = {};
export const getLabelById = (id: string | null | undefined) => {
  if (!id || !labelById[id]) {
    throw new Error(`Label not found for id: ${id}`);
  }
  return labelById[id];
};

const processIntent = (intent: IntentTopology) => {
  labelById[intent.id] = intent.name;
  intent.intents?.forEach(processIntent);
};

const processTopologies = (topologies: Topology[] | MotivationTopology[]) => {
  topologies.forEach((topology) => {
    labelById[topology.id] = topology.name;
    if ("intents" in topology) {
      (topology?.intents as IntentTopology[]).forEach(processIntent);
    }
  });
};

export const processPrepareJourneyTopoData = (
  topoData: PrepareJourneyTopology,
) => {
  processTopologies(topoData.customer_role);
  processTopologies(topoData.customer_type);
  processTopologies(topoData.industry);
  processTopologies(topoData.motivations);
};

export const processYourInsightsTopoData = (topoData: YourInsightsTopology) => {
  processPrepareJourneyTopoData(topoData);
  processTopologies(topoData.regions);
  processTopologies(topoData.priorContact);
  processTopologies(topoData.buyer_types);
  processTopologies(topoData.painpoints);
  processTopologies(topoData.experience_drivers);
};

export * from "./channel";
export * from "./id";
