import React from "react";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { getLabelById } from "@/utils/topology";
import { IntentTopology } from "@motius/customer-heartbeat-utils/schemas";
import { IxIcon } from "@siemens/ix-react";

interface IntentListProps {
  motivationId: string;
  activeIntent: { parents?: string[]; intentId: string };
  onBack: () => void;
  onIntentChange: (intent: {
    parents?: string[];
    intentId: string | undefined;
  }) => void;
  intents: IntentTopology[];
}

const IntentList: React.FC<IntentListProps> = ({
  motivationId,
  activeIntent,
  onBack,
  onIntentChange,
  intents,
}) => {
  const handleBack = () => {
    if (activeIntent && activeIntent.parents) {
      const newParents =
        activeIntent.parents.length > 1
          ? activeIntent.parents.slice(undefined, -1)
          : undefined;
      onIntentChange({
        intentId: undefined,
        parents: newParents,
      });
    } else {
      onIntentChange({ parents: undefined, intentId: undefined });
      onBack();
    }
  };

  const handleIntentClick = (intent: IntentTopology) => {
    if (intent.intents?.length) {
      const newParents =
        activeIntent && activeIntent.parents
          ? [...activeIntent.parents, intent.id]
          : [intent.id];
      onIntentChange({
        parents: newParents,
        intentId: undefined,
      });
    } else {
      onIntentChange({
        ...activeIntent,
        intentId: intent.id,
      });
    }
  };

  return (
    <div
      className="w-full overflow-hidden border border-light-gray"
      id="intent-list"
    >
      <button
        className="flex h-10 w-full cursor-pointer items-center bg-theme-color-2 py-2.5 pr-2 font-bold"
        onClick={handleBack}
        type="button"
      >
        <div className="flex h-full w-9 items-center justify-center">
          <IxIcon
            name="chevron-left-small"
            className="scale-[0.66] text-primary"
          />
        </div>
        <span>{getLabelById(activeIntent?.parents?.[0] || motivationId)}</span>
      </button>
      <div className="max-h-80 overflow-auto">
        {intents.map((intent, index) => {
          const isSelected = activeIntent?.intentId === intent.id;
          return (
            <button
              key={intent.id}
              className={cn(
                "flex items-center pr-2.5  w-full cursor-pointer relative h-10 ",
                "bg-theme-color-ghost hover:!bg-theme-color-ghost--hover",
                "border-t border-theme-color-weak-bdr",
                {
                  "bg-theme-color-secondary--hover hover:!bg-theme-color-ghost--selected text-black":
                    isSelected,
                },
              )}
              type="button"
              onClick={() => handleIntentClick(intent)}
            >
              <div className="flex h-full w-9 items-center justify-center">
                {isSelected && <IxIcon name="success" class="scale-[0.66]" />}
              </div>
              <span>{intent.name}</span>
              {!!intent.intents?.length && (
                <IxIcon
                  name="chevron-right-small"
                  className="absolute right-0 mr-2 scale-75"
                />
              )}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default IntentList;
