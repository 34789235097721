import React, { useEffect } from "react";
import {
  InsightsFormValues,
  insightsFormSchema,
} from "@motius/customer-heartbeat-utils/schemas";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AnyZodObject } from "zod";
// import debug from "debug";
import { Button, Spinner } from "@motius/customer-heartbeat-ui";
import { CompleteDetailsStep, resetRouterDialog } from "@/utils/router";
import { CompleteDetailsCustomerStep } from "../steps/CompleteDetailsCustomerStep";
import { CompleteDetailsPainpointsStep } from "../steps/CompleteDetailsPainpointsStep";
import { CompleteDetailsExperienceDriverStep } from "../steps/CompleteDetailsExperienceDriverStep";
import { CompleteDetailsSuccessStep } from "../steps/CompleteDetailsSuccessStep";
import { useSubmitJourney } from "@/src/hooks/useSubmitJourney";
import { YourInsightsTopology } from "@/utils/topology";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  IxModalContent,
  IxModalHeader,
  Modal,
  ModalRef,
  showModal,
} from "@siemens/ix-react";
import { handleError } from "@/utils/error";
import { CompleteDetailsJourneyTypeStep } from "../steps/CompleteDetailsJourneyTypeStep";
import { PublishSurveyDialog } from "../../Navigation/PublishSurveyDialog";

// const log = debug("CompleteDetailsDialog");

interface CompleteDetailsDialogProps {
  defaultValues: InsightsFormValues;
  topologies: YourInsightsTopology;
  onClose: () => void;
}

export const CompleteDetailsDialog = React.forwardRef<
  ModalRef,
  CompleteDetailsDialogProps
>(({ defaultValues, topologies, onClose }, ref) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  // if (defaultValues) log("defaultValues", defaultValues);

  const validationSchema: { [key: string]: AnyZodObject } = {
    [CompleteDetailsStep.First]: insightsFormSchema.omit({
      experienceDrivers: true,
      painpoints: true,
      journeyType: true,
    }),
    [CompleteDetailsStep.Second]: insightsFormSchema.omit({
      painpoints: true,
      journeyType: true,
    }),
    [CompleteDetailsStep.Third]: insightsFormSchema.omit({
      journeyType: true,
    }),
    [CompleteDetailsStep.Fourth]: insightsFormSchema,
    [CompleteDetailsStep.Fifth]: insightsFormSchema,
  };

  const dialogParam = searchParams.get("dialog") as string;

  // When the component is mounted the step should be the first one
  // After when user changes the dialog param the component is not mounted again so this doesn't effect the behavior
  useEffect(() => {
    if (dialogParam && dialogParam !== CompleteDetailsStep.First) {
      setSearchParams({ dialog: CompleteDetailsStep.First });
    }
  }, []);

  const methods = useForm<InsightsFormValues>({
    resolver: zodResolver(
      validationSchema[dialogParam ?? CompleteDetailsStep.First],
    ),
    defaultValues,
    mode: "all",
  });

  const { submitJourney, loading } = useSubmitJourney(true);

  useEffect(() => {
    methods.reset();
    methods.trigger();
  }, [methods]);

  const handleClose = () => {
    resetRouterDialog(setSearchParams);
    methods.reset();
    onClose();
  };

  const handleFinish = () => {
    methods.reset();
    onClose();
    if (localStorage.getItem("showedSurvey") !== "true") {
      window.setTimeout(() => {
        showModal({
          content: <PublishSurveyDialog />,
          size: "840",
          centered: true,
        });
        localStorage.setItem("showedSurvey", "true");
      }, 2000);
    }
    navigate("/journeys");
  };

  const onSubmit = methods.handleSubmit(async (data: InsightsFormValues) => {
    // log("submit complete your journey", data);
    // store the data in the backend
    try {
      await submitJourney(data);
      handleFinish();
    } catch (e) {
      // log(e);
      console.log("error occurred", e);
      handleError(
        e,
        "An error occurred while processing the journey and storing it",
        true,
      );
    }
  });

  const step = dialogParam ?? "";

  /**
   * Renders the component for the current step.
   */
  const renderStepComponent = () => {
    if (loading) return <Spinner />;
    switch (step) {
      case CompleteDetailsStep.First:
        return (
          <CompleteDetailsCustomerStep
            motivationValues={defaultValues}
            topologies={topologies}
          />
        );
      case CompleteDetailsStep.Second:
        return (
          <CompleteDetailsExperienceDriverStep
            experienceDriverTopologies={topologies.experience_drivers}
          />
        );
      case CompleteDetailsStep.Third:
        return (
          <CompleteDetailsPainpointsStep
            painpointTopologies={topologies.painpoints}
          />
        );
      case CompleteDetailsStep.Fourth:
        return <CompleteDetailsJourneyTypeStep />;
      case CompleteDetailsStep.Fifth:
        return <CompleteDetailsSuccessStep handleClose={() => {}} />;
      default:
        return null;
    }
  };

  return (
    <Modal ref={ref}>
      <div className="flex h-[33rem] flex-col justify-between">
        <div>
          <IxModalHeader onCloseClick={handleClose}>
            Your Journey Summary
          </IxModalHeader>
        </div>
        <IxModalContent className="my-auto flex grow flex-col overflow-hidden px-2 py-0">
          <FormProvider {...methods}>
            <form className="size-full" onSubmit={onSubmit}>
              {renderStepComponent()}
            </form>
          </FormProvider>
        </IxModalContent>
      </div>
    </Modal>
  );
});
