import React, { useState, useMemo, useEffect } from "react";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@motius/customer-heartbeat-ui";
import { IxIcon, IxInputGroup } from "@siemens/ix-react";
import { cn } from "@motius/customer-heartbeat-ui/utils";
// @ts-expect-error This import works
import Search from "./Search.svg?react";

interface AccordionSection {
  title: string;
  items: Array<{
    id: string;
    name: string;
  }>;
}

interface SearchableAccordionListProps {
  sections: AccordionSection[];
  onItemClick: (itemId: string) => void;
  initiallyExpandedTitle?: string;
  action?: React.ReactNode;
  searchPlaceholder: string;
  expandAllByDefault?: boolean;
}

/**
 *
 * The section title should be searched as well.
 * - If a section title matches but none of its items does,
 *   then the section should be collapsed and contain
 *   all items.
 * - If at least one of the items matches, then the section
 *   should be expanded and only show the matching items.
 */

export const SearchableAccordionList: React.FC<
  SearchableAccordionListProps
> = ({
  sections,
  onItemClick,
  initiallyExpandedTitle,
  action,
  searchPlaceholder,
  expandAllByDefault = false,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [expandedSections, setExpandedSections] = useState<string[]>(
    expandAllByDefault
      ? sections.map((section) => section.title)
      : initiallyExpandedTitle
        ? [initiallyExpandedTitle]
        : [],
  );

  const filteredSections = useMemo(() => {
    return sections
      .map((section) => {
        const titleMatches = section.title
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const items = section.items.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase()),
        );
        const itemsToShow = titleMatches ? section.items : items;
        return {
          ...section,
          items: itemsToShow.sort((a, b) => a.name.localeCompare(b.name)),
        };
      })
      .filter(
        (section) =>
          section.items.length > 0 ||
          section.title.toLowerCase().includes(searchTerm.toLowerCase()),
      )
      .sort((a, b) => a.title.localeCompare(b.title));
  }, [sections, searchTerm]);

  useEffect(() => {
    if (searchTerm) {
      const newExpandedSections = sections
        .filter((section) => {
          // Check if any item matches the search term.
          const itemMatches = section.items.some((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()),
          );
          return itemMatches; // Only expand if an item matches.
        })
        .map((section) => section.title);
      setExpandedSections(newExpandedSections);
    } else {
      setExpandedSections(
        expandAllByDefault
          ? sections.map((section) => section.title)
          : initiallyExpandedTitle
            ? [initiallyExpandedTitle]
            : [],
      );
    }
  }, [sections, searchTerm, initiallyExpandedTitle, expandAllByDefault]);

  const handleAccordionChange = (value: string[]) => {
    setExpandedSections(value);
  };

  return (
    <div className="flex grow flex-col overflow-hidden">
      <div className="flex grow flex-col overflow-hidden pl-4">
        <div className="mt-3 pr-4">
          <IxInputGroup>
            <span slot="input-start">
              <Search />
            </span>
            <input
              id="input-string"
              placeholder={searchPlaceholder}
              type="string"
              onChange={(e) => setSearchTerm(e.target.value)}
              value={searchTerm}
            />
            <span slot="input-end">
              <IxIcon
                className="scale-[133.3%] cursor-pointer"
                onClick={() => setSearchTerm("")}
                name="clear"
                size="12"
              />
            </span>
          </IxInputGroup>
          <hr className="mt-3" />
        </div>

        <div className="flex flex-col justify-between overflow-auto pr-4">
          <Accordion
            type="multiple"
            value={expandedSections}
            onValueChange={handleAccordionChange}
          >
            {filteredSections.map((section, index) => (
              <React.Fragment key={section.title}>
                <AccordionItem value={section.title}>
                  <AccordionTrigger
                    leftIcon
                    rightToDown
                    className={cn([
                      "[&[data-state=closed]]:bg-transparent",
                      "[&[data-state=closed]]:hover:bg-theme-color-component-1--hover",
                      "[&[data-state=open]]:bg-theme-color-ghost--hover",
                      "[&[data-state=open]]:hover:bg-theme-color-component-1--hover",
                      "[&[data-state=open]]:font-bold",
                      "pl-[0.2rem]",
                    ])}
                    style={{
                      color: "var(--theme-color-primary)",
                    }}
                    caretCollapsedColor="var(--theme-color-primary)"
                    caretExpandedColor="var(--theme-color-dynamic)"
                    containerClass="w-7"
                    iconSize="16"
                  >
                    <span className="pl-[0.2rem]">{section.title}</span>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="flex flex-col items-start justify-start pl-[0.4em]">
                      {section.items.map((item) => (
                        <button
                          key={item.id}
                          className={cn([
                            "w-full flex items-center cursor-pointer min-h-8 py-[0.5rem]",
                            "whitespace-normal",
                            "hover:bg-[var(--theme-color-ghost-primary--hover)]",
                          ])}
                          onClick={() => onItemClick(item.id)}
                        >
                          <span className="w-7 min-w-7">&nbsp;</span>
                          <span className="flex grow text-left">
                            {item.name}
                          </span>
                        </button>
                      ))}
                    </div>
                  </AccordionContent>
                </AccordionItem>
                {index < filteredSections.length - 1 && <hr />}
              </React.Fragment>
            ))}
          </Accordion>

          {action && <div className="my-3">{action}</div>}
        </div>
      </div>
    </div>
  );
};
