import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Journey } from "@/utils/apollo/resolvers";
import { resetRouterDialog } from "@/utils/router";
import { Spinner, Button } from "@motius/customer-heartbeat-ui";
import {
  EditCustomerFormValues,
  editCustomerFormSchema,
} from "@motius/customer-heartbeat-utils/schemas";
import { useSubmitJourney } from "@/src/hooks/useSubmitJourney";
import { YourInsightsTopology } from "@/utils/topology";
import { useChannelStore } from "@/src/hooks/useChannelStore";
import { CustomerDetailsFormContent } from "../components/CustomerDetailsFormContent";
import { useSearchParams } from "react-router-dom";
import {
  IxModalContent,
  IxModalHeader,
  IxTypography,
  Modal,
  ModalRef,
} from "@siemens/ix-react";
import { handleError } from "@/utils/error";

interface EditCustomerDialogProps {
  defaultValues: EditCustomerFormValues;
  topologies: YourInsightsTopology;
  open: boolean;
  onClose: () => void;
}

export const EditCustomerDialog = React.forwardRef<
  ModalRef,
  EditCustomerDialogProps
>(({ defaultValues, topologies, open, onClose }, ref) => {
  const { updateCustomerIds } = useChannelStore();
  const [, setSearchParams] = useSearchParams();

  const methods = useForm<EditCustomerFormValues>({
    resolver: zodResolver(editCustomerFormSchema),
    defaultValues,
    mode: "all",
  });

  const { submitCustomerInformation, loading } = useSubmitJourney();

  useEffect(() => {
    methods.reset();
    if (open) {
      methods.trigger();
    }
  }, [open, methods]);

  const handleClose = () => {
    resetRouterDialog(setSearchParams);
    methods.reset();
    onClose();
  };

  const onSubmit = methods.handleSubmit(
    async (data: EditCustomerFormValues) => {
      // log("edit customer", data);
      // store the data in the backend
      try {
        resetRouterDialog(setSearchParams);
        const result = await submitCustomerInformation(data);
        handleClose();
        if (result.data?.upsertJourney) {
          updateCustomerIds(result.data.upsertJourney as Partial<Journey>);
        }
      } catch (e) {
        console.log(e);
        handleError(
          e,
          "An error occurred while processing the journey and storing it",
        );
      }
    },
  );

  return (
    <Modal ref={ref}>
      <>
        <IxModalHeader onCloseClick={handleClose}>
          Your Journey Summary
        </IxModalHeader>

        <IxModalContent className="my-auto flex h-[30rem] grow flex-col px-2 py-0">
          <FormProvider {...methods}>
            <form className="h-full" onSubmit={onSubmit}>
              {loading ? (
                <Spinner />
              ) : (
                <div className="flex h-full flex-col justify-between">
                  <div className="flex justify-between">
                    <IxTypography format="h2">
                      Edit Your Customer Details
                    </IxTypography>
                  </div>
                  <div>
                    <CustomerDetailsFormContent
                      motivationValues={defaultValues}
                      topologies={topologies}
                    />
                  </div>
                  <div className="mt-auto self-end">
                    <Button type="submit" disabled={!methods.formState.isValid}>
                      Save
                    </Button>
                  </div>
                </div>
              )}
            </form>
          </FormProvider>
        </IxModalContent>
      </>
    </Modal>
  );
});
