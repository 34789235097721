import { useChannelStore } from "../../../hooks/useChannelStore";
import { OutcomeText } from "../../Outcome/OutcomeText";
import { SummarySection } from "./SummarySection";

export const PointsSection = () => {
  const { channels, outcome } = useChannelStore();

  if ((channels?.length ?? 0) === 0) return null;

  const firstChannel = channels[0];
  const firstTouchpoint = firstChannel.touchpoints[0];

  const lastChannel = channels[channels.length - 1];
  const lastTouchpoint =
    lastChannel.touchpoints[lastChannel.touchpoints.length - 1];

  return (
    <SummarySection>
      <h1 className="mb-1 font-bold text-sm">Starting Point</h1>
      <p className="mb-4">
        {channels[0].name} / {firstTouchpoint.name}
      </p>
      <h1 className="mb-1 font-bold text-sm">Ending Point</h1>
      <p>
        {lastChannel.name} / {lastTouchpoint?.name}
      </p>
      {outcome && <OutcomeText outcome={outcome?.type} />}
    </SummarySection>
  );
};
