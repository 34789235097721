import React from "react";

const EmptyVisualJourney: React.FC = () => {
  return (
    <div className="flex grow items-center justify-center bg-channel-dark">
      <div className="text-center">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="mx-auto text-white"
        >
          <path
            d="M20 12H4"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 18L4 12L10 6"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
        <p className="mt-2 text-white">
          Choose a channel to visualize your journey
        </p>
      </div>
    </div>
  );
};

export default EmptyVisualJourney;
