import { getLocalStorageSizeInMB } from "@/utils/localstorage";
import { YourInsightsTopology } from "@/utils/topology";
import debug from "debug";
import { produce } from "immer";
import { create } from "zustand";
import { persist } from "zustand/middleware";

const log = debug("useYourInsightsTopologyStore");
const localStorageName = "yourInsightsTopology";

export type TopologyState = {
  topologyId?: string;
  currentTopology?: YourInsightsTopology;
  savedTopologies: {
    [key: string]: {
      state: Omit<TopologyState, "savedTopologies">;
      date: Date;
    };
  };
};

export type Actions = {
  saveTopology: (id: string, journeyTopology: YourInsightsTopology) => void;
  switchTopology: (id: string) => void;
};

const currentCacheSize = getLocalStorageSizeInMB(localStorageName);
log("Current cache size: ", currentCacheSize);
if (currentCacheSize > 1.5) {
  log("Cache size exceeded 1.5 MB. Removing the whole cache.");
  localStorage.removeItem(localStorageName);
}

/**
 * Topology Store to keep the already pulled topologies in local storage
 * since they don't change often
 */
export const useYourInsightsTopologyStore = create<TopologyState & Actions>()(
  persist(
    (set, get) => ({
      currentTopology: undefined,
      savedTopologies: {},
      topologyId: undefined,

      saveTopology: (id, topology) =>
        set(
          produce((state: TopologyState) => {
            const date = new Date();
            const newSavedTopology = {
              currentTopology: topology,
              topologyId: id,
            };
            state.topologyId = id;
            state.currentTopology = newSavedTopology.currentTopology;
            state.savedTopologies[id] = {
              state: newSavedTopology,
              date: date,
            };
          }),
        ),
      switchTopology: (id) => {
        set(
          produce((state: TopologyState) => {
            state.topologyId = id;
            const savedState = state.savedTopologies[id].state;
            state.currentTopology = savedState.currentTopology;
          }),
        );
      },
    }),
    {
      name: localStorageName, // name of the item in the storage (must be unique)
      partialize: (state) => ({ savedTopologies: state.savedTopologies }),
    },
  ),
);
