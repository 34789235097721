import React from "react";
import { Button } from "../Button";
import { cn } from "../../utils";

interface StepperProps {
  onBackClick?: () => void;
  onNextClick: () => void;
  isNextDisabled: boolean;
  steps: number;
  activeStep: number;
  nextButtonType?: "button" | "submit";
  nextButtonText?: string;
}

/**
 *  Stepper component
 * @param props Props
 * @param props.onBackClick if undefined back button will not be shown
 * @param props.onNextClick On next click
 * @param props.isNextDisabled Is next disabled
 * @param props.steps Number of steps
 * @param props.activeStep Active step
 * @param props.nextButtonType "button" or "submit"
 * @param props.nextButtonText Next button text
 */
export const Stepper: React.FC<StepperProps> = ({
  onBackClick,
  onNextClick,
  isNextDisabled,
  steps,
  activeStep,
  nextButtonType = "button",
  nextButtonText = "Next",
}) => {
  return (
    <div className="flex items-center justify-end py-2">
      <div className="absolute left-1/2 flex -translate-x-1/2 gap-2">
        {Array.from({ length: steps }, (_, index) => (
          <div
            key={index}
            className={cn("w-2 h-2 rounded-full bg-theme-color-std-bdr", {
              "bg-stepper-green": index === activeStep,
            })}
          ></div>
        ))}
      </div>
      <div>
        {onBackClick && (
          <Button outline onClick={onBackClick} className="mr-2">
            Back
          </Button>
        )}
        <Button
          type={nextButtonType}
          onClick={onNextClick}
          disabled={isNextDisabled}
        >
          {nextButtonText}
        </Button>
      </div>
    </div>
  );
};
