import { MapNode } from "./drawTouchpoint.ts";
import { MapChannel } from "./Map.tsx";

const visitMap: Map<string, string[]> = new Map();

export function incrementVisitCount(compositeId: string, fid: string): void {
  if (!visitMap.has(compositeId)) {
    visitMap.set(compositeId, []);
  }
  visitMap.get(compositeId)!.push(fid);
}

export function getVisitCount(node: MapNode): number {
  const visits = visitMap.get(node.compositeId) || [];
  return visits.indexOf(node.fid) + 1;
}

export function getTotalVisitCount(compositeId: string): number {
  const visits = visitMap.get(compositeId);
  return visits ? visits.length : 0;
}


export function computeVisitCounts(channels: MapChannel[]): void {
  visitMap.clear();
  channels.forEach((channel) => {
    channel.nodes.forEach((node) => {
      const compositeId = node.compositeId;
      incrementVisitCount(compositeId, node.fid);
    });
  });
}
