import { AuthorSection } from "./AuthorSection";
import { ExperienceDriverSection } from "./ExperienceDriverSection";
import { JourneyOriginSection } from "./JourneyOriginSection";
import { PainpointSection } from "./PainpointSection";
import { PointsSection } from "./PointsSection";

export const Summary = () => {
  return (
    <div className="size-full overflow-auto">
      <AuthorSection />
      <ExperienceDriverSection />
      <JourneyOriginSection />
      <PointsSection />
      <PainpointSection />
    </div>
  );
};
