export * from "./cn";
export * from "./pxToRem";

/**
 * Finds all elements in the entire page matching `selector`, even if they are in shadowRoots.
 * Just like `querySelectorAll`, but automatically expand on all child `shadowRoot` elements.
 * @see https://stackoverflow.com/a/71692555/2228771
 */
export function querySelectorAllShadows(
  selector: string,
  el: any = document.body,
) {
  // recurse on childShadows
  const childShadows = Array.from(el.querySelectorAll("*"))
    .map((el) => (el as HTMLElement).shadowRoot)
    .filter((shadow) => !!shadow);

  const childResults: any[] = childShadows.map((child) =>
    querySelectorAllShadows(selector, child),
  );

  // fuse all results into singular, flat array
  const result = Array.from(el.querySelectorAll(selector));
  return result.concat(childResults).flat();
}
