import { gql } from "@apollo/client";

export const STORE_JOURNEY = gql`
  mutation Journey($journey: JourneyInput) {
    upsertJourney(journey: $journey) {
      id
      topologyId
      lastSavedDate
      industry {
        id
        name
      }
      customerRole {
        id
        name
      }
      customerType {
        id
        name
      }
      avatarRef
      motivation {
        topologyId
        name
        intent {
          intent {
            name
            topologyId
          }
          name
          topologyId
        }
      }
      region {
        id
        name
      }
      buyerType {
        id
        name
      }
      priorContact {
        id
        name
      }
      description
      channels {
        id
        isCustom
        touchpoints {
          id
          isCustom
          locations {
            id
            isCustom
            locations {
              id
              isCustom
            }
          }
        }
      }
      outcome {
        type
      }
    }
  }
`;

export const GET_PREPARE_JOURNEY_TOPO = gql`
  query GET_PREPARE_JOURNEY_TOPO($id: ID!) {
    journeyTopology(id: $id) {
      customer_role {
        id
        name
      }
      customer_type {
        id
        name
      }
      industry {
        id
        name
      }
      motivations {
        intents {
          name
          id
          intents {
            id
            name
          }
        }
        name
        id
      }
    }
  }
`;
export const GET_CHANNEL_TOPO = gql`
  query ChannelTopology($id: ID!) {
    channelTopology(id: $id) {
      touchpoints {
        category
        question {
          question
        }
        id
        intro_fragment
        name
        category
      }
    }
  }
`;
