import React, { useMemo } from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import IntentList from "../components/IntentList.tsx";
import { MotivationTopology } from "@motius/customer-heartbeat-utils/schemas";
import { Spinner, Stepper } from "@motius/customer-heartbeat-ui";
import { PrepareYourJourneyStep, openDialogOrStep } from "@/utils/router";
import { useSearchParams } from "react-router-dom";
import Headline from "../components/Headline.tsx";

interface PrepareJourneyCustomerIntentStepProps {
  motivations: MotivationTopology[] | undefined;
}

/**
 * Customer Intent Step for Prepare Your Journey Dialog
 * @param props Props
 * @param props.motivations Motivations
 */
export const PrepareJourneyCustomerIntentStep: React.FC<
  PrepareJourneyCustomerIntentStepProps
> = ({ motivations }) => {
  const [, setSearchParams] = useSearchParams();

  const { isValid } = useFormState();
  const { trigger, watch, resetField } = useFormContext();

  const activeMotivationId = watch("motivationId");
  const activeIntent = watch("intent");

  /**
   *  Handle Back Button Click
   */
  const handleBack = () => {
    resetField("intent");
    openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Fifth);
  };

  const intents = useMemo(() => {
    let currentIntents =
      motivations?.find((m) => m.id === activeMotivationId)?.intents ?? [];
    if (activeIntent?.parents?.length > 0) {
      const lastParentIntentId =
        activeIntent.parents[activeIntent.parents.length - 1];
      currentIntents =
        currentIntents.find((int) => int.id === lastParentIntentId)?.intents ??
        [];
    }
    return currentIntents;
  }, [activeMotivationId, activeIntent, motivations]);

  /**
   *  Handle Next Button Click
   */
  const handleNext = async () => {
    if (await trigger()) {
      openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Seventh);
    }
  };

  if (!motivations || !activeMotivationId) return <Spinner />;

  return (
    <div className="flex-column flex h-full items-center justify-between">
      <Headline text="What is your intent?" />

      <div className="w-full">
        <Controller
          name="intent"
          render={({ field }) => (
            <IntentList
              motivationId={activeMotivationId}
              activeIntent={activeIntent}
              onBack={handleBack}
              onIntentChange={field.onChange}
              intents={intents}
            />
          )}
        />
      </div>
      <div className="mb-4 w-full">
        <Stepper
          onBackClick={handleBack}
          onNextClick={handleNext}
          isNextDisabled={!isValid}
          steps={7}
          activeStep={5}
        />
      </div>
    </div>
  );
};
