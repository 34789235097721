import { ExpectedNextSelection } from "@/src/hooks/useChannelStore";
import { FrontendChannel } from "@/utils/types";
import { notify } from "@motius/customer-heartbeat-ui";
import { state } from "@/src/components/Navigation/state";

const isSaveBlockedDueToPendingInput = (
  expectedNextSelection: ExpectedNextSelection,
) => {
  if (expectedNextSelection === ExpectedNextSelection.Reaction) {
    // if a reaction has already been selected then save should be blocked
    return Boolean(state.reaction);
  }

  return false;
};

export const isSaveBlockedDueToLackOfTp = (channels: FrontendChannel[]) =>
  !((channels?.[channels.length - 1]?.touchpoints?.length ?? 0) > 0);

export const checkIfSaveBlockedShowReason = (
  expectedNextSelection: ExpectedNextSelection,
  channels: FrontendChannel[],
): boolean => {
  if (isSaveBlockedDueToPendingInput(expectedNextSelection)) {
    notify.error("Please provide text input before proceeding");
    return true;
  }

  if (isSaveBlockedDueToLackOfTp(channels)) {
    notify.error("Please add a touchpoint to proceed");
    return true;
  }

  return false;
};
