import { cn } from "@motius/customer-heartbeat-ui/utils";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  isLastCardInList?: boolean;
}

const TextJourneyCard: React.FC<Props> = ({ children, isLastCardInList }) => {
  return (
    <div className="relative m-0 p-0">
      <span className="bg-theme-color-3 absolute left-0 top-0 size-2 rounded-full"></span>
      <div className="bg-theme-color-3 absolute left-[3.5px] top-0 size-2 h-full w-px"></div>

      <div
        className={
          // no padding bottom if it's the last card in the list
          cn("bg-white", { "pb-3": !isLastCardInList })
        }
      >
        <div className="relative ml-3 mt-0 rounded-lg">
          <div className="my-0 ml-1 text-left text-gray-900">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default TextJourneyCard;
