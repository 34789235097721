import { Controller } from "react-hook-form";
import { MotivationTopology } from "@/utils/apollo/resolvers";
import { Select } from "@motius/customer-heartbeat-ui";
import { z } from "zod";
import { insightsFormSchema } from "@motius/customer-heartbeat-utils/schemas";

const motivationValuesSchema = insightsFormSchema.pick({
  motivationId: true,
  intentId: true,
  subIntentId: true,
});

export type MotivationValues = z.infer<typeof motivationValuesSchema>;

type Props = {
  // TODO: Remove default values later so that we can use the values coming from the fields directly
  defaultValues: MotivationValues;
  motivationTopology: MotivationTopology[];
};

export const MotivationInput: React.FC<Props> = ({
  motivationTopology,
  defaultValues,
}) => {
  const motivationOptions = motivationTopology.map((motivation) => ({
    value: motivation.id,
    label: motivation.name,
  }));

  const currentMotivationTopo = motivationTopology.find(
    (topo) => topo.id === defaultValues.motivationId,
  );

  const intentOptions = currentMotivationTopo?.intents.map((intent) => ({
    value: intent.id,
    label: intent.name,
  }));

  if (!intentOptions) return null;

  const subIntentId = defaultValues.subIntentId;
  const currentIntentTopo = currentMotivationTopo?.intents?.find(
    (i) => i.id === defaultValues.intentId,
  );
  const subIntentOptions = (currentIntentTopo?.intents ?? []).map((intent) => ({
    value: intent.id,
    label: intent.name,
  }));

  return (
    <>
      <Controller
        name="motivationId"
        render={({ field, fieldState }) => (
          <Select
            sortAlphabetically
            disabled
            label="Motivation"
            id="motivation"
            error={fieldState.error}
            value={field.value}
            allowClear={false}
            onChange={field.onChange}
            options={motivationOptions}
          />
        )}
      />
      <Controller
        name="intentId"
        render={({ field, fieldState }) => (
          <Select
            sortAlphabetically
            disabled
            label="Intent"
            id="intentId"
            allowClear={false}
            error={fieldState.error}
            value={field.value}
            onChange={field.onChange}
            options={intentOptions!}
          />
        )}
      />
      {subIntentId && (
        <Controller
          name="subIntentId"
          render={({ field, fieldState }) => (
            <Select
              disabled
              label="Subintent"
              id="subIntentId"
              allowClear={false}
              error={fieldState.error}
              value={field.value}
              onChange={field.onChange}
              options={subIntentOptions}
            />
          )}
        />
      )}
    </>
  );
};
