import { IxSpinner } from "@siemens/ix-react";
import React from "react";

/**
 *  Loader Component
 */
export const Spinner: React.FC = () => {
  return (
    <div className="flex size-full items-center justify-center">
      <IxSpinner size="large" className="theme-brand-dark"></IxSpinner>
    </div>
  );
};
