import {
  BrowserCacheLocation,
  PublicClientApplication,
} from "@azure/msal-browser";
import { isDisableAuth } from "@motius/customer-heartbeat-utils/auth";

const CID = import.meta.env.VITE_OAUTH_CLIENT_ID;
const TID = import.meta.env.VITE_OAUTH_TENANT_ID;

export const msalConfig = {
  auth: {
    clientId: CID,
    authority: `https://login.microsoftonline.com/${TID}`,
    redirectUri: `${window.location.origin}`,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false, // Set to true for IE11
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);
if (!isDisableAuth()) msalInstance.initialize();
