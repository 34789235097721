// import { GET_CHANNEL_TOPO } from "@/utils/apollo/queries";
// import { useLazyQuery } from "@apollo/client";

import { useState } from "react";
import { useTopologyStore } from "../useTopologyStore/useTopologyStore";
import { Maybe } from "@motius/customer-heartbeat-utils";
import { FrontendChannelTopology } from "@/utils/types";

// A hook to get channel topologies
// This hook can be used to get channel topologies by their ids
// It will either use the already loaded channel topologies
// or load them from the network if commented code is used
export const useChannelTopologies = () => {
  // Use this one incase you need to load the channel topology from network in the future
  // const [loadChannelTopo, { data: lastChannelInJourneyTopo }] = useLazyQuery(GET_CHANNEL_TOPO);

  const [data, setData] = useState<{
    channelId: Maybe<string> | undefined;
    channelTopology: FrontendChannelTopology | undefined;
  }>();
  const { channelTopologies } = useTopologyStore();

  const handleLoadChannelTopo = (id: Maybe<string> | undefined) => {
    // loadChannelTopo({ variables: { id } });
    if (data?.channelId === id) return;
    const found = channelTopologies.find((channel) => channel.id === id);
    if (found) {
      setData({
        channelId: id,
        channelTopology: found,
      });
    }
  };

  return {
    loadChannelTopo: handleLoadChannelTopo,
    data,
  };
};
