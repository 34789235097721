import { IxDropdownItem } from "@siemens/ix-react";
import React, { useMemo } from "react";
import { parseName } from "./nameUtils";
import { User } from "./useUserSearch";

interface UserSearchDropdownItemProps {
  user: User;
  onClick: (user: User) => void;
  searchTerm: string;
}

export const UserSearchDropdownItem: React.FC<UserSearchDropdownItemProps> = ({
  user,
  onClick,
  searchTerm,
}) => {
  const { fullName, suffix } = parseName(user.name);

  /**
   * Divides a name into parts that match the search term.
   * @param searchTerm - The search term to match
   * @param name - The name to divide
   */
  const dividedName = (searchTerm: string, name?: string) => {
    if (!name || searchTerm.length < 3) return [];

    return name
      .toLowerCase()
      .split(searchTerm.toLowerCase())
      .flatMap((part, i) =>
        i % 2 === 0 ? [part] : [searchTerm.toLowerCase(), part],
      );
  };

  const divided = useMemo(
    () => dividedName(searchTerm, fullName),
    [fullName, searchTerm],
  );

  return (
    <IxDropdownItem onClick={() => onClick(user)} className="min-w-[17.37rem]">
      <span className="capitalize">
        {divided.map((part, index) =>
          index % 2 === 0 ? (
            <span key={index} className="text-black">
              {part}
            </span>
          ) : (
            <strong key={index}>{part}</strong>
          ),
        )}
      </span>
      &nbsp;
      <span className="text-soft">{suffix}</span>
    </IxDropdownItem>
  );
};
