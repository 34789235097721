import { JourneyOutcomeType } from "@/utils/apollo/resolvers";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import React from "react";

interface OutcomeIconProps {
  outcome: JourneyOutcomeType;
}

export const OutcomeIcon: React.FC<OutcomeIconProps> = ({ outcome }) => {
  if (outcome && outcome !== JourneyOutcomeType.Ended) {
    return (
      <div
        className={cn(
          "size-[1.125rem] ml-1",
          {
            "bg-[url('/outcome/handingOverIcon.svg')]":
              outcome === JourneyOutcomeType.HandedOver,
          },
          {
            "bg-[url('/outcome/fulfilledIcon.svg')]":
              outcome === JourneyOutcomeType.IntentFulfilled,
          },
          {
            "bg-[url('/outcome/unfulfilledIcon.svg')]":
              outcome === JourneyOutcomeType.IntentUnfulfilled,
          },
        )}
      />
    );
  }
  return null;
};
