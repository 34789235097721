import { JourneyFilter } from "@/utils/apollo/resolvers";
import { AuthorFilter, FrontendJourneyFilter } from "./Search";
import { handleError } from "@/utils/error";

/**
 * Removes filters with the specified key from the JourneyFilter
 * @param key The key to remove from the filters
 * @param filter The JourneyFilter object to modify
 * @returns The modified JourneyFilter object
 */
export function removeFilters(
  key: keyof FrontendJourneyFilter,
  filter: FrontendJourneyFilter,
): FrontendJourneyFilter {
  if (!Array.isArray(filter?.OR)) {
    return filter;
  }

  return {
    ...filter,
    OR: filter.OR.filter((filter) => !(key in filter)),
    authorGidFilters: filter.authorGidFilters || [],
  } as FrontendJourneyFilter;
}

/**
 * Adds filters with the specified key to the FrontendJourneyFilter
 * @param key The key to add to the filters
 * @param ids The ids to add to the filters
 * @param filter The FrontendJourneyFilter object to modify
 * @returns The modified JourneyFilter object
 */
export function addFilters(
  key: keyof FrontendJourneyFilter,
  ids: string[],
  filter: FrontendJourneyFilter | undefined,
): FrontendJourneyFilter {
  const mappedIds: FrontendJourneyFilter[] = ids.map((id) => ({
    [key]: id,
    authorGidFilters: [],
    authorNameFilters: [],
  }));
  if (!filter) {
    return { authorGidFilters: [], authorNameFilters: [], OR: mappedIds };
  }

  return {
    ...filter,
    OR: [...(filter.OR ?? []), ...mappedIds],
  };
}

/**
 * Handles the change of a filter by first removing a filter key
 * (for example industry) from the array and then adding all the
 * ids for that key
 * @param key The key to add and remove from the filters
 * @param ids The ids to add to the filters
 * @param filter The JourneyFilter object to modify
 * @returns The modified JourneyFilter object
 */
export function handleFilterChange(
  key: keyof FrontendJourneyFilter,
  ids: string[],
  filter: FrontendJourneyFilter,
): FrontendJourneyFilter {
  const updatedFilter: FrontendJourneyFilter = removeFilters(key, filter);
  const result = addFilters(key, ids, updatedFilter);
  return result;
}

/**
 * Gets the filters for a given key
 * @param key The key to get the filters for
 * @param filter The JourneyFilter object to get the filters from
 * @returns The filters for the given key
 */
export function getFilters(
  key: keyof FrontendJourneyFilter,
  filter: FrontendJourneyFilter | undefined,
): string[] {
  if (!Array.isArray(filter?.OR)) {
    return [];
  }

  return (
    filter?.OR.filter((filter: FrontendJourneyFilter) => filter[key]).map(
      (filter) => filter[key] as string,
    ) || []
  );
}

/**
 * Ensures that authorFilters are properly parsed and never undefined
 * @param filter The FrontendJourneyFilter object to normalize
 * @returns The normalized FrontendJourneyFilter object
 */
export function normalizeFilter(
  filter: FrontendJourneyFilter | undefined,
): FrontendJourneyFilter {
  if (!filter) {
    return { authorGidFilters: [], OR: [] };
  }

  let authorFilters: AuthorFilter[] = [];

  if (typeof filter.authorGidFilters === "string") {
    try {
      authorFilters = JSON.parse(filter.authorGidFilters);
    } catch (e) {
      handleError(e, "Failed to parse authorFilters");
    }
  } else if (Array.isArray(filter.authorGidFilters)) {
    authorFilters = filter.authorGidFilters;
  }

  return {
    ...filter,
    authorGidFilters: authorFilters,
    OR: filter.OR || [],
  };
}

/**
 * Adds an author filter to the JourneyFilter if it doesn't already exist
 * @param authorFilter The author filter to add to the filters
 * @param filter The JourneyFilter object to modify
 * @returns The modified JourneyFilter object
 */
export function addAuthorFilter(
  authorFilter: AuthorFilter,
  filter: FrontendJourneyFilter = { authorGidFilters: [] },
): FrontendJourneyFilter {
  const normalizedFilter = normalizeFilter(filter);

  // Check if an author with the same GID already exists
  const authorExists = normalizedFilter.authorGidFilters?.some(
    (a) => a.gid === authorFilter.gid,
  );

  if (!authorExists) {
    normalizedFilter.authorGidFilters = [
      ...(normalizedFilter.authorGidFilters || []),
      authorFilter,
    ];
  }

  return structuredClone(normalizedFilter);
}

/**
 * Gets the author gids from the JourneyFilter
 * @param filter The JourneyFilter object to get the author gids from
 * @returns The author gids
 */
export function getAuthorGids(filter: JourneyFilter = {}): string[] {
  return filter.AND?.[0]?.OR?.map((filter) => filter.ownerGid as string) ?? [];
}

/**
 * Removes an author filter from the JourneyFilter
 * @param gid The gid to remove from the filters
 * @param filter The JourneyFilter object to modify
 * @returns The modified JourneyFilter object
 */
export function removeAuthorFilter(
  gid: string,
  filter: FrontendJourneyFilter = { authorGidFilters: [] },
): FrontendJourneyFilter {
  const normalizedFilter = normalizeFilter(filter);

  const updatedAuthorFilters =
    normalizedFilter.authorGidFilters?.filter((author) => author.gid !== gid) ||
    [];

  return {
    ...normalizedFilter,
    authorGidFilters: updatedAuthorFilters,
  };
}

export const removeAuthorNameFilter = (
  name: string,
  filter: FrontendJourneyFilter,
): FrontendJourneyFilter => {
  return {
    ...filter,
    authorNameFilters:
      filter.authorNameFilters?.filter((n) => n !== name) || [],
  };
};

export function hasFilters(filter: FrontendJourneyFilter): boolean {
  return (
    !!filter.OR?.length ||
    !!filter.authorGidFilters?.length ||
    !!filter.authorNameFilters?.length
  );
}
