export const MOTIVATIONS: Array<{ label: string; icon: string }> = [
  {
    icon: "rocket",
    label: "To Get Inspired",
  },
  {
    icon: "phone",
    label: "Contact",
  },
  {
    icon: "search",
    label: "Find",
  },
  {
    icon: "question",
    label: "How to Use",
  },
  {
    icon: "user",
    label: "Sign Up",
  },
  {
    icon: "configuration",
    label: "Configure",
  },
  {
    icon: "highlight",
    label: "Trial",
  },
  {
    icon: "quality-report",
    label: "Quote",
  },
  {
    icon: "shopping_cart",
    label: "Order",
  },
  { icon: "table", label: "Finance" },
  {
    icon: "corner-arrow-up-left",
    label: "Return & Exchange",
  },
  {
    icon: "download",
    label: "Download Master Data",
  },
  {
    icon: "download",
    label: "Download",
  },
  {
    icon: "user-management",
    label: "Become a Partner/Seller",
  },
];

export const getIconByLabel = (label: string) => {
  const icon = MOTIVATIONS.find((motivation) => motivation.label === label);
  return icon ? icon.icon : "";
};
