import { clsx, type ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

/**
 * Merges tailwind classes and returns a string
 * @param classNames class names
 */
export function cn(...classNames: ClassValue[]) {
  return twMerge(clsx(classNames));
}
