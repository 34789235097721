import { cn } from "@motius/customer-heartbeat-ui/utils";
import { IxIconButton, IxTooltip } from "@siemens/ix-react";
import React from "react";

interface NavigationTooltipProps {
  id: string;
  content: React.ReactNode;
  className?: string;
  tooltipClassname?: string;
}

/**
 *  Navigation Tooltip
 * @param props - the props
 * @param props.id - the id of the tooltip
 * @param props.content - the content of the tooltip
 * @param props.className - the class name of the whole component
 * @param props.tooltipClassname - the class name of the tooltip
 */
export const NavigationTooltip: React.FC<NavigationTooltipProps> = ({
  id,
  content,
  className,
  tooltipClassname,
}) => {
  return (
    <>
      <IxIconButton
        className={cn(className, id)}
        icon="about"
        size="16"
        variant="primary"
        ghost
        aria-describedby={id}
      />
      {content && (
        <IxTooltip
          id={id}
          for={`.${id}`}
          placement="right"
          interactive
          className={cn(tooltipClassname)}
        >
          {content}
        </IxTooltip>
      )}
    </>
  );
};
