import { useChannelStore } from "@/src/hooks/useChannelStore";
import { CompleteDetailsStep, openDialogOrStep } from "@/utils/router";
import { Button } from "@motius/customer-heartbeat-ui";
import { checkIfSaveBlockedShowReason } from "../../SaveAsDraft/utils";
import { useSearchParams } from "react-router-dom";

/**
 *  Complete Details Button
 * This button is used to complete the details of the journey
 * It will open the dialog to complete the details
 * If the save is blocked, it will show the reason
 */
export const CompleteDetailsButton = () => {
  const { channels, expectedNextSelection } = useChannelStore();
  const [, setSearchParams] = useSearchParams();

  /**
   *  Handle the click of the complete button
   * If the save is blocked, show the reason
   */
  const handleCompleteClick = () => {
    if (checkIfSaveBlockedShowReason(expectedNextSelection, channels)) {
      return;
    }

    openDialogOrStep(setSearchParams, CompleteDetailsStep.First);
  };
  return (
    <>
      <Button onClick={handleCompleteClick}>Publish</Button>
    </>
  );
};
