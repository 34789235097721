import React from "react";
import { useImage } from "react-image";

interface ImageProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  imageSrc: string;
  fallback?: string;
}

export const ImageWithFallback: React.FC<ImageProps> = ({
  imageSrc,
  fallback,
  ...rest
}) => {
  const { src } = useImage({
    srcList: fallback ? [imageSrc, fallback] : imageSrc,
    useSuspense: false,
  });

  return <img {...rest} src={src} />;
};
