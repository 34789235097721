import { TopBarBase, ProfileIcon } from "@motius/customer-heartbeat-ui";
import { showMessage } from "@siemens/ix";
import { IxIconButton } from "@siemens/ix-react";
import { useNavigate } from "react-router-dom";

/**
 *  TopBar component
 */
export const TopBar = () => {
  const navigate = useNavigate();

  const handleClose = async () => {
    (
      await showMessage({
        icon: "",
        messageTitle: "Do you really want to exit this journey?",
        message: "Your changes will be lost if you proceed.",
        actions: [
          { id: "cancel", type: "cancel", text: "Cancel" },
          { id: "proceed", type: "okay", text: "Proceed" },
        ],
      })
    ).once((result) => {
      if (result?.actionId === "proceed") {
        navigate("/journeys");
      } else {
      }
    });
  };

  return (
    <TopBarBase>
      <ProfileIcon />
      <IxIconButton
        onClick={handleClose}
        icon="close"
        ghost
        size="16"
      ></IxIconButton>
    </TopBarBase>
  );
};
