import React from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { PrepareYourJourneyStep, openDialogOrStep } from "@/utils/router";
import { CustomerTypeTopology } from "@/utils/apollo/resolvers";
import { Spinner, Stepper } from "@motius/customer-heartbeat-ui";
import { useSearchParams } from "react-router-dom";
import Headline from "../components/Headline.tsx";
import { IxActionCard } from "@siemens/ix-react";

interface PrepareJourneyCustomerTypeStepProps {
  customerTypes: CustomerTypeTopology[] | undefined;
}

/**
 * Customer Type Step for Prepare Your Journey Dialog
 * @param props Props
 * @param props.onClose Callback for close the dialog
 * @param props.customerTypes Customer Types
 */
export const PrepareJourneyCustomerTypeStep: React.FC<
  PrepareJourneyCustomerTypeStepProps
> = ({ customerTypes }) => {
  const [, setSearchParams] = useSearchParams();
  const { isValid } = useFormState();
  const { trigger } = useFormContext();

  /**
   *  Handle Back Button Click
   */
  const handleBack = () => {
    openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Third);
  };

  /**
   *  Handle Next Button Click
   */
  const handleNext = async () => {
    if (await trigger()) {
      openDialogOrStep(setSearchParams, PrepareYourJourneyStep.Fifth);
    }
  };

  if (!customerTypes) return <Spinner />;

  return (
    <div className="flex h-full flex-col items-center justify-between overflow-hidden">
      <Headline text="What kind of customer are you?" />
      <div className="my-[1.8125rem] h-auto w-full shrink grow overflow-auto">
        <Controller
          name="typeId"
          render={({ field }) => (
            <div className="grid w-full grid-cols-4 gap-2">
              {customerTypes.map(({ id, name: label }) => (
                <IxActionCard
                  key={id}
                  className="m-0 h-[6.75rem] min-h-0 w-auto min-w-0 !max-w-[194px]"
                  heading={label}
                  variant="notification"
                  selected={field.value === id}
                  onClick={() => field.onChange(id)}
                />
              ))}
            </div>
          )}
        />
      </div>
      <div className="mb-4 w-full shrink-0">
        <Stepper
          onBackClick={handleBack}
          onNextClick={handleNext}
          isNextDisabled={!isValid}
          steps={7}
          activeStep={3}
        />
      </div>
    </div>
  );
};
