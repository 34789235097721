import React, { useEffect } from "react";
import { TopBar } from "./TopBar";
import { VisualJourneyMap } from "../Map/Map";
import { useJourney } from "@/src/hooks/useJourney";
import { SidePanel } from "./SidePanel";
import { useParams } from "react-router-dom";
import { PageWithTopBar } from "@motius/customer-heartbeat-ui";
import { useIncrementJourneyViews } from "./useIncrementJourneyViews";
import { useSpinner } from "@/src/hooks/useSpinner";

interface ViewJourneyPageProps {}

/**
 *  View Journey Page
 * @param props Props
 * @param props.params Params
 * @param props.params.id Id of the journey
 */
export const ViewJourney: React.FC<ViewJourneyPageProps> = () => {
  const { id } = useParams<{ id: string }>();
  const { loading, error } = useJourney(id, true);
  const { incrementViews } = useIncrementJourneyViews();

  useEffect(() => {
    if (id) {
      incrementViews(id);
    }
  }, [id]);

  useSpinner(loading);
  if (error) return <div>Error! {error.message}</div>;

  return (
    <PageWithTopBar>
      <TopBar />
      <div className="flex flex-1 overflow-hidden">
        <SidePanel />
        <VisualJourneyMap editable={false} />
      </div>
    </PageWithTopBar>
  );
};
