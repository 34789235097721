import React from "react";
import { IxIcon, IxTypography } from "@siemens/ix-react";

interface NothingHereProps {
  iconName: string;
  bodyText: string;
  headline?: string;
}

export const NothingHere: React.FC<NothingHereProps> = ({
  iconName,
  bodyText,
  headline = "There is nothing here yet ...",
}) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <IxIcon
        name={iconName}
        className="h-20 scale-[286%] opacity-30"
        size="32"
      />
      <div className="flex flex-col items-center justify-center gap-2">
        <IxTypography format="display" className="text-[1.375rem]">
          {headline}
        </IxTypography>
        <IxTypography format="body" className="max-w-[20rem] text-center">
          {bodyText}
        </IxTypography>
      </div>
    </div>
  );
};
