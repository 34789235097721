/**
 * The topology contains all the possible paths that a user can take.
 * The journey contains the actual path that user took.
 * Each object in the journey has an id.
 * Here we store all the path he can take from a given id
 */

import { FrontendLocationTopology } from "../types";

const locationsById: Record<string, Array<FrontendLocationTopology>> = {};
export const getLocationsById = (id: string): FrontendLocationTopology[] => {
  return locationsById[id];
};
export const putLocationsForId = (
  id: string,
  locations: FrontendLocationTopology[],
) => {
  locationsById[id] = locations;
};
