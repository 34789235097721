import { handleError } from "@/utils/error";
import { gql, useMutation } from "@apollo/client";
import { notify } from "@motius/customer-heartbeat-ui";
import React, { useState } from "react";

const FAVORITE_JOURNEY = gql`
  mutation MarkJourneyAsFavorite($input: FavoriteJourneyInput!) {
    markJourneyAsFavorite(input: $input)
  }
`;

interface FavoriteButtonProps {
  defaultFavorited?: boolean;
  journeyId: string;
  onUnfavorite?: () => void;
}

export const FavoriteButton: React.FC<FavoriteButtonProps> = ({
  defaultFavorited,
  journeyId,
  onUnfavorite,
}) => {
  const [storeJourney] = useMutation(FAVORITE_JOURNEY);
  const [active, setActive] = useState(defaultFavorited);

  /**
   *  Handle click event for favorite button
   */
  const handleClick = async () => {
    const currentActive = active;
    setActive(!currentActive);
    try {
      await storeJourney({
        variables: {
          input: {
            id: journeyId,
            isFavorite: !currentActive,
          },
        },
      });
      if (active && onUnfavorite) {
        onUnfavorite();
      }
    } catch (e) {
      setActive(currentActive);
      handleError(e, "Failed to favorite journey");
    }
  };

  return (
    <button className="group ml-auto" onClick={handleClick}>
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        className="group-hover:fill-primary"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {active ? (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.60916 1.7206C3.87542 -0.702555 7.59244 -0.545676 9.67302 2.06094L10 2.4706L10.327 2.06095C12.4076 -0.54566 16.1246 -0.702539 18.3908 1.72062C20.5364 4.01471 20.5364 7.73416 18.3908 10.0283L10 19L9.99999 19L9.99582 18.9955L1.60916 10.0282C-0.536387 7.73415 -0.536388 4.01469 1.60916 1.7206Z"
            className="group-hover:fill-theme-color-primary--hover fill-theme-color-primary"
          />
        ) : (
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.3089 1.76882C20.5637 4.12722 20.5637 7.95095 18.3089 10.3094L9.99999 19L1.6911 10.3093C-0.563701 7.95094 -0.563701 4.12721 1.6911 1.7688C3.78317 -0.419388 7.08127 -0.577317 9.34742 1.29502C9.5237 1.44066 9.69374 1.59859 9.85647 1.7688L10 1.91893L10.1435 1.76882C10.3062 1.59861 10.4763 1.44068 10.6526 1.29504C12.9187 -0.577301 16.2168 -0.419374 18.3089 1.76882ZM3.13671 3.15091C4.60385 1.61636 6.94373 1.61636 8.41086 3.15091L10 4.81306L11.5891 3.15092C13.0563 1.61638 15.3961 1.61638 16.8633 3.15092C18.3789 4.73616 18.3788 7.342 16.8633 8.92725L16.7197 9.07735L9.99999 16.1059L3.13671 8.92724C1.6211 7.34198 1.6211 4.73616 3.13671 3.15091Z"
            className="group-hover:fill-theme-color-primary--hover fill-theme-color-primary"
          />
        )}
      </svg>
    </button>
  );
};
