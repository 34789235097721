import {
  IxMenu,
  IxMenuAbout,
  IxMenuAboutItem,
  IxMenuItem,
} from "@siemens/ix-react";
import React from "react";
import { NavLink } from "react-router-dom";
import { Imprint } from "./About/Imprint";
import { TermsOfUse } from "./About/TermsOfUse";

/**
 *  Side menu for the journeys page
 */
export const SideMenu = () => {
  return (
    <IxMenu>
      <NavLink to="/journeys">
        {({ isActive }) => (
          <IxMenuItem home active={isActive} tab-icon="home">
            Your Space
          </IxMenuItem>
        )}
      </NavLink>
      <NavLink to="/discover">
        {({ isActive }) => (
          <IxMenuItem active={isActive} tab-icon="rocket">
            Journey Community
          </IxMenuItem>
        )}
      </NavLink>
      <NavLink to="/statistics">
        {({ isActive }) => (
          <IxMenuItem active={isActive} tab-icon="trend">
            Engagement and Statistics
          </IxMenuItem>
        )}
      </NavLink>
      <IxMenuAbout data-testid="about">
        <IxMenuAboutItem label="Imprint" data-testid="imprint">
          <Imprint />
        </IxMenuAboutItem>
        <IxMenuAboutItem label="Terms of use" data-testid="terms-of-use">
          <TermsOfUse />
        </IxMenuAboutItem>
      </IxMenuAbout>
    </IxMenu>
  );
};
