import { gql, useMutation } from "@apollo/client";
import { Maybe } from "@motius/customer-heartbeat-utils";
import { v4 } from "uuid";

const REQUEST_MISSING_CHANNEL = gql`
  mutation requestChannel($input: RequestChannelInput!) {
    requestChannel(input: $input) {
      id
      name
      category
      url
      comment
    }
  }
`;

const REQUEST_MISSING_TOUCHPOINT = gql`
  mutation requestTouchpoint($input: RequestTouchpointInput!) {
    requestTouchpoint(input: $input) {
      id
      name
      category
      url
      comment
    }
  }
`;

const REQUEST_MISSING_LOCATION = gql`
  mutation requestLocation($input: RequestLocationInput!) {
    requestLocation(input: $input) {
      id
      name
      category
      url
      comment
    }
  }
`;

type Input = {
  name: string;
  category: string;
  url?: string | null;
  comment?: string | null;
};

export const useRequestMissing = (
  journeyId: string,
  journeyTopologyId?: Maybe<string>,
) => {
  const [requestMissingChannel, { loading: isSubmitInProgressChannel }] =
    useMutation(REQUEST_MISSING_CHANNEL);
  const [requestMissingTouchpoint, { loading: isSubmitInProgressTouchpoint }] =
    useMutation(REQUEST_MISSING_TOUCHPOINT);
  const [requestMissingLocation, { loading: isSubmitInProgressLocation }] =
    useMutation(REQUEST_MISSING_LOCATION);

  const forJourneyTopologyId =
    journeyTopologyId ?? import.meta.env.VITE_TOPOLOGY_ID;

  const submitRequest = async (
    data: Input,
    type: "Touchpoint" | "Interaction" | "Channel",
  ) => {
    const id = v4();

    const variables = {
      input: {
        id,
        forJourneyTopologyId,
        forJourneyId: journeyId,
        ...data,
      },
    };

    switch (type) {
      case "Interaction":
        return requestMissingLocation({
          variables,
        });
      case "Touchpoint":
        return requestMissingTouchpoint({
          variables,
        });
      case "Channel":
        return requestMissingChannel({
          variables,
        });
    }
  };
  return {
    submitRequest,
    loading: isSubmitInProgressLocation || isSubmitInProgressTouchpoint,
  };
};
