import React from "react";
import { Avatar } from "../Avatar";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { AvatarType } from "@motius/customer-heartbeat-utils/schemas";
import { IxBlind, IxIconButton, IxTypography } from "@siemens/ix-react";

/**
 *  Customer Information to Be Shown by Profile Card
 */
type CustomerLabels = {
  role: string;
  avatar: AvatarType;
  type: string;
  industry: string;
  words?: string;
  motivation: string;
  intent: string;
  region?: string;
};

/**
 *  ProfileCardProps
 */
interface ProfileCardProps {
  className?: string;
  onEdit?: () => void;
  customer: CustomerLabels;
  fullWidth?: boolean;
}

/**
 *  Profile Card to show customer information
 *
 * @param props Props
 * @param props.className Additional css classes
 * @param props.onEdit Callback to call on edit
 * @param props.customer Customer information
 * @param props.fullWidth Whether it uses the whole width
 * @param props.autoHeight Whether profile card should resize
 */
export const ProfileCard: React.FC<ProfileCardProps> = ({
  className,
  onEdit,
  customer,
  fullWidth,
}) => {
  return (
    <div
      className={cn(
        "bg-light-gray rounded-lg pt-2 relative w-96 text-gray-900 h-auto",
        { "w-full": fullWidth },
        className,
      )}
    >
      <div className="flex h-full flex-col items-start">
        <div className="flex w-full items-center justify-between px-3 py-2">
          <IxTypography format="h4" className="font-bold">
            Customer Details
          </IxTypography>
          {onEdit && (
            <IxIconButton
              icon="pen"
              className="bg-transparent text-[color:var(--bs-green)]"
              onClick={onEdit}
              ghost
              size="16"
              variant="primary"
            ></IxIconButton>
          )}
        </div>
        <div className="flex size-full flex-col items-start p-3">
          <div className="flex items-center space-x-4 pr-4">
            <Avatar type={customer.avatar} size={56} />
            <div className="text-left">
              <p className="font-bold font-semibold">{customer.role}</p>
              <p>{customer.type}</p>
              <p>
                {customer.industry}
                {customer?.region ? `, ${customer.region}` : ""}
              </p>
            </div>
          </div>

          <div className="flex w-full grow flex-col justify-center pt-3">
            <p className="line-clamp-3 break-words text-left text-sm">
              {customer.words && `"${customer.words}"`}
            </p>
          </div>
        </div>
        <IxBlind label="Detail Info" className="w-full" collapsed>
          <div className="flex flex-col items-start gap-1.5 text-left text-sm">
            <div className="flex flex-col gap-0.5">
              <span className="font-bold">Motivation</span>
              <span>{customer.motivation}</span>
            </div>
            <div className="flex flex-col gap-0.5">
              <span className="font-bold">Intent</span>
              <span>{customer.intent}</span>
            </div>
          </div>
        </IxBlind>
      </div>
    </div>
  );
};
