import { IxTypography } from "@siemens/ix-react";
// @ts-expect-error This import works
import NoJourneyIllustration from "./noJourney.svg?react";
import React from "react";
import { cn } from "@motius/customer-heartbeat-ui/utils";

interface JourneyCreationMotivationProps {
  numberOfJourneys: number;
  className?: string;
}

/**
 * This is shown as placeholder when there is no draft journeys yet
 *
 * @param props Props
 * @param props.onCreate Create callback
 */
export const JourneyCreationMotivation: React.FC<
  JourneyCreationMotivationProps
> = ({ numberOfJourneys, className }) => {
  let text =
    "You have no drafted journeys.\nClick “Create New Journey” to get started!";
  switch (numberOfJourneys) {
    case 3:
      text =
        "3 journeys – you are on fire!\nClick “Create New Journey” to continue!";
      break;
    case 2:
      text =
        "2 journeys – you are on fire!\nClick “Create New Journey” to continue!";
      break;
    case 1:
      text =
        " Great job –  you already drafted 1 journey!\nNew journeys will appear here as you add them.\nClick “Create New Journey” to continue!";
      break;
  }
  return (
    <div
      className={cn(
        "flex grow flex-col items-center justify-center rounded bg-no-journey h-full min-h-[33.75rem]",
        className,
      )}
    >
      <div className="flex w-1/2 min-w-52 max-w-[31.25rem] flex-col items-center justify-center gap-3">
        <NoJourneyIllustration
          className="shrink-1"
          height="auto"
          width="auto"
        />
      </div>
      <IxTypography format="body" className="w-full text-center whitespace-pre">
        {text}
      </IxTypography>
    </div>
  );
};
