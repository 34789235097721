/**
 * Parses a full name string into its components.
 *
 * @param name - The full name string to parse. Expected format: "LastName, FirstName (optional suffixes)"
 * @returns An object containing the parsed name components
 * @property firstName - The first name of the person
 * @property lastName - The last name of the person
 * @property suffix - Any additional information after the first name (e.g., titles, departments)
 *
 * @example
 * // Returns { firstName: "Tobias", lastName: "Klock", suffix: "(ext) (CM DEP IT)" }
 * parseName("Klock, Tobias (ext) (CM DEP IT)");
 */
export function parseName(name: string) {
  let firstName, lastName, suffix;

  const nameParts = name.split(", ");
  if (nameParts.length < 2) {
    // the name cannot be parsed, so we return the original name as firstName
    // and empty strings for the lastName and suffix
    // currently this happens for some users that were created for test cases
    return {
      firstName: name,
      lastName: "",
      suffix: "",
      fullName: name,
    };
  }

  lastName = nameParts[0];
  firstName = nameParts[1].split(" ")[0];

  // Join the rest of the parts after the first name to form the suffix
  suffix = nameParts[1].split(" ").slice(1).join(" ");

  return {
    firstName: firstName ?? "",
    lastName: lastName ?? "",
    suffix: suffix ?? "",
    fullName: firstName + (lastName ? " " + lastName : ""),
  };
}
