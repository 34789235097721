import { FrontendJourneyFilter } from "./Search";

/**
 * Serializes the filters into a query string.
 * @param filter - The filter object to serialize.
 * @param prefix - The prefix used in serialization
 *                 (to handle nested objects)
 * @returns The serialized filter string.
 */
export function serializeFilterToUrlParams(
  filter: FrontendJourneyFilter,
  prefix = "",
): string {
  const params = new URLSearchParams();

  // Helper to add parameters recursively
  const appendParamsRecursively = (currentKey: string, currentValue: any) => {
    if (Array.isArray(currentValue)) {
      currentValue.forEach((item, index) => {
        if (typeof item === "object") {
          params.append(`${currentKey}[${index}]`, JSON.stringify(item)); // Serialize objects as JSON
        } else {
          params.append(`${currentKey}[${index}]`, String(item));
        }
      });
    } else if (typeof currentValue === "object" && currentValue !== null) {
      Object.keys(currentValue).forEach((nestedKey) => {
        appendParamsRecursively(
          `${currentKey}.${nestedKey}`,
          currentValue[nestedKey],
        );
      });
    } else if (currentValue !== undefined && currentValue !== null) {
      params.append(currentKey, String(currentValue));
    }
  };

  // Iterate through filter properties
  Object.keys(filter).forEach((filterKey) => {
    const filterValue = (filter as any)[filterKey];
    const paramKey = prefix ? `${prefix}.${filterKey}` : filterKey;

    if (filterValue !== undefined && filterValue !== null) {
      appendParamsRecursively(paramKey, filterValue);
    }
  });

  return params.toString();
}

/**
 * Deserializes URL parameters into a `FrontendJourneyFilter` object.
 * @param queryString - The URL query string to deserialize.
 * @returns he deserialized filter object.
 */
export function deserializeUrlParamsToFilter(
  queryString: string,
): FrontendJourneyFilter {
  const params = new URLSearchParams(queryString);
  const filter: any = {};

  /**
   * Sets a value in a nested object given an array of keys.
   * @param keys - The array of keys representing the path in the object.
   * @param value - The value to set at the specified path.
   * @param obj - The object to modify.
   */
  const setFilterValue = (keys: string[], value: any, obj: any) => {
    if (keys.length === 1) {
      const key = keys[0].replace(/\[\d+\]$/, ""); // Remove array index for final key
      if (key === "OR" || key.endsWith("Filters")) {
        // Store values in arrays for "OR" and "*Filters" keys
        obj[key] = obj[key] || [];
        obj[key].push(value);
      } else {
        obj[key] = value;
      }
    } else {
      const key = keys.shift() as string;
      obj[key] = obj[key] || (key.match(/\[\d+\]$/) ? [] : {});
      setFilterValue(keys, value, obj[key]);
    }
  };

  params.forEach((value, key) => {
    const keys = key.split("."); // Split by dot for nested keys
    setFilterValue(keys, value, filter);
  });

  // Parse array values as JSON objects
  for (const key in filter) {
    if (Array.isArray(filter[key])) {
      filter[key] = filter[key].map((item: string) => {
        try {
          return JSON.parse(item);
        } catch (e) {
          return item;
        }
      });
    }
  }

  return filter as FrontendJourneyFilter;
}
