import { CompleteDetailsDialog } from "./components/CompleteDetailsDialog";
import { useChannelStore } from "../../hooks/useChannelStore";
import { getDefaultId, getOptionalDefaultId } from "@/utils/topology";
import { useYourInsightsTopology } from "@/src/hooks/useYourInsigtsTopology";
import { useEffect, useRef, useState } from "react";
import { CompleteDetailsStep, resetRouterDialog } from "@/utils/router";
import { useSearchParams } from "react-router-dom";
import { ModalRef, showModal } from "@siemens/ix-react";
import { InsightsFormValues } from "@motius/customer-heartbeat-utils/schemas";

export const CompleteDetailsDialogContainer = () => {
  const {
    avatarRef,
    industry: storedIndustry,
    customerRole: storedCustomerRole,
    customerType: storedCustomerType,
    description: storedCustomerDescription,
    motivation: storedMotivation,
    region: storedRegion,
    buyerType: storedBuyerType,
    priorContact: storedPriorContact,
    painpoints: storedPainpoints,
    experienceDrivers: storedExperienceDrivers,
    journeyType: storedJourneyType,
    journeyId,
    journeyTopologyId,
  } = useChannelStore();
  const modalRef = useRef<ModalRef>(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [waitingToOpen, setWaitingToOpen] = useState(false);

  const { topology } = useYourInsightsTopology(journeyId, journeyTopologyId);

  useEffect(() => {
    const dialogParam = searchParams.get("dialog") as CompleteDetailsStep;
    const willOpen = !!(
      dialogParam && Object.values(CompleteDetailsStep).includes(dialogParam)
    );
    setWaitingToOpen(willOpen);

    // Close modal if dialog param is removed and modal is open
    if (!willOpen && modalRef.current) {
      handleClose();
    }
  }, [searchParams]);

  useEffect(() => {
    // still loading data that we need
    if (waitingToOpen && topology != null && journeyId != null) {
      const variables = {
        avatarRef,
        storedIndustry,
        storedCustomerRole,
        storedCustomerType,
        storedMotivation,
        journeyId,
      };

      Object.entries(variables).forEach(([key, value]) => {
        if (value == null) {
          throw new Error(`The value of ${key} is null`);
        }
      });
      show();
    }
  }, [
    waitingToOpen,
    topology,
    journeyId,
    avatarRef,
    storedIndustry,
    storedCustomerRole,
    storedCustomerType,
    storedMotivation,
  ]);

  /**
   *  Closes the complete details dialog
   */
  const handleClose = () => {
    modalRef.current?.close("Close complete details dialog");
  };

  /**
   *  Opens the complete details dialog
   */
  async function show() {
    if (topology == null || journeyId == null) return;
    await showModal({
      centered: true,
      size: "840",
      /**
       *  Before dismiss callback
       */
      beforeDismiss: () => {
        if (document.location.search.includes("dialog")) {
          resetRouterDialog(setSearchParams);
        }
        handleClose();
        return false;
      },
      content: (
        <CompleteDetailsDialog
          ref={modalRef}
          onClose={handleClose}
          topologies={topology}
          defaultValues={{
            avatar: avatarRef!,
            industryId: getDefaultId(topology.industry, storedIndustry!.name),
            roleId: getDefaultId(
              topology.customer_role,
              storedCustomerRole!.name,
            ),
            typeId: getDefaultId(
              topology.customer_type,
              storedCustomerType!.name,
            ),
            words: storedCustomerDescription ?? "",
            motivationId: storedMotivation!.topologyId!,
            intentId: storedMotivation!.intent!.topologyId!,
            subIntentId:
              storedMotivation!.intent?.intent?.topologyId ?? undefined,
            regionId: getOptionalDefaultId(
              topology.regions,
              storedRegion?.name,
            ),
            buyerTypeId: getOptionalDefaultId(
              topology.buyer_types,
              storedBuyerType?.name,
            ),
            statusId: getOptionalDefaultId(
              topology.priorContact,
              storedPriorContact?.name,
            ),
            experienceDrivers:
              structuredClone(storedExperienceDrivers as unknown as any[]) ??
              [],
            painpoints:
              storedPainpoints?.map((p) =>
                getDefaultId(topology.painpoints, p.name),
              ) ?? [],
            journeyType: storedJourneyType as InsightsFormValues["journeyType"],
          }}
        />
      ),
    });
  }

  return null;
};
