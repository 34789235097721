import React from "react";
import { EnthusiasticReaction } from "./EnthusiasticReaction";
import { HappyReaction } from "./HappyReaction";
import { SatisfiedReaction } from "./SatisfiedReaction";
import { FrustratedReaction } from "./FrustratedReaction";
import { ConfusedReaction } from "./ConfusedReaction";
import { AngryReaction } from "./AngryReaction";

export enum Reaction {
  ENTHUSIASTIC = "enthusiastic",
  HAPPY = "happy",
  SATISFIED = "satisfied",
  FRUSTRATED = "frustrated",
  CONFUSED = "confused",
  ANGRY = "angry",
}

interface ReactionIconProps {
  reaction?: Reaction;
}

/**
 *  ReactionIcon component to display the reaction icon.
 * @param {ReactionIconProps} props - The props of the component.
 */
export const ReactionIcon: React.FC<ReactionIconProps> = ({ reaction }) => {
  switch (reaction) {
    case Reaction.ENTHUSIASTIC:
      return <EnthusiasticReaction />;
    case Reaction.HAPPY:
      return <HappyReaction />;
    case Reaction.SATISFIED:
      return <SatisfiedReaction />;
    case Reaction.FRUSTRATED:
      return <FrustratedReaction />;
    case Reaction.CONFUSED:
      return <ConfusedReaction />;
    case Reaction.ANGRY:
      return <AngryReaction />;
    default:
      return null;
  }
};
