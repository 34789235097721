import { FC } from "react";

interface HeadlineProps {
  text: string;
}

const Headline: React.FC<HeadlineProps> = ({ text }) => (
  <div className="w-full align-left shrink-0">
    <h2 className="mt-5 text-l font-bold">Create your customer</h2>
    <h1 className="mt-3 text-2xl font-bold">{text}</h1>
  </div>
);

export default Headline;
