import { Journey } from "@/utils/apollo/resolvers";
import { Button, notify } from "@motius/customer-heartbeat-ui";
import { AvatarType } from "@motius/customer-heartbeat-utils/schemas";
import { Link, useLocation } from "react-router-dom";
import { JourneyCard } from "./JourneyCard";
import { IxDropdownButton, IxDropdownItem } from "@siemens/ix-react";
import { JourneyGrid } from "./JourneyGrid";
import React from "react";
import { useUserDetails } from "@motius/customer-heartbeat-utils/auth";

interface JourneysProps {
  journeys: Journey[];
  onDelete: (id: string) => void;
  onUnfavorite?: () => void;
}

export const PublishedJourneys: React.FC<JourneysProps> = ({
  journeys,
  onDelete,
  onUnfavorite,
}) => {
  const location = useLocation();
  const userDetails = useUserDetails();
  /**
   * @param id of the journey that will be shared
   */
  const handleCopyLink = async (id: string) => {
    await navigator.clipboard.writeText(
      `${window.location.host}/journeys/${id}/view`,
    );
    notify.success("Link copied!");
  };

  return (
    <JourneyGrid>
      {journeys.map((journey: Journey) => {
        const isOwner = journey.ownerGid === userDetails.gid;
        return (
          <li key={journey.id}>
            <JourneyCard
              customer={{
                industry: journey.industry?.name ?? "",
                role: journey.customerRole?.name ?? "",
                type: journey?.customerType?.name ?? "",
                motivation: journey.motivation?.name ?? "",
                intent: journey?.motivation?.intent?.name ?? "",
                avatar: (journey.avatarRef ?? AvatarType.Second) as AvatarType,
                words: journey.description ?? "",
                region: journey?.region?.name,
                ownerName: journey.ownerName,
              }}
              date={journey.lastSavedDate}
              commentCount={journey.comments?.length}
              journeyType={journey.journeyType}
              showFavorite
              favoritedByCurrentUser={journey.favoritedByCurrentUser}
              journeyId={journey.id}
              onUnfavorite={onUnfavorite}
              viewCount={journey.views}
              actions={
                <>
                  {isOwner ? (
                    <IxDropdownButton label="More" outline placement="top-end">
                      <IxDropdownItem
                        onClick={() => handleCopyLink(journey.id)}
                        label="Share"
                        icon="share"
                        className="w-44"
                      ></IxDropdownItem>
                      <IxDropdownItem
                        onClick={() => onDelete(journey.id)}
                        label="Delete"
                        icon="trashcan"
                      />
                    </IxDropdownButton>
                  ) : (
                    <Button
                      variant="primary"
                      color="inverted"
                      outline
                      onClick={() => handleCopyLink(journey.id)}
                    >
                      Share
                    </Button>
                  )}
                  <Link
                    to={`/journeys/${journey.id}/view`}
                    state={{ prevLocation: location }}
                  >
                    <Button variant="primary" color="inverted" outline>
                      View
                    </Button>
                  </Link>
                </>
              }
            />
          </li>
        );
      })}
    </JourneyGrid>
  );
};
