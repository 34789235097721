import { cn } from "@motius/customer-heartbeat-ui/utils";
import { PropsWithChildren } from "react";

interface SummarySectionProps extends PropsWithChildren {
  className?: string;
}

export const SummarySection: React.FC<SummarySectionProps> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cn(
        "px-2 py-[0.75rem] border-b w-full flex flex-col items-start text-left",
        className,
      )}
    >
      {children}
    </div>
  );
};
