import React from "react";
import { FieldError } from "react-hook-form";

interface InputErrorProps {
  error?: FieldError;
}

export const InputError: React.FC<InputErrorProps> = ({ error }) => {
  if (!error || !error.message) return null;

  return (
    <p role="alert" className="text-error text-[10px]">
      {error.message}
    </p>
  );
};
