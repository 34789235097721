import { useChannelStore } from "@/src/hooks/useChannelStore";
import { notify } from "@motius/customer-heartbeat-ui";
import { showMessage } from "@siemens/ix";
import { IxIconButton } from "@siemens/ix-react";
import React from "react";

interface RemoveLastButtonProps {
  show?: boolean;
  type?: "touchpoint" | "interaction";
}

export const RemoveLastButton: React.FC<RemoveLastButtonProps> = ({
  show,
  type,
}) => {
  const { removeLast } = useChannelStore();
  if (!show) return null;

  /**
   * Handles the delete journey action
   * @param id - the journey id
   */
  const handleRemove = async () => {
    // show a confirmation dialog
    (
      await showMessage({
        icon: "",
        messageTitle: `Do you really want to delete your ${type}?`,
        message: "Your changes will be lost if you proceed.",
        actions: [
          { id: "cancel", type: "cancel", text: "Cancel" },
          { id: "proceed", type: "okay", text: "Proceed" },
        ],
      })
    ).once((result) => {
      if (result?.actionId === "proceed") {
        // if the result has a payload then delete was clicked
        removeLast();
        notify.success(`Your ${type} has been deleted.`);
      }
    });
  };
  return (
    <IxIconButton
      icon="close"
      ghost
      size="24"
      className="ml-1 scale-75"
      onClick={handleRemove}
    />
  );
};
