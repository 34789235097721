import React from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { PainpointInput } from "../components/PainpointInput";
import { Topology } from "@motius/customer-heartbeat-utils/schemas";
import { Stepper } from "@motius/customer-heartbeat-ui";
import { CompleteDetailsStep, openDialogOrStep } from "@/utils/router";
import { useSearchParams } from "react-router-dom";
import { IxTypography } from "@siemens/ix-react";

interface CompleteDetailsPainpointsStepProps {
  painpointTopologies: Topology[];
}

/**
 *  Complete Details Experience Driver Step
 * @param props - Props
 * @param props.experienceDriverTopologies - Experience Driver Topologies
 * @param props.painpointTopologies - Painpoint Topologies
 */
export const CompleteDetailsPainpointsStep: React.FC<
  CompleteDetailsPainpointsStepProps
> = ({ painpointTopologies }) => {
  const { isValid } = useFormState();
  const [, setSearchParams] = useSearchParams();
  const { trigger } = useFormContext();

  /**
   *  Handle Previous Button Click
   */
  const prevClick = () => {
    openDialogOrStep(setSearchParams, CompleteDetailsStep.Second);
  };

  const handleNext = async () => {
    if (await trigger()) {
      openDialogOrStep(setSearchParams, CompleteDetailsStep.Fourth);
    }
  };

  return (
    <div className="flex size-full grow flex-col justify-between">
      <div className="flex w-full flex-col justify-between">
        <IxTypography format="h2">
          What were your customer's painpoints
        </IxTypography>
        <p className="mb-3 mt-2">
          If your customer had any painpoints during their <br></br>journey, you
          can add them here.
        </p>
        <div className="w-full grow">
          <Controller
            name="painpoints"
            render={({ field }) => (
              <PainpointInput
                value={field.value}
                painpointTopologies={painpointTopologies}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </div>

      <div className="w-full">
        <Stepper
          onBackClick={prevClick}
          onNextClick={handleNext}
          nextButtonText="Next"
          isNextDisabled={!isValid}
          steps={5}
          activeStep={2}
        />
      </div>
    </div>
  );
};
