import { z } from "zod";
import { Topology, topologySchema } from "./topology";
import {
  ExperienceDriverRating,
  JourneyTypeBadge,
} from "@motius/api-schema/src/server/resolvers-types";
export enum AvatarType {
  First = "first",
  Second = "second",
  Third = "third",
  Fourth = "fourth",
  Fifth = "fifth",
  Sixth = "sixth",
  Seventh = "seventh",
  Eighth = "eighth",
  Ninth = "ninth",
}

export type IntentTopology = Topology & { intents: IntentTopology[] };

export const intentTopologySchema: z.ZodType<IntentTopology> =
  topologySchema.extend({
    intents: z.lazy(() => intentTopologySchema.array()),
  });

export const motivationTopologySchema = topologySchema.extend({
  intents: intentTopologySchema.array(),
});

export type MotivationTopology = z.infer<typeof motivationTopologySchema>;

export const customerFormSchema = z.object({
  roleId: z
    .string({ required_error: "Customer role must be selected to continue" })
    .min(1, "Customer role must be selected to continue"),
  avatar: z.nativeEnum(AvatarType, {
    required_error: "Avatar must be selected to continue",
  }),
  typeId: z
    .string({ required_error: "Customer type must be selected to continue" })
    .min(1, "Customer type must be selected to continue"),
  industryId: z
    .string({ required_error: "Industry must be selected to continue" })
    .min(1, "Industry must be selected to continue"),
  words: z
    .string({ required_error: "Words must be entered to continue" })
    .max(120)
    .min(1, "Words must be entered to continue"),
  motivationId: z
    .string({ required_error: "Motivation must be selected to continue" })
    .min(1, "Motivation must be selected to continue"),
  intent: z.object({
    parents: z.string().array().optional(),
    intentId: z.string(),
  }),
});

export type CustomerFormValues = z.infer<typeof customerFormSchema>;

export const customerSchema = z.object({
  roleId: z.string(),
  avatar: z.nativeEnum(AvatarType),
  typeId: z.string(),
  industryId: z.string(),
  words: z.string().max(120).min(1),
  motivation: z.string(),
  intent: z.string(),
});

/**
 * this is the result of the prepare your journey query
 * the user selects ids for most fields
 */
export type Customer = z.infer<typeof customerSchema>;

export type CustomerLabels = {
  role: string;
  avatar: AvatarType;
  type: string;
  industry: string;
  words: string;
  motivation: string;
  intent: string;
  region?: string;
};

export const editCustomerFormSchema = customerFormSchema
  .extend({
    regionId: z.string().optional(),
    buyerTypeId: z.string().optional(),
    statusId: z.string().optional(),
    intentId: z.string(),
    subIntentId: z.string().optional(),
  })
  .omit({ intent: true });

export type EditCustomerFormValues = z.infer<typeof editCustomerFormSchema>;

export const insightsFormSchema = editCustomerFormSchema.extend({
  experienceDrivers: z
    .object(
      {
        id: z.string(),
        rating: z.nativeEnum(ExperienceDriverRating).optional().nullable(),
        name: z.string(),
        topologyId: z.string(),
      },
      {
        required_error: "Experience driver must be selected to continue",
      }
    )
    .array()
    .min(1, "Experience driver must be selected to continue")
    .default([]),
  painpoints: z.string().array().default([]),
  regionId: z
    .string({ required_error: "Region must be selected to continue" })
    .min(1, "Region must be selected to continue"),
  journeyType: z
    .object({
      badge: z.enum([
        JourneyTypeBadge.ClickPathAnalysis,
        JourneyTypeBadge.CustomerResearch,
        JourneyTypeBadge.CustomerSupportFeedback,
        JourneyTypeBadge.DirectCustomerFeedback,
        JourneyTypeBadge.ProductReviewsAndRatings,
      ]),
      description: z.string().optional(),
    })
    .or(
      z.object({
        badge: z.enum([JourneyTypeBadge.Other]),
        description: z.string().min(1),
      })
    ),
});

export type InsightsFormValues = z.infer<typeof insightsFormSchema>;
