import React from "react";
import { Controller, useFormContext, useFormState } from "react-hook-form";
import { Stepper } from "@motius/customer-heartbeat-ui";
import { CompleteDetailsStep, openDialogOrStep } from "@/utils/router";
import { useSearchParams } from "react-router-dom";
import { IxTypography } from "@siemens/ix-react";
import { JourneyTypeBadge } from "@/utils/apollo/resolvers";

interface CompleteDetailsJourneyTypeStepProps {}

export const JourneyTypeOptions = [
  { value: JourneyTypeBadge.ClickPathAnalysis, label: "Click Path Analysis" },
  {
    value: JourneyTypeBadge.DirectCustomerFeedback,
    label: "Direct Customer Feedback",
  },
  {
    value: JourneyTypeBadge.CustomerSupportFeedback,
    label: "Customer Support Feedback",
  },
  { value: JourneyTypeBadge.CustomerResearch, label: "Customer Research" },
  {
    value: JourneyTypeBadge.ProductReviewsAndRatings,
    label: "Product Reviews and Ratings",
  },
  { value: JourneyTypeBadge.Other, label: "Other" },
];

/**
 *  Complete Details Experience Driver Step
 * @param props - Props
 * @param props.experienceDriverTopologies - Experience Driver Topologies
 * @param props.painpointTopologies - Painpoint Topologies
 */
export const CompleteDetailsJourneyTypeStep: React.FC<
  CompleteDetailsJourneyTypeStepProps
> = ({}) => {
  const { isValid } = useFormState();
  const [, setSearchParams] = useSearchParams();
  const { trigger } = useFormContext();

  /**
   *  Handle Previous Button Click
   */
  const prevClick = () => {
    openDialogOrStep(setSearchParams, CompleteDetailsStep.Third);
  };

  const handleNext = async () => {
    if (await trigger()) {
      openDialogOrStep(setSearchParams, CompleteDetailsStep.Fifth);
    }
  };

  return (
    <div className="flex size-full grow flex-col items-center justify-between">
      <div className="flex w-full flex-col justify-between">
        <IxTypography format="h2">
          One last question: What triggered the creation of your journey?
        </IxTypography>
        <p className="mt-1.5 leading-5 w-80">
          Help us understand the main reason for creating your journey today.
          Please select one of the options below:
        </p>
      </div>
      <div className="w-full grow pb-[1.875rem] pt-4">
        <Controller
          name="journeyType"
          render={({ field }) => {
            /**
             *  Handle Change
             * @param e - Event
             */
            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation();
              field.onChange({
                badge: e.target.value,
                description: "",
              });
            };
            return (
              <div
                className="radio-button flex flex-col gap-2"
                onChange={handleChange}
              >
                {JourneyTypeOptions.map((option) => (
                  <>
                    <input
                      checked={field.value?.badge === option.value}
                      id={`journey_type_radio_${option.value}`}
                      name="journey_type_radio_button_group"
                      type="radio"
                      value={option.value}
                    />
                    <label
                      className="!items-center"
                      htmlFor={`journey_type_radio_${option.value}`}
                    >
                      {option.label}
                    </label>
                    {option.value === JourneyTypeBadge.Other &&
                      field.value?.badge === JourneyTypeBadge.Other && (
                        <input
                          className="ml-[1.8125rem] w-[12.5rem] pl-2"
                          type="text"
                          placeholder="Please specify"
                          value={field.value?.other}
                          onChange={(e) => {
                            e.stopPropagation();
                            field.onChange({
                              badge: JourneyTypeBadge.Other,
                              description: e.target.value,
                            });
                          }}
                        />
                      )}
                  </>
                ))}
              </div>
            );
          }}
        />
      </div>

      <div className="w-full">
        <Stepper
          onBackClick={prevClick}
          onNextClick={handleNext}
          nextButtonText="Next"
          isNextDisabled={!isValid}
          steps={5}
          activeStep={3}
        />
      </div>
    </div>
  );
};
