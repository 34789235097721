import { useChannelStore } from "../../../hooks/useChannelStore";
import { SummarySection } from "./SummarySection";
import {
  ExperienceDriver,
  ExperienceDriverRating,
} from "@motius/api-schema/src/server/resolvers-types";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import {
  thumbsDown,
  thumbsUp,
} from "../../CompleteDetails/components/ExperienceDriverCard";
import { useMemo } from "react";

/**
 *  Experience Driver Section
 */
export const ExperienceDriverSection = () => {
  const { experienceDrivers } = useChannelStore();

  const sorted = useMemo(() => {
    return [...(experienceDrivers || [])].sort((a: ExperienceDriver) => {
      if (a.rating === ExperienceDriverRating.Good) {
        return -1;
      } else {
        return 1;
      }
    });
  }, [experienceDrivers]);

  return (
    <SummarySection>
      <h1 className="mb-2 font-bold text-sm">Experience Driver</h1>
      <div>
        {sorted.map((driver: any) => (
          <div
            className={cn(
              "flex items-center gap-1 pl-[0.75rem] h-8",
              "border-l-4",
              {
                "border-l-theme-color-success":
                  driver.rating === ExperienceDriverRating.Good,
                "border-l-theme-color-alarm":
                  driver.rating === ExperienceDriverRating.Bad,
              },
            )}
            key={driver.id}
          >
            <div className="size-4">
              {driver.rating === ExperienceDriverRating.Good
                ? thumbsUp("fill-black scale-125 !opacity-100")
                : thumbsDown("fill-black scale-125 !opacity-100")}
            </div>
            <div className="pl-1 leading-5">{driver.name}</div>
          </div>
        ))}
      </div>
    </SummarySection>
  );
};
