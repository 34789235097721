export const ANIMATION = {
  v: "4.8.0",
  meta: { g: "LottieFiles AE 3.5.8", a: "", k: "", d: "", tc: "" },
  fr: 30,
  ip: 0,
  op: 162,
  w: 1920,
  h: 1920,
  nm: "logo_animation_update",
  ddd: 0,
  assets: [
    {
      id: "image_0",
      w: 1920,
      h: 1920,
      u: "/",
      p: "animation_1.png",
      e: 0,
    },
    {
      id: "image_1",
      w: 1920,
      h: 1920,
      u: "/",
      p: "animation_2.png",
      e: 0,
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: "Shape Layer 4",
      parent: 4,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-784, -534.5, 0], ix: 2 },
        a: { a: 0, k: [-784, -534.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.963, 0.963, 0.667], y: [0.532, 0.532, 1] },
              o: { x: [0.664, 0.664, 0.333], y: [-0.179, -0.179, 0] },
              t: 34,
              s: [100, 100, 100],
            },
            { t: 42, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [184, 184], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.537254961799, 0.537254961799, 0.537254961799, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 34,
                    s: [1, 1, 1, 1],
                  },
                  { t: 50, s: [0, 1, 0.725494384766, 1] },
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [-784, -534.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [98.347, 98.347], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 1153,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: "Shape Layer 1",
      parent: 5,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [960, 855.5, 0], ix: 2 },
        a: { a: 0, k: [-784, -534.5, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [184, 184], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.537254961799, 0.537254961799, 0.537254961799, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0, 1, 0.725494384766, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [-784, -534.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [134.854, 134.854], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 1153,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 2,
      nm: "location-filled.ai",
      cl: "ai",
      refId: "image_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.702], y: [1] },
              o: { x: [0.17], y: [-13.848] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.455], y: [1] },
              o: { x: [0.114], y: [1.686] },
              t: 9,
              s: [0],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 18,
              s: [0],
            },
            {
              i: { x: [0.432], y: [1.322] },
              o: { x: [0.019], y: [0.81] },
              t: 34,
              s: [0],
            },
            {
              i: { x: [0.321], y: [1] },
              o: { x: [0.083], y: [-10.911] },
              t: 50,
              s: [90],
            },
            {
              i: { x: [0.462], y: [1] },
              o: { x: [0.033], y: [0.948] },
              t: 84,
              s: [90],
            },
            { t: 115, s: [-30], h: 1 },
          ],
          ix: 10,
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.835], y: [1] },
                o: { x: [0.017], y: [2.537] },
                t: 0,
                s: [749.714],
              },
              {
                i: { x: [0.996], y: [-3.738] },
                o: { x: [0.238], y: [0] },
                t: 18,
                s: [681.714],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.122], y: [1.369] },
                t: 34,
                s: [681.714],
              },
              {
                i: { x: [0.29], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 50,
                s: [783.714],
              },
              {
                i: { x: [0.507], y: [0.996] },
                o: { x: [0.012], y: [0.669] },
                t: 84,
                s: [783.714],
              },
              { t: 115, s: [742.214] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.122], y: [1.552] },
                t: 34,
                s: [714.631],
              },
              {
                i: { x: [0.432], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 50,
                s: [852.631],
              },
              {
                i: { x: [0.532], y: [1.002] },
                o: { x: [0.012], y: [0.566] },
                t: 84,
                s: [852.631],
              },
              { t: 115, s: [970.631] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [960, 905.455, 0], ix: 1 },
        s: { a: 0, k: [110, 110, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 1153,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: "Shape Layer 3",
      parent: 7,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [-784, -534.5, 0], ix: 2 },
        a: { a: 0, k: [-784, -534.5, 0], ix: 1 },
        s: {
          a: 1,
          k: [
            {
              i: { x: [0.963, 0.963, 0.667], y: [0.532, 0.532, 1] },
              o: { x: [0.664, 0.664, 0.333], y: [-0.179, -0.179, 0] },
              t: 34,
              s: [100, 100, 100],
            },
            { t: 42, s: [0, 0, 100] },
          ],
          ix: 6,
        },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [184, 184], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.537254961799, 0.537254961799, 0.537254961799, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: {
                a: 1,
                k: [
                  {
                    i: { x: [0.833], y: [0.833] },
                    o: { x: [0.167], y: [0.167] },
                    t: 34,
                    s: [1, 1, 1, 1],
                  },
                  { t: 50, s: [0.501953125, 0.360778808594, 1, 1] },
                ],
                ix: 4,
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [-784, -534.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [98.347, 98.347], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 1153,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 4,
      nm: "Shape Layer 2",
      parent: 8,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [1744, 1390.5, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: "gr",
          it: [
            {
              d: 1,
              ty: "el",
              s: { a: 0, k: [184, 184], ix: 2 },
              p: { a: 0, k: [0, 0], ix: 3 },
              nm: "Ellipse Path 1",
              mn: "ADBE Vector Shape - Ellipse",
              hd: false,
            },
            {
              ty: "st",
              c: {
                a: 0,
                k: [0.537254961799, 0.537254961799, 0.537254961799, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 0, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: "Stroke 1",
              mn: "ADBE Vector Graphic - Stroke",
              hd: false,
            },
            {
              ty: "fl",
              c: { a: 0, k: [0.501953125, 0.360778808594, 1, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: "Fill 1",
              mn: "ADBE Vector Graphic - Fill",
              hd: false,
            },
            {
              ty: "tr",
              p: { a: 0, k: [-784, -534.5], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [122.03, 122.03], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: "Transform",
            },
          ],
          nm: "Ellipse 1",
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: "ADBE Vector Group",
          hd: false,
        },
      ],
      ip: 0,
      op: 1153,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 2,
      nm: "location-filled-1.ai",
      cl: "ai",
      refId: "image_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.066], y: [16.256] },
              t: 0,
              s: [0],
            },
            {
              i: { x: [0.505], y: [1] },
              o: { x: [0.102], y: [-4.214] },
              t: 9,
              s: [0],
            },
            {
              i: { x: [0.833], y: [1] },
              o: { x: [0.333], y: [0] },
              t: 18,
              s: [0],
            },
            {
              i: { x: [0.656], y: [1.287] },
              o: { x: [0.085], y: [0.909] },
              t: 34,
              s: [0],
            },
            {
              i: { x: [0.16], y: [1] },
              o: { x: [0.021], y: [4.019] },
              t: 50,
              s: [-90],
            },
            {
              i: { x: [0.548], y: [1] },
              o: { x: [0.03], y: [0.905] },
              t: 84,
              s: [-90],
            },
            { t: 115, s: [30], h: 1 },
          ],
          ix: 10,
        },
        p: {
          s: true,
          x: {
            a: 1,
            k: [
              {
                i: { x: [0.594], y: [1] },
                o: { x: [0.191], y: [1.553] },
                t: 0,
                s: [1155.714],
              },
              {
                i: { x: [0.999], y: [5.495] },
                o: { x: [0.028], y: [0] },
                t: 18,
                s: [1271.714],
              },
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.027], y: [1.462] },
                t: 34,
                s: [1271.714],
              },
              {
                i: { x: [0.999], y: [-0.909] },
                o: { x: [0.333], y: [0] },
                t: 50,
                s: [1121.714],
              },
              {
                i: { x: [0.505], y: [1] },
                o: { x: [0.02], y: [0.866] },
                t: 84,
                s: [1121.714],
              },
              { t: 115, s: [1148.714] },
            ],
            ix: 3,
          },
          y: {
            a: 1,
            k: [
              {
                i: { x: [0.667], y: [1] },
                o: { x: [0.128], y: [2.552] },
                t: 34,
                s: [1122.702],
              },
              {
                i: { x: [0.918], y: [1] },
                o: { x: [0.333], y: [0] },
                t: 50,
                s: [1160.702],
              },
              {
                i: { x: [0.145], y: [1] },
                o: { x: [0.043], y: [0] },
                t: 84,
                s: [1160.702],
              },
              {
                i: { x: [0.861], y: [1] },
                o: { x: [0.35], y: [0] },
                t: 98.467,
                s: [971.702],
              },
              { t: 115, s: [971.702] },
            ],
            ix: 4,
          },
        },
        a: { a: 0, k: [960, 905.455, 0], ix: 1 },
        s: { a: 0, k: [110, 110, 100], ix: 6 },
      },
      ao: 0,
      ip: 0,
      op: 1153,
      st: 0,
      bm: 0,
    },
  ],
  markers: [
    { tm: 35, cm: "1", dr: 0 },
    { tm: 87, cm: "2", dr: 0 },
    { tm: 133, cm: "3", dr: 0 },
  ],
};
