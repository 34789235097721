import { cn } from "@motius/customer-heartbeat-ui/utils";
import { IxTypography, IxIconButton, IxTooltip } from "@siemens/ix-react";
import React from "react";
import { NavigationTooltip } from "./Tooltips/NavigationTooltipContent";

interface SectionProps {
  hasSeparator?: boolean;
  headline: string;
  children: React.ReactNode;
  headlineDescription?: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({
  hasSeparator = true,
  headline,
  children,
  headlineDescription,
}) => {
  return (
    <>
      {hasSeparator && <hr className="my-3 text-[#e8e8e326] opacity-100" />}
      <div className="relative mb-2 flex items-center justify-between">
        <IxTypography className="text-white" format="label-sm" bold>
          {headline}
        </IxTypography>
        <NavigationTooltip
          id={`tooltip-section-${headline}`}
          content={headlineDescription}
        />
      </div>
      <div className="flex flex-col gap-2">{children}</div>
    </>
  );
};

export default Section;
