import React from "react";
import {
  ExperienceDriver,
  ExperienceDriverRating,
  ExperienceDriverTopology,
} from "@motius/api-schema/src/server/resolvers-types";
import { ExperienceDriverCard } from "./ExperienceDriverCard";
import { v4 } from "uuid";

interface ExperienceDriverInputProps {
  value: ExperienceDriver[];
  onChange: (value: ExperienceDriver[]) => void;
  experienceDriverTopologies: ExperienceDriverTopology[];
}

export const ExperienceDriverInput: React.FC<ExperienceDriverInputProps> = ({
  value,
  onChange,
  experienceDriverTopologies,
}) => {
  /**
   *  Handle Click
   * @param driver Driver
   * @param rating Rating
   */
  const handleClick = (
    driver: ExperienceDriver,
    rating: ExperienceDriverRating,
  ) => {
    const index = value.findIndex(
      (val) => val.topologyId === driver.topologyId,
    );
    if (index >= 0) {
      const temp = value[index];
      if (temp.rating === rating) {
        const copy = [...value];
        copy.splice(index, 1);
        onChange(copy);
      } else {
        temp.rating = rating;
        value[index] = temp;
        onChange(value);
      }
    } else {
      onChange([...value, { ...driver, rating }]);
    }
  };

  /**
   *  Handle Up
   * @param driver Driver
   */
  const handleUp = (driver: ExperienceDriver) => {
    handleClick(driver, ExperienceDriverRating.Good);
  };

  /**
   *  Handle Down
   * @param driver Driver
   */
  const handleDown = (driver: ExperienceDriver) => {
    handleClick(driver, ExperienceDriverRating.Bad);
  };

  const options: { driver: ExperienceDriver; tooltip: string }[] =
    experienceDriverTopologies.map((topoDriver) => {
      const driver: ExperienceDriver = {
        ...topoDriver,
        id: v4(),
        rating: value.find((val) => val.topologyId === topoDriver.id)?.rating,
        topologyId: topoDriver.id,
        __typename: "ExperienceDriver",
      };
      return {
        driver,
        tooltip: topoDriver.tooltip,
      };
    });

  return (
    <div className="grid size-full grid-cols-4 gap-2">
      {options.map((option) => {
        return (
          <ExperienceDriverCard
            key={option.driver.topologyId}
            onUp={handleUp}
            onDown={handleDown}
            driver={option.driver}
            tooltip={option.tooltip}
            currentDrivers={value}
          />
        );
      })}
    </div>
  );
};
