/* eslint-disable @next/next/no-img-element */
import {
  TopBarBase,
  ProfileIcon,
  Spinner,
} from "@motius/customer-heartbeat-ui";
import { IxApplication, IxTypography } from "@siemens/ix-react";
import { SideMenu } from "../components/Journeys/SideMenu";
import { cn } from "@motius/customer-heartbeat-ui/utils";
import { gql, useQuery } from "@apollo/client";
// @ts-expect-error This import works
import StatisticsImage from "../components/statistics.svg?react";

export const TOTAL_JOURNEYS = gql`
  query TotalJourneys($filter: JourneyFilter!, $orderBy: JourneyOrder!) {
    journeyList(filter: $filter, orderBy: $orderBy) {
      totalItems
    }
  }
`;

/**
 * Statistics page
 */
export const Statistics = () => {
  const { data, loading, error } = useQuery(TOTAL_JOURNEYS, {
    variables: {
      orderBy: { algorithm: "CREATED_DATE" },
      filter: { isPublished: true },
    },
    fetchPolicy: "network-only",
  });

  const totalJourneys = data?.journeyList?.totalItems || 0;
  let progressPercentage = Math.min((totalJourneys / 1000) * 100, 100);
  // 1% is too small and looks a bit weird
  // starting from around 2.4% it looks good
  progressPercentage = Math.max(progressPercentage, 2.4);

  if (error) return <div>Error: {error.message}</div>;

  return (
    <IxApplication>
      <TopBarBase>
        <ProfileIcon />
      </TopBarBase>
      <SideMenu />
      <div
        className="theme-brand-dark flex size-full flex-col p-3"
        data-testid="statistic-content"
      >
        <div className="flex size-full flex-col items-center justify-center bg-white">
          <div className="flex size-full flex-col items-center justify-center rounded-[5px] bg-siemens-dark">
            {loading ? (
              <Spinner />
            ) : (
              <>
                <div className="flex w-full">
                  <div className="xl:w-1/2 lg:w-auto flex w-24 min-w-0 shrink grow items-center justify-center px-2">
                    <StatisticsImage
                      className="h-auto w-[28.125rem]"
                      data-testid="statistic-image"
                    />
                  </div>
                  <div className="xl:w-1/2 xl:grow flex h-full w-[31.0625rem] flex-col items-start justify-center text-[var(--theme-color-std-text)]">
                    <div className="flex flex-col gap-4">
                      <div className="flex w-[25rem] flex-col">
                        <div className="flex flex-col gap-2">
                          <IxTypography
                            format="h4"
                            className="tracking-[0.006em]"
                          >
                            Customer Journey Goal for 2025
                          </IxTypography>
                          <div className="flex items-center gap-2">
                            <IxTypography format="h2">
                              {totalJourneys}{" "}
                              <span className="text-theme-color-soft-text">
                                / 1000
                              </span>
                            </IxTypography>
                            <div
                              className={cn(
                                "h-4 grow bg-theme-color-ghost-alt--selected-hover ml-1 rounded-[2.3125rem]",
                              )}
                              role="progressbar"
                              aria-label="Progress bar"
                            >
                              <div
                                className={cn(
                                  "h-full bg-theme-color-component-9--active",
                                  "opacity-80",
                                  "rounded-l-[2.3125rem]",
                                )}
                                style={{
                                  width: `${progressPercentage}%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="flex w-[25rem] flex-col">
                        <IxTypography
                          format="h4"
                          className="tracking-[0.006rem]"
                        >
                          Coming soon...
                        </IxTypography>
                        <IxTypography
                          format="body"
                          className="tracking-[0.012rem]"
                        >
                          This space will soon show more <br /> statistics and
                          insights.
                        </IxTypography>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </IxApplication>
  );
};
