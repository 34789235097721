import {
  ExpectedNextSelection,
  useChannelStore,
} from "@/src/hooks/useChannelStore";
import NavigationSelectAction, {
  LocationFragment,
} from "./NavigationSelectAction";
import NavigationTouchpoints from "./NavigationTouchpoints";
import NavigationLocations from "./NavigationLocations";
import NavigationChannels from "./NavigationChannels";
import useTopology from "./useJourneyTopology";
import { TransitionType } from "@/utils/apollo/resolvers";
import React from "react";
import { NavigationTouchpointsReturn } from "./NavigationTouchpointReturn";

export const Navigation = () => {
  // eslint-disable-next-line no-undef
  const [locationIntroFragment, setLocationIntroFragment] = React.useState<
    LocationFragment | undefined
  >(undefined);

  const { expectedNextSelection, currentTransition, journeyTopologyId } =
    useChannelStore();
  const { journeyTopology, locations } = useTopology(journeyTopologyId);

  switch (expectedNextSelection) {
    case ExpectedNextSelection.Touchpoint:
      if (currentTransition === TransitionType.Return) {
        return <NavigationTouchpointsReturn />;
      } else return <NavigationTouchpoints />;
    case ExpectedNextSelection.Location:
      return (
        <NavigationLocations
          introFragment={locationIntroFragment}
          availableLocations={locations}
        />
      );
    case ExpectedNextSelection.SelectAction:
      return (
        <NavigationSelectAction
          setLocationIntroFragment={setLocationIntroFragment}
        />
      );
    default:
      return <NavigationChannels journeyTopology={journeyTopology} />;
  }
};
